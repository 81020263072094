import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { generatePath, RouteComponentProps } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  DataTable,
  DisplayText,
  EmptyState,
  Layout,
  Page,
  Stack,
  TextStyle,
} from "@shopify/polaris";

import MaxAttemptsReached from "../../../assets/images/max-attempts-reached.jpg";
import api from "../../api";
import AddItemButton from "../../components/AddItemButton";
import ErrorPanel from "../../components/ErrorPanel";
import OwnerModal, { OwnerModalData } from "../../components/modals/OwnerModal";
import RoleModal, { RoleModalData } from "../../components/modals/RoleModal";
import BackButton from "../../components/navigation/BackButton";
import ScreeningSkeletonPage from "../../components/screening/ScreeningSkeletonPage";
import ScreeningStatusBadge from "../../components/screening/ScreeningStatusBadge";
import ScreeningTypeCard from "../../components/screening/ScreeningTypeCard";
import StopScreeningModal from "../../components/screening/StopScreeningModal";
import useRoleOrOwnerRow from "../../components/screening/useRoleOrOwnerRow";
import SectionMargin from "../../components/SectionMargin";
import { ROUTES } from "../../constants/routes";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useDefaultPersonDetails from "../../hooks/useDefaultPersonDetails";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetCompanyScreeningConfiguration from "../../hooks/useGetCompanyScreeningConfiguration";
import { IDParams } from "../../types/params";
import { SelectionMap } from "../../types/utilities";
import ErrorPage from "../ErrorPage";

const CompanyChangeScreening: React.FC<RouteComponentProps<IDParams>> = ({ history, match }) => {
  const id = match.params.id;

  const defaultCountry = useDefaultCountry();

  const [postError, setPostError] = useState();
  const [isPosting, setPosting] = useState(false);

  const [customerName, setCustomerName] = useState("");

  const [owners, setOwners] = useState<api.Owner[]>([]);
  const [roles, setRoles] = useState<api.Role[]>([]);

  const [modalOwner, setModalOwner] = useState<OwnerModalData>();
  const [modalRole, setModalRole] = useState<RoleModalData>();

  const [screeningType, setScreeningType] = useState<api.ScreeningType>("MONITORING");
  const [screeningStatus, setScreeningStatus] = useState<api.ScreeningStatus>("MONITORING");
  const [isNewScreening, setIsNewScreening] = useState(false);

  const [isControlConfirmationActive, setControlConfirmationActive] = useState(false);
  const [selectedOwners, setSelectedOwners] = useState<SelectionMap>({});
  const [selectedRoles, setSelectedRoles] = useState<SelectionMap>({});

  const [isAllOwners, setAllOwners] = useState(false);
  const [isAllRoles, setAllRoles] = useState(false);

  const f = useFormatMessage();
  const features = useFeatures();
  const renderRow = useRoleOrOwnerRow();

  const toggleAllOwners = (newValue: boolean) => {
    setAllOwners(newValue);

    const newSelectedOwners: typeof selectedOwners = {};

    if (newValue) {
      owners.forEach((o) => {
        newSelectedOwners[o.id!] = true;
      });
    } else {
      owners.forEach((o) => {
        if (o.is_ubo) newSelectedOwners[o.id!] = true;
      });
    }

    setSelectedOwners(newSelectedOwners);
  };

  const toggleAllRoles = (newValue: boolean) => {
    setAllRoles(newValue);

    const newSelectedRoles: typeof selectedRoles = {};

    if (newValue) {
      roles.forEach((r) => {
        newSelectedRoles[r.id!] = true;
      });
    }

    setSelectedRoles(newSelectedRoles);
  };

  const updateOwners = (id: string, newValue: boolean) => {
    setAllOwners(false);
    const tmpOwners = Object.assign({}, selectedOwners);
    tmpOwners[id] = newValue;
    setSelectedOwners(tmpOwners);
  };

  const updateRoles = (id: string, newValue: boolean) => {
    setAllRoles(false);
    const tmpRoles = Object.assign({}, selectedRoles);
    tmpRoles[id] = newValue;
    setSelectedRoles(tmpRoles);
  };

  // Get screening config and company customer details
  const companyCustomerDetailsQuery = useQuery(["company-details", id], () => api.getCompanyCustomerDetails(id));
  const companyScreeningConfigQuery = useGetCompanyScreeningConfiguration(id);
  const customerCountry = companyCustomerDetailsQuery.data?.country;
  const newPersonDetails = useDefaultPersonDetails(companyCustomerDetailsQuery.data?.country);

  const closeModalRole = () => setModalRole(undefined);
  const closeModalOwner = () => setModalOwner(undefined);

  useEffect(() => {
    if (companyScreeningConfigQuery.isLoading || !companyScreeningConfigQuery.data) {
      return;
    }

    const tmp = companyScreeningConfigQuery.data;

    setCustomerName(tmp.customer_name);

    const { beneficial_owners: tmp_owners, roles: tmp_roles } = tmp;

    setOwners(tmp_owners.map((o) => o.owner));
    setRoles(tmp_roles.map((r) => r.role));

    const tmp_selectedOwners: typeof selectedOwners = {};
    tmp_owners.forEach((o) => (tmp_selectedOwners[o.owner.id!] = o.owner.is_ubo || o.screening_required));
    setSelectedOwners(tmp_selectedOwners);

    const tmp_selectedRoles: typeof selectedRoles = {};
    tmp_roles.forEach((r) => (tmp_selectedRoles[r.role.id!] = r.screening_required));
    setSelectedRoles(tmp_selectedRoles);

    if (tmp.screening_type) {
      setScreeningType(tmp.screening_type);
      setScreeningStatus(tmp.screening_status);
      setIsNewScreening(tmp.screening_status === "DONE");
    }
  }, [companyScreeningConfigQuery.data, companyScreeningConfigQuery.isLoading]);

  if (companyScreeningConfigQuery.isError || companyCustomerDetailsQuery.isError) {
    if (companyScreeningConfigQuery.isMaxRetriesReached) {
      return (
        <EmptyState
          heading={f("screening.errors.heading")}
          action={{
            content: f("screening.errors.content"),
            onAction: () => window.location.reload(),
          }}
          image={MaxAttemptsReached}
          fullWidth={true}
        />
      );
    } else {
      return <ErrorPage error={companyScreeningConfigQuery.error || companyCustomerDetailsQuery.error} />;
    }
  }

  const displaySkeleton =
    companyScreeningConfigQuery.isLoading ||
    !companyScreeningConfigQuery.data ||
    companyCustomerDetailsQuery.isLoading ||
    !companyCustomerDetailsQuery.data;

  if (displaySkeleton) {
    return (
      <ScreeningSkeletonPage
        progress={companyScreeningConfigQuery.loadingProgress}
        message={f("screening.messages.retrieving-details")}
      />
    );
  }

  const companyCustomer = companyCustomerDetailsQuery.data;
  const canHaveShareholders = companyCustomer.can_have_shareholders === false ? false : true;
  const companyType = companyCustomer.company_type?.description_nb || companyCustomer.company_type?.code || "-";

  return (
    <Page>
      <Stack vertical>
        <BackButton url={generatePath(ROUTES.COMPANY_DETAILS, { id: id })} displayLabel={customerName} showAsLink />

        <DisplayText size="large">
          {!isNewScreening ? f("change-screening.titles.screening-title") : f("change-screening.titles.new-screening")}
        </DisplayText>

        <Layout>
          <Layout.Section>
            <Stack vertical>
              {!isNewScreening && (
                <Stack distribution="equalSpacing">
                  <Stack alignment="center" spacing="tight">
                    <TextStyle variation="strong">Status:</TextStyle>
                    <div>{f(`screening-type.${screeningType}`)}</div>
                    <ScreeningStatusBadge
                      screening={{
                        screening_status: screeningStatus,
                        screening_type: screeningType,
                      }}
                    />
                  </Stack>
                  <Button onClick={() => setControlConfirmationActive(true)}>
                    {f("change-screening.buttons.stop-monitoring")}
                  </Button>
                </Stack>
              )}

              <DisplayText size="small">{f("change-screening.titles.screening-owners")}</DisplayText>

              <Card>
                {!canHaveShareholders && (
                  <Card.Section>
                    {f("screening.cards.cannot-have-shareholders", { companyType: companyType })}
                  </Card.Section>
                )}

                {canHaveShareholders && (
                  <>
                    {!companyScreeningConfigQuery.isLoading && owners.length <= 0 ? (
                      <Card.Section>{f("screening.cards.no-owners")}</Card.Section>
                    ) : null}
                    {owners.length > 0 && (
                      <DataTable
                        hideScrollIndicator={true}
                        columnContentTypes={["text", "text", "text"]}
                        headings={[
                          <Checkbox checked={isAllOwners} onChange={toggleAllOwners} label="" labelHidden />,
                          <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
                          <TextStyle variation="subdued">{f("table.column.shares")}</TextStyle>,
                          "",
                        ]}
                        rows={owners.map((o) => {
                          const row = renderRow({
                            entity: o,
                            onEdit: (id) => {
                              let percent_share = 0;
                              try {
                                percent_share = parseFloat(o.percent_share || "0");
                              } catch (error) {
                                console.error(`Failed to parse percent_share: ${error}`);
                              }

                              const country = o.person.country_of_citizenship || customerCountry || defaultCountry;

                              setModalOwner({
                                id: id,
                                isManuallyCreated: Boolean(o.created_by),
                                comment: o.comment,
                                percent_share,
                                first_name: o.person.first_name,
                                last_name: o.person.last_name,
                                birth_date: o.person.birth_date,
                                country_of_citizenship: country,
                                address: o.person.address ? o.person.address : { country },
                              });
                            },

                            checked: selectedOwners[o.id!] === true,
                            onCheckboxChange: (id, newValue) => updateOwners(id, newValue),
                          });

                          return [row.checkbox, row.stack, row.div, row.editBtn];
                        })}
                      />
                    )}

                    <Card.Section>
                      <AddItemButton onClick={() => setModalOwner(newPersonDetails())}>
                        {f("screening.buttons.add-owner")}
                      </AddItemButton>
                    </Card.Section>
                  </>
                )}
              </Card>

              <SectionMargin size="medium" />
              <DisplayText size="small">{f("change-screening.titles.screening-roles")}</DisplayText>

              <Card>
                {!companyScreeningConfigQuery.isLoading && roles.length <= 0 && (
                  <Card.Section>{f("roles.messages.no-roles")}</Card.Section>
                )}
                {roles.length > 0 && (
                  <DataTable
                    hideScrollIndicator={true}
                    columnContentTypes={["text", "text", "text"]}
                    headings={[
                      <Checkbox checked={isAllRoles} onChange={toggleAllRoles} label="" labelHidden />,
                      <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
                      <TextStyle variation="subdued">{f("table.column.title")}</TextStyle>,
                      "",
                    ]}
                    rows={roles.map((r) => {
                      const row = renderRow({
                        entity: r,
                        onEdit: (id) => {
                          const country = r.person.country_of_citizenship || customerCountry || defaultCountry;
                          setModalRole({
                            id: id,
                            comment: r.comment,
                            type: r.type,
                            first_name: r.person.first_name,
                            last_name: r.person.last_name,
                            birth_date: r.person.birth_date,
                            country_of_citizenship: country,
                            address: r.person.address ? r.person.address : { country },
                            isManuallyCreated: Boolean(r.created_by),
                          });
                        },
                        checked: selectedRoles[r.id!],
                        allowUpdates: features.LEGAL_ENTITY_MONITORING,
                        onCheckboxChange: (id, newValue) => updateRoles(id, newValue),
                      });

                      return [row.checkbox, row.stack, row.div, row.editBtn];
                    })}
                  />
                )}

                <Card.Section>
                  <AddItemButton onClick={() => setModalRole(newPersonDetails())}>
                    {f("screening.buttons.add-role")}
                  </AddItemButton>
                </Card.Section>
              </Card>

              {isNewScreening && (
                <>
                  <SectionMargin size="medium" />
                  <DisplayText size="small">{f("change-screening.titles.choose-screening-type")}</DisplayText>
                </>
              )}

              {isNewScreening && (
                <ScreeningTypeCard
                  screeningType={screeningType}
                  onScreeningTypeChange={(newValue) => setScreeningType(newValue)}
                />
              )}
              {postError && <ErrorPanel message={postError} />}
              <Stack distribution="trailing">
                <Button
                  disabled={isPosting}
                  onClick={() => {
                    history.push(`/companies/${id}`);
                  }}
                >
                  {f("default.cancel")}
                </Button>

                <Button
                  primary
                  disabled={isPosting || !screeningType}
                  loading={isPosting}
                  onClick={() => {
                    setPosting(true);
                    api
                      .updateCompanyScreeningConfiguration(id, {
                        roles: Object.keys(selectedRoles).filter((r) => selectedRoles[r]),
                        owners: Object.keys(selectedOwners).filter((r) => selectedOwners[r]),
                        screening_type: screeningType,
                      })
                      .then(() => {
                        setPosting(false);
                        companyScreeningConfigQuery.invalidateQuery();

                        let snackbar_message = f("screening.snackbar.updated");
                        if (isNewScreening) {
                          snackbar_message = f("screening.snackbar.started");
                        }

                        history.push(`/companies/${id}`, { snackbar: snackbar_message });
                      })
                      .catch((error) => {
                        setPosting(false);
                        setPostError(error.toString());
                      });
                  }}
                >
                  {isNewScreening ? f("change-screening.buttons.start-screening") : f("change-screening.buttons.save")}
                </Button>
              </Stack>
            </Stack>
          </Layout.Section>
        </Layout>
      </Stack>
      {isControlConfirmationActive && (
        <StopScreeningModal
          id={id}
          isOpen={isControlConfirmationActive}
          isPerson={false}
          onStop={() => {
            setControlConfirmationActive(false);
            history.push(`/companies/${id}`, { snackbar: f("screening.snackbar.stopped") });
          }}
          onClose={() => {
            setControlConfirmationActive(false);
          }}
        />
      )}

      {modalOwner && (
        <OwnerModal
          id={id}
          dataOwner={modalOwner}
          onClose={closeModalOwner}
          isDeleteDisabled={true}
          onDelete={(deletedOwnerId) => {
            const i = owners.findIndex((o) => o.id == deletedOwnerId);
            if (i >= 0) {
              const minusOne = [...owners];
              minusOne.splice(i, i);
              setOwners(minusOne);
            }
            closeModalOwner();
          }}
          onUpdate={(updatedOwner) => {
            const ownerToUpdate = owners.find((o) => updatedOwner.id === o.id);
            if (ownerToUpdate) {
              Object.assign(ownerToUpdate, updatedOwner);
              setOwners(owners);
            }
            closeModalOwner();
          }}
          onCreate={(createdOwner) => {
            setOwners([createdOwner].concat(owners));

            if (createdOwner.is_ubo) {
              const tmpSelectedOwners = Object.assign({}, selectedOwners);
              tmpSelectedOwners[createdOwner.id!] = true;
              setSelectedOwners(tmpSelectedOwners);
            }

            closeModalOwner();
          }}
        />
      )}
      {modalRole && (
        <RoleModal
          id={id}
          dataRole={modalRole}
          onClose={closeModalRole}
          isDeleteDisabled={true}
          onDelete={(deletedRoleId) => {
            const i = roles.findIndex((r) => r.id == deletedRoleId);
            if (i >= 0) {
              const minusOne = [...roles];
              minusOne.splice(i, i);
              setRoles(minusOne);
            }
            closeModalRole();
          }}
          onUpdate={(updatedRole) => {
            const roleToUpdate = roles.find((r) => updatedRole.id === r.id);
            if (roleToUpdate) {
              // This way we propagate the change to the original `roles`
              // So the list of roles get updated
              Object.assign(roleToUpdate, updatedRole);
              setRoles(roles);
            }
            closeModalRole();
          }}
          onCreate={(createdRole) => {
            setRoles([createdRole].concat(roles));
            closeModalRole();
          }}
        />
      )}
    </Page>
  );
};

export default CompanyChangeScreening;
