import api from "../../api";
import { CRITICAL_COMPANY_DETAILS_FIELDS } from "../../constants/company-details";
import useFeatures from "../../hooks/useFeatures";

// returns true if there are critical, unreviewed updates to critical company details fields
const useCompanyHasCriticalUpdates = (companyDetails?: api.CompanyCustomerDetailResponse) => {
  const features = useFeatures();

  return (
    features.LEGAL_ENTITY_MONITORING &&
    companyDetails?.info_updates &&
    // find at least one info update
    companyDetails.info_updates.find((infoUpdate) => {
      return (
        !infoUpdate.is_reviewed &&
        // that contains updates to critical fields that were false and now are true
        infoUpdate.field_changes.find((fieldChange) => {
          const fieldName = fieldChange.field_name as keyof api.CompanyCustomerDetailResponse;
          return fieldChange.new && !fieldChange.old && CRITICAL_COMPANY_DETAILS_FIELDS.includes(fieldName);
        })
      );
    }) !== undefined
  );
};

export default useCompanyHasCriticalUpdates;
