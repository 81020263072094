import React from "react";
import { Stack, TextStyle } from "@shopify/polaris";

import SectionDivider from "./extensions/SectionDivider";
import SectionMargin from "./SectionMargin";

interface OnboardingSummarySectionProps {
  title: string;
  hide?: boolean;
}

const OnboardingSummarySection: React.FC<OnboardingSummarySectionProps> = (props) => {
  const { title, hide, children } = props;

  if (hide) {
    return null;
  }

  return (
    <Stack vertical>
      <SectionMargin size={"medium"} />
      <TextStyle variation="strong">{title}</TextStyle>
      {children}
      <SectionDivider />
    </Stack>
  );
};

export default OnboardingSummarySection;
