import api from "../api";
import { ONBOARDING_STATUSES } from "../constants/onboarding";

import { isEmptyString } from "./stringUtils";

const Util = {
  isEmail: (str?: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return Boolean(str) && re.test(String(str).toLowerCase());
  },
  caseLink: (id: string, type: api.CustomerType, status?: api.OnboardingStatus, isManual?: boolean): string => {
    let link = type === "company" ? "companies" : "persons";

    // If onboarding a company manually, the link should start with
    // "manual-companies"
    const isOnboarding = status && ONBOARDING_STATUSES.includes(status);
    if (isOnboarding && type === "company" && isManual) {
      link = "manual-companies";
    }

    switch (status) {
      case "FETCHING_DATA":
      case "SCREENING_CONFIG":
        link = `/${link}/${id}/screening`;
        break;
      case "SIGNATURE_EMAILS":
      case "SIGNATURE_CONFIG":
        if (type == "company") {
          link = `/${link}/${id}/request-onboarding-disclosures`;
        } else {
          link = `/${link}/${id}/request-onboarding-disclosure`;
        }
        break;
      case "READY":
        link = `/${link}/${id}/summary`;
        break;
      default:
        link = `/${link}/${id}`;
        break;
    }

    return link;
  },
  scrollTo: (id: string, options?: boolean | ScrollIntoViewOptions) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView(options);
    }
  },
  cleanupFilename: (filename: string) => {
    if (filename) {
      return filename.split("/").pop();
    }
    return filename;
  },
  capitalize: (sentence: string) => {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  },
  copyToClipboard: (text?: string) => {
    if (!text) return;

    navigator.clipboard.writeText(text);
  },
  parseWebsite: (www?: string): string | undefined => {
    if (!www) {
      return undefined;
    }

    if (www.startsWith("https") || www.startsWith("http")) {
      return www;
    } else {
      return `http://${www}`;
    }
  },
};

export function noop() {
  // do nothing!
}

export function isEmailEnabled(d: { send_email?: boolean }) {
  return d.send_email === undefined || d.send_email;
}

let lastId = 0;

export function uniqueId(prefix = "id") {
  lastId++;
  return `${prefix}${lastId}`;
}

export function isEmpty(object?: any) {
  if (object === undefined || object === null) {
    return true;
  }

  const objectType = typeof object;

  switch (objectType) {
    case "string":
      return isEmptyString(object);
    case "object":
      return Object.values(object).filter(Boolean).length === 0;
    // for all other types, if they're not null or undefined, then they're not empty
    // for example, a number with the value of 0 is not considered an empty value
    default:
      return false;
  }
}

export default Util;
