import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { Banner, Button, Card, DisplayText, Layout, List, Page, Stack, TextStyle } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage";
import CompanyRoleDisclosureForm from "../../components/disclosure/CompanyRoleDisclosureForm";
import ErrorPanel from "../../components/ErrorPanel";
import DataTable from "../../components/extensions/DataTable";
import BackButton from "../../components/navigation/BackButton";
import useNavigationToolbar from "../../components/navigation/useNavigationToolbar";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import useValidateCompanyDisclosureForm from "../../hooks/useValidateCompanyDisclosureForm";
import { IDParams } from "../../types/params";

import DisclosureSkeleton from "./CompanyDisclosureSkeleton";

const CompanyRequestOnboardingDisclosures: React.FC<AsyncPageChild<api.CompanySignatureConfiguration>> = ({ data }) => {
  const { id } = useParams<IDParams>();

  const history = useHistory();
  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const { isManualCompany } = useNavigationToolbar();
  const validateForm = useValidateCompanyDisclosureForm();

  const [validationError, setValidationError] = useState<string>();
  const [signatures, setSignatures] = useState(new Map<string, api.CompanyRoleSignatureConfiguration>());
  const [isFormValid, setFormValid] = useState(false);

  const companyDetailsQuery = useQuery(["company-details", id], () => api.getCompanyCustomerDetails(id));
  const companyDetails = companyDetailsQuery.data;
  const companyName = companyDetails?.name || "";
  const possibleSignatureRoles = data.possible_signers || [];
  const signatureDescription = data.signature_description;
  const signatureDescriptions = data.signature_descriptions || [];

  const updateSignaturesMutation = useMutation(
    () => {
      const signaturesArray = [...signatures.values()];

      return api.updateCompanySignatureConfiguration(id, {
        signature_required: signaturesArray.length > 0,
        signatures: signaturesArray,
      });
    },
    {
      onSuccess: (response) => {
        onSignatureConfigurationUpdated(response);
      },
    }
  );

  const onSignatureConfigurationUpdated = (response: api.CompanySignatureConfiguration) => {
    // Update the current data with the response of api.updateCompanySignatureConfiguration
    queryClient.setQueryData(["company-onboarding-disclosures", id], response);

    // Update the company onboarding summary data
    const summaryData = queryClient.getQueryData<api.CompanyCustomerDetailResponse>(["company-onboarding-summary", id]);
    if (summaryData) {
      summaryData.disclosure_documents[0] = {
        ...summaryData.disclosure_documents[0],
        signature_required: response.signatures.length > 0 ? true : false,
        signatures: response.signatures,
      };
      queryClient.setQueryData(["company-onboarding-summary", id], summaryData);
    }

    // Navigate to the company onboarding summary step
    if (isManualCompany) {
      history.push(generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_SUMMARY, { id: id }));
    } else {
      history.push(generatePath(ROUTES.COMPANY_ONBOARDING_SUMMARY, { id: id }));
    }
  };

  const skipSignatureStepMutation = useMutation(
    () => {
      return api.updateCompanySignatureConfiguration(id, { signature_required: false });
    },
    {
      onSuccess: (response) => {
        onSignatureConfigurationUpdated(response);
      },
    }
  );

  const performValidation = (signatures: Map<string, api.CompanyRoleSignatureConfiguration>) => {
    validateForm(signatures, (isValid, hasDuplicates) => {
      setFormValid(isValid);

      if (hasDuplicates) {
        setValidationError(f("onboarding.disclosures.duplicate.emails.error"));
      } else {
        setValidationError(undefined);
      }
    });
  };

  const onSignerSelectionChange = (id: string, value: boolean) => {
    if (value) {
      signatures.set(id, { id: id, email: undefined, send_email: true });
    } else {
      signatures.delete(id);
    }

    setSignatures(new Map(signatures));
    performValidation(signatures);
  };

  const onSignerSystemEmailChange = (id: string, value: boolean) => {
    const signature = signatures.get(id);

    if (signature) {
      signature.send_email = value;

      // Remove email for a signer if they are generating a link
      if (!value) {
        signature.email = undefined;
      }

      signatures.set(id, signature);
      setSignatures(new Map(signatures));
      performValidation(signatures);
    }
  };

  const onSignerEmailChange = (id: string, value: string) => {
    const signature = signatures.get(id);

    if (signature) {
      signature.email = value;
      signatures.set(id, signature);

      setSignatures(new Map(signatures));
      performValidation(signatures);
    }
  };

  const sendSignatures = () => updateSignaturesMutation.mutate();

  useEffect(() => {
    const signaturesMap = new Map();
    for (const signatureData of data.signatures) {
      const signature = {
        id: signatureData.role_id,
        email: signatureData.email,
        send_email: signatureData.send_email,
      };

      signaturesMap.set(signatureData.role_id, signature);
    }

    setSignatures(signaturesMap);
    performValidation(signaturesMap);
  }, [data]);

  const isMutating = skipSignatureStepMutation.isLoading || updateSignaturesMutation.isLoading;

  if (companyDetailsQuery.isLoading || !companyDetails) {
    return <DisclosureSkeleton />;
  }

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Stack vertical>
            <DisplayText size="large">{f("onboarding.disclosures.title")}</DisplayText>
            <TextStyle>{f("onboarding.disclosures.company-description")}</TextStyle>

            <Banner status="info">
              <Stack vertical>
                <TextStyle variation="strong">
                  <FormattedMessage id="disclosure-request.banner.signatory-rights" values={{ company: companyName }} />
                </TextStyle>
                {signatureDescriptions.length === 0 && (
                  <TextStyle>{f("disclosure-request.banner.no.signing-basis")}</TextStyle>
                )}
                <List>
                  {signatureDescription && signatureDescription.length > 0 && (
                    <List.Item>{signatureDescription}</List.Item>
                  )}
                  {signatureDescriptions.length > 0 &&
                    signatureDescriptions.map((description, index) => <List.Item key={index}>{description}</List.Item>)}
                </List>
              </Stack>
            </Banner>

            <Card>
              {possibleSignatureRoles.length === 0 && <Card.Section>{f("roles.messages.no-roles")}</Card.Section>}
              {possibleSignatureRoles.length > 0 && (
                <DataTable
                  truncate
                  columnContentTypes={["text"]}
                  headings={[]}
                  rows={possibleSignatureRoles.map((signatureRole: api.SignatureRole) => [
                    <CompanyRoleDisclosureForm
                      email={signatures.get(signatureRole.id!)?.email}
                      isSystemEmailEnabled={signatures.get(signatureRole.id!)?.send_email}
                      signatureRole={signatureRole}
                      selected={signatures.has(signatureRole.id!)}
                      onSelectionChanged={(id, value) => onSignerSelectionChange(id, value)}
                      onSystemEmailChanged={(id, value) => onSignerSystemEmailChange(id, value)}
                      onEmailChanged={(id, value) => onSignerEmailChange(id, value)}
                    />,
                  ])}
                />
              )}
            </Card>
          </Stack>
        </Layout.Section>
        <Layout.Section>
          {(validationError || updateSignaturesMutation.isError || skipSignatureStepMutation.isError) && (
            <ErrorPanel
              message={validationError || updateSignaturesMutation.error || skipSignatureStepMutation.error}
            />
          )}

          <Stack distribution="trailing">
            <BackButton
              url={
                companyDetails?.is_manual
                  ? generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_SCREENING, { id: id })
                  : generatePath(ROUTES.COMPANY_ONBOARDING_SCREENING, { id: id })
              }
            />
            <Button
              disabled={isMutating}
              loading={skipSignatureStepMutation.isLoading}
              onClick={() => skipSignatureStepMutation.mutate()}
            >
              {f("common.buttons.actions.skip-credentials")}
            </Button>
            <Button
              primary
              disabled={isMutating || !isFormValid}
              loading={updateSignaturesMutation.isLoading}
              onClick={sendSignatures}
            >
              {f("button.go.next")}
            </Button>
          </Stack>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default withAsyncPage<api.CompanySignatureConfiguration>(CompanyRequestOnboardingDisclosures, {
  name: "company-onboarding-disclosures",
  apiFunction: api.getCompanySignatureConfiguration,
  paramNames: ["id"],
  skeleton: <DisclosureSkeleton />,
});
