import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { Card, Checkbox, RadioButton, Stack } from "@shopify/polaris";

import api from "../../api";
import { RiskTypes } from "../../constants/risk-types";
import useFormatMessage from "../../hooks/useFormatMessage";
import isNil from "../../utils/isNil";
import { isMediumOrHighRisk } from "../../utils/riskUtils";
import IndustryRisk from "../IndustryRisk";
import ReviewForm from "../ReviewForm";

import { Review } from "./types/Review";

export interface CustomerReviewFormProps {
  customerId: string;
  readonly?: boolean;
  industryRisk?: api.IndustryRiskInformation;
  risk?: api.RiskLevel;
  onAfterReviewCreated(review: Review, isPending: boolean, risk: api.RiskLevel): void;
}

const CustomerReviewForm: React.FC<CustomerReviewFormProps> = (props) => {
  const { customerId, readonly, risk, industryRisk, onAfterReviewCreated } = props;

  const [overrideRisk, setOverrideRisk] = useState<api.RiskLevel>();
  const [isConfirmationNeeded, setConfirmationNeeded] = useState(false);
  const [isTextFieldFocused, setTextFieldFocused] = useState(false);
  const [approved, setApproved] = useState<string>();

  const f = useFormatMessage();
  const queryClient = useQueryClient();

  const createReview = (comment: string) =>
    api.createCustomerReview(customerId, {
      accepted: approved === "yes",
      risk_level: overrideRisk,
      comment: comment || "",
      requires_approval: isConfirmationNeeded,
    });

  const handleAfterCreate = (review: api.CustomerReview) => {
    const newReview: Review = { ...review, isNew: true };

    queryClient.invalidateQueries(["company-details", [customerId]]);

    handleCancel();

    onAfterReviewCreated(newReview, isConfirmationNeeded, overrideRisk!);
  };

  const handleRisk = useCallback((_: boolean, newValue: api.RiskLevel) => {
    setOverrideRisk(newValue);

    if (!isTextFieldFocused) {
      setTextFieldFocused(true);
    }
  }, []);

  const handleApproved = (_: boolean, newValue: string) => {
    setApproved(newValue);

    // If not approved, set focus directly on the comments text field.
    if (newValue === "no") {
      setTextFieldFocused(true);
    }
  };

  const handleCancel = () => {
    setApproved(undefined);
    setConfirmationNeeded(false);
    setOverrideRisk(risk);
  };

  /*
  Check whether risk information should be shown, based
  on if there's at least some data available, and if the
  set risk level justifies showing it.
*/
  const shouldShowRisk = isMediumOrHighRisk(industryRisk);

  return (
    <Card>
      <Card.Section title={f("reviews.labels.relationship")}>
        <Stack vertical>
          <Stack>
            <div className={approved === "yes" ? "radio-active" : ""}>
              <RadioButton
                label={f("reviews.labels.relationship.approve")}
                checked={approved === "yes"}
                id="yes"
                name="approve"
                onChange={handleApproved}
                disabled={readonly}
              />
            </div>
            <div className={approved === "no" ? "radio-active" : ""}>
              <RadioButton
                label={f("reviews.labels.relationship.reject")}
                id="no"
                name="approve"
                checked={approved === "no"}
                onChange={handleApproved}
                disabled={readonly}
              />
            </div>
          </Stack>
        </Stack>
      </Card.Section>

      <Card.Section title={f("reviews.labels.relationship.change")}>
        <Stack vertical>
          <Stack spacing="extraLoose">
            {RiskTypes.map((riskType) => (
              <RadioButton
                key={riskType}
                label={f(`risklabel.${riskType}`)}
                id={riskType}
                name="overrideRisk"
                checked={overrideRisk === riskType || (!overrideRisk && risk === riskType)}
                onChange={handleRisk}
                disabled={readonly || approved === "no"}
              />
            ))}
          </Stack>
        </Stack>
      </Card.Section>

      {shouldShowRisk && (
        <Card.Section title={f("reviews.labels.customer-risk")}>
          <Stack vertical>
            <IndustryRisk industryRisk={industryRisk} />
          </Stack>
        </Card.Section>
      )}

      <Card.Section title={f("reviews.labels.review-rational")}>
        <ReviewForm<api.CustomerReview>
          onCreateReview={createReview}
          onAfterReviewCreated={handleAfterCreate}
          readonly={readonly || isNil(approved)}
          placeholder={f("reviews.placeholders.review-rational")}
          onCancel={handleCancel}
          isTextFieldFocused={isTextFieldFocused}
          setTextFieldFocused={setTextFieldFocused}
        >
          <Stack distribution="trailing" alignment="center">
            <Checkbox
              label={f("reviews.labels.ask-for-confirmation")}
              checked={isConfirmationNeeded}
              onChange={setConfirmationNeeded}
              disabled={readonly}
            />
          </Stack>
        </ReviewForm>
      </Card.Section>
    </Card>
  );
};

export default CustomerReviewForm;
