import * as React from "react";
import { Button, EmptyState, Page } from "@shopify/polaris";

import useFormatMessage from "../hooks/useFormatMessage";

import NotFound from "./404";

interface ErrorPageProps {
  errorCode?: number;
  error: Error | unknown | undefined | null;
}

/**
 * Checks if an unknown value is an object with a given property
 */
function hasOwnProperty<X, Y extends PropertyKey>(x: unknown, prop: Y): x is X & Record<Y, unknown> {
  if (typeof x === "object" && x) {
    return Object.prototype.hasOwnProperty.call(x, prop);
  } else {
    return false;
  }
}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const { error, errorCode } = props;
  const f = useFormatMessage();

  // Log error if it's not a production build
  if (process.env.NODE_ENV !== "production") {
    console.log(error);
  }

  // Check if it's a 404-error
  let is404 = false;
  if (errorCode === 404) {
    is404 = true;
  } else if (hasOwnProperty(error, "status")) {
    is404 = error.status === 404;
  } else if (hasOwnProperty(error, "response") && hasOwnProperty(error.response, "status")) {
    is404 = error.response.status === 404;
  }

  if (is404) {
    return <NotFound />;
  }

  return (
    <Page>
      <EmptyState heading={f("error.message.unknown")} image={""}>
        <p>{f("error.status.alerted")}</p>
        <Button onClick={() => window.location.reload()}>{f("common.retry")}</Button>
      </EmptyState>
    </Page>
  );
};

export default ErrorPage;
