import React from "react";
import { Stack, TextField } from "@shopify/polaris";

import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";

import CountrySelect from "./CountrySelect";

interface AddressFormProps {
  value: Partial<api.Address>;
  onChange(value: api.Address): void;
  disabled?: boolean;
  requiredFields?: (keyof api.Address)[];
}

const AddressForm: React.FC<AddressFormProps> = ({
  value: address,
  onChange,
  disabled = false,
  requiredFields = [],
}) => {
  const f = useFormatMessage();

  const onFieldChanged = (field: keyof api.Address, value: string) => {
    // set new value as api.Address in order to avoid TypeScript error regarding undefined value in country field
    onChange({ ...address, [field]: value } as api.Address);
  };

  return (
    <Stack vertical>
      <Stack distribution="fillEvenly">
        <TextField
          value={address.address_line_1}
          onChange={(newValue) => onFieldChanged("address_line_1", newValue)}
          label={f("common.address.addressline1")}
          placeholder={f("common.address.addressline1")}
          disabled={disabled}
          autoComplete="off"
          requiredIndicator={requiredFields.includes("address_line_1")}
        />

        <TextField
          value={address.address_line_2}
          onChange={(newValue) => onFieldChanged("address_line_2", newValue)}
          label={f("common.address.addressline2")}
          placeholder={f("common.address.addressline2")}
          disabled={disabled}
          autoComplete="off"
          requiredIndicator={requiredFields?.includes("address_line_2")}
        />
      </Stack>

      <Stack distribution="fillEvenly">
        <TextField
          value={address.postal_code}
          onChange={(newValue) => onFieldChanged("postal_code", newValue)}
          label={f("common.address.postal.code")}
          placeholder={f("common.address.postal.code")}
          disabled={disabled}
          autoComplete="off"
          requiredIndicator={requiredFields?.includes("postal_code")}
        />
        <TextField
          value={address.city}
          onChange={(newValue) => onFieldChanged("city", newValue)}
          label={f("common.address.city")}
          placeholder={f("common.address.city")}
          disabled={disabled}
          autoComplete="off"
          requiredIndicator={requiredFields?.includes("city")}
        />

        <TextField
          value={address.county}
          onChange={(newValue) => onFieldChanged("county", newValue)}
          label={f("common.address.county")}
          placeholder={f("common.address.county")}
          disabled={disabled}
          autoComplete="off"
          requiredIndicator={requiredFields?.includes("county")}
        />
      </Stack>

      <Stack distribution="fillEvenly">
        <CountrySelect
          value={address.country}
          label={f("common.address.country")}
          onSelect={(newValue) => onFieldChanged("country", newValue || "")}
          disabled={disabled}
          requiredIndicator={requiredFields.includes("country")}
        />
        <div />
      </Stack>
    </Stack>
  );
};

export default AddressForm;
