import * as React from "react";
import { Banner, Collapsible, Link, Stack,TextStyle } from "@shopify/polaris";
import styled from "styled-components";

type InfoBoxProps = {
  title: string;
};

const InfoBox: React.FC<InfoBoxProps> = ({ title, children }) => {
  const [active, setActive] = React.useState(false);

  const handleToggle = React.useCallback(() => setActive((active) => !active), []);
  return (
    <Banner status="info">
      <Stack vertical>
        <Link onClick={handleToggle}>
          <div className="unwrapped">
            <TextStyle variation="strong">{title}</TextStyle>
          </div>
        </Link>
        <Collapsible open={active} transition={{ duration: "150ms", timingFunction: "ease" }} id="">
          <StyledInfoBoxContent>
            <Stack vertical>{children}</Stack>
          </StyledInfoBoxContent>
        </Collapsible>
      </Stack>
    </Banner>
  );
};

const StyledInfoBoxContent = styled.div`
  white-space: pre-wrap;
`;

export default InfoBox;
