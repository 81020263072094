import * as React from "react";

import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";

type ServerAddressInfoProps = {
  address?: api.Address;
  withTitles?: boolean;
};

/*
  TEMPORARY COMPONENT TO DISPLAY GENERATED ADDRESS INFO

  TODO: ONCE THE TRANSITION TO USE GENERATED MODELS IS COMPLETE
  THE ORIGINAL ADDRESSINFO.TSX SHOULD BE DELETED AND THIS
  COMPONENT SHOULD BE RENAMED TO ADDRESSINFO.TSX
 */
const ServerAddressInfo: React.FC<ServerAddressInfoProps> = ({ address, withTitles = false }) => {
  const f = useFormatMessage();
  return address ? (
    <>
      {withTitles
        ? [
            address.address_line_1 && `${f("common.address.addressline1")}: ${address.address_line_1}`,
            address.address_line_2 && `${f("common.address.addressline2")}: ${address.address_line_2}`,
            address.postal_code && `${f("common.address.postal.code")}: ${address.postal_code}`,
            address.city && `${f("common.address.city")}: ${address.city}`,
            address.country && `${f("common.address.country")}: ${address.country}`,
          ]
            .filter((e) => !!e)
            .map((e) => <div key={e}>{e}</div>)
        : [address.address_line_1, address.address_line_2, address.postal_code, address.city, address.country]
            .filter((e) => !!e)
            .join(", ")}
    </>
  ) : (
    <>{f("not.specified")}</>
  );
};

export default ServerAddressInfo;
