import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import { Modal, TextStyle, Toast } from "@shopify/polaris";

import ProjectIcon from "../../../../assets/icons/folder.svg";
import api from "../../../api";
import ErrorPanel from "../../../components/ErrorPanel";
import ProjectsResourceList from "../../../components/ProjectsResourceList";
import { ROUTES } from "../../../constants/routes";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";
import { Customer } from "../../../types/utilities";

interface AddToProjectModalProps {
  customers: Customer[];
  navigateToProject?: boolean;
  onClose(): void;
}

const AddToProjectModal: React.FC<AddToProjectModalProps> = (props) => {
  const { customers, navigateToProject, onClose } = props;

  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [showSuccessToast, toggleSuccessToast, closeSuccessToast] = useOpenClose();
  const [selectedProjectsIds, setSelectedProjectIds] = useState<string[]>([]);
  const [selectedProject, setSelectedProject] = useState<api.Project>();
  const [page, setPage] = useState(0);

  const assignToProjectMutation = useMutation(
    async () => {
      const project = await api.getProject(selectedProjectsIds[0]);

      const existingCustomersIds = project.customers.map((customer) => customer.id);
      const selectedCustomersIds = customers.map((customer) => customer.id);
      const customersIds = [...new Set([...existingCustomersIds, ...selectedCustomersIds])];

      return api.updateProject(selectedProjectsIds[0], { customer_ids: customersIds });
    },
    {
      onSuccess: (updatedProject) => {
        queryClient.setQueryData(["project-details", updatedProject.id], updatedProject);
        if (navigateToProject) {
          history.push(generatePath(ROUTES.PROJECT_DETAILS, { id: updatedProject.id }));
          onClose();
        } else {
          setSelectedProject(updatedProject);
          toggleSuccessToast();
        }
      },
    }
  );

  const saveAction = {
    content: f("default.add"),
    onAction: () => assignToProjectMutation.mutate(),
    disabled: assignToProjectMutation.isLoading || selectedProjectsIds.length === 0,
    loading: assignToProjectMutation.isLoading,
  };

  const cancelAction = {
    content: f("default.cancel"),
    onAction: () => onClose(),
    disabled: assignToProjectMutation.isLoading,
  };

  const navigateToProjectAction = {
    content: selectedProject?.name || "",
    icon: ProjectIcon,
    onAction: () => history.push(generatePath(ROUTES.PROJECT_DETAILS, { id: selectedProject!.id })),
    disabled: !selectedProject,
  };

  const handleToastDismiss = () => {
    closeSuccessToast();
    onClose();
  };

  return (
    <>
      <Modal
        // if the toast is visible, hide the modal;
        open={!showSuccessToast}
        title={f("projects.modal.title")}
        onClose={onClose}
        primaryAction={saveAction}
        secondaryActions={[cancelAction]}
        onScrolledToBottom={() => setPage(page + 1)}
        footer={<TextStyle>{f("common.labels.selected-customers-count", { count: customers.length })}</TextStyle>}
      >
        <Modal.Section>
          <ProjectsResourceList
            selectedIds={selectedProjectsIds}
            page={page}
            resetPage={() => setPage(0)}
            onSelectionChange={setSelectedProjectIds}
          />
        </Modal.Section>
        {assignToProjectMutation.isError && <ErrorPanel message={assignToProjectMutation.error} />}
      </Modal>
      {showSuccessToast && (
        <Toast
          content={f("projects.modal.success.message", { count: customers.length })}
          action={navigateToProjectAction}
          onDismiss={handleToastDismiss}
          duration={8_000}
        />
      )}
    </>
  );
};

export default AddToProjectModal;
