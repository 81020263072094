import React from "react";
import { Stack, TextContainer, TextStyle } from "@shopify/polaris";

import WarningIcon from "../../assets/icons/warning.svg";
import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";

import Badge from "./extensions/Badge";
import Icon from "./extensions/Icon";

type IndustryInfoProps = {
  industry?: api.IndustryRiskDetail;
};

const IndustryInfo: React.FC<IndustryInfoProps> = ({ industry }) => {
  const f = useFormatMessage();

  if (!industry) {
    return null;
  }

  const industryCodeDescriptions = [industry.code, industry.industry_code_description].filter((e) => !!e).join(" — ");

  const WarningBadge = () => (
    <Badge status="warning" size="small">
      <Stack alignment="center" spacing="extraTight">
        <Icon source={WarningIcon} width="16px" height="16px" />
        <TextStyle>{f("industry-info.labels.possible-risk")}</TextStyle>
      </Stack>
    </Badge>
  );

  return (
    <Stack vertical>
      <Stack alignment="center" spacing="tight">
        <TextStyle>{industryCodeDescriptions}</TextStyle>
        {industry.risk_level !== "LOW" && <WarningBadge />}
      </Stack>
      {industry.risk_description && (
        <TextContainer>
          <TextStyle variation="strong">{`${f("industry-info.labels.editorial-explanation")}: `}</TextStyle>
          <TextStyle>{industry.risk_description}</TextStyle>
        </TextContainer>
      )}
    </Stack>
  );
};

export default IndustryInfo;
