import React, { CSSProperties } from "react";
import { TextStyle } from "@shopify/polaris";
import styled from "styled-components";

interface TableColumnHeaderProps {
  centered?: boolean;
  minWidth?: CSSProperties["minWidth"];
}

const TableColumnHeader: React.FC<TableColumnHeaderProps> = (props) => {
  const { centered, minWidth, children } = props;

  return (
    <StyledTableHeaderCell centered={centered} minWidth={minWidth}>
      <TextStyle variation="subdued">{children}</TextStyle>
    </StyledTableHeaderCell>
  );
};

const StyledTableHeaderCell = styled.div<TableColumnHeaderProps>`
  white-space: normal;
  text-align: ${({ centered }) => (centered ? "center" : "inherited")};
  min-width: ${({ minWidth }) => minWidth || "auto"};
`;

export default TableColumnHeader;
