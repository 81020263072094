export enum FeaturesTypes {
  EXTERNAL_ID = "EXTERNAL_ID",
  ATTACHMENTS = "ATTACHMENTS",
  USER_MANAGEMENT = "USER_MANAGEMENT",
  UPDATE_SCREENING_CONFIGURATION = "UPDATE_SCREENING_CONFIGURATION",
  RESPONSIBLE_USER = "RESPONSIBLE_USER",
  LEGAL_ENTITY_MONITORING = "LEGAL_ENTITY_MONITORING",
  ELECTRONIC_SIGNATURES = "ELECTRONIC_SIGNATURES",
  MANUAL_COMPANIES = "MANUAL_COMPANIES",
  ARCHIVE_CUSTOMER = "ARCHIVE_CUSTOMER",
  PROJECTS = "PROJECTS",
  PROJECT_LABELS = "PROJECT_LABELS",
}

export type Features = Record<FeaturesTypes, boolean>;
