import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/types";
import { EmptyState, Page } from "@shopify/polaris";

import EmptyHomeState from "../../assets/images/empty-home-state.png";
import { ROUTES } from "../constants/routes";
import useFormatMessage from "../hooks/useFormatMessage";

const NotFound: React.FC = () => {
  const f = useFormatMessage();

  // Report 404 to Sentry
  useEffect(() => {
    try {
      // Throw error with the URL in order to capture 404s for unique URLs
      throw new Error(
        `A user was redirected to the NotFound page from ${window.location.href}. This may or may not be an error - open the Sentry issue to see why the user was redirected`
      );
    } catch (e) {
      Sentry.captureException(e, { level: Severity.Warning });
    }
  }, []);

  return (
    <Page>
      <EmptyState
        heading={f("not-found.title")}
        action={{ content: f("button.go.back"), url: ROUTES.HOME }}
        image={EmptyHomeState}
      />
    </Page>
  );
};

export default NotFound;
