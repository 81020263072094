export const SORT_BY_NAME_PARAMETER = "name";
export const SORT_BY_ADDED_DATE_PARAMETER = "created";

export const CUSTOMERS_PER_PAGE = 20;

export const ORDER_NAME_PARAMETER = "order";
export const PAGE_NAME_PARAMETER = "page";
export const LIMIT_NAME_PARAMETER = "limit";
export const QUERY_NAME_PARAMETER = "q";

export const CREATED_RANGE_NAME_PARAMETER = "created";
export const CREATED_AFTER_NAME_PARAMETER = "createdAfter";
export const CREATED_BEFORE_NAME_PARAMETER = "createdBefore";

export enum CreatedRanges {
  ALL = "ALL",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
  CUSTOM = "CUSTOM",
}
