import { ActionListItemDescriptor } from "@shopify/polaris";

import ArchiveCustomerIcon from "../../../assets/icons/archivebox.svg";
import NewProjectIcon from "../../../assets/icons/folder-plus.svg";
import ExistingProjectIcon from "../../../assets/icons/folders.svg";
import UpdateResponsibleUserIcon from "../../../assets/icons/person-arrow-toward.svg";
import RestoreCustomerIcon from "../../../assets/icons/restore.svg";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";

const useGetCustomersListBulkActions = (selectedCustomersCount: number, isArchivedCustomers: boolean) => {
  const features = useFeatures();
  const f = useFormatMessage();
  const [showResponsibleUserModal, toggleResponsibleUserModal, closeResponsibleUserModal] = useOpenClose();
  const [showArchiveConfirm, toggleArchiveConfirm, closeArchiveConfirm] = useOpenClose();
  const [showRestoreConfirm, toggleRestoreConfirm, closeRestoreConfirm] = useOpenClose();
  const [showAddToProjectModal, toggleAddToProjectModal, closeAddToProjectModal] = useOpenClose();
  const [showAddToNewProjectModal, toggleAddToNewProjectModal, closeAddToNewProjectModal] = useOpenClose();

  const bulkActions: ActionListItemDescriptor[] = [];

  if (isArchivedCustomers) {
    if (features.ARCHIVE_CUSTOMER) {
      bulkActions.push({
        content: f("bulk.actions.restore.confirmation.title"),
        image: RestoreCustomerIcon,
        onAction: toggleRestoreConfirm,
        disabled: selectedCustomersCount === 0,
      });
    }
  } else {
    if (features.RESPONSIBLE_USER) {
      bulkActions.push({
        content: f("bulk.actions.responsible.label"),
        image: UpdateResponsibleUserIcon,
        onAction: toggleResponsibleUserModal,
        disabled: selectedCustomersCount === 0,
      });
    }

    if (features.ARCHIVE_CUSTOMER) {
      bulkActions.push({
        content: f("bulk.actions.archive.confirmation.title"),
        image: ArchiveCustomerIcon,
        onAction: toggleArchiveConfirm,
        disabled: selectedCustomersCount === 0,
      });
    }

    if (features.PROJECTS) {
      bulkActions.push({
        content: f("bulk.actions.add-to-existing-project.label"),
        image: ExistingProjectIcon,
        onAction: toggleAddToProjectModal,
        disabled: selectedCustomersCount === 0,
      });

      bulkActions.push({
        content: f("bulk.actions.add-to-new-project.label"),
        image: NewProjectIcon,
        onAction: toggleAddToNewProjectModal,
        disabled: selectedCustomersCount === 0,
      });
    }
  }

  const isEmpty = bulkActions.length === 0;

  return {
    isEmpty,
    bulkActions,
    showResponsibleUserModal,
    showArchiveConfirm,
    showRestoreConfirm,
    showAddToProjectModal,
    showAddToNewProjectModal,
    closeResponsibleUserModal,
    closeArchiveConfirm,
    closeRestoreConfirm,
    closeAddToProjectModal,
    closeAddToNewProjectModal,
  };
};

export default useGetCustomersListBulkActions;
