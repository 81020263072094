import React from "react";
import { useQueryClient } from "react-query";

import api from "../../../api";
import ConfirmAction from "../../../components/modals/ConfirmAction";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../../hooks/useFormatMultipleNames";
import { Customer } from "../../../types/utilities";
import { getCustomerName } from "../../../utils/displayUtils";

interface RestoreCustomersProps {
  customers: Customer[];
  onClose(): void;
  onSuccess(): void;
}

const RestoreCustomers: React.FC<RestoreCustomersProps> = (props) => {
  const { customers, onClose, onSuccess } = props;

  const queryClient = useQueryClient();
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const customerIds = customers.map((customer) => customer.id);
  const customersNamesLabel = formatMultipleNames(customers.map(getCustomerName));

  const restoreCustomersApiCall = () => api.restoreCustomers({ customer_ids: customerIds });

  const handleSuccess = async () => {
    await queryClient.invalidateQueries(["customers"]);
    onSuccess();
  };

  return (
    <ConfirmAction
      apiCall={restoreCustomersApiCall}
      onSuccess={handleSuccess}
      isOpen
      isDestructive
      title={f("bulk.actions.restore.confirmation.title")}
      description={f("bulk.actions.restore.confirmation.description", { names: customersNamesLabel })}
      onNo={onClose}
      actionTitle={f("bulk.actions.restore.label")}
      useSuccessToast
      successTitle={f("bulk.actions.restore.success.message", { count: customers.length })}
    />
  );
};

export default RestoreCustomers;
