import React from "react";
import styled from "styled-components";

import IconConfirmed from "../../assets/icons/checkmark-green.svg";
import IconRejected from "../../assets/icons/circle-cross.svg";

import NewInfoBadge from "./NewInfoBadge";

interface MatchCaseStatusProps {
  isResolved?: boolean;
  isConfirmed?: boolean;
  isUpdated?: boolean;
}

const MatchCaseStatus: React.FC<MatchCaseStatusProps> = (props) => {
  const { isUpdated, children, isResolved, isConfirmed } = props;

  return (
    <StyledMatchCaseStatus isResolved={isResolved} isConfirmed={isConfirmed}>
      {children}
      {isUpdated && <NewInfoBadge />}
    </StyledMatchCaseStatus>
  );
};

const StyledMatchCaseStatus = styled.div<MatchCaseStatusProps>`
  display: flex;
  align-items: center;

  &::before {
    background-image: url(${(props: MatchCaseStatusProps) => (props.isConfirmed ? IconConfirmed : IconRejected)});
    display: none;
  }

  ${(props) =>
    props.isResolved &&
    `&::before {
      content: " ";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-size: contain;
      margin-right: 8px;
      padding-right: 15px;
      background-position: center;
      background-repeat: no-repeat;
    }`}
`;

export default MatchCaseStatus;
