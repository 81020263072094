import React from "react";
import { useMutation, useQueryClient } from "react-query";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Entity } from "../../types/utilities";
import Util from "../../utils/util";
import useAttachmentsAPI from "../attachments/useAttachmentsAPI";

import Confirm from "./Confirm";

type DeleteAttachmentConfirmationModalProps = {
  entity: Entity;
  attachment: api.AttachmentDetails;
  deleteSignedAttachment?: boolean;
  onModalClose(): void;
};

const DeleteAttachmentConfirmationModal: React.FC<DeleteAttachmentConfirmationModalProps> = (props) => {
  const { entity, attachment, deleteSignedAttachment, onModalClose } = props;

  const queryClient = useQueryClient();
  const f = useFormatMessage();

  const { entityId, deleteAttachment, deleteSignedAttachment: deleteSignedAttachmentCall } = useAttachmentsAPI(entity);

  const mutation = useMutation(
    () =>
      deleteSignedAttachment
        ? deleteSignedAttachmentCall(entityId, attachment.id)
        : deleteAttachment(entityId, attachment.id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["attachments", entityId]);
        onModalClose();
      },
    }
  );

  const filename = Util.cleanupFilename(attachment.filename);

  const title = deleteSignedAttachment
    ? f("attachments.delete.confirmation.signed.title")
    : f("attachments.delete.confirmation.title");

  const description = deleteSignedAttachment
    ? f("attachments.delete.confirmation.signed.description", { name: filename })
    : f("attachments.delete.confirmation.description", { name: filename });

  const deleteActionTitle = deleteSignedAttachment
    ? f("attachments.delete.confirmation.signed.yes")
    : f("attachments.delete.confirmation.yes");

  return (
    <Confirm
      title={title}
      description={description}
      onYes={() => mutation.mutate()}
      onNo={onModalClose}
      actionTitle={deleteActionTitle}
      isActionInProgress={mutation.isLoading}
      error={mutation.error}
    />
  );
};

export default DeleteAttachmentConfirmationModal;
