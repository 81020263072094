export interface LanguageStorage {
  saveLanguage(lang: string): void;
  getLanguage(): string;
}

export class LocalLanguageStorage implements LanguageStorage {
  static LANG_KEY = "lang";
  static DEFAULT_LANGUAGE = "nb";

  saveLanguage(lang: string): void {
    try {
      const storage = window.localStorage;
      storage.setItem(LocalLanguageStorage.LANG_KEY, lang);
    } catch (error: unknown) {
      console.log("Can't persist the language setting");
    }
  }
  getLanguage(): string {
    try {
      const storage = window.localStorage;
      return storage.getItem(LocalLanguageStorage.LANG_KEY) ?? LocalLanguageStorage.DEFAULT_LANGUAGE;
    } catch (error: unknown) {
      console.log(`Can't read the language setting ${error}`);
    }
    return LocalLanguageStorage.DEFAULT_LANGUAGE;
  }
}

export const getLanguage = () =>
  window.localStorage.getItem(LocalLanguageStorage.LANG_KEY) ?? LocalLanguageStorage.DEFAULT_LANGUAGE;
