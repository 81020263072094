import React from "react";
import { Card, DataTable, Heading, Stack, TextStyle, Tooltip } from "@shopify/polaris";

import PencilIcon from "../../../../assets/icons/pencil.svg";
import api from "../../../api";
import AddressInfo from "../../../components/AddressInfo";
import Button from "../../../components/extensions/Button";
import useCountries from "../../../hooks/useCountries";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";

import EditPersonCustomerDetailsModal from "./EditPersonCustomerDetailsModal";

type PersonInfoSectionProps = {
  person: api.PersonCustomer;
  readonly?: boolean;
};

const PersonInfoSection: React.FC<PersonInfoSectionProps> = (props) => {
  const { person, readonly } = props;

  const features = useFeatures();
  const f = useFormatMessage();
  const { getCountryName } = useCountries();
  const [showEditModal, toggleEditModal, closeEditModal] = useOpenClose();

  return (
    <>
      <Stack vertical>
        <Stack distribution="equalSpacing">
          <Heading>{f("person.details.title")}</Heading>
          <Button size="slim" icon={PencilIcon} onClick={toggleEditModal} disabled={readonly}>
            {f("common.buttons.actions.edit-person-info")}
          </Button>
        </Stack>
        <Card>
          <DataTable
            columnContentTypes={["text", "text"]}
            headings={[]}
            rows={[
              features && features.EXTERNAL_ID
                ? [
                    <TextStyle variation="subdued">{f("person.details.caption.externalId")}</TextStyle>,
                    <div>{person.external_id ? person.external_id : "-"}</div>,
                  ]
                : [null],
              [
                <TextStyle variation="subdued">{f("person.details.caption.first.name")}</TextStyle>,
                <div className="client-name" title={person.first_name}>
                  {person.first_name}
                </div>,
              ],
              [
                <TextStyle variation="subdued">{f("person.details.caption.last.name")}</TextStyle>,
                <div>{person.last_name}</div>,
              ],
              [
                <TextStyle variation="subdued">{f("person.details.caption.birth.date")}</TextStyle>,
                <div>{person.birth_year || person.birth_date}</div>,
              ],
              [
                <TextStyle variation="subdued">{f("person.details.caption.person.number")}</TextStyle>,

                person.national_id ? (
                  <div>
                    <Tooltip content={person.national_id}>
                      <div className="inline">*****</div>
                    </Tooltip>
                  </div>
                ) : (
                  <div>{f("not.specified")}</div>
                ),
              ],
              [
                <TextStyle variation="subdued">{f("person.details.caption.citizenship")}</TextStyle>,
                <div>{person.country_of_citizenship ? getCountryName(person.country_of_citizenship) : "-"}</div>,
              ],
              [
                <TextStyle variation="subdued">{f("person.details.caption.address")}</TextStyle>,
                <div>{person.address ? <AddressInfo address={person.address} /> : f("not.specified")}</div>,
              ],
            ].filter((item) => item !== null)}
          />
        </Card>
      </Stack>
      {showEditModal && <EditPersonCustomerDetailsModal person={person} onClose={closeEditModal} />}
    </>
  );
};

export default PersonInfoSection;
