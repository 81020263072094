export enum PAGE_ANCHORS {
  TOP_SECTION = "root", // element with id="root" is added automatically by Polaris
  DISCLOSURES_SECTION = "disclosures",
  CUSTOMER_DETAILS = "details",
  CUSTOMER_PROJECTS = "projects",
  COMPANY_ROLES_AND_OWNERS_SECTION = "roles",
  ATTACHMENTS_SECTION = "attachments",
  REVIEWS_SECTION = "reviews",
  PENDING_REVIEWS_SECTION = "pending-reviews",
  EXISTING_REVIEWS_SECTION = "existing-reviews",
  MATCH_CASES_HITS_IN_CATEGORIES = "hits-in-categories",
  MATCH_CASES_PEP_TYPES = "pep-types",
  MATCH_CASES_SUMMARY = "summary",
  MATCH_CASES_EVENTS = "events",
  MATCH_CASES_COMMENTS = "comments",
  MATCH_CASES_JOBS = "jobs",
  MATCH_CASES_RELATIONS = "relations",
  MATCH_CASES_SOURCES = "sources",
  MATCH_CASES_LINKS = "links",
  MATCH_CASES_ASSESSMENT = "assessment",
  PROJECT_CUSTOMERS = "customers",
  PROJECT_NOTES = "notes",
}
