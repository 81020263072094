import * as React from "react";
import { useEffect } from "react";
import { Card, DataTable, Heading, Link, Stack, TextStyle } from "@shopify/polaris";

import GearIcon from "../../assets/icons/gear.svg";
import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";

import Button from "./extensions/Button";
import CustomDate from "./CustomDate";
import MatchCaseStatus from "./MatchCaseStatus";

type MatchCasesProps = {
  customerId: string;
  hasFinishedOnboarding: boolean;
  matchCases: Array<api.MatchCaseSummary>;
  readonly?: boolean;
};

const renderMatch = (id: string, matchCase: api.MatchCaseSummary) => {
  const isResolved = matchCase.status == "RESOLVED";
  const isUpdatedSinceLastReview = matchCase.review && !isResolved ? true : false;
  const isConfirmed = matchCase.review ? matchCase.review.is_match : false;

  return [
    <Link url={`/customers/${id}/match-cases/${matchCase.id}`}>{matchCase.subject_name}</Link>,
    <MatchCaseStatus isResolved={isResolved} isConfirmed={isConfirmed} isUpdated={isUpdatedSinceLastReview}>
      {matchCase.summary_text}
    </MatchCaseStatus>,
    <CustomDate date={matchCase.updated_timestamp || matchCase.timestamp} />,
    // Give match case id column a class name to enable styling of rows by its status
    <div className={isResolved ? "resolved-match-case-id-col" : "unresolved-match-case-id-col"}>{matchCase.id}</div>,
  ];
};

const MatchCases: React.FC<MatchCasesProps> = ({ customerId, hasFinishedOnboarding, matchCases, readonly }) => {
  const [showRejectedMatches, setShowRejectedMatches] = React.useState(false);

  const f = useFormatMessage();

  useEffect(() => {
    /**
     * Ugly/hacky way of styling Polaris DataTable rows
     * Highlight all unresolved match cases
     */
    const unresolvedMatchCaseIdCols = document.getElementsByClassName("unresolved-match-case-id-col");
    for (let i = 0; i < unresolvedMatchCaseIdCols.length; i++) {
      const el = unresolvedMatchCaseIdCols[i] as HTMLElement;
      const tableRowElement = el.closest("tr");
      tableRowElement?.classList.add("new-info");
    }
  });

  const rejectedMatchCount = matchCases.filter((m) => m.review && !m.review.is_match).length;

  let rowsToDisplay = matchCases;
  if (!showRejectedMatches) {
    rowsToDisplay = matchCases.filter((m) => !m.review || (m.review && m.review.is_match));
  }

  const showRejectedMatchCasesButton =
    matchCases.find((matchCase) => matchCase.review?.is_match === false) !== undefined;

  const toggleRejectedMatchCasesButtonLabel = !showRejectedMatches
    ? f(`matches.button.show.rejected.matches`, { count: rejectedMatchCount })
    : f(`matches.button.hide.rejected.matches`, { count: rejectedMatchCount });

  return (
    <Stack vertical>
      <Stack distribution="equalSpacing">
        <Heading>{f("matches.title.details.matches")}</Heading>
        {hasFinishedOnboarding && (
          <Button icon={GearIcon} size="slim" url={`${customerId}/change-screening`} disabled={readonly}>
            {f("common.buttons.actions.change-screening")}
          </Button>
        )}
      </Stack>
      <Card>
        {rowsToDisplay.length > 0 ? (
          <DataTable
            columnContentTypes={["text", "text", "text", "numeric"]}
            headings={[
              <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
              <TextStyle variation="subdued">{f("table.column.details")}</TextStyle>,
              <TextStyle variation="subdued">{f("table.column.last.updated")}</TextStyle>,
              <TextStyle variation="subdued">{f("table.column.id")}</TextStyle>,
            ]}
            rows={rowsToDisplay.map((o) => renderMatch(customerId, o))}
          />
        ) : (
          <Card.Section>
            {rejectedMatchCount > 0 ? f("matches.text.no.confirmed.matches") : f("matches.text.no.matches")}
          </Card.Section>
        )}
      </Card>
      {showRejectedMatchCasesButton && (
        <Button
          onClick={() => setShowRejectedMatches(!showRejectedMatches)}
          textAlign="left"
          disclosure={!showRejectedMatches ? "down" : "up"}
        >
          {toggleRejectedMatchCasesButtonLabel}
        </Button>
      )}
    </Stack>
  );
};

export default MatchCases;
