import React, { useState } from "react";
import { TextField } from "@shopify/polaris";

import useFormatMessage from "../hooks/useFormatMessage";
import Util from "../utils/util";

interface EmailFieldProps {
  disabled?: boolean;
  required?: boolean;
  id: string;
  value: string;
  placeholder?: string;
  className?: string;
  onUpdate(id: string, email: string): void;
}

const EmailField: React.FC<EmailFieldProps> = (props) => {
  const { disabled, required, placeholder, id, value, className, onUpdate } = props;

  const [focused, setFocused] = useState(false);
  const f = useFormatMessage();

  const getErrorMessage = () => {
    if (required && focused && value === "") {
      return f("email.required.error");
    }

    return value && !Util.isEmail(value) ? f("email.invalid.address.error") : undefined;
  };

  return (
    <span className={className}>
      <TextField
        value={value}
        type="email"
        disabled={disabled}
        placeholder={placeholder || f("email.placeholder.text")}
        error={getErrorMessage()}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onChange={(newEmail) => {
          onUpdate(id, newEmail);
        }}
        label=""
        labelHidden
        autoComplete="off"
      />
    </span>
  );
};

export default EmailField;
