import React from "react";
import { Card, Stack } from "@shopify/polaris";

import useFormatMessage from "../hooks/useFormatMessage";

import TextField from "./extensions/TextField";

interface AssociateToProjectSectionProps {
  projectName: string;
}

const AssociatedProjectSection: React.FC<AssociateToProjectSectionProps> = (props) => {
  const { projectName } = props;

  const f = useFormatMessage();

  return (
    <Card.Section title={f("onboarding.associated-project.title")}>
      <Stack>
        <TextField disabled value={projectName} labelHidden label="" autoComplete="off" maxLength={50} />
      </Stack>
    </Card.Section>
  );
};

export default AssociatedProjectSection;
