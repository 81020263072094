import React, { useLayoutEffect } from "react";
import { DisplayText, Page, Stack } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage";
import AttachmentsSection from "../../components/attachments/attachments-section/AttachmentsSection";
import DetailsSkeleton from "../../components/DetailsSkeleton";
import BackButton from "../../components/navigation/BackButton";
import NavigationSection from "../../components/navigation/NavigationSection";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import useAppTitle from "../../hooks/useAppTitle";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePageNavigation from "../../hooks/usePageNavigation";
import useSetLocationState from "../../hooks/useSetLocationState";
import { NavigationItem } from "../../providers/PageNavigation";

import ProjectCustomersSection from "./customers-section/ProjectCustomersSection";
import BasicProjectInfo from "./info/BasicProjectInfo";
import ProjectReviewsSection from "./reviews/ProjectReviewsSection";
import ProjectActionsMenu from "./ProjectActionsMenu";
import useProjectDetailsBackButton from "./useProjectDetailsBackButton";

const ProjectDetailsPage: React.FC<AsyncPageChild<api.Project>> = ({ data: project }) => {
  useAppTitle(project.name);
  const f = useFormatMessage();
  const features = useFeatures();
  const { setNavigationItems, clearNavigationItems } = usePageNavigation();
  const { showBackButton, backButtonLabel, backButtonURL } = useProjectDetailsBackButton();

  useSetLocationState("fromProject", { id: project.id, name: project.name });

  useLayoutEffect(() => {
    if (!project) {
      return;
    }

    const navigationItems: NavigationItem[] = [
      { anchor: PAGE_ANCHORS.TOP_SECTION, label: f("project.details.title") },
      { anchor: PAGE_ANCHORS.PROJECT_CUSTOMERS, label: f("project.customers.title") },
    ];

    if (features.ATTACHMENTS) {
      navigationItems.push({
        anchor: PAGE_ANCHORS.ATTACHMENTS_SECTION,
        label: f("attachments.box.title"),
      });
    }

    navigationItems.push({ anchor: PAGE_ANCHORS.PROJECT_NOTES, label: f("project.notes.title") });

    setNavigationItems(navigationItems);

    return () => clearNavigationItems();
  }, [project, features]);

  const readonly = project.status !== "ACTIVE";

  return (
    <Page>
      <Stack vertical wrap={false}>
        {showBackButton && <BackButton url={backButtonURL} displayLabel={backButtonLabel} showAsLink />}
        <DisplayText size="large">{project.name}</DisplayText>
        <Stack wrap={false}>
          <Stack.Item fill>
            <BasicProjectInfo project={project} readonly={readonly} />
          </Stack.Item>
          <ProjectActionsMenu project={project} readonly={readonly} />
        </Stack>

        <NavigationSection anchor={PAGE_ANCHORS.PROJECT_CUSTOMERS} />
        <ProjectCustomersSection project={project} readonly={readonly} />

        {features.ATTACHMENTS && (
          <>
            <NavigationSection anchor={PAGE_ANCHORS.ATTACHMENTS_SECTION} />
            <AttachmentsSection
              entity={project}
              readonly={readonly}
              fetchSuggestedSigners={() =>
                api.getProjectCustomersDetails(project.id).then((response) => response.customers)
              }
            />
          </>
        )}

        <NavigationSection anchor={PAGE_ANCHORS.PROJECT_NOTES} />
        <ProjectReviewsSection projectId={project.id} reviews={project.reviews} readonly={readonly} />
      </Stack>
    </Page>
  );
};

export default withAsyncPage(ProjectDetailsPage, {
  name: "project-details",
  apiFunction: api.getProject,
  paramNames: ["id"],
  skeleton: <DetailsSkeleton />,
});
