import React from "react";

import TagIcon from "../../../assets/icons/tag.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import Icon from "../extensions/Icon";
import PopoverButton, { PopoverButtonProps } from "../PopoverButton";

import LabelsEditorForm, { LabelsEditorFormProps } from "./LabelsEditorForm";

type LabelsEditorPopoverProps = Omit<PopoverButtonProps, "render"> & LabelsEditorFormProps;

const LabelsEditorPopover: React.FC<LabelsEditorPopoverProps> = (props) => {
  const { allLabels, selectedLabels, onSelectionChange, onNewLabel, ...popoverButtonProps } = props;

  const f = useFormatMessage();

  const handleNewLabel = (onClose: () => void) => async (newLabel: string) => {
    await onNewLabel(newLabel);
    onClose();
  };

  return (
    <PopoverButton
      label={f("labels.button.label")}
      icon={<Icon source={TagIcon} />}
      {...popoverButtonProps}
      render={(onClose) => (
        <LabelsEditorForm
          allLabels={allLabels}
          selectedLabels={selectedLabels}
          disabled={popoverButtonProps.disabled}
          onSelectionChange={onSelectionChange}
          onNewLabel={handleNewLabel(onClose)}
        />
      )}
    />
  );
};

export default LabelsEditorPopover;
