import React from "react";
import { Checkbox, Stack, Subheading, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import RoleTypes from "../../constants/role-types";
import useFormatMessage from "../../hooks/useFormatMessage";
import { getBirthDate, getFullName } from "../../utils/displayUtils";
import Util from "../../utils/util";
import DisplayName from "../DisplayName";

import { StyledButtonGroup, StyledEmailField } from "./StyledDisclosureFormComponents";

type CompanyRoleDisclosureFormProps = {
  email?: string;
  isSystemEmailEnabled?: boolean;
  signatureRole: api.SignatureRole;
  selected?: boolean;
  onSelectionChanged: (id: string, value: boolean) => void;
  onSystemEmailChanged: (id: string, value: boolean) => void;
  onEmailChanged: (id: string, value: string) => void;
};

const CompanyRoleDisclosureForm: React.FC<CompanyRoleDisclosureFormProps> = ({
  isSystemEmailEnabled = true,
  email,
  signatureRole,
  selected = false,
  onSelectionChanged,
  onSystemEmailChanged,
  onEmailChanged,
}) => {
  const f = useFormatMessage();

  return (
    <Stack
      wrap={false}
      alignment={isSystemEmailEnabled && !Util.isEmail(email) ? "baseline" : "center"}
      distribution="fill"
    >
      <Stack wrap={false} alignment="center" distribution="leading">
        <Checkbox
          checked={selected}
          labelHidden
          label=""
          onChange={(value) => onSelectionChanged(signatureRole.id!, value)}
        />
        <Stack wrap={false} vertical spacing="extraTight">
          <StyledRoleTitle>
            <Subheading>{f(RoleTypes[signatureRole.type])}</Subheading>
          </StyledRoleTitle>
          <DisplayName
            type="person"
            name={getFullName(signatureRole.person)}
            birthDate={getBirthDate(signatureRole.person)}
          />
        </Stack>
      </Stack>
      {selected && (
        <Stack wrap={false} alignment={!isSystemEmailEnabled ? "center" : undefined} distribution="trailing">
          <StyledButtonGroup
            value={isSystemEmailEnabled ? "email" : "link"}
            onChange={(value) => onSystemEmailChanged(signatureRole.id!, value === "email")}
          />
          {isSystemEmailEnabled ? (
            <StyledEmailField
              required
              placeholder={f("disclosure-request.field.email.placeholder")}
              id={signatureRole.id!}
              value={email || ""}
              onUpdate={(id, newEmail) => onEmailChanged(id, newEmail)}
            />
          ) : (
            <TextStyle variation="subdued">{f("signature.email.not.provided")}</TextStyle>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const StyledRoleTitle = styled.span`
  & .Polaris-Subheading {
    margin-left: 2.65rem;
    color: #6d7175;
  }
`;

export default CompanyRoleDisclosureForm;
