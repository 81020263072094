import React, { ReactElement, ReactNode, useCallback, useMemo } from "react";
import { ActionType, Column, IdType, TableState, useRowSelect, useSortBy, useTable } from "react-table";
import { Checkbox } from "@shopify/polaris";
import { CaretDownMinor, CaretUpMinor } from "@shopify/polaris-icons";
import classNames from "classnames";
import styled from "styled-components";

import useNonInitialEffect from "../hooks/useNonInitialEffect";
import { noop } from "../utils/util";

import Icon from "./extensions/Icon";
import ReactTableSkeleton from "./ReactTableSkeleton";

export type SORT_DIRECTION = "ascending" | "descending";

interface ReactTableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  getRowId(row: T): string;
  hiddenColumns?: string[];
  sortLocally?: boolean;
  sortedColumnId?: string;
  sortedDirection?: SORT_DIRECTION;
  onSortChange?(sortedColumnId?: string, sortedDirection?: SORT_DIRECTION): void;
  selectedIds: string[];
  selectable?: boolean;
  onSelectionChange?(selectedIds: string[]): void;
  isLoading?: boolean;
  footerContent?: ReactNode;
}

function ReactTable<T extends object>(props: ReactTableProps<T>): ReactElement {
  const {
    columns,
    data,
    getRowId,
    hiddenColumns,
    sortLocally,
    sortedColumnId,
    sortedDirection,
    onSortChange = noop,
    selectedIds,
    selectable,
    onSelectionChange = noop,
    isLoading,
    footerContent = null,
  } = props;

  // the type Record<IdType<T>, boolean> is needed by React-Table's useTable
  const EMPTY_SELECTION = {} as Record<IdType<T>, boolean>;

  const sortBy = useMemo(() => {
    // when sorting locally there's no need to provide an initial sorting state
    if (!sortedColumnId || !sortedDirection || sortLocally) {
      return [];
    }

    return [
      {
        id: sortedColumnId,
        desc: sortedDirection === "descending",
      },
    ];
  }, [sortedColumnId, sortedDirection]);

  const handleSortChange = useCallback(
    (columnId: string) => {
      // when sorting locally there's no need to fire an onSortChange events
      if (sortLocally) {
        return;
      }

      onSortChange(columnId, sortedDirection === "ascending" ? "descending" : "ascending");
    },
    [sortedColumnId, sortedDirection, sortLocally, onSortChange]
  );

  const selectedIdsMap: Record<string, boolean> = useMemo(
    () => Object.fromEntries(selectedIds.map((id) => [id, true])),
    [JSON.stringify(selectedIds)]
  );

  // used to for de-selecting of all rows if toggleAllRowsSelected is called
  // see: https://stackoverflow.com/questions/65096897/remove-all-selected-rows-even-though-im-in-other-page-using-react-table-with-co
  const stateReducer = (newState: TableState<T>, action: ActionType): TableState<T> => {
    switch (action.type) {
      case "toggleAllRowsSelected":
        return {
          ...newState,
          selectedRowIds:
            // if toggleAllRowsSelected was called with false and controlled selectedIds is empty
            // set the selection to empty as well
            selectedIds.length === 0 && action.value === false ? EMPTY_SELECTION : newState.selectedRowIds,
        };

      default:
        return newState;
    }
  };

  const tableInstance = useTable<T>(
    {
      columns,
      data,
      getRowId,
      initialState: {
        hiddenColumns,
        sortBy,
        selectedRowIds: selectedIdsMap,
      },
      stateReducer,
      manualSortBy: !sortLocally,
      disableSortRemove: true,
      autoResetSelectedRows: false,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (selectable === true) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            Header: ({ toggleAllRowsSelected, isAllRowsSelected, selectedFlatRows, rows }) => {
              const checkedState =
                selectedFlatRows.length > 0 && selectedFlatRows.length !== rows.length
                  ? "indeterminate"
                  : isAllRowsSelected;
              return <Checkbox labelHidden label="" checked={checkedState} onChange={toggleAllRowsSelected} />;
            },
            // @ts-ignore
            Cell: ({ row }) => {
              const { isSelected, toggleRowSelected } = row;
              return <Checkbox labelHidden label="" checked={isSelected} onChange={toggleRowSelected} />;
            },
          },
          ...columns,
        ]);
      }
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
    toggleAllRowsSelected,
  } = tableInstance;

  useNonInitialEffect(() => {
    if (selectedIds.length === 0) {
      toggleAllRowsSelected(false);
    }
  }, [JSON.stringify(selectedIds)]);

  useNonInitialEffect(() => {
    const selectedIds = Object.entries(selectedRowIds)
      .filter(([id, selected]) => selected)
      .map(([id]) => id);

    onSelectionChange(selectedIds);
  }, [JSON.stringify(Object.keys(selectedRowIds))]);

  const visibleColumnsCount = columns.length - (hiddenColumns?.length || 0) + (selectable ? 1 : 0);

  return (
    <div className="Polaris-DataTable">
      <div className="Polaris-DataTable__ScrollContainer">
        {/* apply the table props */}
        <table {...getTableProps()} className="Polaris-DataTable__Table">
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, index) => {
                      const className = classNames("Polaris-DataTable__Cell Polaris-DataTable__Cell--header", {
                        "Polaris-DataTable__Cell--firstColumn": index === 0,
                        "Polaris-DataTable__Cell--sortable": column.defaultCanSort,
                        "Polaris-DataTable__Cell--sorted": column.isSorted,
                      });

                      return (
                        // Apply the header cell props
                        <th
                          {...column.getHeaderProps(column.defaultCanSort ? column.getSortByToggleProps() : {})}
                          className={className}
                        >
                          {!column.defaultCanSort && <StyledTableHeader>{column.render("Header")}</StyledTableHeader>}
                          {column.defaultCanSort && (
                            <StyledHeaderButton
                              className="Polaris-DataTable__Heading"
                              onClick={() => handleSortChange(column.id)}
                            >
                              <StyledTableHeader>{column.render("Header")}</StyledTableHeader>
                              <span className="Polaris-DataTable__Icon">
                                <Icon source={column.isSortedDesc ? CaretDownMinor : CaretUpMinor} />
                              </span>
                            </StyledHeaderButton>
                          )}
                        </th>
                      );
                    })
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()} className="Polaris-DataTable__TableRow Polaris-DataTable--hoverable">
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()} className="Polaris-DataTable__Cell">
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
            {isLoading && <ReactTableSkeleton columns={visibleColumnsCount} />}
          </tbody>
        </table>
      </div>
      {footerContent && <div className="Polaris-DataTable__Footer">{footerContent}</div>}
    </div>
  );
}

const StyledHeaderButton = styled.button`
  display: flex;
  align-items: center;
`;

const StyledTableHeader = styled.div`
  white-space: normal;
  line-height: 1.2;
  user-select: none;
`;

export default ReactTable;
