import { generatePath, useLocation } from "react-router-dom";

import api from "../../api";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import HistoryLocationState from "../../types/HistoryLocationState";

const CUSTOMER_TYPE_TO_ROUTE: Record<api.CustomerType, string> = {
  person: ROUTES.PERSON_DETAILS,
  company: ROUTES.COMPANY_DETAILS,
};

const useProjectDetailsBackButton = () => {
  const location = useLocation<HistoryLocationState>();
  const f = useFormatMessage();

  const { fromProjectsList, fromCustomer } = location.state || {};

  const showBackButton = fromProjectsList || fromCustomer !== undefined;

  const backButtonLabel = fromProjectsList ? f("projects.page.title") : fromCustomer?.name;

  // returning undefined as a URL will cause the BackButton component to call history.goBack() instead of a regular url
  // so if fromProjectsList is true then fromCustomer will be undefined, hence - backButtonURL will be undefined
  const backButtonRoute = fromCustomer?.type ? CUSTOMER_TYPE_TO_ROUTE[fromCustomer?.type] : undefined;

  const backButtonURL = backButtonRoute ? generatePath(backButtonRoute, { id: fromCustomer!.id }) : undefined;

  return { showBackButton, backButtonLabel, backButtonURL };
};

export default useProjectDetailsBackButton;
