import React from "react";
import { TextStyle } from "@shopify/polaris";

import RowHighlighter from "../extensions/RowHighlighter";

const Caption: React.FC<{ label: string; highlight?: boolean }> = (props) => {
  return (
    <RowHighlighter highlight={props.highlight}>
      <TextStyle variation="subdued">{props.label}</TextStyle>
    </RowHighlighter>
  );
};

export default Caption;
