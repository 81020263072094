import * as React from "react";
import { useEffect, useState } from "react";
import { generatePath, RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { Button, Card, DisplayText, Page, ProgressBar, Stack, TextStyle } from "@shopify/polaris";

import api from "../../api";
import AddressInfo from "../../components/AddressInfo";
import DisplayName from "../../components/DisplayName";
import ErrorPanel from "../../components/ErrorPanel";
import IndustryRisk from "../../components/IndustryRisk";
import { DEFAULT_COUNTRY } from "../../constants/countries";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import HistoryLocationState from "../../types/HistoryLocationState";
import Util from "../../utils/util";

interface ConfirmProps {
  result: api.CompanyInformationLookupInfo;
}

const Confirm: React.FC<ConfirmProps> = ({ result }) => {
  const {
    national_id,
    name,
    business_address,
    mailing_address,
    legal_entity_type,
    is_bankrupt,
    under_forced_liquidation_or_dissolution,
    under_liquidation,
    risk_information,
  } = result;

  const [isPosting, setPosting] = useState(false);
  const [error, setError] = useState<string>();
  const [disabled, setDisabled] = useState(false);
  const history = useHistory<HistoryLocationState>();

  const createdFromProject = history.location.state?.createdFromProject;

  const f = useFormatMessage();

  useEffect(() => {
    if (under_forced_liquidation_or_dissolution || under_liquidation) {
      setError(f("lookup.error.company.closing"));
      setDisabled(true);
    } else if (is_bankrupt) {
      setError(f("lookup.error.company.bankrupt"));
      setDisabled(true);
    }
  }, [under_forced_liquidation_or_dissolution, under_liquidation, is_bankrupt]);

  const description = legal_entity_type.description_nb || legal_entity_type.description_en;

  return (
    <Page title={f("lookup.title")}>
      <Stack vertical>
        <Card>
          <Card.Section>
            <Stack vertical>
              <DisplayName strong name={name} type="company" />

              <Stack>
                <TextStyle variation="subdued">{f("company.details.caption.national_id")}</TextStyle>
                <span>{national_id}</span>
              </Stack>
              <Stack>
                <TextStyle variation="subdued">{f("company.details.caption.company.type")}</TextStyle>
                <span>
                  {legal_entity_type.code} {description && `(${description})`}
                </span>
              </Stack>
              <Stack>
                <TextStyle variation="subdued">{f("company.details.caption.address")}</TextStyle>
                <span>
                  <AddressInfo address={business_address} />
                </span>
              </Stack>
              <Stack>
                <TextStyle variation="subdued">{f("company.details.caption.address.postal")}</TextStyle>
                <span>
                  <AddressInfo address={mailing_address} />
                </span>
              </Stack>
              <IndustryRisk industryRisk={risk_information} title={f("company.details.caption.industry-codes")} />
            </Stack>
          </Card.Section>
        </Card>

        {error && <ErrorPanel message={error} />}

        <Stack distribution="trailing">
          <Button
            onClick={() => {
              const redirectURLAfterClose = createdFromProject
                ? generatePath(ROUTES.PROJECT_DETAILS, { id: createdFromProject.id })
                : generatePath(ROUTES.HOME);
              history.push(redirectURLAfterClose);
            }}
          >
            {f("default.cancel")}
          </Button>{" "}
          <Button
            onClick={() => {
              const lookupPath = generatePath(ROUTES.COMPANY_LOOKUP);
              history.push(lookupPath, { createdFromProject });
            }}
          >
            {f("lookup.again")}
          </Button>
          <Button
            primary
            loading={isPosting}
            disabled={isPosting || disabled}
            onClick={() => {
              setPosting(true);
              api
                .createCompanyCustomer({
                  national_id: `${national_id}`,
                  country: DEFAULT_COUNTRY,
                  name,
                  project_id: createdFromProject?.id,
                })
                .then((r) => {
                  setPosting(false);
                  history.push(`/companies/${r.id}/screening`);
                })
                .catch((error) => {
                  setPosting(false);
                  try {
                    if (error?.status === 409) {
                      // Conflict - company customer already exists. Redirect to company customer
                      const existingCustomerId = error?.data?.id;
                      if (existingCustomerId) {
                        history.push(Util.caseLink(existingCustomerId, "company", error?.data?.onboarding_status));
                      }
                      return;
                    }
                    setError((error?.toString && error.toString()) || f("error.message.unknown"));
                  } catch {
                    setError(f("error.message.unknown"));
                  }
                });
            }}
          >
            {f("lookup.right.client")}
          </Button>
        </Stack>
      </Stack>
    </Page>
  );
};

interface NotFoundProps {
  id: string;
  error: unknown;
  history: RouteComponentProps["history"];
}

const NotFound: React.FC<NotFoundProps> = ({ id, error, history }) => {
  const f = useFormatMessage();

  const lookupPath = generatePath(ROUTES.COMPANY_LOOKUP);

  return (
    <Page>
      <Stack vertical>
        <DisplayText>
          {f("lookup.error.company.not.found")} {id}
        </DisplayText>
        <Button primary onClick={() => history.push(lookupPath)}>
          {f("lookup.again")}
        </Button>
      </Stack>
    </Page>
  );
};

const stripOrgId = (id: string) => parseInt(id ? id.replace(/\W/g, "") : id, 10);

const CompanyLookup: React.FC<RouteComponentProps<{ orgId: string }>> = ({ match, history }) => {
  const { orgId } = match.params;
  const [result, setResult] = useState<api.CompanyInformationLookupResponse>();
  const [error, setError] = useState<unknown>();
  const [isLoading, setIsLoading] = useState(true);

  const f = useFormatMessage();

  useEffect(() => {
    api
      .getCompanyInfo("NO", stripOrgId(orgId))
      .then((r) => {
        setIsLoading(false);
        setResult(r);
      })
      .catch((error: unknown) => {
        setIsLoading(false);
        setError(error);
      });
  }, []);

  if (isLoading) {
    return (
      <Page>
        <Stack vertical>
          <DisplayText size="small">{f("in.progress")}</DisplayText>
          <ProgressBar progress={70} size="small" />
        </Stack>
      </Page>
    );
  }

  if (error || !result) {
    return <NotFound id={orgId} error={error} history={history} />;
  }

  return <Confirm result={result.basic_legal_entity_info} />;
};

export default withRouter(CompanyLookup);
