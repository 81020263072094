import * as React from "react";
import { createContext, useCallback,useEffect, useState } from "react";
import { AppState, Auth0ContextInterface, Auth0Provider, LogoutOptions,useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

import api from "../api";
import history from "../utils/history";

/**
 * Set the Auth0 access token as a bearer credential in
 * the Authorization header of the api client
 */
const setApiAuthorizationHeader = (token: string) => {
  if (!api.defaults.headers) {
    api.defaults.headers = {};
  }
  api.defaults.headers["Authorization"] = `Bearer ${token}`;
};

/**
 * Auth provider that wraps Auth0Provider around BeaufortAuthProvider
 * -> An "extension" to Auth0Provider with the Beaufort user's authentication
 * state
 */
export const AuthProvider: React.FC = ({ children }) => {
  // A function that routes the user to the right place
  // after login (we currently use Auth0's universal login form)
  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      audience={"https://beaufort.io"}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <BeaufortAuthProvider>{children}</BeaufortAuthProvider>
    </Auth0Provider>
  );
};

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <AuthProvider>.");
};

interface AuthContext extends Omit<Auth0ContextInterface, "user"> {
  user?: api.AuthUser;
  isExpired: boolean; // true if the Auth0 access token has expired
}

const initialContext: AuthContext = {
  isAuthenticated: false,
  isLoading: true,
  isExpired: false,
  buildAuthorizeUrl: stub,
  buildLogoutUrl: stub,
  getAccessTokenSilently: stub,
  getAccessTokenWithPopup: stub,
  getIdTokenClaims: stub,
  loginWithRedirect: stub,
  loginWithPopup: stub,
  logout: stub,
  handleRedirectCallback: stub,
};

export const AuthContext = createContext<AuthContext>(initialContext);

/**
 * This provider extends Auth0's auth context and performs the
 * authentication of the user at both Auth0 and Beaufort.
 *
 * It sets the Authorization header on the api, and returns a consumable
 * auth context with the auth state and, if authenticated, the "Beaufort user"
 *
 * If the user authentication fails, e.g. no Beaufort-user exists for the
 * Auth0-user/email, the user is immeditaly logged out
 */
const BeaufortAuthProvider: React.FC = ({ children }) => {
  const auth0context = useAuth0();

  const [beaufortUser, setBeaufortUser] = useState<api.AuthUser>();
  const [isExpired, setIsExpired] = useState(false);

  /**
   * Override Auth0's logout function to default to return to window.location.origin
   */
  const logout = useCallback(
    (options?: LogoutOptions) => auth0context.logout(options || { returnTo: window.location.origin }),
    [auth0context.logout]
  );

  /**
   * Gets access token from Auth0 silently and sets it as a
   * the Authorization header on the API client
   */
  const getAuth0AccessToken = async () => {
    // Try to get access token from Auth0 silenty
    try {
      const auth0AccessToken = await auth0context.getAccessTokenSilently();

      // Set the Authorization header on the API client
      setApiAuthorizationHeader(auth0AccessToken);

      return auth0AccessToken;
    } catch (error) {
      // Getting the access token failed - assume that it has expired
      setIsExpired(true);
    }
  };

  /**
   * Try to authenticate the "Beaufort-user"
   *
   * If successful, it should return the authenticated Beaufort-user
   * In this case, set the user- and features-states
   *
   * If no Beaufort-user exists for the Auth0-user (ie. there are no
   * Beaufort-user with the Auth0-user's email), the request will result
   * in a 401. In this case, log out immediately
   */
  const authenticateBeaufortUser = () => {
    api
      .getLoggedInUser()
      .then((beaufortUser) => {
        // Beaufort-user successfully authenticated!

        // Set Sentry-user to the "Beaufort-user"
        Sentry.setUser({
          email: beaufortUser.email,
          provider: "Beaufort",
          organization: beaufortUser.organization.name,
          features: beaufortUser.features || {},
          permission: beaufortUser.permissions,
        });

        // Set beaufortUser-state
        setBeaufortUser(beaufortUser);
      })
      .catch(() => {
        // Assume 401
        // -> No Beaufort user found for the Auth0-user/email
        // -> Log out immediately as the user should have no access
        // --> The user should basically never be considered "logged in"
        logout();
      });
  };

  // Set up an interval to poll the access token every 15 minutes
  // to see if it has expired or not
  useEffect(() => {
    const interval = setInterval(() => {
      getAuth0AccessToken();
    }, 15 * 60000);

    return () => {
      // Clear interval on unmount
      clearInterval(interval);
    };
  }, []);

  /**
   * Set Sentry-user to the "Auth0-user" when defined
   * NOTE: this will be overwritten with the "Beaufort-user"
   * once the Beaufort-user has been authenticated
   */
  useEffect(() => {
    if (auth0context.user?.email) {
      Sentry.setUser({
        email: auth0context.user.email,
        provider: "Auth0",
      });
    }
  }, [auth0context.user]);

  /**
   * Once the Auth0-user is authenticated, try to authenticate
   * the "Beaufort-user"
   */
  useEffect(() => {
    if (auth0context.isAuthenticated) {
      getAuth0AccessToken().then((accessToken) => {
        if (accessToken && !beaufortUser) {
          authenticateBeaufortUser();
        }
      });
    }
  }, [auth0context.isAuthenticated]);

  /**
   * Capture Auth0-err0r if one occurs
   */
  useEffect(() => {
    if (auth0context.error) {
      Sentry.captureException(auth0context.error);
    }
  }, [auth0context.error]);

  const context: AuthContext = {
    ...auth0context,
    user: beaufortUser,
    isExpired,
    logout,
    // isLoading should be true as long as auth0 is loading or we haven't
    // authenticated the Beaufort user
    isLoading: auth0context.isLoading || beaufortUser === undefined,
    // isAuthenticated should only be true when the user has been authenticated
    // with both Auth0 and Beaufort
    isAuthenticated: auth0context.isAuthenticated && beaufortUser !== undefined,
  };

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
