import React from "react";

import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";
import { Customer } from "../types/utilities";
import { getCustomerName } from "../utils/displayUtils";

import { LightCustomerName } from "./DisplayName";
import EntitiesResourceList, { EntitiesResourceListProps } from "./EntitiesResourceList";

type CustomersResourceListProps = Pick<
  EntitiesResourceListProps<Customer>,
  "selectedIds" | "page" | "resetPage" | "onSelectionChange"
> & {
  existingCustomers: Customer[];
};

const CustomersResourceList: React.FC<CustomersResourceListProps> = (props) => {
  const { existingCustomers = [], ...resourceListProps } = props;

  const f = useFormatMessage();

  return (
    <EntitiesResourceList<Customer>
      getEntities={(searchParams) => api.getCustomers(searchParams).then((response) => response.customers)}
      queryKey="customers"
      allowMultiple
      queryPlaceholder={f("component.search.client.hint")}
      renderItem={(customer) => (
        <LightCustomerName type={customer.type!}>{getCustomerName(customer)}</LightCustomerName>
      )}
      existingEntities={existingCustomers}
      {...resourceListProps}
    />
  );
};

export default CustomersResourceList;
