import React from "react";
import { DataTable as PolarisDataTable, DataTableProps as PolarisDataTableProps } from "@shopify/polaris";
import styled from "styled-components";

interface DataTableProps extends PolarisDataTableProps {
  readonly?: boolean;
}

const DataTable: React.FC<DataTableProps> = ({ readonly, ...props }) => {
  if (readonly) {
    return (
      <StyledDataTableWrapper>
        <PolarisDataTable {...props} />
      </StyledDataTableWrapper>
    );
  } else {
    return <PolarisDataTable {...props} />;
  }
};

const StyledDataTableWrapper = styled.div`
  & .Polaris-DataTable__TableRow:hover > .Polaris-DataTable__Cell {
    background: transparent;
  }
`;

export default DataTable;
