import React, { ReactNode } from "react";
import { ButtonProps, Popover } from "@shopify/polaris";

import useOpenClose from "../hooks/useOpenClose";

import Button from "./extensions/Button";

export interface PopoverButtonProps extends Omit<ButtonProps, "onClick" | "children"> {
  label?: string;
  render(onClose: () => void): ReactNode;
}

const PopoverButton: React.FC<PopoverButtonProps> = (props) => {
  const { label, render, ...buttonProps } = props;

  const [openPopover, togglePopover, closePopover] = useOpenClose();

  return (
    <Popover
      active={openPopover}
      activator={
        <Button {...buttonProps} onClick={togglePopover}>
          {label}
        </Button>
      }
      onClose={closePopover}
    >
      {render(closePopover)}
    </Popover>
  );
};

export default PopoverButton;
