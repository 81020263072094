import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";

import api from "../api";

import useFeatures from "./useFeatures";
import useGetUsers from "./useGetUsers";

const useSelectUser = (
  updateSelectedUser: (user: api.User) => Promise<api.User | undefined>,
  currentUser?: api.User
) => {
  const features = useFeatures();
  const { isLoading: getUsersLoading, error: getUsersError, users: usersList } = useGetUsers(features.RESPONSIBLE_USER);
  const [selectedUserId, setSelectedUserId] = useState<string>(currentUser?.id || "");

  const initialUsersList: api.User[] = currentUser ? [currentUser] : [];
  const users = getUsersLoading || getUsersError ? initialUsersList : usersList;

  useEffect(() => {
    if (currentUser?.id) {
      setSelectedUserId(currentUser?.id);
    }
  }, [currentUser?.id]);

  const updateSelectedUserMutation = useMutation(updateSelectedUser, {
    onSuccess: (response, user) => {
      setSelectedUserId(response?.id || user.id);
    },
  });

  const selectUser = (user: api.User) => {
    if (user.id === selectedUserId) {
      return;
    }

    updateSelectedUserMutation.mutate(user);
  };

  const selectedUser = useMemo(() => {
    return users.find((user) => user.id === selectedUserId);
  }, [users, selectedUserId]);

  const disabled = users.length <= 1;

  return {
    loading: updateSelectedUserMutation.isLoading,
    disabled,
    hasError: updateSelectedUserMutation.isError,
    selectedUser,
    selectUser,
    users,
  };
};

export default useSelectUser;
