import React, { useCallback, useMemo } from "react";
import { Autocomplete } from "@shopify/polaris";
import { OptionDescriptor, SectionDescriptor } from "@shopify/polaris/build/ts/latest/src/types";

import api from "../../../api";
import { ERROR_DEBOUNCE_DELAY_IN_MILLISECONDS } from "../../../constants/attachment-signature-statuses";
import RoleTypes from "../../../constants/role-types";
import useDebounceValue from "../../../hooks/useDebouncedValue";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { getFullName } from "../../../utils/displayUtils";
import DisplayName from "../../DisplayName";
import { AttachmentSignatureRow } from "../useAttachmentSignatures";

// a signer suggestion that has an optional role type and role id
type SignerSuggestion = api.Person & {
  roleId?: string;
  roleType?: api.RoleType;
};

export type SignerSuggestionsSection = {
  name: string;
  suggestions: SignerSuggestion[];
};

interface AttachmentSignatureNameFieldProps {
  row: AttachmentSignatureRow;
  signerSuggestionSections: SignerSuggestionsSection[];
  selectedSignerSuggestionRoleType?: api.RoleType;
  onNameChange(row: AttachmentSignatureRow, name: string): void;
  onSelectSignerSuggestion(row: AttachmentSignatureRow, personId: string, personName: string, roleId?: string): void;
  onClearSignerSuggestion(row: AttachmentSignatureRow): void;
}

const AttachmentSignatureNameField: React.FC<AttachmentSignatureNameFieldProps> = (props) => {
  const {
    row,
    signerSuggestionSections,
    selectedSignerSuggestionRoleType,
    onNameChange,
    onSelectSignerSuggestion,
    onClearSignerSuggestion,
  } = props;
  const error = row.validationErrors.get("fullName");
  // if there's no error - don't wait to remove the error
  const debouncedError = useDebounceValue(error, error ? ERROR_DEBOUNCE_DELAY_IN_MILLISECONDS : 0);

  const f = useFormatMessage();

  const getPersonDisplayName = (person: { first_name: string; last_name: string }, roleType?: api.RoleType) =>
    roleType ? `${getFullName(person)} (${f(RoleTypes[roleType])})` : getFullName(person);

  const convertToOption = (suggestedPerson: SignerSuggestion): OptionDescriptor => ({
    value: suggestedPerson.id,
    label: <DisplayName type="person" name={getFullName(suggestedPerson)} roleType={suggestedPerson.roleType} />,
  });

  const nameOptions: SectionDescriptor[] = useMemo(
    () =>
      signerSuggestionSections.map((suggestionSection) => ({
        title: suggestionSection.name,
        options: suggestionSection.suggestions.map(convertToOption),
      })),
    [signerSuggestionSections]
  );

  // this map is used when filtering the suggestions list according to the input value
  const suggestionsRolesMap = useMemo(
    () =>
      new Map(
        signerSuggestionSections
          // flatMap converts an array of arrays into a single array ([[obj1], [obj2, obj3], [obj4]] -> [obj1, obj2, obj3, obj4])
          // by creating a new Map with an array of key roles ([role.id, role]) we can access a role by its person id
          .flatMap((suggestionSection) => suggestionSection.suggestions)
          .map((suggestedPerson) => [suggestedPerson.id, suggestedPerson])
      ),
    [signerSuggestionSections]
  );

  const filterNameOptions = (name: string) =>
    nameOptions.map((nameOption) => ({
      ...nameOption,
      options: nameOption.options.filter(({ value }) => {
        const suggestedPerson = value ? suggestionsRolesMap.get(value) : undefined;
        if (!suggestedPerson) {
          return true;
        }

        // search for the suggested person based on its person id and check if their display name
        // includes the search term
        return getPersonDisplayName(suggestedPerson, suggestedPerson.roleType)
          .toLowerCase()
          .includes(name.toLowerCase());
      }),
    }));

  const handleSuggestionSelect = useCallback(
    (personId: string) => {
      const suggestPerson = suggestionsRolesMap.get(personId)!;
      onSelectSignerSuggestion(row, suggestPerson.id, getFullName(suggestPerson), suggestPerson.roleId);
    },
    [signerSuggestionSections]
  );

  const name = row.entity.fullName;
  const signerId = row.entity.signer_id;
  const isSuggestionSelected = signerId !== undefined;
  const displayName = selectedSignerSuggestionRoleType
    ? `${name} (${f(RoleTypes[selectedSignerSuggestionRoleType])})`
    : name;

  return (
    <Autocomplete
      options={signerId ? nameOptions : filterNameOptions(name)}
      selected={signerId ? [signerId] : []}
      allowMultiple={false}
      onSelect={([signerId]) => handleSuggestionSelect(signerId)}
      textField={
        <Autocomplete.TextField
          label=""
          labelHidden
          autoFocus={!row.isLocked && nameOptions.length === 0}
          placeholder={f("attachments.signatures.modal.name.placeholder")}
          readOnly={isSuggestionSelected || row.isLocked}
          value={displayName}
          onChange={(value) => onNameChange(row, value)}
          clearButton={isSuggestionSelected}
          onClearButtonClick={() => onClearSignerSuggestion(row)}
          error={debouncedError}
          autoComplete="off"
        />
      }
    />
  );
};

export default AttachmentSignatureNameField;
