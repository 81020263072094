import * as React from "react";
import { useState } from "react";
import { Button, Stack } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import Confirm from "../modals/Confirm";

interface ApproveRejectReviewProps {
  id: string;
  review: api.CustomerReview;
  readonly?: boolean;
  onServerResponse(response: api.CustomerReviewApproval): void;
}

const ApproveRejectReview: React.FC<ApproveRejectReviewProps> = ({ id, review, readonly, onServerResponse }) => {
  const [isConfirmationActive, setConfirmationActive] = useState(false);
  const [isApproving, setApproving] = useState(false);
  const [isRejecting, setRejecting] = useState(false);

  const f = useFormatMessage();

  return (
    <Stack distribution="trailing">
      {isConfirmationActive && (
        <Confirm
          isOpen={isConfirmationActive}
          title={f("details.reviews.confirm.reject.title")}
          onYes={() => {
            setRejecting(true);

            api
              .rejectCustomerReview(id, review.id)
              .then((r) => {
                onServerResponse(r);
                setRejecting(false);
                setConfirmationActive(false);
              })
              .catch(() => {
                setRejecting(false);
              });
          }}
          isDestructive={true}
          onNo={() => setConfirmationActive(false)}
          actionTitle={f("details.reviews.confirm.reject")}
          cancelTitle={f("details.reviews.confirm.cancel")}
          isActionInProgress={isRejecting}
          description={f("details.reviews.confirm.reject.description")}
        />
      )}
      <Button disabled={isApproving || readonly} onClick={() => setConfirmationActive(true)}>
        {f("common.labels.reject")}
      </Button>
      <Button
        loading={isApproving}
        disabled={isRejecting || readonly}
        primary
        onClick={() => {
          setApproving(true);
          api
            .approveCustomerReview(id, review.id)
            .then((r) => {
              onServerResponse(r);
              setApproving(false);
            })
            .catch(() => {
              setApproving(false);
            });
        }}
      >
        {f("details.reviews.confirm.approve")}
      </Button>
    </Stack>
  );
};

export default ApproveRejectReview;
