import api from "../api";

export type SelectionMap = Record<string, boolean>;

export type EntityWithId = { id: string };

export type Customer = api.PersonCustomer | api.CompanyCustomer;

export type CustomerDetails = api.PersonCustomerDetailResponse | api.CompanyCustomerDetailResponse;

export type Entity = Customer | api.Project;

export const isPersonCustomer = (entity: Entity): entity is api.PersonCustomer =>
  (entity as api.PersonCustomer).type === "person";

export const isPersonCustomerDetails = (entity: CustomerDetails): entity is api.PersonCustomerDetailResponse =>
  (entity as api.PersonCustomerDetailResponse).type === "person";

export const isProject = (entity: Entity): entity is api.Project => (entity as api.Project).customers !== undefined;
