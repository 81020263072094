export { S as AbandonedCartMajor } from './icons/AbandonedCartMajor.svg.mjs';
export { S as AccessibilityMajor } from './icons/AccessibilityMajor.svg.mjs';
export { S as ActivitiesMajor } from './icons/ActivitiesMajor.svg.mjs';
export { S as AddCodeMajor } from './icons/AddCodeMajor.svg.mjs';
export { S as AddImageMajor } from './icons/AddImageMajor.svg.mjs';
export { S as AddMajor } from './icons/AddMajor.svg.mjs';
export { S as AddNoteMajor } from './icons/AddNoteMajor.svg.mjs';
export { S as AddProductMajor } from './icons/AddProductMajor.svg.mjs';
export { S as AffiliateMajor } from './icons/AffiliateMajor.svg.mjs';
export { S as AlertMinor } from './icons/AlertMinor.svg.mjs';
export { S as AnalyticsMajor } from './icons/AnalyticsMajor.svg.mjs';
export { S as AnalyticsMinor } from './icons/AnalyticsMinor.svg.mjs';
export { S as AppExtensionMinor } from './icons/AppExtensionMinor.svg.mjs';
export { S as AppsMajor } from './icons/AppsMajor.svg.mjs';
export { S as ArchiveMajor } from './icons/ArchiveMajor.svg.mjs';
export { S as ArchiveMinor } from './icons/ArchiveMinor.svg.mjs';
export { S as ArrowDownMinor } from './icons/ArrowDownMinor.svg.mjs';
export { S as ArrowLeftMinor } from './icons/ArrowLeftMinor.svg.mjs';
export { S as ArrowRightMinor } from './icons/ArrowRightMinor.svg.mjs';
export { S as ArrowUpMinor } from './icons/ArrowUpMinor.svg.mjs';
export { S as AttachmentMajor } from './icons/AttachmentMajor.svg.mjs';
export { S as AutomationMajor } from './icons/AutomationMajor.svg.mjs';
export { S as BackspaceMajor } from './icons/BackspaceMajor.svg.mjs';
export { S as BalanceMajor } from './icons/BalanceMajor.svg.mjs';
export { S as BankMajor } from './icons/BankMajor.svg.mjs';
export { S as BarcodeMajor } from './icons/BarcodeMajor.svg.mjs';
export { S as BehaviorMajor } from './icons/BehaviorMajor.svg.mjs';
export { S as BillingStatementDollarMajor } from './icons/BillingStatementDollarMajor.svg.mjs';
export { S as BillingStatementEuroMajor } from './icons/BillingStatementEuroMajor.svg.mjs';
export { S as BillingStatementPoundMajor } from './icons/BillingStatementPoundMajor.svg.mjs';
export { S as BillingStatementRupeeMajor } from './icons/BillingStatementRupeeMajor.svg.mjs';
export { S as BillingStatementYenMajor } from './icons/BillingStatementYenMajor.svg.mjs';
export { S as BlockMinor } from './icons/BlockMinor.svg.mjs';
export { S as BlockquoteMajor } from './icons/BlockquoteMajor.svg.mjs';
export { S as BlogMajor } from './icons/BlogMajor.svg.mjs';
export { S as BugMajor } from './icons/BugMajor.svg.mjs';
export { S as ButtonCornerPillMajor } from './icons/ButtonCornerPillMajor.svg.mjs';
export { S as ButtonCornerRoundedMajor } from './icons/ButtonCornerRoundedMajor.svg.mjs';
export { S as ButtonCornerSquareMajor } from './icons/ButtonCornerSquareMajor.svg.mjs';
export { S as ButtonMinor } from './icons/ButtonMinor.svg.mjs';
export { S as BuyButtonButtonLayoutMajor } from './icons/BuyButtonButtonLayoutMajor.svg.mjs';
export { S as BuyButtonHorizontalLayoutMajor } from './icons/BuyButtonHorizontalLayoutMajor.svg.mjs';
export { S as BuyButtonMajor } from './icons/BuyButtonMajor.svg.mjs';
export { S as BuyButtonVerticalLayoutMajor } from './icons/BuyButtonVerticalLayoutMajor.svg.mjs';
export { S as CalendarMajor } from './icons/CalendarMajor.svg.mjs';
export { S as CalendarMinor } from './icons/CalendarMinor.svg.mjs';
export { S as CalendarTickMajor } from './icons/CalendarTickMajor.svg.mjs';
export { S as CameraMajor } from './icons/CameraMajor.svg.mjs';
export { S as CancelSmallMinor } from './icons/CancelSmallMinor.svg.mjs';
export { S as CapitalMajor } from './icons/CapitalMajor.svg.mjs';
export { S as CapturePaymentMinor } from './icons/CapturePaymentMinor.svg.mjs';
export { S as CardReaderChipMajor } from './icons/CardReaderChipMajor.svg.mjs';
export { S as CardReaderMajor } from './icons/CardReaderMajor.svg.mjs';
export { S as CardReaderTapMajor } from './icons/CardReaderTapMajor.svg.mjs';
export { S as CaretDownMinor } from './icons/CaretDownMinor.svg.mjs';
export { S as CaretUpMinor } from './icons/CaretUpMinor.svg.mjs';
export { S as CartDownMajor } from './icons/CartDownMajor.svg.mjs';
export { S as CartMajor } from './icons/CartMajor.svg.mjs';
export { S as CartUpMajor } from './icons/CartUpMajor.svg.mjs';
export { S as CashDollarMajor } from './icons/CashDollarMajor.svg.mjs';
export { S as CashEuroMajor } from './icons/CashEuroMajor.svg.mjs';
export { S as CashPoundMajor } from './icons/CashPoundMajor.svg.mjs';
export { S as CashRupeeMajor } from './icons/CashRupeeMajor.svg.mjs';
export { S as CashYenMajor } from './icons/CashYenMajor.svg.mjs';
export { S as CategoriesMajor } from './icons/CategoriesMajor.svg.mjs';
export { S as ChannelsMajor } from './icons/ChannelsMajor.svg.mjs';
export { S as ChatMajor } from './icons/ChatMajor.svg.mjs';
export { S as ChecklistAlternateMajor } from './icons/ChecklistAlternateMajor.svg.mjs';
export { S as ChecklistMajor } from './icons/ChecklistMajor.svg.mjs';
export { S as CheckoutMajor } from './icons/CheckoutMajor.svg.mjs';
export { S as ChevronDownMinor } from './icons/ChevronDownMinor.svg.mjs';
export { S as ChevronLeftMinor } from './icons/ChevronLeftMinor.svg.mjs';
export { S as ChevronRightMinor } from './icons/ChevronRightMinor.svg.mjs';
export { S as ChevronUpMinor } from './icons/ChevronUpMinor.svg.mjs';
export { S as CircleAlertMajor } from './icons/CircleAlertMajor.svg.mjs';
export { S as CircleCancelMajor } from './icons/CircleCancelMajor.svg.mjs';
export { S as CircleCancelMinor } from './icons/CircleCancelMinor.svg.mjs';
export { S as CircleChevronDownMinor } from './icons/CircleChevronDownMinor.svg.mjs';
export { S as CircleChevronLeftMinor } from './icons/CircleChevronLeftMinor.svg.mjs';
export { S as CircleChevronRightMinor } from './icons/CircleChevronRightMinor.svg.mjs';
export { S as CircleChevronUpMinor } from './icons/CircleChevronUpMinor.svg.mjs';
export { S as CircleDisabledMajor } from './icons/CircleDisabledMajor.svg.mjs';
export { S as CircleDisableMinor } from './icons/CircleDisableMinor.svg.mjs';
export { S as CircleDotsMajor } from './icons/CircleDotsMajor.svg.mjs';
export { S as CircleDownMajor } from './icons/CircleDownMajor.svg.mjs';
export { S as CircleInformationMajor } from './icons/CircleInformationMajor.svg.mjs';
export { S as CircleLeftMajor } from './icons/CircleLeftMajor.svg.mjs';
export { S as CircleMinusMajor } from './icons/CircleMinusMajor.svg.mjs';
export { S as CircleMinusMinor } from './icons/CircleMinusMinor.svg.mjs';
export { S as CircleMinusOutlineMinor } from './icons/CircleMinusOutlineMinor.svg.mjs';
export { S as CirclePlusMajor } from './icons/CirclePlusMajor.svg.mjs';
export { S as CirclePlusMinor } from './icons/CirclePlusMinor.svg.mjs';
export { S as CirclePlusOutlineMinor } from './icons/CirclePlusOutlineMinor.svg.mjs';
export { S as CircleRightMajor } from './icons/CircleRightMajor.svg.mjs';
export { S as CircleTickMajor } from './icons/CircleTickMajor.svg.mjs';
export { S as CircleTickMinor } from './icons/CircleTickMinor.svg.mjs';
export { S as CircleTickOutlineMinor } from './icons/CircleTickOutlineMinor.svg.mjs';
export { S as CircleUpMajor } from './icons/CircleUpMajor.svg.mjs';
export { S as ClipboardMinor } from './icons/ClipboardMinor.svg.mjs';
export { S as ClockMajor } from './icons/ClockMajor.svg.mjs';
export { S as ClockMinor } from './icons/ClockMinor.svg.mjs';
export { S as CodeMajor } from './icons/CodeMajor.svg.mjs';
export { S as CollectionsMajor } from './icons/CollectionsMajor.svg.mjs';
export { S as ColorNoneMinor } from './icons/ColorNoneMinor.svg.mjs';
export { S as ColorsMajor } from './icons/ColorsMajor.svg.mjs';
export { S as Column1Major } from './icons/Column1Major.svg.mjs';
export { S as Columns2Major } from './icons/Columns2Major.svg.mjs';
export { S as Columns3Major } from './icons/Columns3Major.svg.mjs';
export { S as Columns3Minor } from './icons/Columns3Minor.svg.mjs';
export { S as ColumnWithTextMajor } from './icons/ColumnWithTextMajor.svg.mjs';
export { S as ComposeMajor } from './icons/ComposeMajor.svg.mjs';
export { S as ConfettiMajor } from './icons/ConfettiMajor.svg.mjs';
export { S as ConnectMinor } from './icons/ConnectMinor.svg.mjs';
export { S as ConversationMinor } from './icons/ConversationMinor.svg.mjs';
export { S as CreditCardMajor } from './icons/CreditCardMajor.svg.mjs';
export { S as CreditCardPercentMajor } from './icons/CreditCardPercentMajor.svg.mjs';
export { S as CreditCardSecureMajor } from './icons/CreditCardSecureMajor.svg.mjs';
export { S as CurrencyConvertMinor } from './icons/CurrencyConvertMinor.svg.mjs';
export { S as CustomerMinusMajor } from './icons/CustomerMinusMajor.svg.mjs';
export { S as CustomerPlusMajor } from './icons/CustomerPlusMajor.svg.mjs';
export { S as CustomersMajor } from './icons/CustomersMajor.svg.mjs';
export { S as CustomersMinor } from './icons/CustomersMinor.svg.mjs';
export { S as DataVisualizationMajor } from './icons/DataVisualizationMajor.svg.mjs';
export { S as DeleteMajor } from './icons/DeleteMajor.svg.mjs';
export { S as DeleteMinor } from './icons/DeleteMinor.svg.mjs';
export { S as DesktopMajor } from './icons/DesktopMajor.svg.mjs';
export { S as DetailedPopUpMajor } from './icons/DetailedPopUpMajor.svg.mjs';
export { S as DiamondAlertMajor } from './icons/DiamondAlertMajor.svg.mjs';
export { S as DigitalMediaReceiverMajor } from './icons/DigitalMediaReceiverMajor.svg.mjs';
export { S as DiscountAutomaticMajor } from './icons/DiscountAutomaticMajor.svg.mjs';
export { S as DiscountCodeMajor } from './icons/DiscountCodeMajor.svg.mjs';
export { S as DiscountsMajor } from './icons/DiscountsMajor.svg.mjs';
export { S as DiscountsMinor } from './icons/DiscountsMinor.svg.mjs';
export { S as DisputeMinor } from './icons/DisputeMinor.svg.mjs';
export { S as DnsSettingsMajor } from './icons/DnsSettingsMajor.svg.mjs';
export { S as DomainNewMajor } from './icons/DomainNewMajor.svg.mjs';
export { S as DomainsMajor } from './icons/DomainsMajor.svg.mjs';
export { S as DraftOrdersMajor } from './icons/DraftOrdersMajor.svg.mjs';
export { S as DragDropMajor } from './icons/DragDropMajor.svg.mjs';
export { S as DragHandleMinor } from './icons/DragHandleMinor.svg.mjs';
export { S as DropdownMinor } from './icons/DropdownMinor.svg.mjs';
export { S as DuplicateMinor } from './icons/DuplicateMinor.svg.mjs';
export { S as EditMajor } from './icons/EditMajor.svg.mjs';
export { S as EditMinor } from './icons/EditMinor.svg.mjs';
export { S as EmailMajor } from './icons/EmailMajor.svg.mjs';
export { S as EmailNewsletterMajor } from './icons/EmailNewsletterMajor.svg.mjs';
export { S as EmbedMinor } from './icons/EmbedMinor.svg.mjs';
export { S as EnableSelectionMinor } from './icons/EnableSelectionMinor.svg.mjs';
export { S as EnvelopeMajor } from './icons/EnvelopeMajor.svg.mjs';
export { S as ExchangeMajor } from './icons/ExchangeMajor.svg.mjs';
export { S as ExistingInventoryMajor } from './icons/ExistingInventoryMajor.svg.mjs';
export { S as ExitMajor } from './icons/ExitMajor.svg.mjs';
export { S as ExportMinor } from './icons/ExportMinor.svg.mjs';
export { S as ExternalMinor } from './icons/ExternalMinor.svg.mjs';
export { S as ExternalSmallMinor } from './icons/ExternalSmallMinor.svg.mjs';
export { S as FaviconMajor } from './icons/FaviconMajor.svg.mjs';
export { S as FavoriteMajor } from './icons/FavoriteMajor.svg.mjs';
export { S as FeaturedCollectionMajor } from './icons/FeaturedCollectionMajor.svg.mjs';
export { S as FeaturedContentMajor } from './icons/FeaturedContentMajor.svg.mjs';
export { S as FilterMajor } from './icons/FilterMajor.svg.mjs';
export { S as FinancesMajor } from './icons/FinancesMajor.svg.mjs';
export { S as FinancesMinor } from './icons/FinancesMinor.svg.mjs';
export { S as FirstOrderMajor } from './icons/FirstOrderMajor.svg.mjs';
export { S as FirstVisitMajor } from './icons/FirstVisitMajor.svg.mjs';
export { S as FlagMajor } from './icons/FlagMajor.svg.mjs';
export { S as FlipCameraMajor } from './icons/FlipCameraMajor.svg.mjs';
export { S as FolderDownMajor } from './icons/FolderDownMajor.svg.mjs';
export { S as FolderMajor } from './icons/FolderMajor.svg.mjs';
export { S as FolderMinusMajor } from './icons/FolderMinusMajor.svg.mjs';
export { S as FolderPlusMajor } from './icons/FolderPlusMajor.svg.mjs';
export { S as FolderUpMajor } from './icons/FolderUpMajor.svg.mjs';
export { S as FollowUpEmailMajor } from './icons/FollowUpEmailMajor.svg.mjs';
export { S as FoodMajor } from './icons/FoodMajor.svg.mjs';
export { S as FooterMajor } from './icons/FooterMajor.svg.mjs';
export { S as FormsMajor } from './icons/FormsMajor.svg.mjs';
export { S as FraudProtectMajor } from './icons/FraudProtectMajor.svg.mjs';
export { S as FraudProtectMinor } from './icons/FraudProtectMinor.svg.mjs';
export { S as FraudProtectPendingMajor } from './icons/FraudProtectPendingMajor.svg.mjs';
export { S as FraudProtectPendingMinor } from './icons/FraudProtectPendingMinor.svg.mjs';
export { S as FraudProtectUnprotectedMajor } from './icons/FraudProtectUnprotectedMajor.svg.mjs';
export { S as FraudProtectUnprotectedMinor } from './icons/FraudProtectUnprotectedMinor.svg.mjs';
export { S as GamesConsoleMajor } from './icons/GamesConsoleMajor.svg.mjs';
export { S as GiftCardMajor } from './icons/GiftCardMajor.svg.mjs';
export { S as GiftCardMinor } from './icons/GiftCardMinor.svg.mjs';
export { S as GlobeMajor } from './icons/GlobeMajor.svg.mjs';
export { S as GlobeMinor } from './icons/GlobeMinor.svg.mjs';
export { S as GrammarMajor } from './icons/GrammarMajor.svg.mjs';
export { S as HashtagMajor } from './icons/HashtagMajor.svg.mjs';
export { S as HeaderMajor } from './icons/HeaderMajor.svg.mjs';
export { S as HeartMajor } from './icons/HeartMajor.svg.mjs';
export { S as HideKeyboardMajor } from './icons/HideKeyboardMajor.svg.mjs';
export { S as HideMinor } from './icons/HideMinor.svg.mjs';
export { S as HintMajor } from './icons/HintMajor.svg.mjs';
export { S as HomeMajor } from './icons/HomeMajor.svg.mjs';
export { S as HomeMinor } from './icons/HomeMinor.svg.mjs';
export { S as HorizontalDotsMinor } from './icons/HorizontalDotsMinor.svg.mjs';
export { S as IconsMajor } from './icons/IconsMajor.svg.mjs';
export { S as IllustrationMajor } from './icons/IllustrationMajor.svg.mjs';
export { S as ImageAltMajor } from './icons/ImageAltMajor.svg.mjs';
export { S as ImageAltMinor } from './icons/ImageAltMinor.svg.mjs';
export { S as ImageMajor } from './icons/ImageMajor.svg.mjs';
export { S as ImagesMajor } from './icons/ImagesMajor.svg.mjs';
export { S as ImageWithTextMajor } from './icons/ImageWithTextMajor.svg.mjs';
export { S as ImageWithTextOverlayMajor } from './icons/ImageWithTextOverlayMajor.svg.mjs';
export { S as ImportMinor } from './icons/ImportMinor.svg.mjs';
export { S as ImportStoreMajor } from './icons/ImportStoreMajor.svg.mjs';
export { S as IncomingMajor } from './icons/IncomingMajor.svg.mjs';
export { S as InfoMinor } from './icons/InfoMinor.svg.mjs';
export { S as InstallMinor } from './icons/InstallMinor.svg.mjs';
export { S as InventoryMajor } from './icons/InventoryMajor.svg.mjs';
export { S as InviteMinor } from './icons/InviteMinor.svg.mjs';
export { S as IqMajor } from './icons/IqMajor.svg.mjs';
export { S as JobsMajor } from './icons/JobsMajor.svg.mjs';
export { S as KeyMajor } from './icons/KeyMajor.svg.mjs';
export { S as LabelPrinterMajor } from './icons/LabelPrinterMajor.svg.mjs';
export { S as LandingPageMajor } from './icons/LandingPageMajor.svg.mjs';
export { S as LanguageMinor } from './icons/LanguageMinor.svg.mjs';
export { S as LegalMajor } from './icons/LegalMajor.svg.mjs';
export { S as LinkMinor } from './icons/LinkMinor.svg.mjs';
export { S as ListMajor } from './icons/ListMajor.svg.mjs';
export { S as LiveViewMajor } from './icons/LiveViewMajor.svg.mjs';
export { S as LocationMajor } from './icons/LocationMajor.svg.mjs';
export { S as LocationsMinor } from './icons/LocationsMinor.svg.mjs';
export { S as LockMajor } from './icons/LockMajor.svg.mjs';
export { S as LockMinor } from './icons/LockMinor.svg.mjs';
export { S as LogoBlockMajor } from './icons/LogoBlockMajor.svg.mjs';
export { S as LogOutMinor } from './icons/LogOutMinor.svg.mjs';
export { S as ManagedStoreMajor } from './icons/ManagedStoreMajor.svg.mjs';
export { S as MarketingMajor } from './icons/MarketingMajor.svg.mjs';
export { S as MarketingMinor } from './icons/MarketingMinor.svg.mjs';
export { S as MarkFulfilledMinor } from './icons/MarkFulfilledMinor.svg.mjs';
export { S as MarkPaidMinor } from './icons/MarkPaidMinor.svg.mjs';
export { S as MaximizeMajor } from './icons/MaximizeMajor.svg.mjs';
export { S as MaximizeMinor } from './icons/MaximizeMinor.svg.mjs';
export { S as MentionMajor } from './icons/MentionMajor.svg.mjs';
export { S as MicrophoneMajor } from './icons/MicrophoneMajor.svg.mjs';
export { S as MinimizeMajor } from './icons/MinimizeMajor.svg.mjs';
export { S as MinimizeMinor } from './icons/MinimizeMinor.svg.mjs';
export { S as MinusMinor } from './icons/MinusMinor.svg.mjs';
export { S as MobileAcceptMajor } from './icons/MobileAcceptMajor.svg.mjs';
export { S as MobileBackArrowMajor } from './icons/MobileBackArrowMajor.svg.mjs';
export { S as MobileCancelMajor } from './icons/MobileCancelMajor.svg.mjs';
export { S as MobileChevronMajor } from './icons/MobileChevronMajor.svg.mjs';
export { S as MobileHamburgerMajor } from './icons/MobileHamburgerMajor.svg.mjs';
export { S as MobileHorizontalDotsMajor } from './icons/MobileHorizontalDotsMajor.svg.mjs';
export { S as MobileMajor } from './icons/MobileMajor.svg.mjs';
export { S as MobilePlusMajor } from './icons/MobilePlusMajor.svg.mjs';
export { S as MobileVerticalDotsMajor } from './icons/MobileVerticalDotsMajor.svg.mjs';
export { S as MonerisMajor } from './icons/MonerisMajor.svg.mjs';
export { S as NatureMajor } from './icons/NatureMajor.svg.mjs';
export { S as NavigationMajor } from './icons/NavigationMajor.svg.mjs';
export { S as NoteMajor } from './icons/NoteMajor.svg.mjs';
export { S as NoteMinor } from './icons/NoteMinor.svg.mjs';
export { S as NotificationMajor } from './icons/NotificationMajor.svg.mjs';
export { S as OnlineStoreMajor } from './icons/OnlineStoreMajor.svg.mjs';
export { S as OnlineStoreMinor } from './icons/OnlineStoreMinor.svg.mjs';
export { S as OrdersMajor } from './icons/OrdersMajor.svg.mjs';
export { S as OrdersMinor } from './icons/OrdersMinor.svg.mjs';
export { S as OrderStatusMinor } from './icons/OrderStatusMinor.svg.mjs';
export { S as OutgoingMajor } from './icons/OutgoingMajor.svg.mjs';
export { S as PackageMajor } from './icons/PackageMajor.svg.mjs';
export { S as PageDownMajor } from './icons/PageDownMajor.svg.mjs';
export { S as PageMajor } from './icons/PageMajor.svg.mjs';
export { S as PageMinusMajor } from './icons/PageMinusMajor.svg.mjs';
export { S as PagePlusMajor } from './icons/PagePlusMajor.svg.mjs';
export { S as PageUpMajor } from './icons/PageUpMajor.svg.mjs';
export { S as PaginationEndMinor } from './icons/PaginationEndMinor.svg.mjs';
export { S as PaginationStartMinor } from './icons/PaginationStartMinor.svg.mjs';
export { S as PaintBrushMajor } from './icons/PaintBrushMajor.svg.mjs';
export { S as PauseCircleMajor } from './icons/PauseCircleMajor.svg.mjs';
export { S as PauseMajor } from './icons/PauseMajor.svg.mjs';
export { S as PauseMinor } from './icons/PauseMinor.svg.mjs';
export { S as PaymentsMajor } from './icons/PaymentsMajor.svg.mjs';
export { S as PhoneInMajor } from './icons/PhoneInMajor.svg.mjs';
export { S as PhoneMajor } from './icons/PhoneMajor.svg.mjs';
export { S as PhoneOutMajor } from './icons/PhoneOutMajor.svg.mjs';
export { S as PinMajor } from './icons/PinMajor.svg.mjs';
export { S as PinMinor } from './icons/PinMinor.svg.mjs';
export { S as PlayCircleMajor } from './icons/PlayCircleMajor.svg.mjs';
export { S as PlayMajor } from './icons/PlayMajor.svg.mjs';
export { S as PlayMinor } from './icons/PlayMinor.svg.mjs';
export { S as PlusMinor } from './icons/PlusMinor.svg.mjs';
export { S as PointOfSaleMajor } from './icons/PointOfSaleMajor.svg.mjs';
export { S as PopularMajor } from './icons/PopularMajor.svg.mjs';
export { S as PriceLookupMinor } from './icons/PriceLookupMinor.svg.mjs';
export { S as PrintMajor } from './icons/PrintMajor.svg.mjs';
export { S as PrintMinor } from './icons/PrintMinor.svg.mjs';
export { S as ProductReturnsMinor } from './icons/ProductReturnsMinor.svg.mjs';
export { S as ProductsMajor } from './icons/ProductsMajor.svg.mjs';
export { S as ProductsMinor } from './icons/ProductsMinor.svg.mjs';
export { S as ProfileMajor } from './icons/ProfileMajor.svg.mjs';
export { S as ProfileMinor } from './icons/ProfileMinor.svg.mjs';
export { S as PromoteMinor } from './icons/PromoteMinor.svg.mjs';
export { S as QuestionMarkInverseMajor } from './icons/QuestionMarkInverseMajor.svg.mjs';
export { S as QuestionMarkInverseMinor } from './icons/QuestionMarkInverseMinor.svg.mjs';
export { S as QuestionMarkMajor } from './icons/QuestionMarkMajor.svg.mjs';
export { S as QuestionMarkMinor } from './icons/QuestionMarkMinor.svg.mjs';
export { S as QuickSaleMajor } from './icons/QuickSaleMajor.svg.mjs';
export { S as ReadTimeMinor } from './icons/ReadTimeMinor.svg.mjs';
export { S as ReceiptMajor } from './icons/ReceiptMajor.svg.mjs';
export { S as RecentSearchesMajor } from './icons/RecentSearchesMajor.svg.mjs';
export { S as RedoMajor } from './icons/RedoMajor.svg.mjs';
export { S as ReferralCodeMajor } from './icons/ReferralCodeMajor.svg.mjs';
export { S as ReferralMajor } from './icons/ReferralMajor.svg.mjs';
export { S as RefreshMajor } from './icons/RefreshMajor.svg.mjs';
export { S as RefreshMinor } from './icons/RefreshMinor.svg.mjs';
export { S as RefundMajor } from './icons/RefundMajor.svg.mjs';
export { S as RefundMinor } from './icons/RefundMinor.svg.mjs';
export { S as RemoveProductMajor } from './icons/RemoveProductMajor.svg.mjs';
export { S as RepeatOrderMajor } from './icons/RepeatOrderMajor.svg.mjs';
export { S as ReplaceMajor } from './icons/ReplaceMajor.svg.mjs';
export { S as ReplayMinor } from './icons/ReplayMinor.svg.mjs';
export { S as ReportMinor } from './icons/ReportMinor.svg.mjs';
export { S as ReportsMajor } from './icons/ReportsMajor.svg.mjs';
export { S as ResetMinor } from './icons/ResetMinor.svg.mjs';
export { S as ResourcesMajor } from './icons/ResourcesMajor.svg.mjs';
export { S as ReturnMinor } from './icons/ReturnMinor.svg.mjs';
export { S as RiskMajor } from './icons/RiskMajor.svg.mjs';
export { S as RiskMinor } from './icons/RiskMinor.svg.mjs';
export { S as SandboxMajor } from './icons/SandboxMajor.svg.mjs';
export { S as SaveMinor } from './icons/SaveMinor.svg.mjs';
export { S as SearchMajor } from './icons/SearchMajor.svg.mjs';
export { S as SearchMinor } from './icons/SearchMinor.svg.mjs';
export { S as SectionMajor } from './icons/SectionMajor.svg.mjs';
export { S as SecureMajor } from './icons/SecureMajor.svg.mjs';
export { S as SelectMinor } from './icons/SelectMinor.svg.mjs';
export { S as SendMajor } from './icons/SendMajor.svg.mjs';
export { S as SettingsMajor } from './icons/SettingsMajor.svg.mjs';
export { S as SettingsMinor } from './icons/SettingsMinor.svg.mjs';
export { S as ShareIosMinor } from './icons/ShareIosMinor.svg.mjs';
export { S as ShareMinor } from './icons/ShareMinor.svg.mjs';
export { S as ShipmentMajor } from './icons/ShipmentMajor.svg.mjs';
export { S as ShopcodesMajor } from './icons/ShopcodesMajor.svg.mjs';
export { S as SidebarLeftMajor } from './icons/SidebarLeftMajor.svg.mjs';
export { S as SidebarRightMajor } from './icons/SidebarRightMajor.svg.mjs';
export { S as SlideshowMajor } from './icons/SlideshowMajor.svg.mjs';
export { S as SmileyHappyMajor } from './icons/SmileyHappyMajor.svg.mjs';
export { S as SmileyJoyMajor } from './icons/SmileyJoyMajor.svg.mjs';
export { S as SmileyNeutralMajor } from './icons/SmileyNeutralMajor.svg.mjs';
export { S as SmileySadMajor } from './icons/SmileySadMajor.svg.mjs';
export { S as SocialAdMajor } from './icons/SocialAdMajor.svg.mjs';
export { S as SocialPostMajor } from './icons/SocialPostMajor.svg.mjs';
export { S as SoftPackMajor } from './icons/SoftPackMajor.svg.mjs';
export { S as SortAscendingMajor } from './icons/SortAscendingMajor.svg.mjs';
export { S as SortDescendingMajor } from './icons/SortDescendingMajor.svg.mjs';
export { S as SortMinor } from './icons/SortMinor.svg.mjs';
export { S as SoundMajor } from './icons/SoundMajor.svg.mjs';
export { S as StarFilledMinor } from './icons/StarFilledMinor.svg.mjs';
export { S as StarOutlineMinor } from './icons/StarOutlineMinor.svg.mjs';
export { S as StoreMajor } from './icons/StoreMajor.svg.mjs';
export { S as StoreMinor } from './icons/StoreMinor.svg.mjs';
export { S as StoreStatusMajor } from './icons/StoreStatusMajor.svg.mjs';
export { S as TabletMajor } from './icons/TabletMajor.svg.mjs';
export { S as TapChipMajor } from './icons/TapChipMajor.svg.mjs';
export { S as TaxMajor } from './icons/TaxMajor.svg.mjs';
export { S as TeamMajor } from './icons/TeamMajor.svg.mjs';
export { S as TemplateMajor } from './icons/TemplateMajor.svg.mjs';
export { S as TextAlignmentCenterMajor } from './icons/TextAlignmentCenterMajor.svg.mjs';
export { S as TextAlignmentLeftMajor } from './icons/TextAlignmentLeftMajor.svg.mjs';
export { S as TextAlignmentRightMajor } from './icons/TextAlignmentRightMajor.svg.mjs';
export { S as TextBlockMajor } from './icons/TextBlockMajor.svg.mjs';
export { S as TextMajor } from './icons/TextMajor.svg.mjs';
export { S as ThemeEditMajor } from './icons/ThemeEditMajor.svg.mjs';
export { S as ThemesMajor } from './icons/ThemesMajor.svg.mjs';
export { S as ThemeStoreMajor } from './icons/ThemeStoreMajor.svg.mjs';
export { S as ThumbsDownMajor } from './icons/ThumbsDownMajor.svg.mjs';
export { S as ThumbsDownMinor } from './icons/ThumbsDownMinor.svg.mjs';
export { S as ThumbsUpMajor } from './icons/ThumbsUpMajor.svg.mjs';
export { S as ThumbsUpMinor } from './icons/ThumbsUpMinor.svg.mjs';
export { S as TickMinor } from './icons/TickMinor.svg.mjs';
export { S as TickSmallMinor } from './icons/TickSmallMinor.svg.mjs';
export { S as TimelineAttachmentMajor } from './icons/TimelineAttachmentMajor.svg.mjs';
export { S as TipsMajor } from './icons/TipsMajor.svg.mjs';
export { S as TitleMinor } from './icons/TitleMinor.svg.mjs';
export { S as ToolsMajor } from './icons/ToolsMajor.svg.mjs';
export { S as TransactionFeeDollarMajor } from './icons/TransactionFeeDollarMajor.svg.mjs';
export { S as TransactionFeeEuroMajor } from './icons/TransactionFeeEuroMajor.svg.mjs';
export { S as TransactionFeePoundMajor } from './icons/TransactionFeePoundMajor.svg.mjs';
export { S as TransactionFeeRupeeMajor } from './icons/TransactionFeeRupeeMajor.svg.mjs';
export { S as TransactionFeeYenMajor } from './icons/TransactionFeeYenMajor.svg.mjs';
export { S as TransactionMajor } from './icons/TransactionMajor.svg.mjs';
export { S as TransferInMajor } from './icons/TransferInMajor.svg.mjs';
export { S as TransferMajor } from './icons/TransferMajor.svg.mjs';
export { S as TransferOutMajor } from './icons/TransferOutMajor.svg.mjs';
export { S as TransferWithinShopifyMajor } from './icons/TransferWithinShopifyMajor.svg.mjs';
export { S as TransportMajor } from './icons/TransportMajor.svg.mjs';
export { S as TroubleshootMajor } from './icons/TroubleshootMajor.svg.mjs';
export { S as TypeMajor } from './icons/TypeMajor.svg.mjs';
export { S as UndoMajor } from './icons/UndoMajor.svg.mjs';
export { S as UnfulfilledMajor } from './icons/UnfulfilledMajor.svg.mjs';
export { S as UnknownDeviceMajor } from './icons/UnknownDeviceMajor.svg.mjs';
export { S as UpdateInventoryMajor } from './icons/UpdateInventoryMajor.svg.mjs';
export { S as UploadMajor } from './icons/UploadMajor.svg.mjs';
export { S as VariantMajor } from './icons/VariantMajor.svg.mjs';
export { S as ViewMajor } from './icons/ViewMajor.svg.mjs';
export { S as ViewMinor } from './icons/ViewMinor.svg.mjs';
export { S as ViewportNarrowMajor } from './icons/ViewportNarrowMajor.svg.mjs';
export { S as ViewportWideMajor } from './icons/ViewportWideMajor.svg.mjs';
export { S as VocabularyMajor } from './icons/VocabularyMajor.svg.mjs';
export { S as WandMajor } from './icons/WandMajor.svg.mjs';
export { S as WandMinor } from './icons/WandMinor.svg.mjs';
export { S as WearableMajor } from './icons/WearableMajor.svg.mjs';
export { S as WholesaleMajor } from './icons/WholesaleMajor.svg.mjs';
export { S as WifiMajor } from './icons/WifiMajor.svg.mjs';
import 'react';
