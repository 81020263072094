import React from "react";
import { useQueryClient } from "react-query";

import api from "../../../api";
import ConfirmAction from "../../../components/modals/ConfirmAction";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../../hooks/useFormatMultipleNames";
import { Customer } from "../../../types/utilities";
import { getCustomerName } from "../../../utils/displayUtils";

interface RemoveCustomersFromProjectConfirmProps {
  project: api.Project;
  customers: Customer[];
  onClose(): void;
  onSuccess(): void;
}

const RemoveCustomersFromProjectConfirm: React.FC<RemoveCustomersFromProjectConfirmProps> = (props) => {
  const { project, customers: customersToRemove, onClose, onSuccess } = props;

  const existingCustomers = project.customers;
  const customersIdsToRemove = customersToRemove.map((customer) => customer.id);

  const queryClient = useQueryClient();
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const customersNamesLabel = formatMultipleNames(customersToRemove.map(getCustomerName));

  const updatedProjectCustomersIds = existingCustomers
    .map((existingCustomer) => existingCustomer.id)
    .filter((existingCustomerId) => !customersIdsToRemove.includes(existingCustomerId));

  const handleSuccess = (updatedProject: api.Project) => {
    queryClient.setQueryData(["project-details", project.id], updatedProject);
    onSuccess();
  };

  return (
    <ConfirmAction
      isOpen
      apiCall={() => api.updateProject(project.id, { customer_ids: updatedProjectCustomersIds })}
      onSuccess={handleSuccess}
      title={f("project.remove-customers.confirmation.title")}
      description={f("project.remove-customers.confirmation.description", {
        names: customersNamesLabel,
        projectName: project.name,
      })}
      onNo={onClose}
      actionTitle={f("project.remove-customers.confirmation.action.label")}
      useSuccessToast
      successTitle={f("project.remove-customers.success.message", { count: customersToRemove.length })}
    />
  );
};

export default RemoveCustomersFromProjectConfirm;
