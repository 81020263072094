import React, { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { Button, Page, SkeletonBodyText, SkeletonDisplayText, Stack, TextStyle } from "@shopify/polaris";

import api from "../../api";
import AddressInfo from "../../components/AddressInfo";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage";
import SectionDivider from "../../components/extensions/SectionDivider";
import BackButton from "../../components/navigation/BackButton";
import OnboardingSummaryProjectCard from "../../components/OnboardingSummaryProjectCard";
import OnboardingSummarySection from "../../components/OnboardingSummarySection";
import OnboardingSummarySignatureCard from "../../components/OnboardingSummarySignatureCard";
import SectionMargin from "../../components/SectionMargin";
import { ROUTES } from "../../constants/routes";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IDParams } from "../../types/params";
import { getFullName } from "../../utils/displayUtils";

const PersonOnboardingSummary: React.FC<AsyncPageChild<api.PersonCustomerDetailResponse>> = ({ data: customer }) => {
  const { id } = useParams<IDParams>();
  const history = useHistory();
  const f = useFormatMessage();
  const features = useFeatures();

  const [isPosting, setPosting] = useState(false);

  let isSignatureRequired = true;
  let onboardingSignature: api.Signature | undefined = undefined;
  if (customer.disclosure_documents[0]) {
    isSignatureRequired = Boolean(customer.disclosure_documents[0].signature_required);
    onboardingSignature = customer.disclosure_documents[0].signatures[0];
  }

  const associatedProject = customer.projects && customer.projects.length === 1 ? customer.projects[0] : undefined;
  const isCreatedViaProject = associatedProject !== undefined;

  const redirectURLAfterFinish = isCreatedViaProject
    ? generatePath(ROUTES.PROJECT_DETAILS, { id: associatedProject.id })
    : generatePath(ROUTES.PERSON_DETAILS, { id });

  const finish = () => {
    setPosting(true);
    api
      .finishOnboarding(id)
      .then(() => {
        setPosting(false);
        history.push(redirectURLAfterFinish);
      })
      .catch((error) => {
        setPosting(false);
        console.log(error);
      });
  };

  return (
    <Page title={f("onboarding.summary.title")}>
      <Stack vertical>
        <div>{f("summary.description")}</div>

        <OnboardingSummarySection title={f("summary.screening-type")}>
          <TextStyle>{f(`screening-type.${customer.screening?.screening_type || "NOT_SET"}`)}</TextStyle>
        </OnboardingSummarySection>

        <OnboardingSummarySection title={f("summary.details")}>
          <Stack distribution="fillEvenly">
            <Stack vertical>
              <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>
              <div>{getFullName(customer) || f("not.specified")}</div>
            </Stack>
            <Stack vertical>
              <TextStyle variation="subdued">{f("table.column.date.of.birth")}</TextStyle>
              <div>{customer.birth_date || f("not.specified")}</div>
            </Stack>
            <Stack vertical>
              <TextStyle variation="subdued">{f("table.column.national.id")}</TextStyle>
              <div>{customer.national_id || f("not.specified")}</div>
            </Stack>
            <Stack vertical>
              <TextStyle variation="subdued">{f("table.column.address")}</TextStyle>
              <AddressInfo address={customer.address} withTitles />
            </Stack>
          </Stack>
        </OnboardingSummarySection>

        <OnboardingSummarySection title={f("summary.signers.title")}>
          {isSignatureRequired && onboardingSignature ? (
            <OnboardingSummarySignatureCard signature={onboardingSignature} />
          ) : (
            <div>{f("summary.signers.text.skipped")}</div>
          )}
        </OnboardingSummarySection>

        {associatedProject && (
          <OnboardingSummarySection
            title={f("summary.project.title")}
            hide={!features.PROJECTS || !isCreatedViaProject}
          >
            <OnboardingSummaryProjectCard projectName={associatedProject.name} />
          </OnboardingSummarySection>
        )}

        <SectionMargin size="medium" />
        <Stack distribution="trailing">
          <BackButton url={`/persons/${id}/request-onboarding-disclosure`} />
          <Button primary disabled={isPosting} loading={isPosting} onClick={finish}>
            {f("summary.signers.start.screening")}
          </Button>
        </Stack>
      </Stack>
    </Page>
  );
};

const Skeleton = () => (
  <Page>
    <Stack vertical>
      <SkeletonDisplayText size="large" />
      <SkeletonBodyText lines={3} />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={1} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={1} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={3} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={3} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={3} />
      <SectionDivider />

      <Stack distribution="trailing">
        <Button primary disabled={true}>
          {/* @ts-ignore*/}
          <SkeletonBodyText lines={1} />
        </Button>
      </Stack>
    </Stack>
  </Page>
);

export default withAsyncPage<api.PersonCustomerDetailResponse>(PersonOnboardingSummary, {
  name: "person-onboarding-summary",
  apiFunction: api.getPersonCustomerDetails,
  paramNames: ["id"],
  skeleton: <Skeleton />,
});
