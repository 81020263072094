import React from "react";
import { useMutation } from "react-query";
import { MutationFunction } from "react-query/types/core/types";
import { Toast } from "@shopify/polaris";

import useOpenClose from "../../hooks/useOpenClose";
import { noop } from "../../utils/util";

import Confirm, { ConfirmProps } from "./Confirm";

interface ConfirmActionProps<T> extends Omit<ConfirmProps, "onYes" | "isActionInProgress" | "error"> {
  apiCall: MutationFunction<T>;
  onSuccess?(response: T): void;
  useSuccessToast?: boolean;
  successTitle?: string;
}

function ConfirmAction<T = void>(props: ConfirmActionProps<T>) {
  const { apiCall, onSuccess = noop, useSuccessToast, successTitle, onNo, isOpen, ...confirmProps } = props;

  const [showSuccessMessageToast, toggleSuccessMessageToast, closeSuccessMessageToast] = useOpenClose();

  const mutation = useMutation(apiCall, {
    onSuccess: (response: T) => {
      if (useSuccessToast) {
        toggleSuccessMessageToast();
      } else {
        onNo();
      }
      onSuccess(response);
    },
  });

  const handleToastDismiss = () => {
    closeSuccessMessageToast();
    onNo();
  };

  const showConfirm = isOpen && (useSuccessToast ? !showSuccessMessageToast : true);

  return (
    <>
      <Confirm
        isOpen={showConfirm}
        isActionInProgress={mutation.isLoading}
        onYes={() => mutation.mutate({})}
        onNo={onNo}
        error={mutation.error}
        {...confirmProps}
      />
      {showSuccessMessageToast && <Toast onDismiss={handleToastDismiss} content={successTitle!} />}
    </>
  );
}

export default ConfirmAction;
