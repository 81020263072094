import * as React from "react";
import { useState } from "react";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import Confirm from "../modals/Confirm";

interface StopScreeningModalProps {
  id: string;
  isOpen: boolean;
  isPerson?: boolean;
  onStop(): void;
  onClose(): void;
}

const StopScreeningModal: React.FC<StopScreeningModalProps> = ({ id, isOpen, isPerson = false, onStop, onClose }) => {
  const [isStopping, setStopping] = useState(false);
  const [error, setError] = useState<string>();

  const f = useFormatMessage();

  if (!isOpen) {
    return null;
  }

  return (
    <Confirm
      isDestructive={true}
      isOpen={isOpen}
      actionTitle={f("change-screening.buttons.stop-monitoring")}
      cancelTitle={f("default.close")}
      isActionInProgress={isStopping}
      title={f("change-screening.stop-screening.title")}
      error={error}
      description={
        isPerson
          ? f("change-screening.stop-screening.description-person")
          : f("change-screening.stop-screening.description")
      }
      onYes={() => {
        setStopping(true);

        const apiCall = isPerson ? api.stopPersonScreening(id) : api.stopCompanyScreening(id);

        apiCall
          .then(() => {
            setStopping(false);
            onStop();
          })
          .catch((error) => {
            setStopping(false);
            setError(error.toString());
            console.log("Error stopping screening", error.toString());
          });
      }}
      onNo={() => {
        setStopping(false);
        onClose();
      }}
    />
  );
};

export default StopScreeningModal;
