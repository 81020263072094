import React from "react";
import { Button as PolarisButton, ButtonProps as PolarisButtonProps } from "@shopify/polaris";
import styled from "styled-components";

interface ButtonProps extends PolarisButtonProps {
  truncate?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ truncate, className = "", icon, ...buttonProps }) => {
  const iconNode =
    typeof icon !== "string" ? (
      icon
    ) : (
      <StyledIconWrapper>
        <img src={icon} />
      </StyledIconWrapper>
    );

  if (truncate || className) {
    return (
      <StyledTruncateWrapper className={className} truncate={truncate}>
        <PolarisButton fullWidth={true} icon={iconNode} {...buttonProps} />
      </StyledTruncateWrapper>
    );
  }

  return <PolarisButton icon={iconNode} {...buttonProps} />;
};

const StyledIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTruncateWrapper = styled.span<{ truncate?: boolean }>`
  & .Polaris-Button__Text {
    ${({ truncate }) =>
      truncate &&
      `
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
  }
`;

export default Button;
