import React from "react";
import { Card, Heading, Stack } from "@shopify/polaris";

import NavigationSection from "../../components/navigation/NavigationSection";
import NewInfoBadge from "../../components/NewInfoBadge";

interface PageSectionProps {
  title: string;
  show?: boolean;
  anchor?: string;
  withoutCard?: boolean;
  newInfo?: boolean;
}

const PageSection: React.FC<PageSectionProps> = (props) => {
  const { title, show = true, anchor, withoutCard, newInfo, children } = props;

  if (!show) {
    return null;
  }

  return (
    <Stack vertical wrap={false}>
      {anchor && <NavigationSection anchor={anchor} />}
      <Heading>
        {title} {newInfo && <NewInfoBadge />}
      </Heading>
      {withoutCard && children}
      {!withoutCard && <Card>{children}</Card>}
    </Stack>
  );
};

export default PageSection;
