import React, { useState } from "react";
import { Button, Heading, Modal, Select, Stack, TextField, Tooltip } from "@shopify/polaris";

import api from "../../api";
import RoleTypes from "../../constants/role-types";
import useFormatMessage from "../../hooks/useFormatMessage";
import { getFullName } from "../../utils/displayUtils";
import AddressForm from "../AddressForm";
import ErrorPanel from "../ErrorPanel";
import PersonForm from "../PersonForm";

import Confirm from "./Confirm";

export interface RoleModalData {
  id?: string;
  comment?: string;
  type?: api.RoleType;
  first_name?: string;
  last_name?: string;
  birth_date?: string;
  country_of_citizenship?: api.CountryEnum;
  address: api.Address;
  isManuallyCreated?: boolean;
}

type RoleModalDataValues = RoleModalData[keyof RoleModalData];

interface RoleModalProps {
  id: string;
  dataRole: RoleModalData;
  isDeleteDisabled: boolean;
  onDelete(ownerId: string): void;
  onUpdate(data: api.Role): void;
  onCreate(data: api.Role): void;
  onClose(): void;
}

const RoleModal: React.FC<RoleModalProps> = ({
  dataRole,
  id,
  isDeleteDisabled,
  onDelete,
  onUpdate,
  onCreate,
  onClose,
}) => {
  const [isRolePosting, setRolePosting] = useState(false);
  const [error, setError] = useState<unknown>();
  const [role, setRole] = useState(dataRole);
  const [isConfirm, setConfirm] = useState(false);
  const f = useFormatMessage();

  const handleRoleChange = (key: keyof RoleModalData, value?: RoleModalDataValues) =>
    setRole({ ...role, [key]: value });

  const removeRole = () => setConfirm(true);

  const saveNewRole = () => {
    const { type = "UNKNOWN_ROLE", comment } = role;

    const person = {
      first_name: role.first_name!,
      last_name: role.last_name!,
      birth_date: role.isManuallyCreated || !dataRole.birth_date ? role.birth_date! : undefined,
      country_of_citizenship:
        role.isManuallyCreated || !dataRole.country_of_citizenship ? role.country_of_citizenship : undefined,
      address: role.address,
    };

    setRolePosting(true);
    const apiCall = role.id
      ? api.updateCompanyRole(id, role.id, { type, comment, person })
      : api.createCompanyRole(id, {
          type,
          comment,
          person: { ...person, birth_date: role.birth_date!, country_of_citizenship: role.country_of_citizenship! },
        });

    apiCall
      .then((updatedRole) => {
        setRolePosting(false);
        closeRolesDialog();

        if (!role.id) {
          updatedRole.is_new = true;
        }

        try {
          role.id ? onUpdate(updatedRole) : onCreate(updatedRole);
        } catch (error: unknown) {
          console.log(error);
          setError(error);
        }
      })
      .catch((error: unknown) => {
        console.log(error);
        setRolePosting(false);
        setError(error);
      });
  };

  const closeRolesDialog = () => setError(undefined);

  return !isConfirm ? (
    <Modal open={true} onClose={onClose} title={role.id ? f("modals.role.title.update") : f("modals.role.title.add")}>
      <Modal.Section>
        <Stack vertical>
          <Heading>{f("modals.headings.personal-information")}</Heading>
          <PersonForm
            value={role}
            disabled={isRolePosting}
            onChange={handleRoleChange}
            isManuallyCreated={role.isManuallyCreated}
          />
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <Stack vertical>
          <Heading>{f("modals.headings.address")}</Heading>
          <AddressForm
            value={role.address}
            onChange={(newAddress) => handleRoleChange("address", newAddress)}
            disabled={isRolePosting}
            requiredFields={["country"]}
          />
        </Stack>
      </Modal.Section>

      <Modal.Section>
        <Stack vertical>
          <Heading>{f("modals.role.headings.role-in-company")}</Heading>
          <Stack>
            <Select
              value={role.type}
              onChange={(newValue) => handleRoleChange("type", newValue)}
              disabled={isRolePosting}
              label={f("modals.role.labels.role_type") + " *"}
              options={Object.keys(RoleTypes)
                .filter((key) => {
                  return (
                    (key !== "null" && key !== "DEPUTY_BOARD_MEMBER") ||
                    (key === "DEPUTY_BOARD_MEMBER" && role.type === "DEPUTY_BOARD_MEMBER")
                  );
                })
                .map((key) => ({
                  label:
                    key === "DEPUTY_BOARD_MEMBER"
                      ? `${f(RoleTypes[key])} (${f(`common.roles.not.in.use`)})`
                      : f(RoleTypes[key as api.RoleType]),
                  value: key,
                }))}
            />
          </Stack>
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <TextField
          placeholder={f("common.labels.comment")}
          label={f("common.labels.comment")}
          multiline={3}
          value={role.comment}
          onChange={(newValue) => handleRoleChange("comment", newValue)}
          disabled={isRolePosting}
          autoComplete="off"
        />
      </Modal.Section>
      <Modal.Section>
        <Stack vertical>
          {error && <ErrorPanel message={error} />}

          <Stack distribution="equalSpacing">
            {role.id ? (
              <Tooltip
                content={isDeleteDisabled ? f("modals.role.tooltips.delete-disabled") : ""}
                preferredPosition="above"
              >
                <Button destructive disabled={isDeleteDisabled || isRolePosting} onClick={removeRole}>
                  {f("default.delete")}
                </Button>
              </Tooltip>
            ) : (
              <div></div>
            )}

            <Stack distribution="trailing">
              <Button onClick={onClose}>{f("default.cancel")}</Button>
              <Button
                onClick={() => saveNewRole()}
                disabled={
                  isRolePosting ||
                  !role.first_name ||
                  !role.last_name ||
                  !role.type ||
                  (!role.id && !role.birth_date) ||
                  !role.country_of_citizenship ||
                  !role.address.country
                }
                loading={isRolePosting}
                primary
              >
                {role.id ? f("default.save") : f("default.add")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Modal.Section>
    </Modal>
  ) : (
    <Confirm
      title={f("modals.role.confirm.delete.title")}
      actionTitle={f("default.delete")}
      description={f("modals.role.confirm.delete.description", { role: getFullName(role) })}
      onYes={() => {
        api
          .deleteCompanyRole(id, role.id!)
          .then(() => {
            try {
              onDelete(role.id!);
            } catch (error: unknown) {
              console.log(error);
              setError(error);
            }
          })
          .catch((error: unknown) => {
            console.log(error);
            setError(error);
          });
      }}
      onNo={() => setConfirm(false)}
    />
  );
};

export default RoleModal;
