import React, { useState } from "react";
import { Button, Heading, Modal, Stack, TextField, Tooltip } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { getFullName } from "../../utils/displayUtils";
import AddressForm from "../AddressForm";
import ErrorPanel from "../ErrorPanel";
import PersonForm from "../PersonForm";

import Confirm from "./Confirm";

export interface OwnerModalData {
  id?: string;
  comment?: string;
  percent_share?: number;
  first_name?: string;
  last_name?: string;
  birth_date?: string;
  country_of_citizenship: api.CountryEnum;
  address: api.Address;
  isManuallyCreated?: boolean;
}

type OwnerModalDataValues = OwnerModalData[keyof OwnerModalData];

interface OwnerModalProps {
  id: string;
  dataOwner: OwnerModalData;
  isDeleteDisabled: boolean;
  onDelete(ownerId: string): void;
  onUpdate(data: api.Owner): void;
  onCreate(data: api.Owner): void;
  onClose(): void;
}

const OwnerModal: React.FC<OwnerModalProps> = ({
  dataOwner,
  id,
  isDeleteDisabled,
  onDelete,
  onUpdate,
  onCreate,
  onClose,
}) => {
  const [isOwnerPosting, setOwnerPosting] = useState(false);
  const [error, setError] = useState<unknown>();
  const [owner, setOwner] = useState(dataOwner);
  const [isConfirm, setConfirm] = useState(false);
  const f = useFormatMessage();

  const handleOwnerChange = (key: keyof OwnerModalData, value?: OwnerModalDataValues) =>
    setOwner({ ...owner, [key]: value });

  const removeOwner = () => setConfirm(true);

  const saveNewOwner = () => {
    const { percent_share = 0, comment } = owner;

    const person = {
      first_name: owner.first_name!,
      last_name: owner.last_name!,
      birth_date: owner.isManuallyCreated || !dataOwner.birth_date ? owner.birth_date : undefined,
      country_of_citizenship:
        owner.isManuallyCreated || !dataOwner.country_of_citizenship ? owner.country_of_citizenship : undefined,
      address: owner.address,
    };

    setOwnerPosting(true);
    const apiCall = owner.id
      ? api.updateCompanyOwner(id, owner.id, { percent_share, comment, person })
      : api.createCompanyOwner(id, {
          percent_share,
          comment,
          person: { ...person, birth_date: owner.birth_date!, country_of_citizenship: owner.country_of_citizenship! },
        });

    apiCall
      .then((updatedOwner) => {
        setOwnerPosting(false);
        closeOwnersDialog();

        try {
          owner.id ? onUpdate(updatedOwner) : onCreate(updatedOwner);
        } catch (error: unknown) {
          console.log(error);
          setError(error);
        }
      })
      .catch((error: unknown) => {
        console.log(error);
        setOwnerPosting(false);
        setError(error);
      });
  };

  const closeOwnersDialog = () => setError(undefined);
  const invalidPercentShareValue = owner.percent_share && (owner.percent_share < 0 || owner.percent_share > 100);

  return !isConfirm ? (
    <Modal
      open={true}
      onClose={onClose}
      title={owner.id ? f("modals.owner.title.update") : f("modals.owner.title.add")}
    >
      <Modal.Section>
        <Stack vertical>
          <Heading>{f("modals.headings.personal-information")}</Heading>
          <PersonForm
            value={owner}
            disabled={isOwnerPosting}
            onChange={handleOwnerChange}
            isManuallyCreated={owner.isManuallyCreated}
          />
        </Stack>
      </Modal.Section>

      <Modal.Section>
        <Stack vertical>
          <Heading>{f("modals.headings.address")}</Heading>
          <AddressForm
            value={owner.address}
            onChange={(newAddress) => handleOwnerChange("address", newAddress)}
            disabled={isOwnerPosting}
            requiredFields={["country"]}
          />
        </Stack>
      </Modal.Section>

      <Modal.Section>
        <Stack vertical>
          <Heading>{f("modals.owner.headings.ownership-in-company")}</Heading>
          <Stack>
            <TextField
              error={invalidPercentShareValue ? f("default.invalid") : undefined}
              placeholder="0.00"
              min={0}
              max={100}
              label={f("modals.owner.labels.percent_share") + " *"}
              value={owner.percent_share?.toString()}
              onChange={(newValue) => handleOwnerChange("percent_share", newValue)}
              disabled={isOwnerPosting}
              type="number"
              autoComplete="off"
            />
          </Stack>
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <TextField
          placeholder={f("common.labels.comment")}
          label={f("common.labels.comment")}
          multiline={3}
          value={owner.comment}
          onChange={(newValue) => handleOwnerChange("comment", newValue)}
          disabled={isOwnerPosting}
          autoComplete="off"
        />
      </Modal.Section>
      <Modal.Section>
        <Stack vertical>
          {error && <ErrorPanel message={error} />}

          <Stack distribution="equalSpacing">
            {owner.id ? (
              <Tooltip
                content={isDeleteDisabled ? f("modals.owner.tooltips.delete-disabled") : ""}
                preferredPosition="above"
              >
                <Button destructive disabled={isDeleteDisabled || isOwnerPosting} onClick={removeOwner}>
                  {f("default.delete")}
                </Button>
              </Tooltip>
            ) : (
              <div />
            )}

            <Stack distribution="trailing">
              <Button onClick={onClose}>{f("default.cancel")}</Button>
              <Button
                onClick={() => saveNewOwner()}
                disabled={
                  isOwnerPosting ||
                  !owner.first_name ||
                  !owner.last_name ||
                  !owner.percent_share ||
                  owner.percent_share < 0 ||
                  owner.percent_share > 100 ||
                  (!owner.id && !owner.birth_date) ||
                  !owner.country_of_citizenship ||
                  !owner.address.country
                }
                loading={isOwnerPosting}
                primary
              >
                {owner.id ? f("default.save") : f("default.add")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Modal.Section>
    </Modal>
  ) : (
    <Confirm
      title={f("modals.owner.confirm.delete.title")}
      actionTitle={f("default.delete")}
      description={f("modals.owner.confirm.delete.description", { owner: getFullName(owner) })}
      onYes={() => {
        api
          .deleteCompanyOwner(id, owner.id!)
          .then(() => onDelete(owner.id!))
          .catch((error) => {
            console.log(error);
            setError(error.toString());
          });
      }}
      onNo={() => setConfirm(false)}
    />
  );
};

export default OwnerModal;
