import React from "react";
import styled from "styled-components";

import TableRowModifier from "./TableRowModifier";

interface RowHighlighterProps {
  highlight?: boolean;
  subdued?: boolean;
  clickable?: boolean;
}

const RowHighlighter: React.FC<RowHighlighterProps> = ({ children, ...props }) => {
  return <StyledRowHighlighter {...props}>{children}</StyledRowHighlighter>;
};

const StyledRowHighlighter = styled(TableRowModifier)<RowHighlighterProps>`
  ${(props) => props.highlight && `background-color: #F2FDFF;`}
  ${(props) => props.subdued && `opacity: 0.5; filter: grayscale(1);`}
  ${(props) => props.clickable && `cursor: pointer;`}
`;

export default RowHighlighter;
