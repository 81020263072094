import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { Banner, Button, Card, DisplayText, Layout, List, Page, Stack, TextStyle } from "@shopify/polaris";

import api from "../../api";
import AddItemButton from "../../components/AddItemButton";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage";
import CompanyRoleDisclosureForm from "../../components/disclosure/CompanyRoleDisclosureForm";
import ErrorPanel from "../../components/ErrorPanel";
import DataTable from "../../components/extensions/DataTable";
import RoleModal from "../../components/modals/RoleModal";
import BackButton from "../../components/navigation/BackButton";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import { ROUTES } from "../../constants/routes";
import useDefaultPersonDetails from "../../hooks/useDefaultPersonDetails";
import useFormatMessage from "../../hooks/useFormatMessage";
import useValidateCompanyDisclosureForm from "../../hooks/useValidateCompanyDisclosureForm";
import { IDParams } from "../../types/params";
import { noop } from "../../utils/util";

import DisclosureSkeleton from "./CompanyDisclosureSkeleton";

const CompanyRequestDisclosures: React.FC<AsyncPageChild<api.CompanySignatureConfiguration>> = ({ data }) => {
  const { id } = useParams<IDParams>();

  const history = useHistory();
  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const validateForm = useValidateCompanyDisclosureForm();

  const [validationError, setValidationError] = useState<string>();
  const [signatures, setSignatures] = useState(new Map<string, api.CompanyRoleSignatureConfiguration>());
  const [possibleSignatureRoles, setPossibleSignatureRoles] = useState<api.SignatureRole[]>(
    data.possible_signers || []
  );
  const [signatureDescription] = useState(data.signature_description);
  const [signatureDescriptions] = useState<string[]>(data.signature_descriptions || []);
  const [isFormValid, setFormValid] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);

  const { data: companyDetails } = useQuery(["company-details", id], () => api.getCompanyCustomerDetails(id));
  const companyName = companyDetails?.name || "";

  const newPersonDetails = useDefaultPersonDetails(companyDetails?.country);

  const updateSignaturesMutation = useMutation(
    () => {
      const signaturesArray = [...signatures.values()];

      return api.createCompanyCustomerDisclosureDocument(id, {
        signatures: signaturesArray,
      });
    },
    {
      onSuccess: async (response) => {
        // Retrieve the company details for the current customer and add the new disclosure document
        const customerData = queryClient.getQueryData<api.CompanyCustomerDetailResponse>(["company-details", id]);
        if (customerData) {
          // Add the newly created disclosure document to the start of the array
          customerData.disclosure_documents.unshift(response);
          queryClient.setQueryData(["company-details", id], customerData);
        }

        await queryClient.invalidateQueries(["company-request-disclosures", id]);
        history.push(`/companies/${id}#${PAGE_ANCHORS.DISCLOSURES_SECTION}`);
      },
    }
  );

  const performValidation = (signatures: Map<string, api.CompanyRoleSignatureConfiguration>) => {
    validateForm(signatures, (isValid, hasDuplicates) => {
      setFormValid(isValid);

      if (hasDuplicates) {
        setValidationError(f("onboarding.disclosures.duplicate.emails.error"));
      } else {
        setValidationError(undefined);
      }
    });
  };

  const onSignerSelectionChange = (id: string, value: boolean) => {
    if (value) {
      signatures.set(id, { id: id, email: undefined, send_email: true });
    } else {
      signatures.delete(id);
    }

    setSignatures(new Map(signatures));
    performValidation(signatures);
  };

  const onSignerSystemEmailChange = (id: string, value: boolean) => {
    const signature = signatures.get(id);

    if (signature) {
      signature.send_email = value;

      // Remove email for a signer if they are generating a link
      if (!value) {
        signature.email = undefined;
      }

      signatures.set(id, signature);
      setSignatures(new Map(signatures));
      performValidation(signatures);
    }
  };

  const onSignerEmailChange = (id: string, value: string) => {
    const signature = signatures.get(id);

    if (signature) {
      signature.email = value;
      signatures.set(id, signature);

      setSignatures(new Map(signatures));
      performValidation(signatures);
    }
  };

  const sendSignatures = () => updateSignaturesMutation.mutate();

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Stack vertical>
            <BackButton
              url={generatePath(ROUTES.COMPANY_DETAILS, { id: id }) + `#${PAGE_ANCHORS.DISCLOSURES_SECTION}`}
              displayLabel={companyName}
              showAsLink
            />

            <DisplayText size="large">{f("disclosure-request.title")}</DisplayText>
            <br />

            <Banner status="info">
              <Stack vertical>
                <TextStyle variation="strong">
                  <FormattedMessage id="disclosure-request.banner.signatory-rights" values={{ company: companyName }} />
                </TextStyle>
                {signatureDescriptions.length === 0 && (
                  <TextStyle>{f("disclosure-request.banner.no.signing-basis")}</TextStyle>
                )}
                <List>
                  {signatureDescription && signatureDescription.length > 0 && (
                    <List.Item>{signatureDescription}</List.Item>
                  )}
                  {signatureDescriptions.length > 0 &&
                    signatureDescriptions.map((description, index) => <List.Item key={index}>{description}</List.Item>)}
                </List>
              </Stack>
            </Banner>

            <Card>
              {possibleSignatureRoles.length === 0 && <Card.Section>{f("roles.messages.no-roles")}</Card.Section>}
              {possibleSignatureRoles.length > 0 && (
                <DataTable
                  truncate
                  columnContentTypes={["text"]}
                  headings={[]}
                  rows={data.possible_signers.map((signatureRole: api.SignatureRole) => [
                    <CompanyRoleDisclosureForm
                      email={signatures.get(signatureRole.id!)?.email}
                      isSystemEmailEnabled={signatures.get(signatureRole.id!)?.send_email}
                      signatureRole={signatureRole}
                      selected={signatures.has(signatureRole.id!)}
                      onSelectionChanged={(id, value) => onSignerSelectionChange(id, value)}
                      onSystemEmailChanged={(id, value) => onSignerSystemEmailChange(id, value)}
                      onEmailChanged={(id, value) => onSignerEmailChange(id, value)}
                    />,
                  ])}
                />
              )}
              <Card.Section>
                <AddItemButton onClick={() => setShowRoleModal(true)}>{f("screening.buttons.add-role")}</AddItemButton>
              </Card.Section>
            </Card>

            {(validationError || updateSignaturesMutation.isError) && (
              <ErrorPanel message={validationError || updateSignaturesMutation.error} />
            )}

            <Stack distribution="trailing">
              <BackButton
                url={generatePath(ROUTES.COMPANY_DETAILS, { id: id }) + `#${PAGE_ANCHORS.DISCLOSURES_SECTION}`}
              />
              <Button
                primary
                disabled={updateSignaturesMutation.isLoading || !isFormValid || signatures.size == 0}
                loading={updateSignaturesMutation.isLoading}
                onClick={sendSignatures}
              >
                {f("disclosure-request.button.send")}
              </Button>
            </Stack>
          </Stack>
          {/* Render the role modal for adding a new role */}
          {showRoleModal && (
            <RoleModal
              id={id}
              dataRole={newPersonDetails()}
              onClose={() => setShowRoleModal(false)}
              isDeleteDisabled={true}
              onDelete={(deleteRoleId) => {
                if (signatures.has(deleteRoleId)) {
                  signatures.delete(deleteRoleId);

                  performValidation(signatures);
                  setSignatures(new Map(signatures));
                }
                setShowRoleModal(false);
              }}
              onUpdate={noop}
              onCreate={(role) => {
                const possibleSigners = possibleSignatureRoles;
                possibleSigners.push(role);
                setPossibleSignatureRoles(possibleSigners);
                setShowRoleModal(false);
              }}
            />
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default withAsyncPage<api.CompanySignatureConfiguration>(CompanyRequestDisclosures, {
  name: "company-request-disclosures",
  apiFunction: api.getCompanySignatureConfiguration,
  paramNames: ["id"],
  skeleton: <DisclosureSkeleton />,
});
