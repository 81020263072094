import React, { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { Banner, BannerStatus } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import useFeatures from "../../hooks/useFeatures";

const POLLING_INTERVAL = 1_000 * 60 * 15; // poll every 15 minutes

const SERVER_SEVERITY_TO_BANNER_SEVERITY_MAP: Record<api.StatusMessageSeverity, BannerStatus> = {
  INFO: "info",
  WARNING: "warning",
  CRITICAL: "critical",
};

const StatusMessage: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const features = useFeatures();

  const { data } = useQuery(["status-message"], () => api.getStatusMessageList(), {
    refetchInterval: POLLING_INTERVAL,
    refetchIntervalInBackground: true,
    enabled: Object.keys(features).length > 0, // this is the current way to test if the user successfully logged in
  });

  // we currently only show the first message
  const statusMessage = data?.status_messages[0];

  const showBanner = statusMessage !== undefined;

  useEffect(() => {
    const messageOffset =
      showBanner && wrapperRef.current ? `${wrapperRef.current.getBoundingClientRect().height}px` : "0px";

    // if showBanner is true, set Polaris top padding to match the new toolbar height
    // in beaufort.kdk.css --message-offset
    document.documentElement.style.setProperty("--message-offset", messageOffset);
  }, [showBanner, wrapperRef.current]);

  if (!showBanner) {
    return null;
  }

  const { message, severity } = statusMessage;

  return (
    <StyledBannerWrapper ref={wrapperRef}>
      <Banner status={SERVER_SEVERITY_TO_BANNER_SEVERITY_MAP[severity]} title={message} />
    </StyledBannerWrapper>
  );
};

const StyledBannerWrapper = styled.div`
  & .Polaris-Banner {
    padding: 1.4rem;
    justify-content: center;
    border-radius: 0;
  }

  & .Polaris-Banner__ContentWrapper {
    flex: 0 1 auto;
  }

  & .Polaris-Heading {
    font-weight: 400;
  }
`;

export default StatusMessage;
