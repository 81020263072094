import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  ActionListItemDescriptor,
  Button,
  Card,
  DataTable,
  EmptyState,
  FormLayout,
  Modal,
  Page,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import styled from "styled-components";

import EditIcon from "../../../assets/icons/pen-line.svg";
import PlusMajorIcon from "../../../assets/icons/plus-major.svg";
import LabelIcon from "../../../assets/icons/tag.svg";
import DeleteIcon from "../../../assets/icons/trash.svg";
import api from "../../api";
import ActionsDropdown from "../../components/ActionsDropdown";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage";
import ErrorPanel from "../../components/ErrorPanel";
import Icon from "../../components/extensions/Icon";
import RowHighlighter from "../../components/extensions/RowHighlighter";
import TableCellChild from "../../components/extensions/TableCellChild";
import ConfirmAction from "../../components/modals/ConfirmAction";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import { isEmptyString } from "../../utils/stringUtils";

import AdminLabelsSkeleton from "./AdminLabelsSkeleton";

const EMPTY_LABEL: api.Label = {
  id: "",
  title: "",
  is_assigned: false,
};

const AdminLabelsPage: React.FC<AsyncPageChild<api.LabelList>> = ({ data }) => {
  const [isModalOpen, toggleModalOpen, closeModal] = useOpenClose();
  const [isDeleteConfirmOpen, toggleDeleteConfirm, closeDeleteConfirm] = useOpenClose();
  const [currentLabel, setCurrentLabel] = useState<api.Label>(EMPTY_LABEL);

  const queryClient = useQueryClient();
  const f = useFormatMessage();

  const labels = data.labels;

  const saveLabelMutation = useMutation<api.Label, unknown, api.Label>(
    (params) => {
      return currentLabel.id ? api.updateLabel(currentLabel.id, params) : api.createLabel(params);
    },
    {
      onSuccess: (label) => {
        addLabelToList(!currentLabel.id, label);
        closeAndClearModal();
      },
    }
  );

  const deleteLabelMutation = useMutation(api.deleteLabel, {
    onSuccess: (response, deletedLabelId) => {
      const labelsData = queryClient.getQueryData<api.LabelList>("all-labels") || { count: 0, labels: [] };

      const updatedLabelsData: api.LabelList = {
        count: labelsData.count - 1,
        labels: labelsData.labels.filter((label) => label.id !== deletedLabelId),
      };

      queryClient.setQueryData("all-labels", updatedLabelsData);
    },
  });

  const handleUpdate = (value: string, property: keyof api.Label) => {
    const updatedLabel = { ...currentLabel, [property]: value };
    setCurrentLabel(updatedLabel);
  };

  const addLabelToList = (isNew: boolean, serverLabel: api.Label) => {
    const labelsData = queryClient.getQueryData<api.LabelList>("all-labels");
    const cachedLabels = labelsData?.labels || [];

    if (!isNew) {
      const i = cachedLabels.findIndex((label) => label.id === serverLabel.id);

      if (i >= 0) {
        cachedLabels[i] = serverLabel;
      }
    } else {
      cachedLabels.push(serverLabel);
    }

    queryClient.setQueryData("all-labels", { labels: cachedLabels });
  };

  const saveLabel = () => saveLabelMutation.mutate({ ...currentLabel, title: currentLabel.title.trim() });

  const closeAndClearModal = () => {
    closeModal();
    setCurrentLabel(EMPTY_LABEL);
    saveLabelMutation.reset();
  };

  const editLabel = (label: api.Label) => {
    setCurrentLabel(label);
    toggleModalOpen();
  };

  const getLabelActions = (label: api.Label): ActionListItemDescriptor[] => [
    {
      content: f("default.edit"),
      image: EditIcon,
      onAction: () => editLabel(label),
    },
    {
      content: f("default.delete"),
      image: DeleteIcon,
      onAction: () => {
        setCurrentLabel(label);
        toggleDeleteConfirm();
      },
    },
  ];

  const isNewLabel = !currentLabel.id;
  const modalTitle = isNewLabel ? f("labels.modal.title.add-label") : f("labels.modal.title.update-label");

  // a label title is invalid if it is empty or already exists in the unique Set of all other labels
  const isInvalidLabel =
    isEmptyString(currentLabel.title.trim()) ||
    new Set(labels.map((label) => (label.id !== currentLabel.id ? label.title.toLowerCase() : ""))).has(
      currentLabel.title.trim().toLowerCase()
    );

  // do not show error message when a new label editor is opened
  const showValidationError = currentLabel !== EMPTY_LABEL && isInvalidLabel;

  const saveAction = {
    content: isNewLabel ? f("default.add") : f("default.save"),
    onAction: saveLabel,
    disabled: isInvalidLabel,
    loading: saveLabelMutation.isLoading,
  };

  const cancelAction = {
    content: f("default.cancel"),
    onAction: closeAndClearModal,
    disabled: saveLabelMutation.isLoading,
  };

  return (
    <Page
      title={f("labels.page.title")}
      primaryAction={
        <Button onClick={toggleModalOpen} icon={<Icon source={PlusMajorIcon} width="14px" height="14px" />}>
          {f("labels.modal.title.add-label")}
        </Button>
      }
    >
      <Stack vertical>
        <Card>
          {labels.length > 0 && (
            <DataTable
              columnContentTypes={["text", "numeric"]}
              headings={[
                <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
                <TextStyle variation="subdued">{f("common.buttons.actions.button").toUpperCase()}</TextStyle>,
              ]}
              rows={labels.map((label) => [
                <RowHighlighter clickable>
                  <StyleLabelCell onClick={() => editLabel(label)}>
                    <Stack spacing="tight" alignment="center">
                      <Icon source={LabelIcon} />
                      <TextStyle>{label.title}</TextStyle>
                    </Stack>
                  </StyleLabelCell>
                </RowHighlighter>,
                <TableCellChild width="10%">
                  <ActionsDropdown items={getLabelActions(label)} hideTitle />
                </TableCellChild>,
              ])}
            />
          )}
          {labels.length === 0 && <EmptyState image="" heading={f("labels.modal.empty-state")} />}
        </Card>
      </Stack>
      {isModalOpen && (
        <Modal
          open
          title={modalTitle}
          onClose={closeAndClearModal}
          primaryAction={saveAction}
          secondaryActions={[cancelAction]}
        >
          <Modal.Section>
            <Stack vertical>
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    autoFocus
                    label={f("labels.modal.label.placeholder")}
                    value={currentLabel.title}
                    disabled={saveLabelMutation.isLoading}
                    onChange={(value) => handleUpdate(value, "title")}
                    autoComplete="off"
                    error={showValidationError ? f("labels.modal.label.validation-error") : undefined}
                  />
                  <StyledHiddenFormElement />
                </FormLayout.Group>
              </FormLayout>

              {saveLabelMutation.isError && <ErrorPanel message={saveLabelMutation.error} />}
            </Stack>
          </Modal.Section>
        </Modal>
      )}
      {isDeleteConfirmOpen && (
        <ConfirmAction
          apiCall={() => deleteLabelMutation.mutateAsync(currentLabel.id)}
          title={f("labels.modal.label.delete-label")}
          description={f("labels.modal.label.delete-label.description", { name: currentLabel.title })}
          onNo={() => {
            closeDeleteConfirm();
            setCurrentLabel(EMPTY_LABEL);
          }}
          actionTitle={f("labels.modal.label.delete-label")}
        />
      )}
    </Page>
  );
};

const StyleLabelCell = styled.div`
  margin: -1rem;
  padding: 1rem;
  line-height: 3rem;
`;

// this element is used to shorten the label TextField by adding a hidden element to the Form.Group
const StyledHiddenFormElement = styled.div`
  margin-bottom: 12rem;
`;

export default withAsyncPage<api.LabelList>(AdminLabelsPage, {
  name: "all-labels",
  apiFunction: api.getAllLabels,
  paramNames: [],
  skeleton: <AdminLabelsSkeleton />,
});
