import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { ActionListItemDescriptor, Toast } from "@shopify/polaris";

import DownloadIcon from "../../../assets/icons/arrow-down-rectangle.svg";
import EditIcon from "../../../assets/icons/pen-line.svg";
import RestoreIcon from "../../../assets/icons/restore.svg";
import EndIcon from "../../../assets/icons/xmark-circle.svg";
import api from "../../api";
import ActionsDropdown from "../../components/ActionsDropdown";
import ProjectInfoModal from "../../components/modals/ProjectInfoModal";
import useDownload from "../../hooks/useDownload";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";

import ProjectEndConfirmation from "./actions/ProjectEndConfirmation";

interface ProjectActionsMenuProps {
  project: api.Project;
  readonly?: boolean;
}

const ProjectActionsMenu: React.FC<ProjectActionsMenuProps> = (props) => {
  const { project, readonly } = props;

  const [showInfoModal, toggleShowInfoModal, closeInfoModal] = useOpenClose();
  const [showEndProjectConfirmation, toggleEndProjectConfirmation, closeEndProjectConfirmation] = useOpenClose();

  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const disabled = readonly || project.status !== "ACTIVE";

  const download = useDownload();
  const downloadReportMutation = useMutation(() => download(`/project/${project.id}/report`));

  const reactivateProjectMutation = useMutation(() => api.updateProject(project.id, { status: "ACTIVE" }), {
    onSuccess: async (response) => queryClient.setQueryData(["project-details", project.id], response),
  });

  // TODO disable until project report is implemented
  // const downloadReport = () => downloadReportMutation.mutate();
  const reactivateProject = () => reactivateProjectMutation.mutate();

  const actions: ActionListItemDescriptor[] = [
    {
      content: f("project.actions.labels.edit"),
      onAction: toggleShowInfoModal,
      image: EditIcon,
      disabled,
    },
    // TODO disable until project report is implemented
    // {
    //   content: f("project.actions.labels.download-report"),
    //   onAction: downloadReport,
    //   image: DownloadIcon,
    //   disabled: downloadReportMutation.isLoading,
    // },
  ];

  if (project.status === "ACTIVE") {
    actions.push({
      content: f("project.actions.labels.end-project"),
      onAction: toggleEndProjectConfirmation,
      image: EndIcon,
      disabled: reactivateProjectMutation.isLoading || readonly,
    });
  }

  if (project.status === "INACTIVE") {
    actions.push({
      content: f("project.actions.labels.reactivate"),
      onAction: reactivateProject,
      image: RestoreIcon,
    });
  }

  const isLoading = downloadReportMutation.isLoading || reactivateProjectMutation.isLoading;
  const isError = downloadReportMutation.isError || reactivateProjectMutation.isError;
  const error = downloadReportMutation.error || reactivateProjectMutation.error;

  const resetErroneousMutation = () => {
    if (downloadReportMutation.isError) {
      downloadReportMutation.reset();
    } else if (reactivateProjectMutation.isError) {
      reactivateProjectMutation.reset();
    }
  };

  return (
    <>
      <ActionsDropdown items={actions} loading={isLoading} size="slim" />
      {isError && <Toast content={error as string} error onDismiss={resetErroneousMutation} />}
      {showInfoModal && <ProjectInfoModal project={project} onClose={closeInfoModal} />}
      {showEndProjectConfirmation && <ProjectEndConfirmation project={project} onClose={closeEndProjectConfirmation} />}
    </>
  );
};

export default ProjectActionsMenu;
