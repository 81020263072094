import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import HistoryLocationState from "../types/HistoryLocationState";

/**
 * Sets an entry in the location state for the current location in order for AdapterLink
 * to access it and inject it into links created in the current location page.
 *
 * @see AdapterLink
 *
 * @param stateKey
 * @param stateValue
 */
function useSetLocationState(stateKey: keyof HistoryLocationState, stateValue: HistoryLocationState[typeof stateKey]) {
  const history = useHistory<HistoryLocationState>();

  const isStateEmpty = !history.location.state || history.location.state[stateKey] === undefined;

  useEffect(() => {
    if (isStateEmpty) {
      history.replace(history.location.pathname, { ...history.location.state, [stateKey]: stateValue });
    }
  }, [isStateEmpty]);
}

export default useSetLocationState;
