import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Toast } from "@shopify/polaris";

import api from "../../../api";
import LabelsEditorPopover from "../../../components/labels/LabelsEditorPopover";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";
import useProjectLabels from "../../../hooks/useProjectLabels";
import { Customer } from "../../../types/utilities";

// returns an array of unique selected labels
const getSelectedLabels = (customers: Customer[]) => [
  // flatMap converts an array of arrays into a single array ([[obj1], [obj2, obj3], [obj4]] -> [obj1, obj2, obj3, obj4])
  // by creating a new Map with an array of key values ([label.id, label]) we save by label id which guaranties uniqueness
  ...new Map(customers.flatMap((customer) => customer.labels || []).map((label) => [label.id, label])).values(),
];

interface ProjectCustomersLabelPopoverProps {
  project: api.Project;
  customers: Customer[];
  readonly?: boolean;
}

const ProjectCustomersLabelPopover: React.FC<ProjectCustomersLabelPopoverProps> = (props) => {
  const { project, customers, readonly } = props;

  const customersIds = customers.map((customer) => customer.id);
  const [selectedLabels, setSelectedLabels] = useState<api.Label[]>(getSelectedLabels(customers));
  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const projectLabels = useProjectLabels();
  const [showSuccessMessageToast, toggleSuccessMessageToast, closeSuccessMessageToast] = useOpenClose();

  const updateProjectCustomers = (updatedCustomers: Customer[]) => {
    const projectData = queryClient.getQueryData<api.Project>(["project-details", project.id]);
    if (projectData) {
      projectData.customers = projectData.customers.map((customer) => {
        return updatedCustomers.find((updatedCustomer) => updatedCustomer.id === customer.id) || customer;
      });
      queryClient.setQueryData(["project-details", project.id], project);
      setSelectedLabels(getSelectedLabels(updatedCustomers));
    }
  };

  const assignLabelMutation = useMutation<api.CustomerListResponse, unknown, string[]>(
    (labelIds) =>
      api.batchSetLabelsOnProjectCustomers(project.id, {
        target_ids: customersIds,
        label_ids: labelIds,
      }),
    {
      onSuccess: ({ customers }) => {
        toggleSuccessMessageToast();
        updateProjectCustomers(customers);
      },
    }
  );

  const createAndAssignLabelMutation = useMutation<api.CustomerListResponse, unknown, string>(
    (title) => api.batchCreateAndAssignLabelOnProjectCustomers(project.id, { title, target_ids: customersIds }),
    {
      onSuccess: async ({ customers }) => {
        await queryClient.invalidateQueries(["all-labels"]);
        updateProjectCustomers(customers);
      },
    }
  );

  const assignLabel = (selectedLabels: api.Label[]) => {
    assignLabelMutation.mutate(selectedLabels.map((label) => label.id));
  };

  const createLabelAndAssign = async (title: string) => await createAndAssignLabelMutation.mutateAsync(title);

  const isLoading = assignLabelMutation.isLoading || createAndAssignLabelMutation.isLoading;

  return (
    <>
      <LabelsEditorPopover
        allLabels={projectLabels}
        selectedLabels={selectedLabels}
        onSelectionChange={(selectedLabels) => assignLabel(selectedLabels)}
        onNewLabel={createLabelAndAssign}
        size="slim"
        disabled={readonly || isLoading}
      />
      {showSuccessMessageToast && (
        <Toast onDismiss={closeSuccessMessageToast} content={f("labels.popover.success.message")} />
      )}
    </>
  );
};

export default ProjectCustomersLabelPopover;
