import React from "react";
import { DisplayText, Page, ProgressBar, Stack } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";

interface ScreeningSkeletonPageProps {
  message?: string;
  progress?: number;
}

const ScreeningSkeletonPage: React.FC<ScreeningSkeletonPageProps> = (props) => {
  const f = useFormatMessage();

  const { progress = 15, message = f("common.loading.data") } = props;

  return (
    <Page>
      <Stack vertical spacing="extraLoose">
        <DisplayText size="small">{message}</DisplayText>
        <ProgressBar progress={progress} size="small" />
      </Stack>
    </Page>
  );
};

export default ScreeningSkeletonPage;
