import React, { useLayoutEffect, useState } from "react";
import { Banner, DisplayText, Page, Stack } from "@shopify/polaris";

import api from "../../api";
import { getPersonCustomerDetails, PersonCustomerDetailResponse } from "../../client.generated";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage";
import AttachmentsSection from "../../components/attachments/attachments-section/AttachmentsSection";
import Badges from "../../components/Badges";
import BasicCustomerInfo from "../../components/BasicCustomerInfo";
import CustomerActionsMenu from "../../components/CustomerActionsMenu";
import DetailsSkeleton from "../../components/DetailsSkeleton";
import DisclosureDocumentSection from "../../components/disclosure/DisclosureDocumentSection";
import MatchCases from "../../components/MatchCases";
import BackButton from "../../components/navigation/BackButton";
import NavigationSection from "../../components/navigation/NavigationSection";
import PossibleMatchBanner from "../../components/PossibleMatchBanner";
import ProjectsSection from "../../components/ProjectsSection";
import CustomerReviewsSection from "../../components/reviews-section/CustomerReviewsSection";
import SectionMargin from "../../components/SectionMargin";
import Snackbar from "../../components/Snackbar";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import useAppTitle from "../../hooks/useAppTitle";
import useCustomerBackButton from "../../hooks/useCustomerBackButton";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePageNavigation from "../../hooks/usePageNavigation";
import useSetLocationState from "../../hooks/useSetLocationState";
import { NavigationItem } from "../../providers/PageNavigation";
import { getFullName } from "../../utils/displayUtils";
import { isOnboardingFinished } from "../../utils/onboardingUtils";
import { getCustomerRisk } from "../../utils/riskUtils";

import PersonInfoSection from "./person-info-section/PersonInfoSection";

const PersonDetails: React.FC<AsyncPageChild<PersonCustomerDetailResponse>> = ({ data: person }) => {
  const features = useFeatures();
  const f = useFormatMessage();
  useAppTitle(getFullName(person));

  const [risk, setRisk] = useState<api.RiskLevel | undefined>(getCustomerRisk(person));

  const { setNavigationItems, clearNavigationItems } = usePageNavigation();

  const matchCases = person.match_cases;
  const disclosureDocuments = person.disclosure_documents;
  const reviews = person.reviews;
  const pendingReviews = person.pending_reviews;
  const showProjects = features.PROJECTS && person.projects && person.projects.length > 0;

  const { backButtonDisplayLabel, backButtonURL } = useCustomerBackButton();

  useSetLocationState("fromCustomer", { id: person.id, name: getFullName(person), type: "person" });

  useLayoutEffect(() => {
    if (!person) {
      return;
    }

    const navigationItems: NavigationItem[] = [
      { anchor: PAGE_ANCHORS.TOP_SECTION, label: f("navigation.details.overview.title") },
      { anchor: PAGE_ANCHORS.DISCLOSURES_SECTION, label: f("disclosure-documents.title") },
      { anchor: PAGE_ANCHORS.CUSTOMER_DETAILS, label: f("person.details.title") },
    ];

    if (showProjects) {
      navigationItems.push({
        anchor: PAGE_ANCHORS.CUSTOMER_PROJECTS,
        label: f("projects.page.title"),
      });
    }

    if (features.ATTACHMENTS) {
      navigationItems.push({
        anchor: PAGE_ANCHORS.ATTACHMENTS_SECTION,
        label: f("attachments.box.title"),
      });
    }
    navigationItems.push({ anchor: PAGE_ANCHORS.REVIEWS_SECTION, label: f("reviews.header.title") });

    setNavigationItems(navigationItems);

    return () => clearNavigationItems();
  }, [person, features]);

  const hasFinishedOnboarding = isOnboardingFinished(person);

  const hasNoMatchedCases = hasFinishedOnboarding && matchCases.length === 0;

  const hasUnresolvedMatchedCases = matchCases.length > 0 && matchCases.findIndex((m) => m.status !== "RESOLVED") >= 0;

  const isMonitoring = person.screening?.screening_status === "MONITORING";

  const readonly = person.is_archived;

  return (
    <Page>
      <Stack vertical wrap={false} spacing="baseTight">
        {/* Header  */}
        <Stack vertical wrap={false}>
          <BackButton showAsLink displayLabel={backButtonDisplayLabel} url={backButtonURL} />
          <Stack alignment="trailing" spacing="tight">
            <DisplayText size="large">{getFullName(person)}</DisplayText>
            <Badges isArchived={person.is_archived} isPep={person.is_pep} isSanctioned={person.is_sanctioned} />
          </Stack>
          <Stack distribution="equalSpacing">
            <BasicCustomerInfo
              customerId={person.id}
              risk={risk}
              onboardingStatus={person.onboarding_status}
              reviewStatus={person.review_status}
              screening={person.screening_status}
              responsibleUser={person.responsible}
              readonly={readonly}
            />
            {hasFinishedOnboarding && <CustomerActionsMenu customer={person} isMonitoring={isMonitoring} />}
          </Stack>
          {hasNoMatchedCases && (
            <Banner title={f("details.banners.no.matches.title")} status="success">
              <p>{f("details.banners.no.matches.content")}</p>
            </Banner>
          )}
          {hasUnresolvedMatchedCases && <PossibleMatchBanner />}
        </Stack>
        <SectionMargin />
        <MatchCases
          customerId={person.id}
          hasFinishedOnboarding={hasFinishedOnboarding}
          matchCases={matchCases}
          readonly={readonly}
        />
        <NavigationSection anchor={PAGE_ANCHORS.DISCLOSURES_SECTION} />
        <DisclosureDocumentSection
          customerId={person.id}
          disclosureDocuments={disclosureDocuments}
          readonly={readonly}
        />
        <NavigationSection anchor={PAGE_ANCHORS.CUSTOMER_DETAILS} />
        <PersonInfoSection person={person} readonly={readonly} />,
        {showProjects && (
          <>
            <NavigationSection anchor={PAGE_ANCHORS.CUSTOMER_PROJECTS} />
            <ProjectsSection projects={person.projects} />
          </>
        )}
        {features.ATTACHMENTS && (
          <>
            <NavigationSection anchor={PAGE_ANCHORS.ATTACHMENTS_SECTION} />
            <AttachmentsSection entity={person} readonly={readonly} />
          </>
        )}
        <NavigationSection anchor={PAGE_ANCHORS.REVIEWS_SECTION} />
        <CustomerReviewsSection
          risk={risk}
          customerId={person.id}
          readonly={readonly}
          reviews={reviews}
          pendingReviews={pendingReviews}
          onRiskTypeChange={(newRisk) => setRisk(newRisk)}
        />
        <Snackbar />
      </Stack>
    </Page>
  );
};

export default withAsyncPage<PersonCustomerDetailResponse>(PersonDetails, {
  name: "person-details",
  apiFunction: getPersonCustomerDetails,
  paramNames: ["id"],
  skeleton: <DetailsSkeleton />,
});
