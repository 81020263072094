import React from "react";

import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";

import UserSelect, { UserSelectProps } from "./UserSelect";

interface CustomerResponsibleUserSelectProps
  extends Omit<UserSelectProps, "updateUser" | "emptySelectionLabel" | "updateErrorMessage"> {
  customerId: string;
}

const CustomerResponsibleUserSelect: React.FC<CustomerResponsibleUserSelectProps> = (props) => {
  const { customerId, ...responsibleUserSelectProps } = props;

  const f = useFormatMessage();

  const updateResponsibleUser = (user: api.User) =>
    api.updateCustomerDetails(customerId, { responsible_id: user.id }).then((response) => response.responsible);

  return (
    <UserSelect
      updateUser={updateResponsibleUser}
      emptySelectionLabel={f("responsible.empty.user")}
      updateErrorMessage={f("responsible.error")}
      {...responsibleUserSelectProps}
    />
  );
};

export default CustomerResponsibleUserSelect;
