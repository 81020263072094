import React from "react";
import styled from "styled-components";

import { AttachmentSignatureRow } from "../useAttachmentSignatures";

interface AttachmentTableCellProps {
  row: AttachmentSignatureRow;
  centered?: boolean;
}

const AttachmentTableCell: React.FC<AttachmentTableCellProps> = (props) => {
  const { row, centered, children } = props;

  return (
    <StyledItalic isModified={row.isModified} centered={centered}>
      {children}
    </StyledItalic>
  );
};

const StyledItalic = styled.div<{ isModified: boolean; centered?: boolean }>`
  ${({ isModified }) =>
    isModified &&
    `
    font-style: italic;
    color: #777;
  `}

  ${({ centered }) =>
    centered &&
    `
      text-align: center;
  `}
`;

export default AttachmentTableCell;
