import React from "react";
import { Stack, Tooltip } from "@shopify/polaris";
import styled from "styled-components";

import EditIcon from "../../../assets/icons/edit.svg";
import useFormatMessage from "../../hooks/useFormatMessage";

interface EditInfoButtonProps {
  onClick(): void;
}

const EditInfoButton: React.FC<EditInfoButtonProps> = (props) => {
  const { onClick } = props;

  const f = useFormatMessage();

  return (
    <Stack alignment="center" spacing="extraTight" distribution="center">
      <Stack.Item>
        <Tooltip content={f("common.buttons.action.edit")}>
          <StyledContainer onClick={onClick}>
            <StyledImage src={EditIcon} />
          </StyledContainer>
        </Tooltip>
      </Stack.Item>
    </Stack>
  );
};

const StyledImage = styled.img`
  height: 34px;
`;

const StyledContainer = styled.div`
  cursor: pointer;
  height: 24px;
  margintop: -2px;
`;

export default EditInfoButton;
