import React from "react";
import { Tag, TagProps } from "@shopify/polaris";
import styled from "styled-components";

const Label: React.FC<TagProps> = (props) => {
  return (
    <StyledTagWrapper>
      <Tag {...props} />
    </StyledTagWrapper>
  );
};

const StyledTagWrapper = styled.span`
  & .Polaris-Tag {
    font-size: 1.1rem;
    line-height: 1.3rem;
    min-height: 1.3rem;
    padding: 0.2rem 0.8rem;
    --p-surface-neutral: #ffea8a;
    --p-border-radius-base: 0.2rem;
  }

  & .Polaris-Tag__TagText {
    user-select: none;
  }
`;

export default Label;
