import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Card, ColumnContentType, DataTable, Heading, Stack, TextStyle, Toast } from "@shopify/polaris";

import PlusMajorIcon from "../../../../assets/icons/plus-major.svg";
import api from "../../../api";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { CustomerDetails, Entity } from "../../../types/utilities";
import { getFullName } from "../../../utils/displayUtils";
import { isPDFAttachment } from "../../../utils/filename-utils";
import Util from "../../../utils/util";
import CustomDate from "../../CustomDate";
import Button from "../../extensions/Button";
import useAttachmentsAPI from "../useAttachmentsAPI";

import AttachmentActions from "./AttachmentActions";
import AttachmentDownloadButton from "./AttachmentDownloadButton";
import AttachmentSignaturesStatus from "./AttachmentSignaturesStatus";

interface AttachmentsSectionProps {
  entity: Entity;
  fetchSuggestedSigners?: () => Promise<CustomerDetails[]>;
  readonly?: boolean;
}

const AttachmentsSection: React.FC<AttachmentsSectionProps> = (props) => {
  const { entity, fetchSuggestedSigners, readonly } = props;

  const [attachments, setAttachments] = useState<api.AttachmentDetails[]>([]);
  const [isError, setError] = useState(false);
  const [selectedAttachmentIdToOpen, setSelectedAttachmentIdToOpen] = useState<string>();

  const f = useFormatMessage();
  const features = useFeatures();
  const { entityId, getAttachments, addAttachment } = useAttachmentsAPI(entity);

  const signaturesEnabled = features.ELECTRONIC_SIGNATURES ? true : false;

  const { isLoading, isError: isLoadingError, error, data } = useQuery(["attachments", entityId], () =>
    getAttachments(entityId)
  );

  useEffect(() => {
    if (data?.attachment_list) {
      setAttachments(data.attachment_list);
    }
  }, [data?.attachment_list]);

  const toggleError = useCallback(() => setError((e) => !e), []);

  const clearSelectedAttachmentIdToOpen = () => setSelectedAttachmentIdToOpen(undefined);

  const handleStatusClick = (attachment: api.AttachmentDetails) => {
    const isPDF = isPDFAttachment(attachment);

    if (isPDF) {
      setSelectedAttachmentIdToOpen(attachment.id);
    }
  };

  const errorMessage = isError && <Toast content={f("error.message.unknown")} error onDismiss={toggleError} />;

  const renderRow = (isLoading: boolean, attachment: api.AttachmentDetails) => {
    if (isLoading) {
      return [<div>{f("attachments.box.uploading")}</div>];
    }

    const row = [
      <AttachmentDownloadButton entity={entity} attachment={attachment} />,
      <div>{getFullName(attachment.created_by)}</div>,
      <CustomDate date={new Date(attachment.created_dt).toString()} />,
    ];

    if (signaturesEnabled) {
      row.push(<AttachmentSignaturesStatus attachment={attachment} onClick={() => handleStatusClick(attachment)} />);
    }

    row.push(
      <AttachmentActions
        entity={entity}
        attachment={attachment}
        fetchSuggestedSigners={fetchSuggestedSigners}
        open={attachment.id === selectedAttachmentIdToOpen}
        readonly={readonly}
        onClose={clearSelectedAttachmentIdToOpen}
        signaturesEnabled={signaturesEnabled}
      />
    );

    return row;
  };

  const uploadFile = async (file: File) => {
    const filename = Util.cleanupFilename(file.name) || "default-document";

    try {
      // get upload_url
      const { upload_url, upload_id } = await api.generateAttachmentUploadIdAndUrl({
        filename,
        content_type: "multipart/form-data",
      });

      // upload the file to the cloud
      await fetch(upload_url, { method: "PUT", body: file });

      // save the result
      const request = { filename, upload_id };
      const r2 = await addAttachment(entityId, request);

      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i] == null) {
          attachments[i] = r2;
          setAttachments([...attachments]);
          return;
        }
      }
    } catch (error: unknown) {
      console.log(error);
      toggleError();
      const loadingPlaceholder = attachments.findIndex((v) => v == null);
      if (loadingPlaceholder > -1) {
        attachments.splice(loadingPlaceholder, 1);
        setAttachments([...attachments]);
      }
    }
  };

  if (isLoadingError) {
    console.log(error);
  }

  const tableContentTypes: ColumnContentType[] = signaturesEnabled
    ? ["text", "text", "text", "text", "numeric"]
    : ["text", "text", "text", "numeric"];

  const tableHeadings = [
    <TextStyle variation="subdued">{f("attachments.table.header.document")}</TextStyle>,
    <TextStyle variation="subdued">{f("attachments.table.header.created.by")}</TextStyle>,
    <TextStyle variation="subdued">{f("attachments.table.header.created.date")}</TextStyle>,
  ];
  if (signaturesEnabled) {
    tableHeadings.push(<TextStyle variation="subdued">{f("attachments.table.header.signature-status")}</TextStyle>);
  }
  tableHeadings.push(<TextStyle variation="subdued" />);

  return (
    <Stack vertical>
      {errorMessage}
      <Stack distribution="equalSpacing">
        <Heading>{f("attachments.box.title")}</Heading>
        <input
          onChange={(event) => {
            const filesCount = event.target?.files?.length || 0;
            for (let i = 0; i < filesCount || 0; i++) {
              uploadFile(event.target.files![i]);

              // @ts-ignore
              attachments.unshift(null);
              setAttachments([...attachments]);
            }
          }}
          id="file"
          type="file"
          style={{ visibility: "hidden" }}
        />

        <Button
          size="slim"
          icon={PlusMajorIcon}
          onClick={() => {
            document.getElementById("file")?.click();
          }}
          disabled={readonly}
        >
          {f("attachments.upload.button")}
        </Button>
      </Stack>
      <Card>
        {attachments.length > 0 ? (
          <DataTable
            truncate
            columnContentTypes={tableContentTypes}
            headings={tableHeadings}
            rows={attachments.map((attachment: api.AttachmentDetails) => renderRow(attachment === null, attachment))}
          />
        ) : (
          <Card.Section>{isLoading ? f("attachments.labels.loading") : f("attachments.labels.empty")}</Card.Section>
        )}
      </Card>
    </Stack>
  );
};

export default AttachmentsSection;
