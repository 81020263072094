import React from "react";

import ActionsDropdown from "../../components/ActionsDropdown";
import ProjectInfoModal from "../../components/modals/ProjectInfoModal";
import { Customer } from "../../types/utilities";

import AddToProjectModal from "./actions/AddToProjectModal";
import ArchiveCustomers from "./actions/ArchiveCustomers";
import ResponsibleUserModal from "./actions/ResponsibleUserModal";
import RestoreCustomers from "./actions/RestoreCustomers";
import useGetCustomersListBulkActions from "./useGetCustomersListBulkActions";

interface CustomersListBulkActionsProps {
  customers: Customer[];
  isArchived: boolean;
  onClearSelection(): void;
}

const CustomersListBulkActions: React.FC<CustomersListBulkActionsProps> = (props) => {
  const { customers, isArchived, onClearSelection } = props;

  const {
    bulkActions,
    showResponsibleUserModal,
    showArchiveConfirm,
    showRestoreConfirm,
    showAddToProjectModal,
    showAddToNewProjectModal,
    closeResponsibleUserModal,
    closeArchiveConfirm,
    closeRestoreConfirm,
    closeAddToProjectModal,
    closeAddToNewProjectModal,
  } = useGetCustomersListBulkActions(customers.length, isArchived);

  return (
    <>
      <ActionsDropdown items={bulkActions} cutoff={bulkActions.length === 1 ? 1 : 0} />
      {showResponsibleUserModal && <ResponsibleUserModal customers={customers} onClose={closeResponsibleUserModal} />}
      {showArchiveConfirm && (
        <ArchiveCustomers customers={customers} onClose={closeArchiveConfirm} onSuccess={onClearSelection} />
      )}
      {showRestoreConfirm && (
        <RestoreCustomers customers={customers} onClose={closeRestoreConfirm} onSuccess={onClearSelection} />
      )}
      {showAddToProjectModal && <AddToProjectModal customers={customers} onClose={closeAddToProjectModal} />}
      {showAddToNewProjectModal && <ProjectInfoModal customers={customers} onClose={closeAddToNewProjectModal} />}
    </>
  );
};

export default CustomersListBulkActions;
