import React from "react";
import { useQueryClient } from "react-query";
import { Modal } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import CompanyCustomerForm from "../CompanyCustomerForm";

interface EditCompanyCustomerInfoModalProps {
  companyCustomer: api.CompanyCustomerDetailResponse;
  onClose(): void;
}

const EditCompanyCustomerInfoModal: React.FC<EditCompanyCustomerInfoModalProps> = ({ companyCustomer, onClose }) => {
  const f = useFormatMessage();
  const queryClient = useQueryClient();

  return (
    <Modal open={true} onClose={onClose} title={f("modals.company.edit.title")}>
      <CompanyCustomerForm
        companyCustomer={companyCustomer}
        submitLabel={f("default.save")}
        onAfterSubmit={(updatedCompanyDetails) => {
          // Update query data on "company-details"-query
          const companyDetailsQueryKey = ["company-details", companyCustomer.id];
          const currentCompanyDetailsData = queryClient.getQueryData<api.CompanyCustomerDetailResponse>(
            companyDetailsQueryKey
          );
          queryClient.setQueryData(companyDetailsQueryKey, {
            ...currentCompanyDetailsData,
            ...updatedCompanyDetails,
            // NOTE: `api.CompanyCustomer.address` is the same as `business_address`
            // This will/should be fixed/improved in beaufort-api, but
            // do this as a quick-fix for now
            business_address: updatedCompanyDetails.address,
          });

          // Close modal
          onClose();
        }}
      />
    </Modal>
  );
};

export default EditCompanyCustomerInfoModal;
