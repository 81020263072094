import * as React from "react";
import { useState } from "react";
import { generatePath } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { Button, Page, SkeletonBodyText, SkeletonDisplayText, Stack, TextStyle } from "@shopify/polaris";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage";
import DisplayName from "../../components/DisplayName";
import DataTable from "../../components/extensions/DataTable";
import SectionDivider from "../../components/extensions/SectionDivider";
import BackButton from "../../components/navigation/BackButton";
import useNavigationToolbar from "../../components/navigation/useNavigationToolbar";
import OnboardingSummaryProjectCard from "../../components/OnboardingSummaryProjectCard";
import OnboardingSummarySection from "../../components/OnboardingSummarySection";
import OnboardingSummarySignatureCard from "../../components/OnboardingSummarySignatureCard";
import SectionMargin from "../../components/SectionMargin";
import { ROUTES } from "../../constants/routes";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IDParams } from "../../types/params";
import { getBirthDate, getFullName } from "../../utils/displayUtils";

const CompanyOnboardingSummary: React.FC<AsyncPageChild<api.CompanyCustomerDetailResponse>> = ({ data: customer }) => {
  const { id } = useParams<IDParams>();
  const history = useHistory();
  const f = useFormatMessage();
  const features = useFeatures();
  const { isManualCompany } = useNavigationToolbar();

  const [isPosting, setPosting] = useState(false);

  const roles = customer.screening?.roles || [];
  const owners = customer.screening?.beneficial_owners || [];

  const onboardingSignatures = customer.disclosure_documents[0] ? customer.disclosure_documents[0].signatures : [];

  const renderRoles = (roles: api.RoleScreeningConfiguration[]) => {
    return roles.map(({ role }) => [
      <DisplayName
        key={role.id}
        type="person"
        name={getFullName(role.person)}
        birthDate={getBirthDate(role.person)}
        comment={role.comment}
        roleType={role.type}
      />,
    ]);
  };

  const renderOwners = (owners: api.BeneficialOwnerScreeningConfiguration[]) => {
    return owners.map(({ owner }) => {
      return [
        <DisplayName
          type="person"
          name={getFullName(owner.person)}
          birthDate={getBirthDate(owner.person)}
          comment={owner.comment}
        />,
        `${owner.percent_share || "0"}%`,
      ];
    });
  };

  const associatedProject = customer.projects && customer.projects.length === 1 ? customer.projects[0] : undefined;
  const isCreatedViaProject = associatedProject !== undefined;

  const redirectURLAfterFinish = isCreatedViaProject
    ? generatePath(ROUTES.PROJECT_DETAILS, { id: associatedProject.id })
    : generatePath(ROUTES.COMPANY_DETAILS, { id });

  const finish = () => {
    setPosting(true);
    api
      .finishOnboarding(id)
      .then(() => {
        setPosting(false);
        history.push(generatePath(redirectURLAfterFinish));
      })
      .catch((error) => {
        setPosting(false);
        console.log(error);
      });
  };

  return (
    <Page title={f("onboarding.summary.title")}>
      <Stack vertical>
        <div>{f("summary.description")}</div>

        <OnboardingSummarySection title={f("summary.screening-type")}>
          <TextStyle>{f(`screening-type.${customer.screening?.screening_type || "NOT_SET"}`)}</TextStyle>
        </OnboardingSummarySection>

        <OnboardingSummarySection title={f("summary.text.screening.company")}>
          <DisplayName name={customer.name} type={customer.type || "company"} orgId={customer.national_id} />
        </OnboardingSummarySection>

        <OnboardingSummarySection title={f("summary.text.screening.owners")}>
          {owners.length > 0 && (
            <DataTable
              columnContentTypes={["text", "numeric"]}
              headings={[
                <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
                <TextStyle variation="subdued">{f("table.column.shares")}</TextStyle>,
              ]}
              rows={renderOwners(owners)}
              readonly
            />
          )}
          {owners.length === 0 && <div>{f("summary.text.no-owners")}</div>}
        </OnboardingSummarySection>

        <OnboardingSummarySection title={f("summary.text.screening.roles")}>
          {roles.length > 0 && (
            <DataTable
              columnContentTypes={["text", "text"]}
              headings={[
                <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
                <TextStyle variation="subdued">{f("table.column.role")}</TextStyle>,
              ]}
              rows={renderRoles(roles)}
              readonly
            />
          )}
          {roles.length === 0 && <div>{f("summary.text.no-roles")}</div>}
        </OnboardingSummarySection>

        <OnboardingSummarySection title={f("summary.signers.title")}>
          {onboardingSignatures.length === 0 && <>{f("summary.signers.text.skipped")}</>}
          {onboardingSignatures.length > 0 &&
            onboardingSignatures.map((signature, index) => (
              <OnboardingSummarySignatureCard key={index} signature={signature} />
            ))}
        </OnboardingSummarySection>

        {associatedProject && (
          <OnboardingSummarySection
            title={f("summary.project.title")}
            hide={!features.PROJECTS || !isCreatedViaProject}
          >
            <OnboardingSummaryProjectCard projectName={associatedProject.name} />
          </OnboardingSummarySection>
        )}

        <SectionMargin size="medium" />
        <Stack distribution="trailing">
          <BackButton
            url={
              isManualCompany
                ? generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_DISCLOSURES, { id: id })
                : generatePath(ROUTES.COMPANY_ONBOARDING_DISCLOSURES, { id: id })
            }
          />
          <Button primary loading={isPosting} disabled={isPosting} onClick={finish}>
            {f("summary.signers.start.screening")}
          </Button>
        </Stack>
      </Stack>
    </Page>
  );
};

const Skeleton = () => (
  <Page>
    <Stack vertical>
      <SkeletonDisplayText size="large" />
      <SkeletonBodyText lines={3} />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={1} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={1} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={3} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={3} />
      <SectionDivider />

      <SkeletonDisplayText size="medium" />
      <SkeletonBodyText lines={3} />
      <SectionDivider />

      <Stack distribution="trailing">
        <Button primary disabled={true}>
          {/* @ts-ignore */}
          <SkeletonBodyText lines={1} />
        </Button>
      </Stack>
    </Stack>
  </Page>
);

export default withAsyncPage<api.CompanyCustomerDetailResponse>(CompanyOnboardingSummary, {
  name: "company-onboarding-summary",
  apiFunction: api.getCompanyCustomerDetails,
  paramNames: ["id"],
  skeleton: <Skeleton />,
});
