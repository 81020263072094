import React, { useEffect, useState } from "react";
import { ActionListItemDescriptor, ActionListSection, Spinner } from "@shopify/polaris";

import DownloadIcon from "../../../../assets/icons/arrow-down-rectangle.svg";
import EditESignaturesIcon from "../../../../assets/icons/pen-line.svg";
import DeleteIcon from "../../../../assets/icons/trash.svg";
import api from "../../../api";
import useAttachmentDownload from "../../../hooks/useAttachmentDownload";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { CustomerDetails, Entity, isPersonCustomer } from "../../../types/utilities";
import { hasSignedAttachment, isAttachmentSigned } from "../../../utils/attachmentUtils";
import { isPDFAttachment } from "../../../utils/filename-utils";
import ActionsDropdown from "../../ActionsDropdown";
import DeleteAttachmentConfirmationModal from "../../modals/DeleteAttachmentConfirmationModal";
import AttachmentSignaturesModal from "../modal/AttachmentSignaturesModal";

type AttachmentActionsProps = {
  entity: Entity;
  attachment: api.AttachmentDetails;
  fetchSuggestedSigners?: () => Promise<CustomerDetails[]>;
  open: boolean;
  readonly?: boolean;
  signaturesEnabled?: boolean;
  onClose(): void;
};

const AttachmentActions: React.FC<AttachmentActionsProps> = (props) => {
  const { entity, attachment, fetchSuggestedSigners, open, readonly, signaturesEnabled, onClose } = props;

  const [showSignaturesAttachmentModal, setShowSignaturesAttachmentModal] = useState(open);
  const [deleteConfirmationModalState, setDeleteConfirmationModalState] = useState({
    open: false,
    deleteSignedAttachment: false,
  });

  const f = useFormatMessage();

  const closeDeleteModal = () => setDeleteConfirmationModalState({ ...deleteConfirmationModalState, open: false });

  useEffect(() => {
    setShowSignaturesAttachmentModal(open);
  }, [open]);

  const downloadAttachmentMutation = useAttachmentDownload(entity);

  const actions: ActionListItemDescriptor[] = [];

  const isSigned = isAttachmentSigned(attachment);

  const hasSignedAttachmentFile = hasSignedAttachment(attachment);

  if (signaturesEnabled) {
    const isPDF = isPDFAttachment(attachment);

    actions.push({
      content: readonly || isSigned ? f("attachments.actions.view-signature") : f("attachments.actions.edit-signature"),
      image: EditESignaturesIcon,
      onAction: () => setShowSignaturesAttachmentModal(true),
      disabled: !isPDF || downloadAttachmentMutation.isLoading,
      helpText: !isPDF ? f("attachments.actions.signature.help-text") : undefined,
    });
  }

  actions.push(
    {
      content: f("attachments.actions.download-attachment"),
      image: DownloadIcon,
      disabled: downloadAttachmentMutation.isLoading,
      prefix:
        downloadAttachmentMutation.isLoading && !downloadAttachmentMutation.variables?.downloadSignedAttachment ? (
          <Spinner size="small" />
        ) : null,
      onAction: () => downloadAttachmentMutation.mutate({ attachment }),
    },
    {
      content: f("attachments.actions.delete-attachment"),
      image: DeleteIcon,
      onAction: () => setDeleteConfirmationModalState({ open: true, deleteSignedAttachment: false }),
      disabled: readonly || isSigned || downloadAttachmentMutation.isLoading,
    }
  );

  const signedAttachmentSection: ActionListSection = {
    title: f("attachments.actions.section.signed.document.title"),
    items: [
      {
        content: f("attachments.actions.download-signed-attachment"),
        image: DownloadIcon,
        disabled: downloadAttachmentMutation.isLoading,
        prefix:
          downloadAttachmentMutation.isLoading && downloadAttachmentMutation.variables?.downloadSignedAttachment ? (
            <Spinner size="small" />
          ) : null,
        onAction: () => downloadAttachmentMutation.mutate({ attachment, downloadSignedAttachment: true }),
      },
      {
        content: f("attachments.actions.delete-signed-attachment"),
        image: DeleteIcon,
        onAction: () => setDeleteConfirmationModalState({ open: true, deleteSignedAttachment: true }),
        disabled: readonly || downloadAttachmentMutation.isLoading,
      },
    ],
  };

  return (
    <>
      <ActionsDropdown
        hideTitle
        items={actions}
        forceOpen={downloadAttachmentMutation.isLoading}
        sections={hasSignedAttachmentFile ? [signedAttachmentSection] : undefined}
      />
      {showSignaturesAttachmentModal && (
        <AttachmentSignaturesModal
          entity={entity}
          attachment={attachment}
          fetchSuggestedSigners={fetchSuggestedSigners}
          readonly={readonly}
          initialPerson={isPersonCustomer(entity) ? entity : undefined}
          onModalClose={() => {
            setShowSignaturesAttachmentModal(false);
            onClose();
          }}
        />
      )}
      {deleteConfirmationModalState.open && (
        <DeleteAttachmentConfirmationModal
          entity={entity}
          attachment={attachment}
          deleteSignedAttachment={deleteConfirmationModalState.deleteSignedAttachment}
          onModalClose={closeDeleteModal}
        />
      )}
    </>
  );
};

export default AttachmentActions;
