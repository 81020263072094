import * as React from "react";
import { Stack, Tooltip } from "@shopify/polaris";

import useFormatMessage from "../hooks/useFormatMessage";

import Badge from "./extensions/Badge";

type BadgesProps = {
  isArchived?: boolean;
  isPep?: boolean;
  isSanctioned?: boolean;
  hasNegativeNews?: boolean;
  isOther?: boolean;
};

const Badges: React.FC<BadgesProps> = ({
  isArchived = false,
  isPep = false,
  isSanctioned = false,
  hasNegativeNews = false,
  isOther = false,
}) => {
  const f = useFormatMessage();

  const hasBadges: boolean = isArchived || isPep || isSanctioned || hasNegativeNews || isOther;

  if (!hasBadges) {
    return null;
  }

  return (
    <Stack spacing="extraTight">
      {isArchived && (
        <Tooltip dismissOnMouseOut content={f("badge.archived.description")}>
          <Badge size="xsmall">{f("badge.archived")}</Badge>
        </Tooltip>
      )}
      {isPep && (
        <Tooltip dismissOnMouseOut content={f("badge.pep.description")}>
          <Badge size="xsmall">{f("badge.pep")}</Badge>
        </Tooltip>
      )}
      {isSanctioned && (
        <Tooltip dismissOnMouseOut content={f("badge.sanctioned.description")}>
          <Badge size="xsmall">{f("badge.sanctioned")}</Badge>
        </Tooltip>
      )}
      {hasNegativeNews && (
        <Tooltip dismissOnMouseOut content={f("badge.negative-news.description")}>
          <Badge size="xsmall">{f("badge.negative-news")}</Badge>
        </Tooltip>
      )}
      {isOther && (
        <Tooltip dismissOnMouseOut content={f("badge.other.description")}>
          <Badge size="xsmall">{f("badge.other")}</Badge>
        </Tooltip>
      )}
    </Stack>
  );
};

export default Badges;
