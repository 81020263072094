import React, { PropsWithChildren, useState } from "react";
import { useMutation } from "react-query";
import { Stack, TextField } from "@shopify/polaris";

import useFormatMessage from "../hooks/useFormatMessage";
import { isEmptyString } from "../utils/stringUtils";
import { noop } from "../utils/util";

import Button from "./extensions/Button";
import ErrorPanel from "./ErrorPanel";

/**
 * Generic Review form that calls onCreateReview to create a new review
 */
interface ReviewFormProps<T> {
  initialComment?: string;
  readonly?: boolean;
  placeholder?: string;
  onCreateReview(comment: string): Promise<T>;
  onAfterReviewCreated(review: T): void;
  onCancel?(): void;
  isTextFieldFocused?: boolean;
  setTextFieldFocused?(isTextFieldFocused: boolean): void;
}

function ReviewForm<T>(props: PropsWithChildren<ReviewFormProps<T>>) {
  const {
    initialComment = "",
    readonly,
    placeholder,
    onCreateReview,
    onAfterReviewCreated,
    onCancel = noop,
    isTextFieldFocused,
    setTextFieldFocused = noop,
    children,
  } = props;

  const [comment, setComment] = useState(initialComment);
  const f = useFormatMessage();

  const createReviewMutation = useMutation(() => onCreateReview(comment), {
    onSuccess: (review: T) => {
      setComment("");
      onAfterReviewCreated(review);
    },
  });

  const handleCancel = () => {
    createReviewMutation.reset();
    setComment("");
    onCancel();
  };

  const isReviewSubmitDisabled = createReviewMutation.isLoading || isEmptyString(comment) || readonly;

  return (
    <Stack vertical>
      <TextField
        focused={isTextFieldFocused}
        onFocus={() => setTextFieldFocused(true)}
        onBlur={() => setTextFieldFocused(false)}
        label=""
        labelHidden
        value={comment}
        placeholder={placeholder}
        onChange={setComment}
        multiline={4}
        id="text"
        autoComplete="off"
        disabled={readonly}
      />

      {createReviewMutation.isError && <ErrorPanel message={createReviewMutation.error} />}

      <Stack distribution="trailing" alignment="center">
        <Stack.Item fill>{children}</Stack.Item>
        <Button onClick={handleCancel} disabled={readonly}>
          {f("default.cancel")}
        </Button>
        <Button
          primary
          disabled={isReviewSubmitDisabled}
          loading={createReviewMutation.isLoading}
          onClick={() => createReviewMutation.mutate()}
        >
          {f("default.save")}
        </Button>
      </Stack>
    </Stack>
  );
}

export default ReviewForm;
