import React, { useMemo } from "react";
import { generatePath } from "react-router-dom";
import { Card, ColumnContentType, Heading, Link, Stack, TextStyle } from "@shopify/polaris";

import api from "../api";
import { ROUTES } from "../constants/routes";
import useFormatMessage from "../hooks/useFormatMessage";

import DataTable from "./extensions/DataTable";
import TableCellChild from "./extensions/TableCellChild";
import ProjectStatus from "./ProjectStatus";

interface ProjectsSectionProps {
  projects?: api.ProjectListItem[];
}

const ProjectsSection: React.FC<ProjectsSectionProps> = (props) => {
  const { projects = [] } = props;

  const f = useFormatMessage();

  const columnContentTypes: ColumnContentType[] = ["text", "text"];

  const headings = [
    <TextStyle variation="subdued">{f("table.column.status")}</TextStyle>,
    <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
  ];

  const renderProjectRow = (project: api.ProjectListItem) => [
    <TableCellChild width={"25%"}>
      <ProjectStatus status={project.status} />
    </TableCellChild>,
    <Link url={generatePath(ROUTES.PROJECT_DETAILS, { id: project.id })}>
      <TextStyle>{project.name}</TextStyle>
    </Link>,
  ];

  const rows = useMemo(() => projects.map(renderProjectRow), [projects]);

  if (projects.length === 0) {
    return null;
  }

  return (
    <Stack vertical>
      <Stack distribution="equalSpacing">
        <Heading>{f("projects.page.title")}</Heading>
      </Stack>
      <Card>
        <DataTable columnContentTypes={columnContentTypes} headings={headings} rows={rows} />
      </Card>
    </Stack>
  );
};

export default ProjectsSection;
