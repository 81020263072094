import React, { createContext, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { PAGE_ANCHORS } from "../constants/page-anchors";
import useTimeout from "../hooks/useTimeout";
import Util, { noop } from "../utils/util";

interface NavigationItemOptions {
  count?: number;
  isNew?: boolean;
}

export interface NavigationItem {
  label: string;
  anchor: string;
  options?: NavigationItemOptions;
}

export interface PageNavigation {
  navigationItems: NavigationItem[];
  setNavigationItems(items: NavigationItem[]): void;
  clearNavigationItems(): void;
  showNavigationMenu: boolean;
  isNavigationScrolling: boolean;
  navigateInPage(anchor: string, options?: boolean | ScrollIntoViewOptions): void;
}

export const PageNavigationContext = createContext<PageNavigation>({
  navigationItems: [],
  // initial value for PageNavigationContext requires empty methods
  setNavigationItems: noop,
  clearNavigationItems: noop,
  showNavigationMenu: false,
  isNavigationScrolling: false,
  navigateInPage: noop,
});

export const PageNavigationContextProvider: React.FC = ({ children }) => {
  const [navigationItems, setNavigationItems] = useState<NavigationItem[]>([]);
  const [isNavigationScrolling, setIsNavigationScrolling] = useState(false);

  useTimeout(() => setIsNavigationScrolling(false), 500, isNavigationScrolling);

  const location = useLocation();
  const history = useHistory();

  const showNavigationMenu = navigationItems.length > 0;

  const clearNavigationItems = () => setNavigationItems([]);

  const navigateInPage = useCallback(
    (anchor: string, options?: boolean | ScrollIntoViewOptions) => {
      location.hash = anchor === PAGE_ANCHORS.TOP_SECTION ? "" : `#${anchor}`;
      history.replace(location);
      setIsNavigationScrolling(true);
      Util.scrollTo(anchor, options !== undefined ? options : { behavior: "smooth" });
    },
    [history, location]
  );

  const value: PageNavigation = {
    navigationItems,
    setNavigationItems,
    clearNavigationItems,
    showNavigationMenu,
    isNavigationScrolling,
    navigateInPage,
  };

  return <PageNavigationContext.Provider value={value}>{children}</PageNavigationContext.Provider>;
};
