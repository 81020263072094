import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ComplexAction, Modal, TextStyle } from "@shopify/polaris";

import api from "../../../api";
import CustomersResourceList from "../../../components/CustomersResourceList";
import ErrorPanel from "../../../components/ErrorPanel";
import useFormatMessage from "../../../hooks/useFormatMessage";

interface AddCustomersToProjectModalProps {
  project: api.Project;
  readonly?: boolean;
  onClose(): void;
}

const AddCustomersToProjectModal: React.FC<AddCustomersToProjectModalProps> = (props) => {
  const { project, readonly, onClose } = props;

  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[]>(project.customers.map((customer) => customer.id));

  const addCustomersToProjectMutation = useMutation(
    () => api.updateProject(project.id, { customer_ids: selectedIds }),
    {
      onSuccess: (updatedProject) => {
        queryClient.setQueryData(["project-details", updatedProject.id], updatedProject);
        onClose();
      },
    }
  );

  const saveAction: ComplexAction = {
    content: f("default.add"),
    onAction: () => addCustomersToProjectMutation.mutate(),
    disabled: readonly || addCustomersToProjectMutation.isLoading,
    loading: addCustomersToProjectMutation.isLoading,
  };

  const cancelAction: ComplexAction = {
    content: f("default.close"),
    onAction: onClose,
    disabled: addCustomersToProjectMutation.isLoading,
  };

  return (
    <Modal
      open
      title={f("project.customers.modal.title", { name: project.name })}
      onClose={onClose}
      primaryAction={saveAction}
      secondaryActions={[cancelAction]}
      onScrolledToBottom={() => setPage(page + 1)}
      footer={<TextStyle>{f("common.labels.selected-customers-count", { count: selectedIds.length })}</TextStyle>}
    >
      <Modal.Section>
        <CustomersResourceList
          page={page}
          resetPage={() => setPage(0)}
          selectedIds={selectedIds}
          existingCustomers={project.customers}
          onSelectionChange={setSelectedIds}
        />
      </Modal.Section>
      {addCustomersToProjectMutation.isError && <ErrorPanel message={addCustomersToProjectMutation.error} />}
    </Modal>
  );
};

export default AddCustomersToProjectModal;
