import { generatePath, matchPath, useHistory } from "react-router-dom";

import {
  COMPANY_ONBOARDING_ROUTES,
  MANUAL_COMPANY_ONBOARDING_ROUTES,
  PERSON_ONBOARDING_ROUTES,
  ROUTES,
} from "../../constants/routes";
import { IDParams } from "../../types/params";

type MenuType = "person" | "company" | "manualCompany";

const useNavigationToolbar = () => {
  const history = useHistory();
  const path = history.location.pathname;

  const matchPersonOnboard = matchPath<IDParams>(path, PERSON_ONBOARDING_ROUTES);
  const matchCompanyOnboard = matchPath<IDParams>(path, COMPANY_ONBOARDING_ROUTES);
  const matchManualCompanyOnboard = matchPath<IDParams>(path, MANUAL_COMPANY_ONBOARDING_ROUTES);

  const isNewPersonOnboard = matchPath(path, ROUTES.PERSON_ONBOARDING_NEW) !== null;

  let menuType: MenuType = "company";
  let onboardingStepIndex = 0;
  if (matchPersonOnboard || isNewPersonOnboard) {
    menuType = "person";
    onboardingStepIndex = isNewPersonOnboard
      ? 0
      : PERSON_ONBOARDING_ROUTES.findIndex((route) => matchPath(path, route));
  } else if (matchManualCompanyOnboard) {
    menuType = "manualCompany";
    onboardingStepIndex = MANUAL_COMPANY_ONBOARDING_ROUTES.findIndex((route) => matchPath(path, route));
  } else {
    onboardingStepIndex = COMPANY_ONBOARDING_ROUTES.findIndex((route) => matchPath(path, route));
  }

  const goToStep = (stepIndex: number) => {
    const steps =
      menuType === "company"
        ? COMPANY_ONBOARDING_ROUTES
        : menuType === "manualCompany"
        ? MANUAL_COMPANY_ONBOARDING_ROUTES
        : PERSON_ONBOARDING_ROUTES;

    if (stepIndex < 0 || stepIndex >= steps.length) {
      throw new Error(`stepIndex must be between 0 and ${steps.length - 1}`);
    }

    const route = steps[stepIndex];
    const pathname = generatePath(route, { id });
    history.push(pathname);
  };

  const id = matchPersonOnboard?.params.id || matchCompanyOnboard?.params.id || matchManualCompanyOnboard?.params.id;

  const isManualCompany = menuType === "manualCompany";

  return {
    id,
    menuType,
    isManualCompany,
    onboardingStepIndex,
    goToStep,
  };
};

export default useNavigationToolbar;
