import React from "react";
import { Button, ButtonGroup, ButtonGroupProps,ButtonProps } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";

export type EmailOrLink = "email" | "link";

interface EmailOrLinkButtonGroupInputProps extends ButtonGroupProps {
  value?: EmailOrLink;
  className?: string;
  onChange(value: EmailOrLink): void;
  buttonsSize?: ButtonProps["size"];
}

const EmailOrLinkButtonGroupInput: React.FC<EmailOrLinkButtonGroupInputProps> = (props) => {
  const { value, onChange, buttonsSize = "slim", className, ...buttonGroupProps } = props;
  const f = useFormatMessage();

  const isEmail = value === "email";

  return (
    <span className={className}>
      <ButtonGroup segmented {...buttonGroupProps}>
        <Button size={buttonsSize} primary={isEmail} onClick={() => onChange("email")}>
          {f("default.email")}
        </Button>
        <Button size={buttonsSize} primary={!isEmail} onClick={() => onChange("link")}>
          {f("default.link")}
        </Button>
      </ButtonGroup>
    </span>
  );
};

export default EmailOrLinkButtonGroupInput;
