import React, { CSSProperties, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import { Column } from "react-table";
import { Link } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import CustomDate from "../../components/CustomDate";
import TableColumnHeader from "../../components/extensions/TableColumnHeader";
import PaginationControls from "../../components/PaginationControls";
import ProjectStatus from "../../components/ProjectStatus";
import ReactTable, { SORT_DIRECTION } from "../../components/ReactTable";
import UserInitials from "../../components/UserInitials";
import { SORT_BY_ADDED_DATE_PARAMETER, SORT_BY_NAME_PARAMETER } from "../../constants/pagination";
import { ROUTES } from "../../constants/routes";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useLanguage from "../../hooks/useLanguage";
import { getFullName } from "../../utils/displayUtils";

import useProjectListParams from "./useProjectListParams";

interface ProjectsListTableProps {
  isLoading: boolean;
  projects: api.ProjectListItem[];
  count: number;
  selectedIds?: string[];
  onSelectionChange?(projectsIds: string[]): void;
}

const ProjectsListTable: React.FC<ProjectsListTableProps> = (props) => {
  const { isLoading, projects, count, selectedIds, onSelectionChange } = props;

  const features = useFeatures();
  const language = useLanguage();
  const f = useFormatMessage();
  const { formatNumber } = useIntl();
  const { page, setPage, limit, setLimit, order, setOrder } = useProjectListParams();

  const columns: Column<api.ProjectListItem>[] = useMemo(
    () => [
      {
        Header: f("projects.list.column.status"),
        accessor: "status",
        Cell: ({ value }) => <ProjectStatus status={value} />,
      },
      {
        Header: f("projects.list.column.name"),
        accessor: "id",
        id: SORT_BY_NAME_PARAMETER,
        defaultCanSort: true,
        Cell: ({ row: { original: project } }) => (
          <Link url={generatePath(ROUTES.PROJECT_DETAILS, { id: project.id })}>
            <StyledWrapped title={project.name}>{project.name}</StyledWrapped>
          </Link>
        ),
      },
      {
        Header: <TableColumnHeader centered>{f("customers.list.column.customers.count")}</TableColumnHeader>,
        accessor: "customer_count",
        Cell: ({ value }) => <StyledTableCell>{formatNumber(value)}</StyledTableCell>,
      },
      {
        Header: <TableColumnHeader centered>{f("customers.list.column.responsible.user")}</TableColumnHeader>,
        accessor: "responsible",
        Cell: ({ value: responsible }) => (
          <UserInitials
            user={responsible}
            tooltip={`${f("responsible.label")}: ${getFullName(responsible)}`}
            hideOnNoUser
          />
        ),
      },
      {
        Header: <TableColumnHeader centered>{f("customers.list.column.added")}</TableColumnHeader>,
        accessor: "created_dt",
        id: SORT_BY_ADDED_DATE_PARAMETER,
        defaultCanSort: true,
        Cell: ({ value }) => (
          <StyledTableCell>
            <CustomDate date={value} />
          </StyledTableCell>
        ),
      },
    ],
    [language]
  );

  const getRowId = useCallback((project: api.ProjectListItem) => project.id, []);

  const hiddenColumns = useMemo(() => {
    const results: string[] = [];

    if (!features.RESPONSIBLE_USER) {
      results.push("responsible");
    }

    return results;
  }, [features]);

  const handleSortChange = useCallback(
    (columnId: string, sortDirection: SORT_DIRECTION) => {
      const orderDirection = sortDirection === "ascending" ? "" : "-";
      const newOrder = orderDirection + columnId;

      setOrder(newOrder);
      setPage(0);
    },
    [setOrder, setPage]
  );

  const sortedColumnId = order.includes(SORT_BY_NAME_PARAMETER) ? SORT_BY_NAME_PARAMETER : SORT_BY_ADDED_DATE_PARAMETER;
  const sortedDirection = order.startsWith("-") ? "descending" : "ascending";

  return (
    <ReactTable<api.ProjectListItem>
      columns={columns}
      data={projects}
      getRowId={getRowId}
      hiddenColumns={hiddenColumns}
      sortedColumnId={sortedColumnId}
      sortedDirection={sortedDirection}
      onSortChange={handleSortChange}
      selectedIds={selectedIds || []}
      onSelectionChange={onSelectionChange}
      isLoading={isLoading}
      footerContent={
        <PaginationControls
          isLoading={isLoading}
          page={page}
          limit={limit}
          count={count}
          itemsCount={projects.length}
          setPage={setPage}
          setLimit={setLimit}
        />
      }
    />
  );
};

const StyledWrapped = styled.div<{ maxWidth?: CSSProperties["maxWidth"] }>`
  display: block;
  max-width: ${({ maxWidth }) => maxWidth || "600px"};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledTableCell = styled.div`
  text-align: center;
`;

export default ProjectsListTable;
