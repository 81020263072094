import React, { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ActionList, ActionListItemDescriptor, Modal, Stack, TextStyle, Toast } from "@shopify/polaris";
import { TickSmallMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import api from "../../../api";
import EmailView from "../../../components/EmailView";
import ErrorPanel from "../../../components/ErrorPanel";
import DataTable from "../../../components/extensions/DataTable";
import Icon from "../../../components/extensions/Icon";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useFormatMultipleNames from "../../../hooks/useFormatMultipleNames";
import useGetUsers from "../../../hooks/useGetUsers";
import useOpenClose from "../../../hooks/useOpenClose";
import { Customer } from "../../../types/utilities";
import { getCustomerName, getFullName } from "../../../utils/displayUtils";

const getSharedResponsibleUser = (customers: Customer[]) => {
  const responsibleUsersIds = customers.map((customer) => customer.responsible?.id);
  const responsibleUsersIdsSet = new Set(responsibleUsersIds);

  if (responsibleUsersIdsSet.size !== 1 || responsibleUsersIds.find((id) => id === undefined)) {
    return undefined;
  } else {
    return customers[0].responsible;
  }
};

interface ResponsibleUserModalProps {
  customers: Customer[];
  onClose(): void;
}

const ResponsibleUserModal: React.FC<ResponsibleUserModalProps> = (props) => {
  const { customers, onClose } = props;

  const customerIds = customers.map((customer) => customer.id);

  const sharedResponsibleUser = getSharedResponsibleUser(customers);

  const [showSuccessMessageToast, toggleSuccessMessageToast, closeSuccessMessageToast] = useOpenClose();
  const [selectedUser, setSelectedUser] = useState<api.User | undefined>(sharedResponsibleUser);
  const f = useFormatMessage();
  const formatMultipleNames = useFormatMultipleNames();

  const queryClient = useQueryClient();

  const { isLoading: isLoadingUsers, users, error: loadingUsersError } = useGetUsers();

  const setResponsibleUserMutation = useMutation(
    () => api.batchSetResponsibleOnCustomers({ customer_ids: customerIds, user_id: selectedUser!.id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("customers");
        toggleSuccessMessageToast();
      },
    }
  );

  const saveAction = {
    content: f("default.save"),
    onAction: () => setResponsibleUserMutation.mutate(),
    disabled: setResponsibleUserMutation.isLoading || selectedUser === undefined,
    loading: setResponsibleUserMutation.isLoading,
  };

  const cancelAction = {
    content: f("default.cancel"),
    onAction: () => onClose(),
    disabled: setResponsibleUserMutation.isLoading,
  };

  const customersNamesLabel = formatMultipleNames(customers.map(getCustomerName));

  const renderContent = (user: api.User, isSelected: boolean): string => {
    const line = (
      <Stack distribution={"fillEvenly"} wrap={false}>
        <TextStyle>{getFullName(user)}</TextStyle>
        <StyledEmailView isSelected={isSelected} title={user.email || ""}>
          {user.email || ""}
        </StyledEmailView>
      </Stack>
    );

    // this type conversion purpose is to overcome ActionListItemDescriptor content accepting only strings
    return (line as unknown) as string;
  };

  const items: ActionListItemDescriptor[] = useMemo(
    () =>
      users.map((user) => {
        const isSelected = user.id === selectedUser?.id;

        return {
          content: renderContent(user, isSelected),
          active: isSelected,
          suffix: isSelected ? <Icon source={TickSmallMinor} /> : undefined,
          onAction: () => setSelectedUser(user),
          disabled: !user.is_active,
        };
      }),
    [users, selectedUser]
  );

  const handleToastDismiss = () => {
    closeSuccessMessageToast();
    onClose();
  };

  return (
    <>
      <Modal
        open={!showSuccessMessageToast}
        title={f("responsible.modal.title")}
        onClose={onClose}
        primaryAction={saveAction}
        secondaryActions={[cancelAction]}
        noScroll
        loading={isLoadingUsers}
        footer={<TextStyle>{f("common.labels.selected-customers-count", { count: customers.length })}</TextStyle>}
      >
        <Modal.Section>
          <TextStyle>{f("responsible.modal.message", { names: customersNamesLabel })}</TextStyle>
          <StyledStickyHeader>
            {/* This is just to render the data table headers - the rows are rendered by ActionList*/}
            <DataTable
              columnContentTypes={["text", "text"]}
              headings={[
                <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
                <TextStyle variation="subdued">{f("table.column.email")}</TextStyle>,
              ]}
              rows={[]}
            />
          </StyledStickyHeader>
          <ActionList items={items} />
        </Modal.Section>
        {(loadingUsersError || setResponsibleUserMutation.isError) && (
          <ErrorPanel message={loadingUsersError || setResponsibleUserMutation.error} />
        )}
      </Modal>
      {showSuccessMessageToast && (
        <Toast onDismiss={handleToastDismiss} content={f("bulk.actions.responsible.success.message")} />
      )}
    </>
  );
};

// this is used to add left padding to the email component if the action item is selected
// so it would not "shift" to the left
const StyledEmailView = styled(EmailView)<{ isSelected: boolean }>`
  padding-left: ${({ isSelected }) => (isSelected ? "1.8rem" : "")};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 28ch;
`;

const StyledStickyHeader = styled.div`
  position: sticky;
  top: 0;
  background: var(--p-surface);
  z-index: 10;
`;

export default ResponsibleUserModal;
