import React, { useEffect,useState } from "react";
import { generatePath } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { Page } from "@shopify/polaris";

import api from "../../api";
import CompanyCustomerForm from "../../components/CompanyCustomerForm";
import DetailsSkeleton from "../../components/DetailsSkeleton";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IDParams } from "../../types/params";

/**
 * Form for updating manually created company customer during onboarding
 */
const CompanyOnboardingForm = () => {
  const history = useHistory();
  const f = useFormatMessage();
  const { id } = useParams<IDParams>();

  const [companyCustomerDetails, setCompanyCustomerDetails] = useState<api.CompanyCustomerDetailResponse>();

  // Get existing company customer by the ID param
  const [isLoading, setIsLoading] = useState(id ? true : false);
  useEffect(() => {
    // Set loading while fetching
    setIsLoading(true);

    api.getCompanyCustomerDetails(id).then((data) => {
      setCompanyCustomerDetails(data);

      // Disable loading state
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    // Display skeleton while loading company customer
    return <DetailsSkeleton />;
  } else {
    // Not loading - display form with input fields
    return (
      <Page title={f("onboarding.manual-company.step1.title")}>
        <CompanyCustomerForm
          companyCustomer={companyCustomerDetails}
          onAfterSubmit={() => {
            // Navigate to the screening step after the company customer has
            // successfully been updated
            history.push(generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_SCREENING, { id: id }));
          }}
        />
      </Page>
    );
  }
};

export default CompanyOnboardingForm;
