import React, { useMemo, useState } from "react";
import { Card, Heading, Stack, TableData } from "@shopify/polaris";

import PencilIcon from "../../../assets/icons/pencil.svg";
import api from "../../api";
import { CRITICAL_COMPANY_DETAILS_FIELDS } from "../../constants/company-details";
import useCountries from "../../hooks/useCountries";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useYesNo from "../../hooks/useYesNo";
import isNil from "../../utils/isNil";
import { isEmptyString } from "../../utils/stringUtils";
import Util from "../../utils/util";
import { isFieldInCompanyCustomerForm } from "../CompanyCustomerForm";
import Button from "../extensions/Button";
import DataTable from "../extensions/DataTable";
import ServerAddressInfo from "../ServerAddressInfo";

import Caption from "./Caption";
import EditCompanyCustomerInfoModal from "./EditCompanyCustomerInfoModal";
import InfoValue from "./InfoValue";
import { hasUnreviewedChanges, InfoUpdateFieldChange } from "./ValueWithChanges";

const formatIndustryCode = (industry_code: api.IndustryCode) =>
  [industry_code.code, industry_code.description].filter((e) => !!e).join(" — ");

interface CompanyInfoProp {
  companyDetails: api.CompanyCustomerDetailResponse;
  info_updates: api.CompanyInfoUpdate[];
}

const CompanyInfo: React.FC<CompanyInfoProp> = ({ companyDetails, info_updates }) => {
  const f = useFormatMessage();
  const features = useFeatures();
  const yesNo = useYesNo();
  const { getCountryName } = useCountries();

  const [showEditCompanyInfoModal, setShowEditCompanyInfoModal] = useState(false);

  const updates = useMemo(
    () =>
      info_updates.reduce<Record<string, InfoUpdateFieldChange[]>>((fieldUpdates, currentInfoUpdate) => {
        currentInfoUpdate.field_changes.forEach((infoUpdateFieldChange) => {
          if (!fieldUpdates[infoUpdateFieldChange.field_name]) {
            fieldUpdates[infoUpdateFieldChange.field_name] = [];
          }

          if (infoUpdateFieldChange.new !== infoUpdateFieldChange.old) {
            fieldUpdates[infoUpdateFieldChange.field_name].push({
              ...infoUpdateFieldChange,
              timestamp: currentInfoUpdate.timestamp,
              isReviewed: currentInfoUpdate.is_reviewed,
            });
          }
        });

        return fieldUpdates;
      }, {}),
    [info_updates]
  );

  const hasUnreviewedUpdates = (...fieldNames: (keyof api.CompanyCustomerDetailResponse)[]) =>
    features.LEGAL_ENTITY_MONITORING &&
    fieldNames.find((fieldName) => hasUnreviewedChanges(updates[fieldName])) !== undefined;

  const companyInfoRows: TableData[][] = useMemo(() => {
    const createBooleanValueInfoRow = (fieldName: keyof api.CompanyCustomerDetailResponse, label: string) => [
      <Caption label={label} highlight={hasUnreviewedUpdates(fieldName)} />,
      <InfoValue
        value={yesNo(companyDetails[fieldName] as boolean)}
        changes={updates[fieldName]}
        hideHistory
        status={CRITICAL_COMPANY_DETAILS_FIELDS.includes(fieldName) ? "critical" : "info"}
      />,
    ];

    const hasValueOrUpdate = (fieldName: keyof api.CompanyCustomerDetailResponse) => {
      return !isNil(companyDetails[fieldName]) || updates[fieldName];
    };

    /**
     * Checks if a company info field should be displayed or not
     * - If the field has a value or an update, it should be displayed
     * - If the company customer is manual, all fields that can be manually
     *    defined should be displayed even if they have no value
     * - Else, the field should not be displayed
     */
    const shouldDisplayField = (fieldName: keyof api.CompanyCustomerDetailResponse) => {
      if (hasValueOrUpdate(fieldName)) {
        return true;
      }

      if (companyDetails.is_manual) {
        return isFieldInCompanyCustomerForm(fieldName);
      }

      return false;
    };

    // Always display name, country and national ID
    const rows: TableData[][] = [
      [
        <Caption label={f("company.details.caption.name")} highlight={hasUnreviewedUpdates("name")} />,
        <InfoValue value={companyDetails.name} changes={updates["name"]} />,
      ],
      [
        <Caption label={f("company.details.caption.country")} />,
        <InfoValue value={getCountryName(companyDetails.country)} />,
      ],
      [<Caption label={f("company.details.caption.national_id")} />, <InfoValue value={companyDetails.national_id} />],
    ];

    if (shouldDisplayField("company_type")) {
      rows.push([
        <Caption label={f("company.details.caption.company.type")} highlight={hasUnreviewedUpdates("company_type")} />,
        <InfoValue
          value={
            companyDetails?.company_type?.code
              ? companyDetails.company_type.code +
                (companyDetails.company_type?.description_nb && ` (${companyDetails.company_type.description_nb})`)
              : "-"
          }
          changes={updates["company_type"]}
        />,
      ]);
    }

    const industryCodesMap = {
      industry_code_1: companyDetails.industry_code_1,
      industry_code_2: companyDetails.industry_code_2,
      industry_code_3: companyDetails.industry_code_3,
    };
    const hasIndustryCode = Object.values(industryCodesMap).some((industryCode) => !isNil(industryCode));
    const hasIndustryCodeChanges = Object.keys(industryCodesMap).some((fieldName) => updates[fieldName]);
    if (hasIndustryCode || hasIndustryCodeChanges) {
      rows.push([
        <Caption
          label={f("company.details.caption.industry-codes")}
          highlight={hasUnreviewedUpdates(
            ...(Object.keys(industryCodesMap) as (keyof api.CompanyCustomerDetailResponse)[])
          )}
        />,
        <Stack vertical>
          {Object.entries(industryCodesMap).map(([fieldName, industryCode]) =>
            industryCode ? (
              <InfoValue key={fieldName} value={formatIndustryCode(industryCode)} changes={updates[fieldName]} />
            ) : null
          )}
        </Stack>,
      ]);
    }

    if (shouldDisplayField("founded_date")) {
      rows.push([
        <Caption label={f("company.details.caption.est")} />,
        <InfoValue value={companyDetails.founded_date || "-"} />,
      ]);
    }

    if (shouldDisplayField("number_of_employees")) {
      rows.push([
        <Caption
          label={f("company.details.caption.employees")}
          highlight={hasUnreviewedUpdates("number_of_employees")}
        />,
        <InfoValue
          value={companyDetails.number_of_employees?.toLocaleString() || "-"}
          changes={updates["number_of_employees"]}
        />,
      ]);
    }

    if (shouldDisplayField("business_address"))
      rows.push([
        <Caption
          label={f("company.details.caption.address.visit")}
          highlight={hasUnreviewedUpdates("business_address")}
        />,
        <InfoValue<api.Address>
          value={companyDetails.business_address}
          changes={updates["business_address"]}
          render={(value) => <ServerAddressInfo address={value} />}
        />,
      ]);

    if (shouldDisplayField("mailing_address")) {
      rows.push([
        <Caption
          label={f("company.details.caption.address.postal")}
          highlight={hasUnreviewedUpdates("mailing_address")}
        />,
        <InfoValue<api.Address>
          value={companyDetails.mailing_address}
          changes={updates["mailing_address"]}
          render={(value) => <ServerAddressInfo address={value} />}
        />,
      ]);
    }

    if (shouldDisplayField("office_address")) {
      rows.push([
        <Caption
          label={f("company.details.caption.address.office")}
          highlight={hasUnreviewedUpdates("office_address")}
        />,
        <InfoValue<api.Address>
          value={companyDetails.office_address}
          changes={updates["office_address"]}
          render={(value) => <ServerAddressInfo address={value} />}
        />,
      ]);
    }

    if (shouldDisplayField("website")) {
      const website = Util.parseWebsite(companyDetails.website);
      rows.push([
        <Caption label={f("company.details.caption.website")} highlight={hasUnreviewedUpdates("website")} />,
        <InfoValue
          value={website}
          changes={updates["website"]}
          render={(value) =>
            isEmptyString(value) ? (
              <>{f("not.specified")}</>
            ) : (
              <a href={Util.parseWebsite(value)} target="_blank" rel="noreferrer">
                {value!.replace(/https?:\/\//, "")}
              </a>
            )
          }
        />,
      ]);
    }

    if (shouldDisplayField("registered_in_vat_registry")) {
      rows.push(
        createBooleanValueInfoRow("registered_in_vat_registry", f("company.details.caption.registered-in-vat"))
      );
    }

    if (shouldDisplayField("registered_in_foundation_registry")) {
      rows.push(
        createBooleanValueInfoRow(
          "registered_in_foundation_registry",
          f("company.details.caption.registered-in-foundation")
        )
      );
    }

    // The following fields should always be displayed if they have a truthy value,
    // as they could indicate a higher risk on the company customer
    if (companyDetails.is_bankrupt) {
      rows.push(createBooleanValueInfoRow("is_bankrupt", f("company.details.caption.is_bankrupt")));
    }

    if (companyDetails.under_liquidation) {
      rows.push(createBooleanValueInfoRow("under_liquidation", f("company.details.caption.under_liquidation")));
    }

    if (companyDetails.under_forced_liquidation_or_dissolution) {
      rows.push(
        createBooleanValueInfoRow(
          "under_forced_liquidation_or_dissolution",
          f("company.details.caption.under_forced_liquidation")
        )
      );
    }

    if (companyDetails.closure_date) {
      rows.push([
        <Caption label={f("company.details.caption.closure_date")} highlight={hasUnreviewedUpdates("closure_date")} />,
        <InfoValue value={companyDetails.closure_date} changes={updates["closure_date"]} hideHistory />,
      ]);
    }

    // Add external ID field first if feature is enabled and company has an external ID
    if (features && features.EXTERNAL_ID && companyDetails.external_id) {
      rows.unshift([
        <Caption label={f("company.details.caption.external-id")} />,
        <InfoValue value={companyDetails.external_id} />,
      ]);
    }

    return rows;
  }, [companyDetails, updates]);

  return (
    <>
      <Stack vertical>
        <Stack distribution="equalSpacing">
          <Heading>{f("company.details.title")}</Heading>
          {companyDetails.is_manual && (
            // If company customer is manual, it should be possible to update the company info manually
            <Button size="slim" icon={PencilIcon} onClick={() => setShowEditCompanyInfoModal(true)}>
              {f("common.buttons.actions.edit-company-info")}
            </Button>
          )}
        </Stack>
        <Card>
          <DataTable columnContentTypes={["text", "text"]} headings={[]} rows={companyInfoRows} />
        </Card>
      </Stack>
      {showEditCompanyInfoModal && (
        <EditCompanyCustomerInfoModal
          companyCustomer={companyDetails}
          onClose={() => setShowEditCompanyInfoModal(false)}
        />
      )}
    </>
  );
};

export default CompanyInfo;
