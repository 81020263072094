import React from "react";
import { useQueryClient } from "react-query";
import { Card } from "@shopify/polaris";

import api from "../../../api";
import ReviewForm from "../../../components/ReviewForm";
import useFormatMessage from "../../../hooks/useFormatMessage";

export interface ProjectReviewFormProps {
  projectId: string;
  readonly?: boolean;
  onAfterReviewCreated(review: api.ProjectReview): void;
}

const ProjectReviewForm: React.FC<ProjectReviewFormProps> = (props) => {
  const { projectId, readonly, onAfterReviewCreated } = props;

  const f = useFormatMessage();
  const queryClient = useQueryClient();

  const createReview = (comment: string) =>
    api.createProjectReview(projectId, {
      comment: comment || "",
    });

  const handleAfterCreate = (review: api.ProjectReview) => {
    queryClient.invalidateQueries(["project-details", [projectId]]);

    onAfterReviewCreated(review);
  };

  return (
    <Card>
      <Card.Section>
        <ReviewForm<api.ProjectReview>
          onCreateReview={createReview}
          onAfterReviewCreated={handleAfterCreate}
          readonly={readonly}
          placeholder={f("project.notes.placeholders.review-rational")}
        />
      </Card.Section>
    </Card>
  );
};

export default ProjectReviewForm;
