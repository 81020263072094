import api from "../../../api";
import { getFullName } from "../../../utils/displayUtils";

// the UI shows a single input field for editing the signer name
// this type and following methods allow converting from/to fullName to first_name and last_name
export type AttachmentSignature = api.SavedAttachmentSignature & {
  fullName: string;
};

export const mapToAttachmentSignature = (saveSignature: api.SavedAttachmentSignature): AttachmentSignature => ({
  ...saveSignature,
  fullName: getFullName(saveSignature),
});

export const mapToSavedSignature = (signature: AttachmentSignature): api.SavedAttachmentSignature => {
  const { fullName, ...savedAttachmentProps } = signature;

  const indexOfSeparator = fullName.lastIndexOf(" ");
  // don't send first and last name if role id is selected
  const first_name = signature.role_id ? undefined : fullName.substring(0, indexOfSeparator).trim();
  const last_name = signature.role_id ? undefined : fullName.substring(indexOfSeparator + 1).trim();

  // return all the signature props except fullName which is locally added
  return { ...savedAttachmentProps, first_name, last_name };
};
