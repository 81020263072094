import * as React from "react";
import { EmptyState } from "@shopify/polaris";

import EmptyHomeState from "../../assets/images/empty-home-state.png";
import { ROUTES } from "../constants/routes";
import useAuth from "../hooks/useAuth";
import useFormatMessage from "../hooks/useFormatMessage";

const AccessDenied: React.FC = () => {
  const { logout } = useAuth();
  const f = useFormatMessage();

  const logoutAction = {
    content: f("menu.logout"),
    onAction: () => logout(),
  };

  return (
    <EmptyState
      heading={f("access-denied.message")}
      action={{ content: f("default.home"), url: ROUTES.HOME }}
      secondaryAction={logoutAction}
      image={EmptyHomeState}
    />
  );
};

export default AccessDenied;
