import React, { ReactNode, useMemo } from "react";
import { OptionList, Popover, SelectGroup, SelectProps } from "@shopify/polaris";
import { OptionDescriptor } from "@shopify/polaris/build/ts/latest/src/types";

import useOpenClose from "../hooks/useOpenClose";

import Button from "./extensions/Button";

type StrictOption = {
  disabled?: boolean;
  label: string;
  prefix?: ReactNode;
  value: string;
};

const isStrictOption = (option: string | StrictOption | SelectGroup): option is StrictOption =>
  typeof option !== "string";

interface PlainSelectProps extends SelectProps {
  loading?: boolean;
}

const PlainSelect: React.FC<PlainSelectProps> = (props) => {
  const { loading } = props;
  const [active, toggle, close] = useOpenClose(false);

  const mapOptionToDescriptor = (option: string | StrictOption | SelectGroup): OptionDescriptor =>
    isStrictOption(option)
      ? {
          ...option,
          active: option.value === props.value,
        }
      : {
          label: option,
          value: option as string,
          active: option === props.value,
          disabled: false,
        };

  const handleChange = (selected: string[]) => {
    props.onChange && props.onChange(selected[0], "");
    close();
  };

  const options = useMemo(() => (props.options || []).map(mapOptionToDescriptor), [props.value, props.options]);

  const label = props.label as string;

  const activator = (
    <Button
      onClick={toggle}
      size="slim"
      plain
      loading={loading}
      disabled={props.disabled}
      disclosure={active ? "up" : "down"}
    >
      {label}
    </Button>
  );

  return (
    <Popover active={active} activator={activator} onClose={close}>
      <OptionList options={options} onChange={handleChange} selected={props.value ? [props.value] : []} />
    </Popover>
  );
};

export default PlainSelect;
