import React, { useMemo, useState } from "react";
import { Button, ButtonGroup, FormLayout, Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import LockIcon from "../../../../assets/icons/lock.svg";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { CustomerDetails, isPersonCustomerDetails } from "../../../types/utilities";
import { getCompanyDisplayName } from "../../../utils/displayUtils";
import CopyLabel from "../../CopyLabel";
import CustomDate from "../../CustomDate";
import DisplayName from "../../DisplayName";
import DataTable from "../../extensions/DataTable";
import Icon from "../../extensions/Icon";
import TableCellChild from "../../extensions/TableCellChild";
import TextField from "../../extensions/TextField";
import EmailOrLinkButtonGroupInput from "../../inputs/EmailOrLinkButtonGroupInput";
import ToggleInput from "../../inputs/ToggleInput";
import { AttachmentSignature } from "../types/AttachmentSignature";
import { AttachmentSignatureRow } from "../useAttachmentSignatures";

import AttachmentSignatureActions from "./AttachmentSignatureActions";
import AttachmentSignatureEmailOrLinkLabel from "./AttachmentSignatureEmailOrLinkLabel";
import AttachmentSignatureNameField, { SignerSuggestionsSection } from "./AttachmentSignatureNameField";
import AttachmentSignatureStatusLabel from "./AttachmentSignatureStatusLabel";
import AttachmentTableCell from "./AttachmentTableCell";

interface AttachmentSignaturesTableProps {
  customerId: string;
  rows: Array<AttachmentSignatureRow>;
  customers?: CustomerDetails[];
  readonly?: boolean;
  editRow(row: AttachmentSignatureRow): void;
  updateProperty(row: AttachmentSignatureRow, key: keyof AttachmentSignature, value?: string | boolean): void;
  deleteRow(row: AttachmentSignatureRow): void;
  saveRow(row: AttachmentSignatureRow): void;
  revertEditRow(row: AttachmentSignatureRow): void;
  selectSuggestion(row: AttachmentSignatureRow, personId: string, personName: string, roleId?: string): void;
  clearSuggestion(row: AttachmentSignatureRow): void;
}

const AttachmentSignaturesTable: React.FC<AttachmentSignaturesTableProps> = (props) => {
  const {
    customerId,
    rows,
    customers,
    readonly,
    editRow,
    updateProperty,
    deleteRow,
    selectSuggestion,
    clearSuggestion,
    saveRow,
    revertEditRow,
  } = props;

  // selected attachment signature id to show copy link modal
  const [showCopyLinkModalRowId, setShowCopyLinkModalRowId] = useState<string>();

  const f = useFormatMessage();

  const allRolesMap = useMemo(
    () =>
      new Map(
        (customers || [])
          // flatMap converts an array of arrays into a single array ([[obj1], [obj2, obj3], [obj4]] -> [obj1, obj2, obj3, obj4])
          // by creating a new Map with an array of key roles ([role.id, role]) we can access a role by its id
          .flatMap((customer) => (isPersonCustomerDetails(customer) ? [] : customer.roles))
          .map((role) => [role.id, role])
      ),
    [customers]
  );

  const getRoleType = (row: AttachmentSignatureRow) => allRolesMap.get(row.entity.role_id)?.type;

  const isInitialState = rows.length === 0 || (rows.length === 1 && rows[0].isCreating);

  const selectedSignersIds = rows.map((row) => row.entity.signer_id);

  const suggestionsToDisplay = useMemo(() => {
    // this is a suggestion section with an empty name that holds all the person customers of the project
    // that do not belong to a company customer
    const suggestedPersonSection: SignerSuggestionsSection = {
      name: "",
      suggestions: [],
    };

    const customersMap: Record<string, SignerSuggestionsSection> = {
      "": suggestedPersonSection,
    };

    (customers || []).forEach((customer) => {
      if (isPersonCustomerDetails(customer)) {
        suggestedPersonSection.suggestions.push(customer);
      } else {
        customersMap[customer.id] = {
          name: getCompanyDisplayName(customer),
          suggestions: customer.roles.map((role) => ({
            ...role.person,
            roleId: role.id,
            roleType: role.type,
          })),
        };
      }
    });

    const allCustomers = Object.values(customersMap);

    return allCustomers.map((customer) => ({
      ...customer,
      suggestedPeople: customer.suggestions.filter(
        (suggestedPerson) => !selectedSignersIds.includes(suggestedPerson.id)
      ),
    }));
  }, [customers, selectedSignersIds]);

  const renderViewRow = (row: AttachmentSignatureRow) => [
    <AttachmentTableCell row={row}>
      <Stack alignment="center">
        <DisplayName name={row.entity.fullName} type="person" roleType={getRoleType(row)} />
        {row.entity.use_verification && <Icon source={LockIcon} />}
      </Stack>
    </AttachmentTableCell>,
    <AttachmentTableCell row={row}>
      <AttachmentSignatureEmailOrLinkLabel attachmentSignature={row.entity} />
    </AttachmentTableCell>,
    <AttachmentTableCell row={row} centered={row.entity.pin === undefined}>
      {row.entity.pin ? <CopyLabel size="slim">{row.entity.pin}</CopyLabel> : " - "}
    </AttachmentTableCell>,
    <AttachmentTableCell row={row} centered={row.isNew}>
      <AttachmentSignatureStatusLabel status={row.isNew ? undefined : row.entity.status} />
    </AttachmentTableCell>,
    <AttachmentTableCell row={row} centered={row.entity.signed_dt === undefined}>
      {row.entity.signed_dt && <CustomDate date={new Date(row.entity.signed_dt).toString()} />}
      {!row.entity.signed_dt && <TextStyle> - </TextStyle>}
    </AttachmentTableCell>,
    <AttachmentSignatureActions
      customerId={customerId}
      attachmentSignature={row.entity}
      showCopyLinkModal={showCopyLinkModalRowId === row.entity.id}
      readonly={readonly || row.entity.status === "SIGNED"}
      setShowCopyLinkModalRowId={setShowCopyLinkModalRowId}
      onModalClose={() => setShowCopyLinkModalRowId(undefined)}
      onEdit={() => editRow(row)}
      onDelete={() => deleteRow(row)}
    />,
  ];

  const renderEditRow = (row: AttachmentSignatureRow) => [
    <StyledEditRow colSpan={6}>
      <Stack wrap={false} alignment="fill">
        <Stack.Item fill>
          <FormLayout>
            <FormLayout.Group condensed>
              <AttachmentSignatureNameField
                row={row}
                signerSuggestionSections={suggestionsToDisplay}
                selectedSignerSuggestionRoleType={getRoleType(row)}
                onSelectSignerSuggestion={selectSuggestion}
                onClearSignerSuggestion={clearSuggestion}
                onNameChange={(row, name) => updateProperty(row, "fullName", name)}
              />
              <Stack wrap={false}>
                <EmailOrLinkButtonGroupInput
                  buttonsSize="medium"
                  value={row.entity.send_email ? "email" : "link"}
                  onChange={(value) => updateProperty(row, "send_email", value === "email")}
                />
                <Stack.Item fill>
                  {row.entity.send_email && (
                    <TextField
                      label=""
                      labelHidden
                      type="email"
                      autoComplete="off"
                      placeholder={f("email.placeholder.text")}
                      value={row.entity.email}
                      onChange={(value) => updateProperty(row, "email", value)}
                      error={row.validationErrors.get("email")}
                      debounceError
                      hideErrorIfUntouched
                    />
                  )}
                  {!row.entity.send_email && (
                    <StyledWaitForLinkMessage>
                      <TextStyle>{f("attachments.signatures.modal.labels.missing-url")}</TextStyle>
                    </StyledWaitForLinkMessage>
                  )}
                </Stack.Item>
              </Stack>
            </FormLayout.Group>
            <Stack alignment="center">
              <ToggleInput
                checked={row.entity.use_verification}
                onChange={() => {
                  updateProperty(row, "use_verification", !row.entity.use_verification);
                  if (!row.entity.use_verification) {
                    updateProperty(row, "national_id", undefined);
                  }
                }}
                label={f("attachments.signatures.modal.labels.national-id")}
              />
              {row.entity.use_verification && (
                <Stack.Item fill>
                  <TextField
                    label=""
                    labelHidden
                    maxLength={11}
                    autoComplete="off"
                    disabled={!row.entity.use_verification}
                    placeholder={f("attachments.signatures.modal.labels.national-id.placeholder", { maxDigits: 11 })}
                    value={row.entity.national_id}
                    onChange={(value) => updateProperty(row, "national_id", value)}
                    error={row.validationErrors.get("national_id")}
                    debounceError
                    hideErrorIfUntouched
                  />
                </Stack.Item>
              )}
              <div />
            </Stack>
          </FormLayout>
        </Stack.Item>

        <StyledActionButtonsGroup>
          <ButtonGroup fullWidth>
            <Button outline destructive onClick={() => (row.isCreating ? deleteRow(row) : revertEditRow(row))}>
              {f("default.cancel")}
            </Button>
            <Button outline disabled={row.validationErrors.size !== 0} onClick={() => saveRow(row)}>
              {f("default.confirm")}
            </Button>
          </ButtonGroup>
        </StyledActionButtonsGroup>
      </Stack>
    </StyledEditRow>,
  ];

  return (
    <DataTable
      verticalAlign="middle"
      columnContentTypes={["text", "text", "text", "text", "text", "numeric"]}
      headings={
        isInitialState
          ? // when in initial state, don't show the table headers - only a single edit line will be rendered
            []
          : [
              f("table.column.name"),
              f("table.column.email.or.url"),
              f("table.column.pin"),
              f("table.column.status"),
              f("attachments.signatures.modal.table.column.date-signed"),
              "",
            ]
      }
      rows={rows.map((row) => (row.isEditing ? renderEditRow(row) : renderViewRow(row)))}
    />
  );
};

// edit row should always have a background
// the margin/padding is to overwrite the hover color of the parent row element
const StyledEditRow = styled(TableCellChild)`
  background: white;
  margin: -1.6rem;
  padding: 1.6rem;
`;

// necessary styling to center message text vertically
const StyledWaitForLinkMessage = styled.div`
  display: flex;
  height: 100%;

  & > * {
    margin: auto 0;
  }
`;

const StyledActionButtonsGroup = styled.div`
  display: flex;
  height: 100%;
  padding-left: 1.6rem;
  border-left: solid thin #dfe3e8;
`;

export default AttachmentSignaturesTable;
