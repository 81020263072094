import React, { useEffect, useRef } from "react";

// Since @shopify/Polaris data tables don't allow to access the table rows
// use this table cell wrapper to modify the cells row directly
// It's a hack that can be removed once we use a different component to render tables
const TableRowModifier: React.FC<React.HTMLProps<HTMLTableRowElement>> = ({ children, className, ...rowProps }) => {
  const cellRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cellRef.current) {
      let tableRow: HTMLElement | undefined | null = cellRef.current;
      while (tableRow && tableRow.tagName !== "TR") {
        tableRow = tableRow.parentElement;
      }

      if (className) {
        className.split(" ").forEach((name) => tableRow?.classList.add(name));
      }

      Object.keys(rowProps).forEach((name: string) =>
        tableRow?.setAttribute(name, String((rowProps as Record<string, string>)[name]))
      );

      return () => {
        if (className) {
          className.split(" ").forEach((name) => tableRow?.classList.remove(name));
        }

        for (const name in rowProps) {
          tableRow?.setAttribute(name, "");
        }
      };
    }
  }, [cellRef.current, className]);

  return <div ref={cellRef}>{children}</div>;
};

export default TableRowModifier;
