import React from "react";
import { AppliedFilterInterface, ChoiceList, FilterInterface, Filters } from "@shopify/polaris";

import CountrySelect from "../../components/CountrySelect";
import CreatedDateFilter from "../../components/CreatedDateFilter";
import useCountries from "../../hooks/useCountries";
import useCreatedDataAppliedFilters from "../../hooks/useCreatedDataAppliedFilters";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { createFilter } from "../../utils/filtersUtils";

import useCustomersListParams from "./useCustomerListParams";
import useFiltersOptions from "./useFiltersOptions";

interface CustomersListFiltersProps {
  isLoading: boolean;
}

const CustomersListFilters: React.FC<CustomersListFiltersProps> = (props) => {
  const { isLoading } = props;

  const {
    q,
    setQ,
    status,
    setStatus,
    customerType,
    setCustomerType,
    risk,
    setRisk,
    screeningType,
    setScreeningType,
    isArchived,
    setIsArchived,
    unresolved,
    setUnresolved,
    isPep,
    setIsPep,
    isSanctioned,
    setIsSanctioned,
    responsibleUserId,
    setResponsibleUserId,
    countryCode,
    setCountryCode,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    matchFilters,
    handleMatchFiltersChange,
    resetAllFilters,
  } = useCustomersListParams();

  const appliedCreatedDateFilters = useCreatedDataAppliedFilters({
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
  });

  const features = useFeatures();
  const f = useFormatMessage();
  const filterOptions = useFiltersOptions();
  const { getCountryName } = useCountries();

  const [statusFilter, statusAppliedFilter] = createFilter(
    "status",
    f("component.filters.status.title"),
    status || "",
    isLoading,
    filterOptions.customerStatus,
    setStatus
  );

  const [customerTypeFilter, customerTypeAppliedFilter] = createFilter(
    "customerType",
    f("component.filter.customer.title"),
    customerType,
    isLoading,
    filterOptions.customerType,
    setCustomerType
  );

  const [riskFilter, riskAppliedFilter] = createFilter(
    "risk",
    f("component.filter.risk.title"),
    risk,
    isLoading,
    filterOptions.risk,
    setRisk
  );

  const [screeningTypeFilter, screeningTypeAppliedFilter] = createFilter(
    "screening",
    f("component.filter.screening.title"),
    screeningType,
    isLoading,
    filterOptions.screening,
    setScreeningType
  );

  const countryCodeFilter: FilterInterface = {
    key: "country",
    label: f("component.filter.country.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <CountrySelect
        labelHidden
        label=""
        value={countryCode === "all" ? undefined : countryCode}
        onSelect={(country) => setCountryCode(country || "all")}
      />
    ),
  };

  const matchFilter: FilterInterface = {
    key: "match",
    label: f("component.filters.match.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <ChoiceList
        title={f("component.filters.match.title")}
        titleHidden
        choices={filterOptions.match}
        allowMultiple
        selected={matchFilters}
        onChange={(selected) => handleMatchFiltersChange(selected)}
      />
    ),
  };

  const createdDateFilter: FilterInterface = {
    key: "created",
    label: f("component.filters.created.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <CreatedDateFilter
        isLoading={isLoading}
        createdRange={createdRange}
        createdAfter={createdAfter}
        createdBefore={createdBefore}
        setCreatedRange={setCreatedRange}
        setCreatedAfter={setCreatedAfter}
        setCreatedBefore={setCreatedBefore}
      />
    ),
  };

  const filters = [
    statusFilter,
    customerTypeFilter,
    riskFilter,
    matchFilter,
    screeningTypeFilter,
    createdDateFilter,
    countryCodeFilter,
  ];

  const appliedFilters = [
    statusAppliedFilter,
    customerTypeAppliedFilter,
    riskAppliedFilter,
    screeningTypeAppliedFilter,
  ].filter(Boolean) as AppliedFilterInterface[];

  if (unresolved) {
    appliedFilters.push({
      key: "match-is-unresolved",
      label: f("component.filters.match.text.open.cases"),
      onRemove: () => setUnresolved(false),
    });
  }

  if (isPep) {
    appliedFilters.push({
      key: "match-is-pep",
      label: f("component.filters.match.text.pep.matches"),
      onRemove: () => setIsPep(false),
    });
  }

  if (isSanctioned) {
    appliedFilters.push({
      key: "match-is-sanctioned",
      label: f("component.filters.match.text.sanction.matches"),
      onRemove: () => setIsSanctioned(false),
    });
  }

  appliedFilters.push(...appliedCreatedDateFilters);

  if (countryCode !== "all") {
    appliedFilters.push({
      key: "country",
      label: `${f("component.filter.country.title")}: ${getCountryName(countryCode)}`,
      onRemove: () => setCountryCode("all"),
    });
  }

  if (features.ARCHIVE_CUSTOMER) {
    const archivedCustomerFilter: FilterInterface = {
      key: "archived-customer",
      label: f("component.filters.archive.title"),
      shortcut: true,
      disabled: isLoading,
      hideClearButton: true,
      filter: (
        <ChoiceList
          title={f("component.filters.archive.title")}
          titleHidden
          choices={filterOptions.archive}
          allowMultiple
          selected={isArchived ? ["isArchived"] : []}
          onChange={(selected) => setIsArchived(selected.includes("isArchived"))}
        />
      ),
    };

    filters.push(archivedCustomerFilter);

    if (isArchived) {
      appliedFilters.push({
        key: "archived-customer",
        label: f("component.filters.archive.option.include-archived-customers"),
        onRemove: () => setIsArchived(false),
      });
    }
  }

  if (features.RESPONSIBLE_USER) {
    const [responsibleUserFilter, responsibleUserAppliedFilter] = createFilter(
      "responsible-user",
      f("responsible.label"),
      responsibleUserId,
      isLoading,
      filterOptions.responsibleUser,
      setResponsibleUserId
    );

    // responsible user filter should be visible in the "default" filters group - add it after the status (first) filter
    filters.splice(1, 0, responsibleUserFilter);

    if (responsibleUserAppliedFilter) {
      appliedFilters.push(responsibleUserAppliedFilter);
    }
  }

  return (
    <Filters
      queryValue={q}
      queryPlaceholder={f("component.search.client.hint")}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={(queryValue) => setQ(queryValue)}
      onQueryClear={() => setQ("")}
      onClearAll={() => resetAllFilters()}
    />
  );
};

export default CustomersListFilters;
