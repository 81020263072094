import { useEffect,useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import HistoryLocationState from "../types/HistoryLocationState";

export default function useSnackbar(): [string | undefined, () => void] {
  const [snackbar, setSnackbar] = useState<string>();

  const history = useHistory();
  const location = useLocation<HistoryLocationState>();

  const clearSnackbar = () => setSnackbar(undefined);

  useEffect(() => {
    const location_state = location.state;

    if (location_state) {
      if ("snackbar" in location_state) {
        setSnackbar(location_state.snackbar);

        // Remove snackbar message from history
        const state = location.state;
        delete state.snackbar;

        history.replace({ ...location, state });
      }
    }
  }, []);

  return [snackbar, clearSnackbar];
}
