import React, { useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { TextField } from "@shopify/polaris";
import classNames from "classnames";
import nb from "date-fns/locale/nb";

import useFormatMessage from "../../hooks/useFormatMessage";
import useLanguage from "../../hooks/useLanguage";
import { isSafari } from "../../utils/browserUtils";
import isValidDate from "../../utils/isValidDate";

import "react-datepicker/dist/react-datepicker.css";

/**
 * Function to check if the current browser
 * supports native HTML5 date input
 */
const isNativeDateInputSupported = () => {
  const input = document.createElement("input");
  const value = "a";
  input.setAttribute("type", "date");
  input.setAttribute("value", value);
  return input.value !== value;
};

interface DateInputProps {
  label: string;
  onChange(value: string | undefined, isValid: boolean): void;
  minDate: Date;
  maxDate: Date;
  errorMsg: string;
  disabled: boolean;
  value?: string;
  requiredIndicator?: boolean;
}

const NativeDateInput: React.FC<DateInputProps> = ({
  label,
  onChange,
  minDate,
  maxDate,
  errorMsg,
  disabled,
  value = "",
  requiredIndicator,
}) => {
  const [date, setDate] = useState<string>(value);
  const [dateError, setDateError] = useState<string | undefined>();

  return (
    <TextField
      onChange={(newValue) => {
        setDate(newValue);
        onChange(newValue, isValidDate(newValue, minDate, maxDate));
      }}
      onBlur={() => {
        if (isValidDate(date, minDate, maxDate)) {
          setDateError(undefined);
        } else {
          setDateError(errorMsg);
        }
      }}
      value={date}
      label={label}
      maxLength={10}
      minLength={10}
      type="date"
      error={dateError}
      disabled={disabled}
      autoComplete="off"
      requiredIndicator={requiredIndicator}
    />
  );
};

const LocaleMap = new Map([["nb", nb]]);

/**
 * Fallback date input field, using react-datepicker
 * Styled to look like a Solaris TextField
 */
const FallbackDateInput: React.FC<DateInputProps> = ({
  label,
  onChange,
  minDate,
  maxDate,
  disabled,
  value,
  requiredIndicator,
}) => {
  const inputRef = useRef<DatePicker>(null);
  const [date, setDate] = useState<Date | undefined>(value ? new Date(value) : undefined);

  const language = useLanguage();
  const f = useFormatMessage();

  const locale = LocaleMap.get(language);

  const labelClassname = classNames("Polaris-Label__Text", { "Polaris-Label__RequiredIndicator": requiredIndicator });

  return (
    <div>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label id="PolarisTextField3Label" className={labelClassname}>
            {label}
          </label>
        </div>
      </div>
      <div className="Polaris-Connected" style={{ zIndex: 99999 }}>
        <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
          <div className="Polaris-TextField">
            <DatePicker
              ref={inputRef}
              dateFormat="dd/MM/yyyy"
              placeholderText={f("common.date-input.placeholder")}
              locale={locale}
              className="Polaris-TextField__Input"
              minDate={minDate}
              maxDate={maxDate}
              openToDate={new Date("1980-01-01")}
              showYearDropdown
              disabledKeyboardNavigation
              selected={date}
              onKeyDown={(event) => {
                // Close the calendar when user using 'Tab'
                // key to tab off the custom input
                if (event.code === "Tab") {
                  inputRef.current?.setOpen(false, true);
                }
              }}
              onChange={(value: Date) => {
                setDate(value);
                if (value) {
                  // onChange accepts a date in the format of
                  // yyyy-mm-dd
                  const year = value.getFullYear();
                  const month = value.getMonth() + 1;
                  const monthStr = String(month).padStart(2, "0");
                  const day = value.getDate();
                  const dayStr = String(day).padStart(2, "0");
                  const dateStr = `${year}-${monthStr}-${dayStr}`;
                  onChange(dateStr, true);
                } else {
                  // undefined -> isValid = false
                  onChange(undefined, false);
                }
              }}
              disabled={disabled}
            />
            <div className="Polaris-TextField__Backdrop" />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Depending on the browser's native date input
 * support, export the appropriate date input
 * component
 */
const DateInput: React.FC<DateInputProps> = ({ value, ...props }) => {
  const isNativeSupported: boolean = useMemo(() => isNativeDateInputSupported(), []);

  // Safari browser supports date input but does not show empty placeholder if date input value is empty/undefined
  // instead, it displays the current date - but its value is still empty
  //
  // Render native view if either native date input is supported AND the browser is NOT Safari
  // or if the browser IS Safari but the value is not empty (which means it will display the value properly)
  //
  // in order to not "jump" between native and fallback inputs, calculate renderNative with useMemo
  // so that if the user starts with an empty date value the input will not change after selecting a date
  const renderNative = useMemo(() => (isNativeSupported && !isSafari()) || (isSafari() && value && value !== ""), [
    value,
  ]);

  return renderNative ? <NativeDateInput value={value} {...props} /> : <FallbackDateInput value={value} {...props} />;
};

export default DateInput;
