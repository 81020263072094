import api from "../api";

export function groupMatchAttributesByCode(match: api.Match) {
  const nationality: api.MatchAttribute[] = [];
  const national_ids: api.MatchAttribute[] = [];
  const gender: api.MatchAttribute[] = [];
  const riskography: api.MatchAttribute[] = [];
  const occupations: api.MatchAttribute[] = [];
  const links: api.MatchAttribute[] = [];
  const remarks: api.MatchAttribute[] = [];
  const pepTypes: api.MatchAttribute[] = [];
  const avatarImage: api.MatchAttribute[] = [];
  const yearOfIncorporations: api.MatchAttribute[] = [];

  match.attributes.forEach((a) => {
    switch (a.code) {
      case "NAT": // nationality
      case "CTZ": // citizenship
      case "COI": // country of incorporation
        nationality.push(a);
        break;
      case "NIN": // national id number
        national_ids.push(a);
        break;
      case "SEX":
        gender.push(a);
        break;
      case "RGP": // riskography
      case "SUM": // summary
      case "BIO": // bio
        riskography.push(a);
        break;
      case "IMG":
        avatarImage.push(a);
        break;
      case "OCU":
        occupations.push(a);
        break;
      case "URL":
        links.push(a);
        break;
      case "RMK":
        remarks.push(a);
        break;
      case "PTY":
        pepTypes.push(a);
        break;
      case "YOI":
        yearOfIncorporations.push(a);
        break;
      default:
        break;
    }
  });

  return {
    nationality,
    national_ids,
    gender,
    riskography,
    occupations,
    links,
    remarks,
    pepTypes,
    avatarImage,
    yearOfIncorporations,
  };
}
