import React, { useState } from "react";
import { Heading, Stack } from "@shopify/polaris";

import api from "../../api";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import useFormatMessage from "../../hooks/useFormatMessage";
import Util from "../../utils/util";

import { Review } from "./types/Review";
import CustomerReviewForm, { CustomerReviewFormProps } from "./CustomerReviewForm";
import ReviewsList from "./ReviewsList";

interface CustomerReviewsSectionProps extends Omit<CustomerReviewFormProps, "onAfterReviewCreated"> {
  reviews: api.CustomerReview[];
  pendingReviews: api.CustomerReview[];
  onRiskTypeChange(riskType: api.RiskLevel): void;
}

const CustomerReviewsSection: React.FC<CustomerReviewsSectionProps> = ({
  customerId,
  reviews: outerReviews,
  pendingReviews: outerPendingReviews,
  readonly,
  onRiskTypeChange,
  ...reviewFormProps
}) => {
  const [pendingReviews, setPendingReviews] = useState<Review[]>(outerPendingReviews as Review[]);
  const [existingReviews, setExistingReviews] = useState<Review[]>(outerReviews as Review[]);

  const f = useFormatMessage();

  const onCreateReview = (review: Review, isPending: boolean, overrideRisk: api.RiskLevel) => {
    if (isPending) {
      setPendingReviews([review].concat(pendingReviews));
      requestAnimationFrame(() => {
        Util.scrollTo(PAGE_ANCHORS.PENDING_REVIEWS_SECTION);
      });
    } else {
      setExistingReviews([review].concat(existingReviews));
      requestAnimationFrame(() => {
        Util.scrollTo(PAGE_ANCHORS.EXISTING_REVIEWS_SECTION);
      });
      onRiskTypeChange(overrideRisk);
    }
  };

  return (
    <Stack vertical spacing="extraLoose">
      <Stack vertical>
        <Heading>{f("reviews.header.title")}</Heading>
        <CustomerReviewForm customerId={customerId} onAfterReviewCreated={onCreateReview} {...reviewFormProps} />
      </Stack>
      <div id={PAGE_ANCHORS.PENDING_REVIEWS_SECTION}>
        <ReviewsList
          id={customerId}
          reviews={pendingReviews}
          title={f("reviews.titles.pending-reviews")}
          readonly={readonly}
        />
      </div>

      <div id={PAGE_ANCHORS.EXISTING_REVIEWS_SECTION}>
        <ReviewsList
          id={customerId}
          reviews={existingReviews}
          title={f("reviews.titles.existing-reviews")}
          readonly={readonly}
        />
      </div>
    </Stack>
  );
};

export default CustomerReviewsSection;
