import * as React from "react";
import { useState } from "react";
import { Button, Modal, Stack, TextField } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { getBirthDate, getFullName } from "../../utils/displayUtils";
import Util from "../../utils/util";
import DisplayName from "../DisplayName";
import ErrorPanel from "../ErrorPanel";

type UpdateEmailModalProps = {
  customerId: string;
  signatureId: string;
  email: string;
  roleId?: string;
  person: api.Person;
  open: boolean;
  existingEmails: string[];
  onEmailUpdated(email: string): void;
  onModalClose?(): void;
};

const UpdateEmailModal: React.FC<UpdateEmailModalProps> = ({
  customerId,
  signatureId,
  email: initialEmail,
  existingEmails,
  roleId,
  person,
  open,
  onEmailUpdated,
  onModalClose,
}) => {
  const [email, setEmail] = useState(initialEmail);
  const [isUpdating, setUpdating] = useState(false);
  const [error, setError] = useState<string>();

  const handleUpdate = (newEmail: string) => {
    setEmail(newEmail);

    // If email is already used for another signature, inform the user
    if (existingEmails.includes(newEmail)) {
      setError(f("modals.update.email.error.duplicate-email"));
    } else {
      setError(undefined);
    }
  };

  const f = useFormatMessage();

  const closeModal = () => {
    onModalClose && onModalClose();
  };

  const updateEmail = () => {
    setError(undefined);
    setUpdating(true);

    // If roleId is defined it's a company signature
    const apiCall = roleId
      ? api.updateCompanySignature(customerId, signatureId, {
          email,
          send_email: true,
        })
      : api.updatePersonSignature(customerId, signatureId, {
          email,
          send_email: true,
        });

    apiCall
      .then(() => {
        setUpdating(false);
        onEmailUpdated(email);
        closeModal();
      })
      .catch((error) => {
        setError(error.toString());
        setUpdating(false);
      });
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={initialEmail ? f("modals.update.email.title") : f("modals.update.email.title.send-new")}
    >
      <Modal.Section>
        <Stack vertical>
          <DisplayName type="person" name={getFullName(person)} birthDate={getBirthDate(person)} />
          <TextField
            disabled={isUpdating}
            value={email}
            type="email"
            placeholder={f("email.placeholder.text")}
            error={email && !Util.isEmail(email) ? "Ugyldig adresse" : undefined}
            onChange={handleUpdate}
            label=""
            labelHidden
            autoComplete="off"
          />

          {error && <ErrorPanel message={error} />}

          <Stack distribution="trailing">
            <Button onClick={closeModal} disabled={isUpdating}>
              {f("default.cancel")}
            </Button>
            <Button
              primary
              onClick={updateEmail}
              loading={isUpdating}
              disabled={isUpdating || !email || (email && !Util.isEmail(email)) || email == initialEmail}
            >
              {f("modals.update.email.button")}
            </Button>
          </Stack>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default UpdateEmailModal;
