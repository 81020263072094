import * as React from "react";
import { Stack } from "@shopify/polaris";

import CustomerRiskHighIcon from "../../assets/icons/customer-risk-high.svg";
import CustomerRiskLowIcon from "../../assets/icons/customer-risk-low.svg";
import CustomerRiskMediumIcon from "../../assets/icons/customer-risk-medium.svg";
import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";

import Icon from "./extensions/Icon";

type RiskProps = {
  risk?: api.RiskLevel;
  showHyphenIfNotSet?: boolean;
  displayFullLabel?: boolean;
  wrap?: boolean;
};

const Risk: React.FC<RiskProps> = ({ risk, showHyphenIfNotSet, displayFullLabel, wrap }) => {
  const f = useFormatMessage();

  const isRiskSet = risk !== undefined;
  const riskLabel = isRiskSet ? f(`risklabel.${risk}`) : showHyphenIfNotSet ? "-" : f("risklabel.NOT_SET");

  return (
    <Stack alignment="center" distribution="center" spacing="extraTight" wrap={wrap}>
      {risk === "LOW" && <Icon source={CustomerRiskLowIcon} width="36px" height="5px" />}
      {risk === "MEDIUM" && <Icon source={CustomerRiskMediumIcon} width="36px" height="5px" />}
      {risk === "HIGH" && <Icon source={CustomerRiskHighIcon} width="36px" height="5px" />}
      <div className="not-on-mobile">
        {displayFullLabel ? f("risklabel.complete-label", { risk: riskLabel }) : riskLabel}
      </div>
    </Stack>
  );
};

export default Risk;
