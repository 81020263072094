import React, { useMemo, useState } from "react";
import { Card, ChoiceList, ChoiceListProps, Form, FormLayout, Stack } from "@shopify/polaris";
import styled from "styled-components";

import PlusMajorIcon from "../../../assets/icons/plus-major.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmptyString } from "../../utils/stringUtils";
import Button from "../extensions/Button";
import Icon from "../extensions/Icon";
import TextField from "../extensions/TextField";

import Label from "./Label";

type Choices = ChoiceListProps["choices"];

export interface LabelsEditorFormProps {
  allLabels?: api.Label[];
  selectedLabels?: api.Label[];
  disabled?: boolean;
  onSelectionChange(selectedLabels: api.Label[]): void;
  onNewLabel(title: string): void;
}

const LabelsEditorForm: React.FC<LabelsEditorFormProps> = (props) => {
  const { allLabels = [], selectedLabels: initialSelection = [], disabled, onSelectionChange, onNewLabel } = props;

  const f = useFormatMessage();
  const [newLabelValue, setNewLabelValue] = useState("");
  const [selectedLabels, setSelectedLabels] = useState(initialSelection);

  const selectedLabelsIds = selectedLabels.map((label) => label.id);

  const choices: Choices = useMemo(
    () =>
      allLabels
        .filter((label) => label.title.toLowerCase().includes(newLabelValue.toLowerCase()))
        .map((label) => ({
          label: <Label>{label.title}</Label>,
          value: label.id,
        })),
    [allLabels, newLabelValue]
  );

  const handleNewLabel = async () => await onNewLabel(newLabelValue.trim());

  const handleSelectionChange = (selectedIds: string[]) => {
    const labels = allLabels.filter((label) => selectedIds.includes(label.id));

    onSelectionChange(labels);
    setSelectedLabels(labels);
  };

  const newLabelExists = new Set(allLabels.map((label) => label.title.toLowerCase())).has(
    newLabelValue.trim().toLowerCase()
  );
  const isInvalidNewLabel = newLabelExists || isEmptyString(newLabelValue.trim());
  const showExistingLabels = choices.length > 0;
  const showAddLabelButton = choices.length === 0 || !isEmptyString(newLabelValue);

  return (
    <Card>
      <Card.Section>
        <Stack vertical spacing="extraTight">
          <Form onSubmit={handleNewLabel}>
            <StyledFormWrapper>
              <FormLayout>
                <TextField
                  label={f("labels.editor.new-label-input")}
                  value={newLabelValue}
                  onChange={setNewLabelValue}
                  autoComplete="off"
                  disabled={disabled}
                />
                {showAddLabelButton && (
                  <Button
                    plain
                    monochrome
                    removeUnderline
                    submit
                    icon={<Icon source={PlusMajorIcon} width="12px" height="12px" />}
                    disabled={isInvalidNewLabel || disabled}
                  >
                    {f("labels.editor.add-and-apply-button", { label: newLabelValue })}
                  </Button>
                )}
              </FormLayout>
            </StyledFormWrapper>
          </Form>
          {showExistingLabels && (
            <ChoiceList
              title={f("labels.editor.existing-labels")}
              allowMultiple
              choices={choices}
              selected={selectedLabelsIds}
              disabled={disabled}
              onChange={handleSelectionChange}
            />
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
};

const StyledFormWrapper = styled.div`
  min-height: 10rem;
`;

export default LabelsEditorForm;
