import React from "react";
import { Avatar, Stack, Tooltip } from "@shopify/polaris";
import styled from "styled-components";

import api from "../api";
import { getUserInitials } from "../utils/displayUtils";

interface UserInitialsProps {
  user?: api.User;
  tooltip?: string;
  hideOnNoUser?: boolean;
}

const UserInitials: React.FC<UserInitialsProps> = ({ user, tooltip, hideOnNoUser }) => {
  if (hideOnNoUser && !user) {
    return null;
  }

  const avatar = (
    <StyledAvatar showInitials={user !== undefined}>
      <Avatar size="small" customer={false} initials={user && getUserInitials(user)} />
    </StyledAvatar>
  );

  return (
    <Stack distribution="center">
      {tooltip ? (
        <Tooltip content={tooltip} preferredPosition="above">
          {avatar}
        </Tooltip>
      ) : (
        avatar
      )}
    </Stack>
  );
};

const StyledAvatar = styled.div<{ showInitials: boolean }>`
  & .Polaris-Avatar {
    min-width: 2.5rem;
    width: 2.5rem;
    border: solid 2px white;
    color: ${({ showInitials }) => (showInitials ? "black" : "initial")};
    background: ${({ showInitials }) => (showInitials ? "#91E0D6" : "initial")};
  },
`;

export default UserInitials;
