import React, { useEffect, useState } from "react";
import { Badge } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

interface ScreeningStatusBadgeProps {
  screening: api.CustomerScreeningStatus;
}

const ScreeningStatusBadge: React.FC<ScreeningStatusBadgeProps> = ({ screening }) => {
  const [isActive, setActive] = useState(false);
  const [isDone, setDone] = useState(false);

  const f = useFormatMessage();

  useEffect(() => {
    switch (screening.screening_type) {
      case "ONE_TIME":
        switch (screening.screening_status) {
          case "DONE":
            setDone(true);
            break;
        }
        break;
      case "MONITORING":
        switch (screening.screening_status) {
          case "DONE":
            setDone(true);
            break;
          case "MONITORING":
            setActive(true);
            break;
        }
        break;
    }
  }, []);

  if (!isActive && !isDone) {
    return null;
  }

  if (isActive) {
    return (
      <Badge status="success" progress="complete">
        {f("screening.status.active")}
      </Badge>
    );
  }

  if (!isDone) {
    return null;
  }

  const screeningStarted = screening.screening_started ? new Date(screening.screening_started) : undefined;
  const screeningStopped = screening.screening_stopped ? new Date(screening.screening_stopped) : undefined;

  return (
    <>
      {screening.screening_type === "ONE_TIME" &&
        screeningStarted &&
        f("screening.status.finished", { finished: screeningStarted })}
      {screening.screening_type === "MONITORING" &&
        screeningStarted &&
        screeningStopped &&
        f("screening.status.range", { started: screeningStarted, stopped: screeningStopped })}
    </>
  );
};

export default ScreeningStatusBadge;
