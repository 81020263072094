import React from "react";
import { useIntl } from "react-intl";
import { Stack, TextStyle } from "@shopify/polaris";

import useFormatMessage from "../hooks/useFormatMessage";

import Button from "./extensions/Button";
import PlainSelect from "./PlainSelect";

interface PaginationControlsProps {
  isLoading: boolean;
  page: number;
  limit: number;
  count: number;
  itemsCount: number;
  setPage(page: number): void;
  setLimit(limit: number): void;
}

const PaginationControls: React.FC<PaginationControlsProps> = (props) => {
  const { isLoading, page, limit, count, itemsCount, setPage, setLimit } = props;

  const hasNext = (page + 1) * limit < count;
  const hasPrev = page > 0;

  const f = useFormatMessage();
  const { formatNumber } = useIntl();

  return (
    <Stack>
      <PlainSelect
        label={f("component.pagination.labels.select-limit", { limit })}
        labelInline
        options={["20", "50", "100", "200"]}
        onChange={(limitValue) => setLimit(parseInt(limitValue, 10))}
        value={limit + ""}
        disabled={isLoading}
      />
      <TextStyle>
        {f("component.pagination.labels.showing", {
          start: formatNumber(limit * page || 1),
          end: formatNumber(Math.min(limit * page + itemsCount, count)),
          count: formatNumber(count),
        })}
      </TextStyle>
      {hasPrev && (
        <Button plain onClick={() => setPage(page - 1)} disabled={isLoading}>
          {f("default.previous")}
        </Button>
      )}
      {hasNext && (
        <Button plain onClick={() => setPage(page + 1)} disabled={isLoading}>
          {f("default.next")}
        </Button>
      )}
    </Stack>
  );
};

export default PaginationControls;
