import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import { LinkLikeComponentProps } from "@shopify/polaris/build/ts/latest/src/utilities/link";

import { ROUTES } from "../constants/routes";
import HistoryLocationState from "../types/HistoryLocationState";

const AdapterLink: React.FC<LinkLikeComponentProps> = (props) => {
  const { url, children, external, download, target, ...rest } = props;

  const location = useLocation<HistoryLocationState>();

  const htmlAnchorParams: React.RefAttributes<HTMLAnchorElement> = rest as React.RefAttributes<HTMLAnchorElement>;

  const fromMatchPage = matchPath(location.pathname, { path: ROUTES.MATCH_CASE_DETAILS }) !== null;
  const fromCustomersList = !fromMatchPage && matchPath(location.pathname, { path: ROUTES.CUSTOMERS }) !== null;
  const fromProjectsList = matchPath(location.pathname, { path: ROUTES.PROJECTS_LIST }) !== null;

  // if link is generated from within the project details page
  // get the project id and project name from the current link state
  const createdFromProject = location.state?.createdFromProject;

  const fromCustomer = location.state?.fromCustomer;
  const fromProject = location.state?.fromProject;

  return (
    <Link<HistoryLocationState>
      to={{
        pathname: url,
        state: { fromCustomersList, fromProjectsList, createdFromProject, fromCustomer, fromProject },
      }}
      download={download || rest.download}
      target={external ? "_blank" : target}
      {...htmlAnchorParams}
    >
      {children}
    </Link>
  );
};

export default AdapterLink;
