import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { Card, DataTable, Heading, Stack, TextStyle } from "@shopify/polaris";

import api from "../../api";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useDefaultPersonDetails from "../../hooks/useDefaultPersonDetails";
import useFormatMessage from "../../hooks/useFormatMessage";
import AddItemButton from "../AddItemButton";
import OwnerModal, { OwnerModalData } from "../modals/OwnerModal";
import useRoleOrOwnerRow from "../screening/useRoleOrOwnerRow";

interface CompanyOwnersProps {
  customerId: string;
  owners: api.Owner[];
  readonly?: boolean;
  companyType?: api.CompanyType;
  companyCountry: api.CountryEnum;
  canHaveShareholders: boolean;
}

const CompanyOwners: React.FC<CompanyOwnersProps> = (props) => {
  const { customerId, owners = [], readonly, canHaveShareholders, companyType, companyCountry } = props;

  const renderOwner = useRoleOrOwnerRow();
  const defaultCountry = useDefaultCountry();
  const [modalOwner, setModalOwner] = useState<OwnerModalData>();
  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const newPersonDetails = useDefaultPersonDetails(companyCountry);

  const closeModal = () => setModalOwner(undefined);

  const handleCreateOwner = (createdOwner: api.Owner) => {
    const companyDetailsQueryKey = ["company-details", customerId];

    const companyDetailsData = queryClient.getQueryData<api.CompanyCustomerDetailResponse>(companyDetailsQueryKey);

    if (companyDetailsData) {
      companyDetailsData.beneficial_owners = companyDetailsData.beneficial_owners.concat(createdOwner);
      queryClient.setQueryData(companyDetailsQueryKey, companyDetailsData);
    }

    closeModal();
  };

  const companyTypeText = companyType?.description_nb || companyType?.code || "-";

  return (
    <Stack vertical>
      <Heading>{f("company.owners.title")}</Heading>
      <Card>
        {!canHaveShareholders && (
          <Card.Section>{f("screening.cards.cannot-have-shareholders", { companyType: companyTypeText })}</Card.Section>
        )}
        {canHaveShareholders && (
          <>
            {owners.length === 0 && (
              <Card.Section>
                <TextStyle>{f("owners.messages.no-owners")}</TextStyle>
              </Card.Section>
            )}
            {owners.length > 0 && (
              <DataTable
                columnContentTypes={["text", "numeric", "text"]}
                headings={[
                  <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
                  <TextStyle variation="subdued">{f("table.column.shares")}</TextStyle>,
                  <></>,
                ]}
                rows={owners.map((o) => {
                  const row = renderOwner({
                    entity: o,
                    onEdit: (id) => {
                      let percent_share = 0;
                      try {
                        percent_share = parseFloat(o.percent_share || "0");
                      } catch (error) {
                        console.error(`Failed to parse percent_share: ${error}`);
                      }

                      const country = o.person.country_of_citizenship || companyCountry || defaultCountry;

                      setModalOwner({
                        id: id,
                        isManuallyCreated: Boolean(o.created_by),
                        comment: o.comment,
                        percent_share,
                        first_name: o.person.first_name,
                        last_name: o.person.last_name,
                        birth_date: o.person.birth_date,
                        country_of_citizenship: country,
                        address: o.person.address ? o.person.address : { country },
                      });
                    },
                  });

                  return [row.stack, row.div, !readonly ? row.editBtn : undefined];
                })}
              />
            )}
            <Card.Section>
              <AddItemButton onClick={() => setModalOwner(newPersonDetails())} disabled={readonly}>
                {f("screening.buttons.add-owner")}
              </AddItemButton>
            </Card.Section>
          </>
        )}
      </Card>
      {modalOwner && (
        <OwnerModal
          id={customerId}
          dataOwner={modalOwner}
          onClose={closeModal}
          isDeleteDisabled
          onDelete={closeModal}
          onUpdate={(updatedOwner) => {
            const ownerToUpdate = owners.find((o) => updatedOwner.id === o.id);
            if (ownerToUpdate) {
              Object.assign(ownerToUpdate, updatedOwner);
            }
            closeModal();
          }}
          onCreate={(createdOwner) => handleCreateOwner(createdOwner)}
        />
      )}
    </Stack>
  );
};

export default CompanyOwners;
