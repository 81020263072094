import { useQuery } from "react-query";

import api from "../api";

const useGetUsers = (fetchOnInit = true) => {
  const { isLoading, error, data } = useQuery(["users"], () => api.getAllUsersAndGroups(), { enabled: fetchOnInit });

  return { isLoading, error, users: data?.users || [], groups: data?.groups || [] };
};

export default useGetUsers;
