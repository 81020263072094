import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Heading, Stack } from "@shopify/polaris";

import PlusMajorIcon from "../../../assets/icons/plus-major.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import Button from "../extensions/Button";

import DisclosureDocumentSignature from "./DisclosureDocumentSignature";

type DisclosureDocumentSectionProps = {
  customerId: string;
  disclosureDocuments: api.CustomerDisclosureDocument[];
  readonly?: boolean;
};

const DisclosureDocumentSection: React.FC<DisclosureDocumentSectionProps> = ({
  customerId,
  disclosureDocuments,
  readonly,
}) => {
  const f = useFormatMessage();
  const history = useHistory();

  const disclosureDocumentSignatures = disclosureDocuments
    .map((disclosureDocument) => {
      // Get all emails used in document
      // -> emails should be unique for each document
      const existingEmails = disclosureDocument.signatures
        .map((s) => s.email)
        .filter((email): email is string => email !== undefined);

      return disclosureDocument.signatures.map((disclosureDocumentSignature, i) => (
        <DisclosureDocumentSignature
          key={i}
          customerId={customerId}
          signature={disclosureDocumentSignature}
          existingEmails={existingEmails}
          createdTimestamp={disclosureDocument.created_dt}
          readonly={readonly}
        />
      ));
    })
    .flat();

  return (
    <Stack vertical>
      <Stack distribution="equalSpacing">
        <Heading>{f("disclosure-documents.title")}</Heading>
        <Button
          size="slim"
          icon={PlusMajorIcon}
          onClick={() => history.push(`${customerId}/request-disclosure`)}
          disabled={readonly}
        >
          {f("common.buttons.actions.new-disclosure")}
        </Button>
      </Stack>
      {disclosureDocumentSignatures}
      {disclosureDocumentSignatures.length === 0 && (
        <Card>
          <Card.Section>{f("disclosure-documents.empty")}</Card.Section>
        </Card>
      )}
    </Stack>
  );
};

export default DisclosureDocumentSection;
