import React, { CSSProperties, useState } from "react";
import { ButtonGroup, ButtonProps, Popover, TextStyle, Tooltip } from "@shopify/polaris";
import styled from "styled-components";

import CheckIcon from "../../assets/icons/checkmark-white.svg";
import CopyIcon from "../../assets/icons/copy.svg";
import useFormatMessage from "../hooks/useFormatMessage";
import useTimeout from "../hooks/useTimeout";
import Util from "../utils/util";

import Button from "./extensions/Button";
import Icon from "./extensions/Icon";

interface CopyLabelProps extends ButtonProps {
  maxWidth?: CSSProperties["width"];
}

const CopyLabel: React.FC<CopyLabelProps> = (props) => {
  const { children, maxWidth, ...buttonProps } = props;

  const [isCopied, setCopied] = useState(false);
  const f = useFormatMessage();

  const content = children as string;

  const closeTooltip = () => setCopied(false);

  // After 2s, reset the `isCopied` state to allow copying
  useTimeout(
    () => {
      setCopied(false);
    },
    2000,
    isCopied
  );

  const copyLink = () => {
    Util.copyToClipboard(content);
    setCopied(true);
  };

  return (
    <Popover
      preferredPosition="above"
      fluidContent
      active={isCopied}
      onClose={closeTooltip}
      activator={
        <StyledTrimmedButtonsWrapper maxWidth={maxWidth}>
          <ButtonGroup segmented>
            <Tooltip content={f("copy-label.buttons.copy.tooltip")}>
              <Button outline icon={<img src={CopyIcon} />} {...buttonProps} onClick={copyLink} />
            </Tooltip>
            <Tooltip content={content} preferredPosition="above">
              <Button outline {...buttonProps} onClick={copyLink}>
                {content}
              </Button>
            </Tooltip>
          </ButtonGroup>
        </StyledTrimmedButtonsWrapper>
      }
    >
      <StyledBanner>
        <Icon source={CheckIcon} />
        <TextStyle>{f("modals.copy.link.button.copied")}</TextStyle>
      </StyledBanner>
    </Popover>
  );
};

const StyledBanner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.5rem 1rem;
  color: white;
  background: #127e2a;
  stroke: white;
  fill: white;
`;

const StyledTrimmedButtonsWrapper = styled.div<{ maxWidth?: CSSProperties["width"] }>`
  & .Polaris-Button__Text {
    max-width: ${({ maxWidth }) => maxWidth || "250px"};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default CopyLabel;
