import * as React from "react";
import { DataTable, Subheading, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useYesNo from "../../hooks/useYesNo";
import isNil from "../../utils/isNil";
import CustomDate from "../CustomDate";

const valueWithDescription = (value: string, valueDescription?: string) => {
  return value + (valueDescription ? ` (${valueDescription})` : "");
};

const createRow = ({
  label,
  value,
  isHeading = false,
  isMultiline = false,
}: {
  label: string;
  value: string | JSX.Element;
  isHeading?: boolean;
  isMultiline?: boolean;
}): [JSX.Element, JSX.Element] => {
  const labelOrHeadingElement = isHeading ? (
    <Subheading>{label}</Subheading>
  ) : (
    <StyledSubduedText>
      <TextStyle>{label}</TextStyle>
    </StyledSubduedText>
  );

  const valueElement =
    typeof value === "string" ? <div className={`${isMultiline && "multiline-text"}`}>{value}</div> : value;

  return [labelOrHeadingElement, valueElement];
};

type DisclosureProps = {
  disclosure: api.CustomerDisclosure;
};

const Disclosure: React.FC<DisclosureProps> = ({ disclosure }) => {
  const f = useFormatMessage();
  const yesNo = useYesNo();

  // Populate rows with disclosure answers
  const rows: JSX.Element[][] = [];

  // Header
  rows.push(
    createRow({
      label: disclosure.disclosed_by,
      value: (
        <TextStyle>
          {f("disclosure.timestamp")} <CustomDate date={disclosure.timestamp} />
        </TextStyle>
      ),
      isHeading: true,
    })
  );

  // Customer relationship + source of funds
  rows.push(
    createRow({
      label: f("disclosure.purpose"),
      value: valueWithDescription(disclosure.purpose.label, disclosure.purpose_desc),
      isMultiline: true,
    })
  );

  rows.push(
    createRow({
      label: f("disclosure.source-of-funds"),
      value: valueWithDescription(disclosure.source_of_funds.label, disclosure.source_of_funds_desc),
      isMultiline: true,
    })
  );

  if (disclosure.additional_info) {
    rows.push(
      createRow({
        label: f("disclosure.additional-info"),
        value: disclosure.additional_info,
        isMultiline: true,
      })
    );
  }

  // PEP info
  if (disclosure.pep_role) {
    // Add "PEP" heading if `pepRole` is defined
    // -> in this case there will be a lot of PEP questions
    rows.push(
      createRow({
        label: f("disclosure.pep.heading"),
        value: "",
        isHeading: true,
      })
    );
  }

  rows.push(
    createRow({
      label: f("disclosure.is-pep"),
      value: yesNo(disclosure.is_pep),
    })
  );

  if (disclosure.pep_desc) {
    rows.push(
      createRow({
        label: f("disclosure.pep-description"),
        value: disclosure.pep_desc,
        isMultiline: true,
      })
    );
  }

  if (disclosure.pep_role) {
    rows.push(
      createRow({
        label: f("disclosure.pep-role"),
        value: disclosure.pep_role.label,
      })
    );
  }

  if (disclosure.country_of_origin) {
    rows.push(
      createRow({
        label: f("disclosure.country-of-origin"),
        value: disclosure.country_of_origin,
      })
    );
  }

  if (disclosure.pep_name) {
    rows.push(
      createRow({
        label: f("disclosure.pep-name"),
        value: disclosure.pep_name,
      })
    );
  }

  if (disclosure.pep_birth_date) {
    rows.push(
      createRow({
        label: f("disclosure.pep-birth-date"),
        value: disclosure.pep_birth_date,
      })
    );
  }

  if (disclosure.pep_relation) {
    rows.push(
      createRow({
        label: f("disclosure.pep-relation"),
        value: valueWithDescription(disclosure.pep_relation.label, disclosure.pep_relation_desc),
        isMultiline: true,
      })
    );
  }

  if (disclosure.pep_position) {
    rows.push(
      createRow({
        label: f("disclosure.pep-position"),
        value: valueWithDescription(disclosure.pep_position.label, disclosure.pep_position_desc),
        isMultiline: true,
      })
    );
  }

  if (disclosure.pep_position_org) {
    rows.push(
      createRow({
        label: f("disclosure.pep-position-org"),
        value: disclosure.pep_position_org,
      })
    );
  }

  if (disclosure.pep_position_country) {
    rows.push(
      createRow({
        label: f("disclosure.pep-position-country"),
        value: disclosure.pep_position_country,
      })
    );
  }

  if (disclosure.pep_position_termination_date) {
    rows.push(
      createRow({
        label: f("disclosure.pep-position-termination-date"),
        value: disclosure.pep_position_termination_date,
      })
    );
  }

  // Ownership info (only applies for companies)
  if (!isNil(disclosure.ubo_agreements_exists)) {
    // Add "ownership info" heading if `uboAgreementsExists` is defined
    // -> in this case there will be a lot of ownership questions
    rows.push(
      createRow({
        label: f("disclosure.ownership.heading"),
        value: "",
        isHeading: true,
      })
    );

    rows.push(
      createRow({
        label: f("disclosure.ubo-agreements-exists"),
        value: yesNo(disclosure.ubo_agreements_exists),
      })
    );

    if (disclosure.ubo_agreements_desc) {
      rows.push(
        createRow({
          label: f("disclosure.ubo-agreements-description"),
          value: disclosure.ubo_agreements_desc,
          isMultiline: true,
        })
      );
    }

    if (!isNil(disclosure.ubo_family_members_exists)) {
      rows.push(
        createRow({
          label: f("disclosure.ubo-family-members-exists"),
          value: yesNo(disclosure.ubo_family_members_exists),
        })
      );
    }

    if (disclosure.ubo_family_members_desc) {
      rows.push(
        createRow({
          label: f("disclosure.ubo-family-members-description"),
          value: disclosure.ubo_family_members_desc,
          isMultiline: true,
        })
      );
    }

    if (!isNil(disclosure.is_listed_on_regulated_market)) {
      rows.push(
        createRow({
          label: f("disclosure.is-listed-on-regulated-market"),
          value: yesNo(disclosure.is_listed_on_regulated_market),
        })
      );
    }

    if (disclosure.regulated_market_name) {
      rows.push(
        createRow({
          label: f("disclosure.regulated-market-name"),
          value: disclosure.regulated_market_name,
          isMultiline: true,
        })
      );
    }

    if (!isNil(disclosure.is_publicly_owned_or_administrative_body)) {
      rows.push(
        createRow({
          label: f("disclosure.is-publicly-owned-or-administrative-body"),
          value: yesNo(disclosure.is_publicly_owned_or_administrative_body),
        })
      );
    }

    if (disclosure.additional_ownership_info) {
      rows.push(
        createRow({
          label: f("disclosure.additional-ownership-info"),
          value: disclosure.additional_ownership_info,
          isMultiline: true,
        })
      );
    }
  }

  if (!isNil(disclosure.is_foreign_assignment)) {
    rows.push(
      createRow({
        label: f("disclosure.is-foreign-assignment"),
        value: yesNo(disclosure.is_foreign_assignment),
      })
    );
  }

  if (disclosure.foreign_assignment_desc) {
    rows.push(
      createRow({
        label: f("disclosure.foreign-assignment-description"),
        value: disclosure.foreign_assignment_desc,
        isMultiline: true,
      })
    );
  }

  // MiFID
  if (disclosure.mifid) {
    rows.push(
      createRow({
        label: f("disclosure.mifid.heading"),
        value: "",
        isHeading: true,
      })
    );

    rows.push(
      createRow({
        label: f("disclosure.mifid.transaction-role"),
        value: valueWithDescription(
          disclosure.mifid.transaction_role?.label || "",
          disclosure.mifid.transaction_role_desc
        ),
        isMultiline: true,
      })
    );

    if (disclosure.mifid.profession) {
      rows.push(
        createRow({
          label: f("disclosure.mifid.profession"),
          value: disclosure.mifid.profession,
          isMultiline: true,
        })
      );
    }

    if (disclosure.mifid.industry) {
      rows.push(
        createRow({
          label: f("disclosure.mifid.industry"),
          value: disclosure.mifid.industry,
          isMultiline: true,
        })
      );
    }

    rows.push(
      createRow({
        label: f("disclosure.mifid.education-level"),
        value: disclosure.mifid.education_level?.label || "",
        isMultiline: true,
      })
    );

    rows.push(
      createRow({
        label: f("disclosure.mifid.financial-instrument-trading-experience"),
        value: disclosure.mifid.financial_instrument_trading_experience?.label || "",
        isMultiline: true,
      })
    );

    if (disclosure.mifid.additional_mifid_info) {
      rows.push(
        createRow({
          label: f("disclosure.mifid.additional-info"),
          value: disclosure.mifid.additional_mifid_info,
          isMultiline: true,
        })
      );
    }

    if (!isNil(disclosure.mifid.is_accounting_balance_over_threshold)) {
      rows.push(
        createRow({
          label: f("disclosure.mifid.is-accounting-balance-over-threshold"),
          value: yesNo(disclosure.mifid.is_accounting_balance_over_threshold),
        })
      );
    }

    if (!isNil(disclosure.mifid.is_net_turnover_over_threshold)) {
      rows.push(
        createRow({
          label: f("disclosure.mifid.is-net-turnover-over-threshold"),
          value: yesNo(disclosure.mifid.is_net_turnover_over_threshold),
        })
      );
    }

    if (!isNil(disclosure.mifid.is_equity_over_threshold)) {
      rows.push(
        createRow({
          label: f("disclosure.mifid.is-equity-over-threshold"),
          value: yesNo(disclosure.mifid.is_equity_over_threshold),
        })
      );
    }
  }

  return <DataTable columnContentTypes={["text", "text"]} headings={[]} rows={rows} />;
};

const StyledSubduedText = styled.div`
  color: var(--p-text-subdued);
`;

export default Disclosure;
