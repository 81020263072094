import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetUsers from "../../hooks/useGetUsers";
import { getFullName } from "../../utils/displayUtils";

const useFiltersOptions = () => {
  const f = useFormatMessage();

  const mapUserToSelectOption = (user: api.User) => ({
    value: user.id,
    label: `${getFullName(user)}${user.is_active ? "" : ` (${f("component.filters.responsible.user.inactive")})`}`,
    disabled: false,
  });

  const ALL_ITEMS_FILTER_ITEM = { label: f("component.filters.all.title"), value: "all", disabled: false };

  const CUSTOMER_STATUS_OPTIONS = [
    ALL_ITEMS_FILTER_ITEM,
    { label: f("component.filters.status.started"), value: "STARTED" },
    { label: f("component.filters.status.not-signed"), value: "NOT_SIGNED" },
    { label: f("component.filters.status.not-ready"), value: "NOT_READY" },
    { label: f("component.filters.status.review-required"), value: "REVIEW_REQUIRED" },
    { label: f("component.filters.status.approved"), value: "APPROVED" },
    { label: f("component.filters.status.rejected"), value: "REJECTED" },
  ];

  const CUSTOMER_TYPE_OPTIONS = [
    ALL_ITEMS_FILTER_ITEM,
    { label: f("component.filter.customer.option.company.title"), value: "companies" },
    { label: f("component.filter.customer.option.person.title"), value: "persons" },
  ];

  const RISK_OPTIONS = [
    ALL_ITEMS_FILTER_ITEM,
    { label: f("risklabel.LOW"), value: "LOW" },
    { label: f("risklabel.MEDIUM"), value: "MEDIUM" },
    { label: f("risklabel.HIGH"), value: "HIGH" },
  ];

  const MATCH_OPTIONS = [
    { label: f("component.filters.match.option.open.cases"), value: "unresolved" },
    { label: f("component.filters.match.option.pep.matches"), value: "isPep" },
    { label: f("component.filters.match.option.sanction.matches"), value: "isSanctioned" },
  ];

  const ARCHIVE_OPTIONS = [
    { label: f("component.filters.archive.option.include-archived-customers"), value: "isArchived" },
  ];

  const SCREENING_OPTIONS = [
    ALL_ITEMS_FILTER_ITEM,
    { label: f("component.filters.screening.option.monitoring"), value: "MONITORING" },
    { label: f("component.filters.screening.option.one-time"), value: "ONE_TIME" },
  ];

  const PROJECT_STATUS_OPTIONS = [
    ALL_ITEMS_FILTER_ITEM,
    { label: f("component.filters.project.option.active"), value: "ACTIVE" },
    { label: f("component.filters.project.option.inactive"), value: "INACTIVE" },
  ];

  const { users, isLoading, error } = useGetUsers();

  const responsibleUserOptions = [ALL_ITEMS_FILTER_ITEM];

  if (isLoading) {
    responsibleUserOptions.push({ label: f("default.loading"), value: "", disabled: true });
  } else if (error) {
    responsibleUserOptions.push({ label: f("component.filters.responsible.user.error"), value: "", disabled: true });
  } else if (users) {
    responsibleUserOptions.push(...users.map((user) => mapUserToSelectOption(user)));
  }

  return {
    customerStatus: CUSTOMER_STATUS_OPTIONS,
    customerType: CUSTOMER_TYPE_OPTIONS,
    risk: RISK_OPTIONS,
    match: MATCH_OPTIONS,
    responsibleUser: responsibleUserOptions,
    archive: ARCHIVE_OPTIONS,
    screening: SCREENING_OPTIONS,
    projectStatus: PROJECT_STATUS_OPTIONS,
  };
};

export default useFiltersOptions;
