import React from "react";
import * as Sentry from "@sentry/react";
import { ErrorBoundaryProps as SentryErrorBoundaryProps, FallbackRender } from "@sentry/react/dist/errorboundary";

import ErrorPage from "../pages/ErrorPage";

/**
 * Use Sentry's ErrorBoundary with ErrorPage as default fallback component
 * -> Will automatically capture and report errors/exceptions to Sentry
 */
const ErrorBoundary: React.FC<SentryErrorBoundaryProps> = (props) => {
  // If fallback is undefined, default to a fallback render function
  // that renders ErrorPage
  const { fallback } = props;
  let fallbackRenderFunction: FallbackRender | undefined;
  if (fallback === undefined) {
    /* eslint-disable react/prop-types */
    fallbackRenderFunction = ({ error }) => <ErrorPage error={error} />;
  }

  return (
    <Sentry.ErrorBoundary {...props} fallback={fallback || fallbackRenderFunction}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
