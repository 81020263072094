import React from "react";

import api from "../../../api";
import UserSelect, { UserSelectProps } from "../../../components/UserSelect";
import useFormatMessage from "../../../hooks/useFormatMessage";

interface ResponsibleUserSelectProps
  extends Omit<UserSelectProps, "updateUser" | "emptySelectionLabel" | "updateErrorMessage"> {
  projectId: string;
}

const ResponsibleUserSelect: React.FC<ResponsibleUserSelectProps> = (props) => {
  const { projectId, ...responsibleUserSelectProps } = props;

  const f = useFormatMessage();

  const updateResponsibleUser = (user: api.User) =>
    api.updateProject(projectId, { responsible_id: user.id }).then((response) => response.responsible);

  return (
    <UserSelect
      updateUser={updateResponsibleUser}
      emptySelectionLabel={f("project.info.manager.empty.user")}
      updateErrorMessage={f("project.info.manager.error")}
      {...responsibleUserSelectProps}
    />
  );
};

export default ResponsibleUserSelect;
