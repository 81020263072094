import useFormatMessage from "../../hooks/useFormatMessage";

const usePersonUpdateErrorParser = () => {
  const f = useFormatMessage();

  const parsePersonUpdateError = (error: any) => {
    let generalError: string | undefined = undefined;
    const fieldsErrorsMap = new Map<string, string>();

    // don't try to parse the error if it doesn't exist
    if (error === undefined || error === null) {
      return { generalError, fieldsErrorsMap };
    }

    const errorStatus = error?.status;

    // Try to handle some 422 (beaufort-api),
    // 400 (data-service) errors,
    // 409 error (conflict)
    try {
      if (errorStatus === 422) {
        error.data.detail.forEach((errorDetail: any) => {
          const field = Array.isArray(errorDetail.loc) ? errorDetail.loc[1] : undefined;
          if (field === "first_name") {
            fieldsErrorsMap.set("first_name", f("common.errors.invalid.first.name"));
          } else if (field === "last_name") {
            fieldsErrorsMap.set("last_name", f("common.errors.invalid.last.name"));
          } else if (field === "birth_date") {
            fieldsErrorsMap.set("birth_date", f("common.errors.invalid.birth.date"));
          } else if (field === "national_id") {
            fieldsErrorsMap.set("national_id", f("common.errors.invalid.person.national-id"));
          } else if (field === "country_of_citizenship") {
            generalError = f("common.errors.invalid.citizenship");
          } else if (field === "address") {
            generalError = f("common.errors.invalid.address");
          } else {
            generalError = f("common.errors.generic");
          }
        });
      } else if (errorStatus === 400) {
        const personErrors = error?.data?.subject?.person || error?.data?.person || [];
        if ("national_id" in personErrors) {
          fieldsErrorsMap.set("national_id", f("common.errors.invalid.person.national-id"));
        } else {
          generalError = f("common.errors.generic");
        }
      } else if (errorStatus === 409) {
        // If conflict, try to find the field that triggered the conflict
        const errorDetails: string | undefined = error?.data?.detail;
        if (errorDetails) {
          if (errorDetails.includes("national_id")) {
            fieldsErrorsMap.set("national_id", f("common.errors.person-national-id.exists"));
          } else if (errorDetails.includes("external_id")) {
            fieldsErrorsMap.set("external_id", f("common.errors.person-external-id.exists"));
          } else {
            generalError = f("common.errors.person.exists");
          }
        } else {
          generalError = f("common.errors.person.exists");
        }
      } else {
        generalError = f("common.errors.generic");
      }
    } catch (parseError: unknown) {
      generalError = f("common.errors.generic");
    }

    return { generalError, fieldsErrorsMap };
  };

  return parsePersonUpdateError;
};

export default usePersonUpdateErrorParser;
