import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, DisplayText, Page, Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import ErrorPanel from "../../components/ErrorPanel";
import Button from "../../components/extensions/Button";
import ProjectInfoModal from "../../components/modals/ProjectInfoModal";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";

import ProjectsListEmptyState from "./ProjectsListEmptyState";
import ProjectsListFilters from "./ProjectsListFilters";
import ProjectsListTable from "./ProjectsListTable";
import useProjectListParams from "./useProjectListParams";

const ProjectsListPage: React.FC<RouteComponentProps> = () => {
  const f = useFormatMessage();

  const { searchParams, isEmptySearch, resetAllFilters } = useProjectListParams();
  const [showModal, toggleShowModal, closeModal] = useOpenClose();
  const [selectedProjectsMap, setSelectedProjectsMap] = useState<Record<string, api.ProjectListItem>>({});

  const selectedProjectsIds = Object.keys(selectedProjectsMap);

  useEffect(() => {
    // if current search is empty reset all filters
    // this is used if the user navigates to the projects list url from the main toolbar
    // this code is here and not inside useProjectListParams since useProjectListParams is wrapped inside useBetween hook
    // useBetween enables sharing state between multiple instance of custom hooks
    // but does not allow using context inside the wrapped hooks - so we can't use useLocation inside useProjectListParams
    if (!location.search) {
      resetAllFilters();
    }
  }, [location.search]);

  const { isLoading, isError, error, data } = useQuery(["projects", ...Object.values(searchParams)], () =>
    api.getProjects(searchParams)
  );

  const count = data?.count || 0;
  const projects = data?.projects || [];

  const handleSelectionChange = useCallback(
    (selectedIds: string[]) => {
      setSelectedProjectsMap((currentSelectedProjectsMap) => {
        const selectionMap: Record<string, api.ProjectListItem> = {};

        selectedIds.forEach((selectedProjectId) => {
          selectionMap[selectedProjectId] =
            currentSelectedProjectsMap[selectedProjectId] ||
            projects.find((project) => project.id === selectedProjectId);
        });

        return selectionMap;
      });
    },
    [projects]
  );

  const showEmptyState = isEmptySearch && count === 0 && !isLoading && !isError;

  return (
    <Page>
      <Stack vertical spacing="extraLoose">
        <Stack>
          <Stack.Item fill>
            <DisplayText size="large">{f("projects.page.title")}</DisplayText>
          </Stack.Item>
          <Button primary onClick={toggleShowModal}>
            {f("projects.common.labels.create.project")}
          </Button>
        </Stack>
        <ProjectsListFilters isLoading={isLoading} />
        <StyledTableToolbar>
          <Stack alignment="center">
            <TextStyle>
              {count > 0
                ? f("projects.page.header.projects.count", { count, selected: selectedProjectsIds.length })
                : ""}
            </TextStyle>
          </Stack>
        </StyledTableToolbar>
      </Stack>
      <Card>
        {!showEmptyState && (
          <ProjectsListTable
            isLoading={isLoading}
            projects={projects}
            count={count}
            selectedIds={selectedProjectsIds}
            onSelectionChange={handleSelectionChange}
          />
        )}
        {showEmptyState && <ProjectsListEmptyState />}
      </Card>
      {isError && (
        <Card>
          <ErrorPanel message={error} />
        </Card>
      )}
      {showModal && <ProjectInfoModal onClose={closeModal} />}
    </Page>
  );
};

const StyledTableToolbar = styled.div`
  & > div {
    min-height: 7rem;
  }
`;

export default withRouter(ProjectsListPage);
