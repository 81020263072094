import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { generatePath, useLocation } from "react-router-dom";
import { Card, DisplayText, EmptyState, Page, Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import EmptyHomeState from "../../../assets/images/empty-home-state.png";
import NotFoundState from "../../../assets/images/no-match.png";
import api from "../../api";
import CustomersListTable from "../../components/CustomersListTable";
import { ROUTES } from "../../constants/routes";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useSelectionMap from "../../hooks/useSelectionMap";
import { Customer } from "../../types/utilities";

import CustomersListBulkActions from "./CustomersListBulkActions";
import CustomersListFilters from "./CustomersListFilters";
import RegisterCustomerDropdownMenu from "./RegisterCustomerDropdownMenu";
import useCustomersListParams from "./useCustomerListParams";
import useGetCustomersListBulkActions from "./useGetCustomersListBulkActions";

const CustomersListPage: React.FC = () => {
  const f = useFormatMessage();
  const features = useFeatures();
  const location = useLocation();
  const { searchParams, isEmptySearch, isArchived, resetAllFilters } = useCustomersListParams();

  useEffect(() => {
    // if current search is empty reset all filters
    // this is used if the user navigates to the customers list url from the main toolbar
    // this code is here and not inside useCustomersListParams since useCustomersListParams is wrapped inside useBetween hook
    // useBetween enables sharing state between multiple instance of custom hooks
    // but does not allow using context inside the wrapped hooks - so we can't use useLocation inside useCustomersListParams
    if (!location.search) {
      resetAllFilters();
    }
  }, [location.search]);

  const { isLoading, data } = useQuery(["customers", ...Object.values(searchParams)], () =>
    api.getCustomers(searchParams)
  );

  const count = data?.count || 0;
  const customers = data?.customers || [];

  const isFreshCustomer = customers.length === 0 && isEmptySearch;

  const [selectedCustomersIds, selectedCustomers, handleSelectionChange, clearSelection] = useSelectionMap<Customer>(
    customers
  );

  // switching between normal and archived customers changes the available actions in the page
  // clear the selected customers if the filter is changed so there won't be a mix of archived and regular customers
  useEffect(clearSelection, [isArchived]);

  const selectedCount = selectedCustomersIds.length;

  const { isEmpty: areBulkActionEmpty } = useGetCustomersListBulkActions(selectedCount, isArchived);

  const lookupPath = generatePath(ROUTES.COMPANY_LOOKUP);

  const isAllSelected = count === selectedCustomersIds.length;
  const selectedLabel = isAllSelected
    ? f("customers.list.header.customers.count.all", { count })
    : f("customers.list.header.customers.count", { count, selected: selectedCustomersIds.length });

  const countersLabel = count > 0 ? selectedLabel : "";

  return (
    <Page>
      <Stack vertical spacing="extraLoose">
        <Stack>
          <Stack.Item fill>
            <DisplayText size="large">{f("customers.list.title")}</DisplayText>
          </Stack.Item>
          {features.PROJECTS && <RegisterCustomerDropdownMenu />}
        </Stack>
        <CustomersListFilters isLoading={isLoading} />
        <StyledTableToolbar>
          <Stack alignment="center" wrap={false}>
            <TextStyle>{countersLabel}</TextStyle>
            {selectedCount > 0 && (
              <CustomersListBulkActions
                customers={selectedCustomers}
                isArchived={isArchived}
                onClearSelection={clearSelection}
              />
            )}
          </Stack>
        </StyledTableToolbar>
      </Stack>
      <Card>
        <CustomersListTable
          isLoading={isLoading}
          customers={customers}
          count={data?.count || 0}
          sortable
          selectable={!areBulkActionEmpty}
          selectedIds={selectedCustomersIds}
          onSelectionChange={handleSelectionChange}
        />
        {!isLoading &&
          customers.length === 0 &&
          (isFreshCustomer ? (
            <EmptyState
              heading={f("customers.list.fresh-customer.title")}
              action={{ content: f("customers.list.fresh-customer.label"), url: lookupPath }}
              image={EmptyHomeState}
            />
          ) : (
            <EmptyState
              heading={f("customers.list.empty.title")}
              footerContent={f("customers.list.empty.content")}
              image={NotFoundState}
            />
          ))}
      </Card>
    </Page>
  );
};

const StyledTableToolbar = styled.div`
  & > div {
    min-height: 7rem;
  }
`;

export default CustomersListPage;
