import { useMutation } from "react-query";

import api from "../api";
import useAttachmentsAPI from "../components/attachments/useAttachmentsAPI";
import { Entity } from "../types/utilities";
import download from "../utils/download";
import { addSuffixToFilename } from "../utils/filename-utils";
import Util from "../utils/util";

interface DownloadAttachmentParams {
  attachment: api.AttachmentDetails;
  downloadSignedAttachment?: boolean;
}

const useAttachmentDownload = (entity: Entity) => {
  const { entityId, downloadAttachment, downloadSignedDocumentAttachment } = useAttachmentsAPI(entity);

  return useMutation<unknown, unknown, DownloadAttachmentParams>(async (params) => {
    const { attachment, downloadSignedAttachment } = params;

    const data: { download_url: string } = downloadSignedAttachment
      ? await downloadSignedDocumentAttachment(entityId, attachment.id)
      : await downloadAttachment(entityId, attachment.id);

    const filename = addSuffixToFilename(
      Util.cleanupFilename(attachment.filename),
      downloadSignedAttachment ? " - signed" : ""
    );

    return download(data?.download_url, filename);
  });
};

export default useAttachmentDownload;
