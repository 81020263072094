import React from "react";
import { SkeletonBodyText } from "@shopify/polaris";

interface ReactTableSkeletonProps {
  lines?: number;
  columns?: number;
}

const ReactTableSkeleton: React.FC<ReactTableSkeletonProps> = (props) => {
  const { lines = 4, columns = 5 } = props;

  return (
    <>
      {Array.from({ length: lines }, (_, rowIndex) => (
        <tr className="Polaris-DataTable__TableRow" key={rowIndex}>
          {Array.from({ length: columns }, (_, colIndex) => (
            <th className="Polaris-DataTable__Cell" key={colIndex}>
              <SkeletonBodyText lines={1} />
            </th>
          ))}
        </tr>
      ))}
    </>
  );
};

export default ReactTableSkeleton;
