import * as React from "react";
import {
  Banner,
  Card,
  DataTable,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  Stack,
  TextStyle,
} from "@shopify/polaris";

const DisclosureSkeleton = () => (
  <Page>
    <Stack vertical>
      <SkeletonDisplayText size="large" />

      <TextStyle>
        <SkeletonBodyText lines={1} />
      </TextStyle>

      <Banner status="info">
        <Stack vertical>
          <TextStyle variation="strong">
            <SkeletonBodyText lines={1} />
          </TextStyle>
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
        </Stack>
      </Banner>

      <Card>
        <DataTable
          columnContentTypes={["text", "text"]}
          headings={[]}
          rows={[[<SkeletonBodyText lines={1} />], [<SkeletonBodyText lines={1} />], [<SkeletonBodyText lines={1} />]]}
        />
      </Card>
    </Stack>
  </Page>
);

export default DisclosureSkeleton;
