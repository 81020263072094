import React from "react";
import { useIntl } from "react-intl";
import { Card, Stack, TextStyle } from "@shopify/polaris";

import CalendarIcon from "../../../../assets/icons/calendar.svg";
import api from "../../../api";
import Icon from "../../../components/extensions/Icon";
import UserInitials from "../../../components/UserInitials";
import { getFullName } from "../../../utils/displayUtils";

interface ProjectReviewCardProps {
  review: api.ProjectReview;
}

const ProjectReviewCard: React.FC<ProjectReviewCardProps> = (props) => {
  const { review } = props;

  const { formatDate } = useIntl();

  return (
    <Card>
      <Card.Section>
        <Stack alignment="center" spacing="extraLoose">
          <Stack alignment="center" spacing="extraTight">
            <Icon source={CalendarIcon} />
            <TextStyle>{formatDate(review.created_dt)}</TextStyle>
          </Stack>
          <Stack alignment="center" spacing="extraTight">
            <UserInitials user={review.reviewed_by} />
            <TextStyle>{getFullName(review.reviewed_by)}</TextStyle>
          </Stack>
        </Stack>
      </Card.Section>
      <Card.Section>
        <TextStyle>{review.comment}</TextStyle>
      </Card.Section>
    </Card>
  );
};

export default ProjectReviewCard;
