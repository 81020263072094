const isValidDate = (dateValue?: string, minDate?: Date, maxDate?: Date) => {
  if (!dateValue) return false;

  // Check if valid date
  // see https://medium.com/@esganzerla/simple-date-validation-with-javascript-caea0f71883c
  const date = new Date(dateValue);
  const day = parseInt(dateValue.substr(8, 2));
  const isValidDate = Boolean(+date) && date.getDate() == day;
  if (!isValidDate) return false;

  // Check if date is within min/max limits
  if (minDate && date < minDate) {
    return false;
  }
  if (maxDate && date > maxDate) {
    return false;
  }
  return true;
};

export default isValidDate;
