import React from "react";

import useCustomersListParams from "../pages/customers/useCustomerListParams";
import { Customer } from "../types/utilities";

import PaginationControls from "./PaginationControls";

interface CustomersListPaginationProps {
  isLoading: boolean;
  customers: Customer[];
  count: number;
}

const CustomersListPagination: React.FC<CustomersListPaginationProps> = (props) => {
  const { isLoading, customers, count } = props;

  const { page, setPage, limit, setLimit } = useCustomersListParams();

  return (
    <PaginationControls
      isLoading={isLoading}
      page={page}
      limit={limit}
      count={count}
      itemsCount={customers.length}
      setPage={setPage}
      setLimit={setLimit}
    />
  );
};

export default CustomersListPagination;
