import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Entity } from "../../types/utilities";
import Util from "../../utils/util";
import { isNameValid, isNationalIdValid } from "../../utils/validators";

import { AttachmentSignature, mapToAttachmentSignature, mapToSavedSignature } from "./types/AttachmentSignature";
import useAttachmentsAPI from "./useAttachmentsAPI";
import useEditableRows, { Row } from "./useEditableRows";

export type AttachmentSignatureRow = Row<AttachmentSignature>;

const hasUnsentEmail = (row: AttachmentSignatureRow) =>
  row.entity.send_email && (row.isNew || row.originalEntity?.email !== row.entity.email);

const hasUnsavedLink = (row: AttachmentSignatureRow) => !row.entity.send_email && row.isNew;

const useAttachmentSignatures = (entity: Entity, attachment: api.AttachmentDetails) => {
  const queryClient = useQueryClient();
  const f = useFormatMessage();

  const { entityId, getAttachmentSignatures, updateAttachmentSignatures } = useAttachmentsAPI(entity);

  const { isLoading, isError: isLoadingError, error: loadingError, data } = useQuery(
    ["attachment-signatures", entityId, attachment.id],
    () => getAttachmentSignatures(entityId, attachment.id)
  );

  const saveAttachmentSignaturesMutation = useMutation<
    api.AttachmentSignaturesResponse,
    unknown,
    { updateAttachmentSignaturesRequest: api.UpdateAttachmentSignaturesRequest }
  >(
    ({ updateAttachmentSignaturesRequest }) =>
      updateAttachmentSignatures(entityId, attachment.id, updateAttachmentSignaturesRequest),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(["attachment-signatures", entityId, attachment.id], response);
        queryClient.invalidateQueries(["attachments", entityId]);
      },
    }
  );

  const attachedSignatures = useMemo(() => (data?.signatures || []).map(mapToAttachmentSignature), [data]);

  const { rows, updateProperty, saveRow, ...rowsMethods } = useEditableRows<AttachmentSignature>({
    entities: attachedSignatures,
    createEmptyEntity: () => ({
      id: String("new-row-" + Math.round(Math.random() * 1_000_000)),
      fullName: "",
      send_email: true,
      use_verification: false,
      signing_page_url: "",
      pin: "",
      status: "PENDING",
    }),
    isValidEntity: (entity, rows) => {
      const validationErrors = new Map<keyof AttachmentSignature, string>();

      // validate name
      const nameValidationError = isNameValid(entity.fullName);

      if (nameValidationError) {
        validationErrors.set("fullName", f(`common.errors.invalid.${nameValidationError}`));
      } else {
        const nonEmptyNames = rows.map((row) => row.entity.fullName.trim().toLowerCase()).filter(Boolean);
        const allNames = new Set(nonEmptyNames);
        const isUniqueName = allNames.size === nonEmptyNames.length;

        if (!isUniqueName) {
          validationErrors.set("fullName", f("common.errors.invalid.name.exists"));
        }
      }

      // validate email
      const isEmailValid = !entity.send_email || Util.isEmail(entity.email);

      if (!isEmailValid) {
        validationErrors.set("email", f("email.invalid.address.error"));
      } else {
        const allEmails = rows.map((row) => row.entity.email?.toLowerCase());
        const isUniqueEmail =
          !entity.send_email ||
          allEmails.indexOf(entity.email?.toLowerCase()) === allEmails.lastIndexOf(entity.email?.toLowerCase());

        if (!isUniqueEmail) {
          validationErrors.set("email", f("common.errors.email.not-unique"));
        }
      }

      // validate national id
      const isValidNationalId = !entity.use_verification || isNationalIdValid(entity.national_id);
      if (!isValidNationalId) {
        validationErrors.set("national_id", f("common.errors.invalid.person.national-id"));
      } else {
        const allNationalIds = rows.map((row) => row.entity.national_id?.toLowerCase());
        const isUniqueNationalId =
          allNationalIds.indexOf(entity.national_id?.toLowerCase()) ===
          allNationalIds.lastIndexOf(entity.national_id?.toLowerCase());

        if (entity.use_verification && !isUniqueNationalId) {
          validationErrors.set("national_id", f("common.errors.invalid.national-id.exists"));
        }
      }

      return validationErrors;
    },
    isEqualEntity: (firstEntity, secondEntity) => {
      return (
        firstEntity.fullName === secondEntity.fullName &&
        firstEntity.send_email === secondEntity.send_email &&
        firstEntity.email === secondEntity.email &&
        firstEntity.role_id === secondEntity.role_id &&
        firstEntity.use_verification === secondEntity.use_verification &&
        firstEntity.national_id === secondEntity.national_id
      );
    },
  });

  const saveChanges = () => {
    const currentAttachmentSignatures = rows
      .map((row) => (row.isNew ? { ...row.entity, id: undefined } : row.entity))
      .map(mapToSavedSignature);

    const previousAttachmentSignaturesCount = data?.signatures?.length || 0;
    // tells the server to delete the signicat document associated with signatures for this attachment
    const deleteSignaturesDocument = previousAttachmentSignaturesCount > 0 && currentAttachmentSignatures.length === 0;

    return saveAttachmentSignaturesMutation.mutateAsync({
      updateAttachmentSignaturesRequest: {
        signatures: currentAttachmentSignatures,
        delete: deleteSignaturesDocument,
      },
    });
  };

  const selectSuggestion = (row: AttachmentSignatureRow, personId: string, personName: string, roleId?: string) => {
    updateProperty(row, "signer_id", personId);
    updateProperty(row, "fullName", personName);
    updateProperty(row, "role_id", roleId);
  };

  const clearSuggestion = (row: AttachmentSignatureRow) => {
    updateProperty(row, "signer_id", undefined);
    updateProperty(row, "fullName", "");
    updateProperty(row, "role_id", undefined);
  };

  const handleSaveRow = (row: AttachmentSignatureRow) => {
    if (!row.entity.send_email) {
      row.entity.email = undefined;
    }
    saveRow(row);
  };

  const hasInvalidRows = rows.find((row) => row.isCreating || row.validationErrors.size !== 0) !== undefined;
  const hasUnsavedChanges =
    !hasInvalidRows && (rows.find((row) => row.isModified) !== undefined || rows.length !== attachedSignatures.length);
  const isEmailOnly = rows.find((row) => !row.entity.send_email) === undefined;
  const isLinkOnly = rows.find((row) => row.entity.send_email) === undefined;
  const hasUnsentEmails = rows.filter(hasUnsentEmail).length > 0;
  const hasUnsavedLinks = rows.filter(hasUnsavedLink).length > 0;

  return {
    isLoading,
    isError: isLoadingError,
    error: loadingError || saveAttachmentSignaturesMutation.error,
    isInitialEmpty: !isLoading && data?.signatures.length === 0,
    isSaving: saveAttachmentSignaturesMutation.isLoading,
    hasInvalidRows,
    hasUnsavedChanges,
    isEmailOnly,
    isLinkOnly,
    hasUnsentEmails,
    hasUnsavedLinks,
    rows,
    updateProperty,
    saveRow: handleSaveRow,
    saveChanges,
    selectSuggestion,
    clearSuggestion,
    ...rowsMethods,
  };
};

export default useAttachmentSignatures;
