import api from "../api";
import { Customer } from "../types/utilities";

export const isRiskLevelEqualsTo = (levels: api.RiskLevel[], level?: api.RiskLevel) => level && levels.includes(level);

export const isMediumOrHighRisk = (industryRisk?: api.IndustryRiskInformation) =>
  isRiskLevelEqualsTo(["MEDIUM", "HIGH"], industryRisk?.industry_types.industry_code_1?.risk_level) ||
  isRiskLevelEqualsTo(["MEDIUM", "HIGH"], industryRisk?.industry_types.industry_code_2?.risk_level) ||
  isRiskLevelEqualsTo(["MEDIUM", "HIGH"], industryRisk?.industry_types.industry_code_3?.risk_level);

const isReviewedStatus = (reviewStatus: api.ReviewStatus) => reviewStatus === "APPROVED" || reviewStatus === "REJECTED";

export const getCustomerRisk = (customer: Customer) =>
  isReviewedStatus(customer.review_status) ? customer.risk_level : undefined;
