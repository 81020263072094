import React from "react";
import { InlineError, InlineErrorProps } from "@shopify/polaris";

interface ErrorPanel extends Omit<InlineErrorProps, "fieldID" | "message"> {
  message: string | unknown;
  fieldID?: string;
}

const ErrorPanel: React.FC<ErrorPanel> = ({ fieldID = "", message, ...rest }) => {
  const displayMessage = message && typeof message === "object" ? message.toString() : (message as string);
  return <InlineError fieldID={fieldID} message={displayMessage} {...rest} />;
};

export default ErrorPanel;
