import React from "react";
import { Caption, Stack } from "@shopify/polaris";
import styled from "styled-components";

import CheckmarkIcon from "../../../../assets/icons/checkmark.svg";
import InfoIcon from "../../../../assets/icons/info-circle.svg";
import useFormatMessage from "../../../hooks/useFormatMessage";
import Icon from "../../extensions/Icon";

interface AttachmentSignaturesModalFooterProps {
  hasUnsavedChanges: boolean;
  savedSuccessfully: boolean;
}

const AttachmentSignaturesModalFooter: React.FC<AttachmentSignaturesModalFooterProps> = (props) => {
  const { hasUnsavedChanges, savedSuccessfully } = props;

  const f = useFormatMessage();

  if (!savedSuccessfully && !hasUnsavedChanges) {
    return null;
  }

  return (
    <Stack distribution="center" alignment="center">
      <StyledChangeLabel hasUnsavedChanges={hasUnsavedChanges}>
        <Stack spacing="extraTight" alignment="center">
          <Icon source={hasUnsavedChanges ? InfoIcon : CheckmarkIcon} useMask />
          <Caption>
            {hasUnsavedChanges && f("attachments.signatures.modal.labels.unsaved-changes")}
            {!hasUnsavedChanges && savedSuccessfully && f("attachments.signatures.modal.labels.saved-changes")}
          </Caption>
        </Stack>
      </StyledChangeLabel>
    </Stack>
  );
};

const StyledChangeLabel = styled.div<{ hasUnsavedChanges: boolean }>`
  color: ${({ hasUnsavedChanges }) => (hasUnsavedChanges ? "#777" : "#3AAD53")};
`;

export default AttachmentSignaturesModalFooter;
