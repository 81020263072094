import React, { CSSProperties, useCallback, useMemo } from "react";
import { Column } from "react-table";
import { Link, Stack, TextStyle, Tooltip } from "@shopify/polaris";
import styled from "styled-components";

import { SORT_BY_ADDED_DATE_PARAMETER, SORT_BY_NAME_PARAMETER } from "../constants/pagination";
import useFeatures from "../hooks/useFeatures";
import useFormatMessage from "../hooks/useFormatMessage";
import useLanguage from "../hooks/useLanguage";
import useCustomersListParams from "../pages/customers/useCustomerListParams";
import { Customer } from "../types/utilities";
import { getCustomerName, getFullName } from "../utils/displayUtils";
import { getCustomerRisk } from "../utils/riskUtils";
import Util from "../utils/util";

import TableColumnHeader from "./extensions/TableColumnHeader";
import DisplayLabelsWrapper from "./labels/DisplayLabelsWrapper";
import Badges from "./Badges";
import CaseStatus from "./CaseStatus";
import CustomDate from "./CustomDate";
import CustomersListPagination from "./CustomersListPagination";
import { LightCustomerName } from "./DisplayName";
import ReactTable, { SORT_DIRECTION } from "./ReactTable";
import Risk from "./Risk";
import UserInitials from "./UserInitials";

// this type is needed when defining a column accessor which is a function
type CustomerRowType = { row: { original: Customer } };

interface CustomersListTableProps {
  isLoading: boolean;
  customers: Customer[];
  count: number;
  hidePagination?: boolean;
  sortable?: boolean;
  sortLocally?: boolean;
  selectable?: boolean;
  selectedIds?: string[];
  onSelectionChange?(customersIds: string[]): void;
}

const CustomersListTable: React.FC<CustomersListTableProps> = (props) => {
  const {
    isLoading,
    customers,
    count,
    hidePagination,
    sortable,
    sortLocally,
    selectable,
    selectedIds,
    onSelectionChange,
  } = props;

  const { order, setOrder, setPage } = useCustomersListParams();
  const features = useFeatures();
  const language = useLanguage();
  const f = useFormatMessage();

  const columns: Column<Customer>[] = useMemo(
    () => [
      {
        Header: f("customers.list.column.status"),
        accessor: "review_status",
        Cell: ({ row, value }) => <CaseStatus onboardingStatus={row.original.onboarding_status} reviewStatus={value} />,
      },
      {
        Header: f("customers.list.column.client"),
        // return the underlying value for the table data model - use toLowerCase() so sorting will more human readable
        accessor: (row) => getCustomerName(row).toLowerCase(),
        id: SORT_BY_NAME_PARAMETER,
        defaultCanSort: sortable || sortLocally,
        Cell: ({ row: { original: customer } }: CustomerRowType) => {
          const customerName = getCustomerName(customer);
          const url = Util.caseLink(customer.id, customer.type!, customer.onboarding_status, customer.is_manual);

          return (
            <Stack alignment="leading" spacing="extraTight" vertical>
              <StyledWrapped>
                <DisplayLabelsWrapper labels={customer.labels} cutoff={1}>
                  <LightCustomerName type={customer.type!} name={customerName}>
                    <Link url={url}>{customerName}</Link>
                  </LightCustomerName>
                </DisplayLabelsWrapper>
              </StyledWrapped>
              <Badges isArchived={customer.is_archived} isPep={customer.is_pep} isSanctioned={customer.is_sanctioned} />
            </Stack>
          );
        },
      },
      {
        Header: <TableColumnHeader centered>{f("customers.list.column.responsible.user")}</TableColumnHeader>,
        accessor: "responsible",
        Cell: ({ value: responsible }) => (
          <UserInitials
            user={responsible}
            tooltip={`${f("responsible.label")}: ${getFullName(responsible)}`}
            hideOnNoUser
          />
        ),
      },
      {
        Header: f("customers.list.column.external-id"),
        accessor: "external_id",
        Cell: ({ value }) => (
          <Tooltip content={f("customers.list.external-id.tooltip", { id: value })} preferredPosition="above">
            <StyledWrapped title={value} maxWidth="10ch">
              {value}
            </StyledWrapped>
          </Tooltip>
        ),
      },
      {
        Header: <TableColumnHeader centered>{f("customers.list.column.open.cases")}</TableColumnHeader>,
        accessor: "unresolved_case_count",
        Cell: ({ value }) => (
          <StyledCenteredCell>
            <TextStyle>{value === 0 ? "-" : value}</TextStyle>
          </StyledCenteredCell>
        ),
      },
      {
        Header: <StyledCenteredCell>{f("customers.list.column.risk")}</StyledCenteredCell>,
        accessor: "risk_level",
        Cell: ({ row: { original } }) => {
          const risk = getCustomerRisk(original);
          return <Risk risk={risk} showHyphenIfNotSet wrap={false} />;
        },
      },
      {
        Header: <TableColumnHeader centered>{f("customers.list.column.added")}</TableColumnHeader>,
        accessor: (row) => new Date(row.created!),
        id: SORT_BY_ADDED_DATE_PARAMETER,
        sortType: "datetime",
        defaultCanSort: sortable || sortLocally,
        Cell: ({ row: { original } }: CustomerRowType) => (
          <Stack distribution="center">
            <CustomDate date={original.created} />
          </Stack>
        ),
      },
    ],
    [language]
  );

  const hiddenColumns = useMemo(() => {
    const results: string[] = [];

    if (!features.EXTERNAL_ID) {
      results.push("external_id");
    }

    if (!features.RESPONSIBLE_USER) {
      results.push("responsible");
    }

    return results;
  }, [features]);

  const getRowId = useCallback((customer: Customer) => customer.id, []);

  const handleSortChange = useCallback(
    (columnId: string, sortDirection: SORT_DIRECTION) => {
      const orderDirection = sortDirection === "ascending" ? "" : "-";
      const newOrder = orderDirection + columnId;

      setOrder(newOrder);
      setPage(0);
    },
    [setOrder, setPage]
  );

  const sortedColumnId = order.includes(SORT_BY_NAME_PARAMETER) ? SORT_BY_NAME_PARAMETER : SORT_BY_ADDED_DATE_PARAMETER;
  const sortedDirection = order.startsWith("-") ? "descending" : "ascending";

  const showPagination = !hidePagination && customers.length > 0;

  return (
    <ReactTable<Customer>
      columns={columns}
      data={customers}
      getRowId={getRowId}
      hiddenColumns={hiddenColumns}
      sortLocally={sortLocally}
      sortedColumnId={sortedColumnId}
      sortedDirection={sortedDirection}
      onSortChange={handleSortChange}
      selectedIds={selectedIds || []}
      onSelectionChange={onSelectionChange}
      selectable={selectable}
      isLoading={isLoading}
      footerContent={
        showPagination ? <CustomersListPagination isLoading={isLoading} customers={customers} count={count} /> : null
      }
    />
  );
};

const StyledWrapped = styled.div<{ maxWidth?: CSSProperties["maxWidth"] }>`
  max-width: ${({ maxWidth }) => maxWidth || "200px"};
`;

const StyledCenteredCell = styled.div`
  text-align: center;
`;

export default CustomersListTable;
