import React from "react";
import { Button } from "@shopify/polaris";
import styled from "styled-components";

import Icon from "./extensions/Icon";

interface ActionableLabelProps {
  icon?: string;
  onClick(): void;
  disabled?: boolean;
}

const ActionableLabel: React.FC<ActionableLabelProps> = ({ children, ...props }) => {
  const { icon, onClick, disabled } = props;

  return (
    <StyledButtonWrapper disabled={disabled}>
      <Button
        onClick={onClick}
        size="slim"
        plain
        removeUnderline
        monochrome={true}
        textAlign="center"
        icon={icon ? <Icon source={icon} width="16px" height="16px" /> : undefined}
      >
        {children as string}
      </Button>
    </StyledButtonWrapper>
  );
};

const StyledButtonWrapper = styled.div<{ disabled?: boolean }>`
  border-radius: 50vh;
  display: inline-block;
  cursor: pointer;
  ${({ disabled }) => disabled && "pointer-events: none;"}

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:focus-within {
    background-color: rgba(0, 0, 0, 0.15);
  }

  & .Polaris-Button--sizeSlim:focus > .Polaris-Button__Content {
    background-color: transparent;
  }

  & .Polaris-Button--sizeSlim {
    padding: 0.4rem 1.6rem 0.2rem 1.4rem;
  }
`;

export default ActionableLabel;
