import * as React from "react";

import api from "../api";
import useCountries from "../hooks/useCountries";
import useFormatMessage from "../hooks/useFormatMessage";

type AddressInfoProps = {
  address?: api.Address;
  withTitles?: boolean;
};

const AddressInfo: React.FC<AddressInfoProps> = ({ address, withTitles = false }) => {
  const f = useFormatMessage();
  const { getCountryName } = useCountries();
  return address ? (
    <>
      {withTitles
        ? [
            address.address_line_1 && `${f("common.address.addressline1")}: ${address.address_line_1}`,
            address.address_line_2 && `${f("common.address.addressline2")}: ${address.address_line_2}`,
            address.postal_code && `${f("common.address.postal.code")}: ${address.postal_code}`,
            address.city && `${f("common.address.city")}: ${address.city}`,
            address.country && `${f("common.address.country")}: ${getCountryName(address.country)}`,
          ]
            .filter((e) => !!e)
            .map((e) => <div key={e}>{e}</div>)
        : [
            address.address_line_1,
            address.address_line_2,
            address.postal_code,
            address.city,
            getCountryName(address.country),
          ]
            .filter((e) => !!e)
            .join(", ")}
    </>
  ) : (
    <>{f("not.specified")}</>
  );
};

export default AddressInfo;
