import React from "react";
import { Card, DataTable, Page, SkeletonBodyText, Stack, TextStyle } from "@shopify/polaris";

import PlusMajorIcon from "../../../assets/icons/plus-major.svg";
import Button from "../../components/extensions/Button";
import Icon from "../../components/extensions/Icon";
import TableCellChild from "../../components/extensions/TableCellChild";
import useFormatMessage from "../../hooks/useFormatMessage";

const AdminLabelsSkeleton: React.FC = () => {
  const f = useFormatMessage();

  return (
    <Page
      title={f("labels.page.title")}
      primaryAction={
        <Button disabled icon={<Icon source={PlusMajorIcon} width="14px" height="14px" />}>
          {f("labels.modal.title.add-label")}
        </Button>
      }
    >
      <Stack vertical>
        <Card>
          <DataTable
            columnContentTypes={["text", "numeric"]}
            headings={[<TextStyle variation="subdued">{f("table.column.name")}</TextStyle>, ""]}
            rows={Array(4).fill([
              <SkeletonBodyText lines={1} />,
              <TableCellChild width="10%">
                <SkeletonBodyText lines={1} />
              </TableCellChild>,
            ])}
          />
        </Card>
      </Stack>
    </Page>
  );
};

export default AdminLabelsSkeleton;
