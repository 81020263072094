import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, RouteComponentProps } from "react-router-dom";
import { Button,Card, DisplayText, Layout, Page, Stack } from "@shopify/polaris";

import api from "../../api";
import PersonDisclosureForm from "../../components/disclosure/PersonDisclosureForm";
import ErrorPanel from "../../components/ErrorPanel";
import BackButton from "../../components/navigation/BackButton";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IDParams } from "../../types/params";
import { getFullName } from "../../utils/displayUtils";
import Util from "../../utils/util";

import DisclosureSkeleton from "./PersonDisclosureSkeleton";

const PersonRequestDisclosure: React.FC<RouteComponentProps<IDParams>> = ({ history, match }) => {
  const id = match.params.id;

  const queryClient = useQueryClient();
  const f = useFormatMessage();

  const [error, setError] = useState<unknown>();
  const [email, setEmail] = useState<string | undefined>();
  const [isSystemEmailEnabled, setSystemEmailEnabled] = useState(true);
  const [isFormValid, setFormValid] = useState(true);

  // Perform form validation when `email` or `isSystemEmailEnabled` changes state
  useEffect(() => {
    setFormValid(!isSystemEmailEnabled || Util.isEmail(email));
  }, [email, isSystemEmailEnabled]);

  const mutation = useMutation(
    () => api.createPersonCustomerDisclosureDocument(id, { email: email, send_email: isSystemEmailEnabled }),
    {
      onSuccess: async (response) => {
        // Retrieve the person details for the current customer and add the new disclosure document
        const customerData = queryClient.getQueryData<api.PersonCustomerDetailResponse>(["person-details", id]);
        if (customerData) {
          // Add the newly created disclosure document to the start of the array
          customerData.disclosure_documents.unshift(response);
          queryClient.setQueryData(["person-details", id], customerData);
        }

        await queryClient.invalidateQueries(["person-request-disclosure", id]);
        history.push(`/persons/${id}#${PAGE_ANCHORS.DISCLOSURES_SECTION}`);
      },
    }
  );

  const { isLoading, isError, error: fetchError, data } = useQuery(["person-request-disclosure", id], () =>
    Promise.all([api.getPersonCustomerDetails(id), api.getPersonSignatureConfiguration(id)])
  );

  useEffect(() => {
    if (isError) {
      setError(fetchError);
    }
  }, [isError, fetchError]);

  if (isLoading || !data) {
    return <DisclosureSkeleton />;
  }

  const person = data[0];

  return (
    <Page>
      <Stack vertical>
        <Layout>
          <Layout.Section>
            <Stack vertical>
              <BackButton
                url={generatePath(ROUTES.PERSON_DETAILS, { id: id }) + `#${PAGE_ANCHORS.DISCLOSURES_SECTION}`}
                displayLabel={getFullName(person)}
                showAsLink
              />

              <DisplayText size="large">{f("disclosure-request.title")}</DisplayText>
              <br />

              <Card>
                <Card.Section>
                  <PersonDisclosureForm
                    isSystemEmailEnabled={isSystemEmailEnabled}
                    email={email}
                    person={person!}
                    onSystemEmailChanged={(value) => {
                      setSystemEmailEnabled(value);
                    }}
                    onEmailChanged={(value) => {
                      setEmail(value);
                    }}
                  />
                </Card.Section>
              </Card>

              {error && <ErrorPanel message={error} />}
              {mutation.isError && <ErrorPanel message={mutation.error} />}

              <Stack distribution="trailing">
                <BackButton
                  url={generatePath(ROUTES.PERSON_DETAILS, { id: id }) + `#${PAGE_ANCHORS.DISCLOSURES_SECTION}`}
                />
                <Button
                  disabled={mutation.isLoading || !isFormValid}
                  loading={isLoading}
                  primary
                  onClick={() => mutation.mutate()}
                >
                  {f("disclosure-request.button.send")}
                </Button>
              </Stack>
            </Stack>
          </Layout.Section>
        </Layout>
      </Stack>
    </Page>
  );
};

export default PersonRequestDisclosure;
