import enCountries from "i18n-iso-countries/langs/en.json";
import nbCountries from "i18n-iso-countries/langs/nb.json";

const convertLocaleCountries = (countries: Record<string, string[] | string>) =>
  Object.keys(countries).map((key) => ({
    name: Array.isArray(countries[key]) ? (countries[key] as string[]).join(",") : (countries[key] as string),
    code: key,
  }));

const COUNTRIES: Record<string, ReturnType<typeof convertLocaleCountries>> = {
  // this has to be repeated for all locales we want to be supported
  nb: convertLocaleCountries(nbCountries.countries),
  en: convertLocaleCountries(enCountries.countries),
};

export default COUNTRIES;
