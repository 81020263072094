import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox, Stack } from "@shopify/polaris";

import api from "../../api";
import RoleTypes from "../../constants/role-types";
import useFormatMessage from "../../hooks/useFormatMessage";
import { getFullName } from "../../utils/displayUtils";
import { transformDate } from "../../utils/transformers";
import { noop } from "../../utils/util";
import CustomDate from "../CustomDate";
import DisplayName from "../DisplayName";
import Badge from "../extensions/Badge";

import EditInfoButton from "./EditInfoButton";

const isRole = (entity: api.Role | api.Owner): entity is api.Role => (entity as api.Role).is_new !== undefined;

const useRoleOrOwnerRow = () => {
  const f = useFormatMessage();

  return ({
    entity,
    disabled,
    checked,
    allowUpdates,
    onEdit = noop,
    onCheckboxChange = noop,
  }: {
    entity: api.Role | api.Owner;
    disabled?: boolean;
    checked?: boolean;
    allowUpdates?: boolean;
    onEdit?(id: string): void;
    onCheckboxChange?(id: string, checked: boolean): void;
  }) => {
    const isOwner = !isRole(entity);
    const id = entity.id!;
    const isNew = isOwner ? false : entity.is_new;
    const isManuallyCreated = Boolean(entity.created_by);
    const isManuallyUpdated = Boolean(entity.updated_by);

    return {
      checkbox: (
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={(newValue) => {
            onCheckboxChange(id, newValue);
          }}
          labelHidden
          label=""
        />
      ),
      stack: (
        <Stack alignment="center">
          <DisplayName
            type="person"
            name={getFullName(entity.person)}
            birthDate={transformDate(entity.person.birth_date, entity.person.birth_year)}
            comment={entity.comment}
          />
          {allowUpdates && isNew ? (
            <Badge size="small" status="info">
              <FormattedMessage id="default.new" /> ({<CustomDate date={entity.updated_dt} />})
            </Badge>
          ) : null}
          {isManuallyCreated ? (
            <Badge status="info" size="small">
              <FormattedMessage id="screening.badges.manually-defined" />
            </Badge>
          ) : null}
          {!isManuallyCreated && isManuallyUpdated ? (
            <Badge status="info" size="small">
              <FormattedMessage id="screening.badges.modified" />
            </Badge>
          ) : null}
        </Stack>
      ),
      div: <div>{isOwner ? `${entity.percent_share}%` : f(RoleTypes[entity.type])}</div>,
      editBtn: <EditInfoButton onClick={() => onEdit(id)} />,
    };
  };
};

export default useRoleOrOwnerRow;
