import * as React from "react";
import { Banner,Card, Layout, Page, SkeletonBodyText, SkeletonDisplayText, Stack } from "@shopify/polaris";

function DisclosureSkeleton() {
  return (
    <Page>
      <Stack vertical>
        <Layout>
          <Layout.Section>
            <Stack vertical>
              <SkeletonDisplayText />
              <SkeletonBodyText lines={1} />
              <Banner status="info">
                <SkeletonBodyText lines={1} />
              </Banner>
            </Stack>
          </Layout.Section>
          <Layout.Section secondary></Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section>
            <Stack vertical>
              <Card>
                <Card.Section>
                  <SkeletonBodyText lines={5} />
                </Card.Section>
              </Card>
              <Stack distribution="trailing"></Stack>
            </Stack>
          </Layout.Section>
          <Layout.Section secondary></Layout.Section>
        </Layout>
      </Stack>
    </Page>
  );
}

export default DisclosureSkeleton;
