export enum ROUTES {
  HOME = "/",
  ADMIN_USERS = "/admin/users",
  ADMIN_LABELS = "/admin/labels",
  COMPANY_DETAILS = "/companies/:id",
  PERSON_DETAILS = "/persons/:id",
  MATCH_CASE_DETAILS = "/customers/:customerId/match-cases/:id",
  COMPANY_ONBOARDING_SCREENING = "/companies/:id/screening",
  COMPANY_ONBOARDING_DISCLOSURES = "/companies/:id/request-onboarding-disclosures",
  COMPANY_ONBOARDING_SUMMARY = "/companies/:id/summary",
  COMPANY_REQUEST_DISCLOSURES = "/companies/:id/request-disclosure",
  PERSON_REQUEST_DISCLOSURES = "/persons/:id/request-disclosure",
  PERSON_ONBOARDING_NEW = "/person",
  PERSON_ONBOARDING = "/persons/:id/edit",
  PERSON_ONBOARDING_SCREENING = "/persons/:id/screening",
  PERSON_ONBOARDING_DISCLOSURES = "/persons/:id/request-onboarding-disclosure",
  PERSON_ONBOARDING_SUMMARY = "/persons/:id/summary",
  MANUAL_COMPANY_ONBOARDING_EDIT = "/manual-companies/:id/edit",
  MANUAL_COMPANY_ONBOARDING_SCREENING = "/manual-companies/:id/screening",
  MANUAL_COMPANY_ONBOARDING_DISCLOSURES = "/manual-companies/:id/request-onboarding-disclosures",
  MANUAL_COMPANY_ONBOARDING_SUMMARY = "/manual-companies/:id/summary",
  CUSTOMERS = "/customers",
  COMPANY_LOOKUP = "/lookup",
  COMPANY_LOOKUP_RESULT = "/lookup/:orgId",
  PROJECTS_LIST = "/projects",
  PROJECT_DETAILS = "/project/:id",
  ACCESS_DENIED = "/denied",
  EXPIRED = "/expired",
}

// list of person onboarding steps urls - in order
export const PERSON_ONBOARDING_ROUTES = [
  ROUTES.PERSON_ONBOARDING,
  ROUTES.PERSON_ONBOARDING_SCREENING,
  ROUTES.PERSON_ONBOARDING_DISCLOSURES,
  ROUTES.PERSON_ONBOARDING_SUMMARY,
];

// list of company onboarding steps urls - in order
export const COMPANY_ONBOARDING_ROUTES = [
  ROUTES.COMPANY_ONBOARDING_SCREENING,
  ROUTES.COMPANY_ONBOARDING_DISCLOSURES,
  ROUTES.COMPANY_ONBOARDING_SUMMARY,
];

// list of manual company onboarding steps urls - in order
export const MANUAL_COMPANY_ONBOARDING_ROUTES = [
  ROUTES.MANUAL_COMPANY_ONBOARDING_EDIT,
  ROUTES.MANUAL_COMPANY_ONBOARDING_SCREENING,
  ROUTES.MANUAL_COMPANY_ONBOARDING_DISCLOSURES,
  ROUTES.MANUAL_COMPANY_ONBOARDING_SUMMARY,
];
