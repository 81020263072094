import { useState } from "react";

const useOpenClose = (initialValue = false): [boolean, () => void, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialValue);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const toggle = () => setIsOpen((preValue) => !preValue);

  return [isOpen, toggle, close, open];
};

export default useOpenClose;
