import React, { useMemo, useState } from "react";
import {
  ActionList,
  ActionListItemDescriptor,
  Button,
  FormLayout,
  Icon,
  InlineError,
  Popover,
  Stack,
} from "@shopify/polaris";
import { TickSmallMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import api from "../api";
import useSelectUser from "../hooks/useSelectUser";
import { getFullName } from "../utils/displayUtils";

import UserInitials from "./UserInitials";

export interface UserSelectProps {
  updateUser?: (user: api.User) => Promise<api.User | undefined>;
  onUserSelection?(user: api.User): void;
  currentUser?: api.User;
  readonly?: boolean;
  emptySelectionLabel: string;
  updateErrorMessage: string;
}

const UserSelect: React.FC<UserSelectProps> = (props) => {
  const { updateUser, onUserSelection, currentUser, readonly, emptySelectionLabel, updateErrorMessage } = props;

  const [active, setActive] = useState(false);

  const toggleActive = () => setActive((active) => !active);
  const close = () => setActive(false);

  const { loading, disabled, hasError, selectedUser, selectUser, users } = useSelectUser(
    updateUser || Promise.resolve,
    currentUser
  );

  const userDisplayName = selectedUser ? getFullName(selectedUser) : emptySelectionLabel;

  const mapUserToActionItem = (user: api.User): ActionListItemDescriptor => ({
    content: getFullName(user),
    suffix: user.id === selectedUser?.id ? <Icon source={TickSmallMinor} /> : null,
    active: user.id === selectedUser?.id,
    disabled: user.is_active === false,
    onAction: () => {
      if (updateUser) {
        selectUser(user);
      } else if (onUserSelection) {
        onUserSelection(user);
      }
      close();
    },
  });

  const actionItems = useMemo(() => {
    return users.filter((user) => user.is_active || user.id === selectedUser?.id).map(mapUserToActionItem);
  }, [users, selectedUser?.id]);

  const activator = (
    <Stack spacing="extraTight" alignment="center">
      <UserInitials user={selectedUser} />
      <StyledButtonWrapper>
        <Button
          id="user-select"
          onClick={toggleActive}
          size="slim"
          plain
          // this is to fix a bug with @polaris that hides the loading indicator if button is monochrome
          monochrome={!loading}
          loading={loading}
          disabled={disabled || readonly}
          disclosure={active ? "up" : "down"}
        >
          {userDisplayName}
        </Button>
      </StyledButtonWrapper>
    </Stack>
  );

  return (
    <FormLayout>
      <Popover active={active} activator={activator} onClose={close}>
        <ActionList items={actionItems} />
      </Popover>
      {hasError && <InlineError message={updateErrorMessage} fieldID="user-select" />}
    </FormLayout>
  );
};

const StyledButtonWrapper = styled.div`
  & .Polaris-Button .Polaris-Button__Text {
    text-decoration: none;
  },
`;

export default UserSelect;
