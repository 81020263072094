import React from "react";
import Toggle, { ToggleProps } from "react-toggle";
import { Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import "react-toggle/style.css";

interface ToggleInputProps extends ToggleProps {
  label?: string;
}

const ToggleInput: React.FC<ToggleInputProps> = ({ label, icons, ...props }) => {
  return (
    <Stack spacing="tight" alignment="center" wrap={false}>
      <StyledToggle id="toggle" icons={false} {...props} />
      {label && (
        <Stack.Item fill>
          <TextStyle>{label}</TextStyle>
        </Stack.Item>
      )}
    </Stack>
  );
};

const StyledToggle = styled(Toggle)`
  &.react-toggle {
    transform: translateY(0.2rem);
  }

  // Toggle Off
  &.react-toggle .react-toggle-track {
    background-color: #f3f3f3;
    border: 2px solid #8c9196;
    width: 34px;
    height: 18px;
  }
  &.react-toggle .react-toggle-thumb {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    background-color: #8c9196;
    border: none;
  }
  &.react-toggle:hover .react-toggle-track {
    background-color: #f3f3f3;
  }

  // Toggle On
  &.react-toggle--checked .react-toggle-track {
    background-color: white;
    border-color: #2c6ecb;
  }
  &.react-toggle--checked .react-toggle-thumb {
    background-color: #2c6ecb;
    left: 19px;
  }
  &.react-toggle--checked:hover .react-toggle-track {
    background-color: white;
  }

  // Remove the box shadows on active/focus
  &.react-toggle--focus .react-toggle-thumb {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  &.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
`;

export default ToggleInput;
