import React, { useCallback, useState } from "react";
import { Autocomplete, TextFieldProps } from "@shopify/polaris";

import api from "../api";
import useCountries from "../hooks/useCountries";
import useFormatMessage from "../hooks/useFormatMessage";

type CountrySelectProps = Omit<TextFieldProps, "autoComplete" | "value"> & {
  value?: api.CountryEnum;
  autoComplete?: "on" | "off";
  onSelect(country?: api.CountryEnum): void;
  excludedCountries?: api.CountryEnum[];
};

const CountrySelect: React.FC<CountrySelectProps> = (props) => {
  const {
    value,
    label,
    autoComplete,
    placeholder,
    clearButton,
    onClearButtonClick,
    excludedCountries,
    onSelect,
    ...textFieldProps
  } = props;

  const f = useFormatMessage();

  const { getCountriesOptions, getCountryName } = useCountries();
  const deselectedOptions = getCountriesOptions(excludedCountries);

  const [selectedOptions, setSelectedOptions] = useState<string[]>(value ? [value] : []);
  const [inputValue, setInputValue] = useState<string>(value ? getCountryName(value) : "");
  const [options, setOptions] = useState(deselectedOptions);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) => option.label.match(filterRegex));
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const updateSelection = useCallback(
    (selected: api.CountryEnum[]) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => option.value.match(selectedItem));
        return matchedOption?.label;
      });

      setSelectedOptions(selected);
      onSelect(selected[0]);
      setInputValue(selectedValue[0] || "");
    },
    [options]
  );

  const clearInput = () => {
    updateText("");
    onSelect();
  };

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      {...textFieldProps}
      label={label || f("common.address.country")}
      value={inputValue}
      autoComplete={autoComplete || "off"}
      placeholder={placeholder || f("common.country-select.choose-country-option")}
      clearButton={clearButton !== false}
      onClearButtonClick={onClearButtonClick || clearInput}
    />
  );

  return <Autocomplete options={options} selected={selectedOptions} onSelect={updateSelection} textField={textField} />;
};

export default CountrySelect;
