import React from "react";
import { Banner, Button, Stack, TextContainer } from "@shopify/polaris";

import ChevronIcon from "../../../assets/icons/chevron-right.svg";
import api from "../../api";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import useFormatMessage from "../../hooks/useFormatMessage";
import Util from "../../utils/util";
import Icon from "../extensions/Icon";

import useCompanyHasCriticalUpdates from "./useCompanyHasCriticalUpdates";

interface CriticalUpdatesBannerProps {
  companyDetails: api.CompanyCustomerDetailResponse;
}

const CriticalUpdatesBanner: React.FC<CriticalUpdatesBannerProps> = (props) => {
  const { companyDetails } = props;

  const f = useFormatMessage();
  const hasCriticalUpdates = useCompanyHasCriticalUpdates(companyDetails);

  const navigateToDetailsSection = () => Util.scrollTo(PAGE_ANCHORS.CUSTOMER_DETAILS, { behavior: "smooth" });

  if (!hasCriticalUpdates) {
    return null;
  }

  return (
    <Banner status="critical" title={f("details.banners.critical-update.title")}>
      <TextContainer>
        <p>{f("details.banners.critical-update.content")}</p>
        <Stack spacing="extraTight" alignment="center">
          <Button plain onClick={navigateToDetailsSection}>
            {f("details.banners.critical-update.link-label")}
          </Button>
          <Icon source={ChevronIcon} width="8px" height="14px" />
        </Stack>
      </TextContainer>
    </Banner>
  );
};

export default CriticalUpdatesBanner;
