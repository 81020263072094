import { useQuery, useQueryClient } from "react-query";

import api from "../api";
import RetryError from "../types/RetryError";

const MAX_RETRIES = 10;

const useGetCompanyScreeningConfiguration = (id: string) => {
  const queryClient = useQueryClient();

  const queryKey = ["company-screening", id];

  const { isLoading, isError, error, failureCount, ...rest } = useQuery(
    queryKey,
    async () => {
      const response = await api.getCompanyScreeningConfiguration(id);

      if (!response?.beneficial_owners_fetched || !response?.roles_fetched) {
        throw new RetryError("Fetching roles and owners again.");
      }

      return Promise.resolve(response);
    },
    { retry: MAX_RETRIES, retryDelay: 5_000 }
  );

  const loadingProgress = isLoading ? Math.round((failureCount / MAX_RETRIES) * 100) : 100;

  const invalidateQuery = () => queryClient.invalidateQueries(queryKey);

  const isMaxRetriesReached = isError && error instanceof RetryError;

  return { isLoading, failureCount, isError, error, isMaxRetriesReached, loadingProgress, invalidateQuery, ...rest };
};

export default useGetCompanyScreeningConfiguration;
