import React, { useState } from "react";
import { Heading, Stack } from "@shopify/polaris";

import api from "../../../api";
import { PAGE_ANCHORS } from "../../../constants/page-anchors";
import useFormatMessage from "../../../hooks/useFormatMessage";
import Util from "../../../utils/util";

import ProjectReviewCard from "./ProjectReviewCard";
import ProjectReviewForm from "./ProjectReviewForm";

interface CustomerReviewsSectionProps {
  projectId: string;
  reviews: api.ProjectReview[];
  readonly?: boolean;
}

const ProjectReviewsSection: React.FC<CustomerReviewsSectionProps> = (props) => {
  const { projectId, reviews, readonly } = props;

  const [existingReviews, setExistingReviews] = useState<api.ProjectReview[]>(reviews);

  const f = useFormatMessage();

  const onCreateReview = (review: api.ProjectReview) => {
    setExistingReviews([review].concat(existingReviews));
    requestAnimationFrame(() => {
      Util.scrollTo(PAGE_ANCHORS.PROJECT_NOTES);
    });
  };

  return (
    <Stack vertical>
      <Heading>{f("project.notes.title")}</Heading>
      <ProjectReviewForm projectId={projectId} onAfterReviewCreated={onCreateReview} readonly={readonly} />
      <Stack vertical wrap={false} spacing="loose">
        {existingReviews.map((review) => (
          <ProjectReviewCard review={review} key={review.id} />
        ))}
      </Stack>
    </Stack>
  );
};

export default ProjectReviewsSection;
