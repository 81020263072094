import { useMemo } from "react";
import { useIntl } from "react-intl";

import COUNTRIES from "../utils/countries";

const useCountries = () => {
  const intl = useIntl();

  const localeCountries = useMemo(() => {
    return COUNTRIES[intl.locale];
  }, [intl.locale]);

  const allCountriesOptions = useMemo(() => {
    const selectOptions = localeCountries.map((country) => ({
      label: country.name,
      value: country.code,
      disabled: false,
    }));

    return selectOptions;
  }, [localeCountries]);

  const getCountriesOptions = (excludedCountries?: string[]) => {
    let countryOptions = allCountriesOptions;

    if (excludedCountries) {
      countryOptions = countryOptions.filter((countryOption) =>
        typeof countryOption === "string"
          ? !excludedCountries.includes(countryOption)
          : !excludedCountries.includes(countryOption.value)
      );
    }

    return countryOptions;
  };

  /**
   * Gets country name (in the current locale) given a country code
   * If no country is found for the country code it returns the country code
   */
  const getCountryName = (countryCode: string) => {
    const country = localeCountries.find((country) => country.code === countryCode);
    return country ? country.name : countryCode;
  };

  return {
    getCountriesOptions,
    getCountryName,
  };
};

export default useCountries;
