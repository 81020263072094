import api from "../api";
import { Customer, CustomerDetails, isPersonCustomer } from "../types/utilities";

import { firstLetter } from "./stringUtils";

export const getFullName = (person?: { first_name?: string; last_name?: string }) =>
  `${person?.first_name || ""} ${person?.last_name || ""}`;

export const getBirthDate = (person?: { birth_date?: string; birth_year?: string }) =>
  person?.birth_date || person?.birth_year;

export const getUserInitials = (user?: { first_name?: string; last_name?: string }) =>
  `${firstLetter(user?.first_name)}${firstLetter(user?.last_name)}`;

export const getCompanyDisplayName = (company: api.CompanyCustomer) => `${company.name || company.national_id}`;

export const getCustomerName = (customer: Customer | CustomerDetails) =>
  isPersonCustomer(customer) ? getFullName(customer) : getCompanyDisplayName(customer);

export const getAddressDisplayLabel = (address?: api.Address) => {
  if (!address) {
    return "";
  }

  return [
    address.address_line_1,
    address.address_line_2,
    address.postal_code,
    address.city,
    address.county,
    address.country,
  ]
    .filter(Boolean)
    .join(", ");
};

export const getMatchAddressDisplayLabel = (address?: api.MatchAddress) => {
  if (!address) {
    return "";
  }

  return [address.addr1, address.addr2, address.city, address.state_prov, address.country_code]
    .filter(Boolean)
    .join(", ");
};
