import React from "react";
import { useMutation, useQueryClient } from "react-query";

import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";
import { Customer } from "../types/utilities";
import { getCustomerName } from "../utils/displayUtils";

import Confirm from "./modals/Confirm";

interface CustomerArchiveConfirmationProps {
  customer: Customer;
  isMonitoring: boolean;
  open: boolean;
  onClose(): void;
}

const CustomerArchiveConfirmation: React.FC<CustomerArchiveConfirmationProps> = (props) => {
  const { customer, isMonitoring, open, onClose } = props;

  const queryClient = useQueryClient();
  const archiveCustomerMutation = useMutation(() => api.archiveCustomer(customer.id), {
    onSuccess: () => {
      const queryKey = [customer.type === "company" ? "company-details" : "person-details", customer.id];

      const queryData = queryClient.getQueryData<api.CompanyCustomerDetailResponse>(queryKey);
      if (queryData) {
        queryData.is_archived = true;
      }

      queryClient.setQueryData(queryKey, queryData);
      closeModal();
    },
  });

  const closeModal = () => {
    archiveCustomerMutation.reset();
    onClose();
  };

  const archiveCustomer = () => archiveCustomerMutation.mutate();

  const f = useFormatMessage();
  const name = getCustomerName(customer);

  const content = isMonitoring
    ? f("archive-customer.confirmation-modal.description.monitoring", { name })
    : f("archive-customer.confirmation-modal.description", { name });

  return (
    <Confirm
      isOpen={open}
      title={f("archive-customer.confirmation-modal.title")}
      description={content}
      onYes={archiveCustomer}
      onNo={closeModal}
      actionTitle={f("archive-customer.confirmation-modal.labels.button.archive", { name })}
      isDestructive
      isActionInProgress={archiveCustomerMutation.isLoading}
      error={archiveCustomerMutation.error}
    />
  );
};

export default CustomerArchiveConfirmation;
