import React, { useState } from "react";
import { StaticContext } from "react-router";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Page, Stack, TextField, TextStyle } from "@shopify/polaris";

import ErrorPanel from "../../components/ErrorPanel";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import HistoryLocationState from "../../types/HistoryLocationState";

interface NewCompanyLookupProps extends RouteComponentProps<object, StaticContext, HistoryLocationState> {
  projectId?: string;
  projectName?: string;
}

const NewCompanyLookup: React.FC<NewCompanyLookupProps> = (props) => {
  const { history, projectId, projectName } = props;

  const f = useFormatMessage();

  const [value, setValue] = useState<string>();
  const [error, setError] = useState<string>();

  const createdFromProject = projectId && projectName ? { id: projectId, name: projectName } : undefined;

  const handleChange = (newValue: string) => {
    if (!/^(?=.*\d)[\d ]+$/.test(newValue)) {
      setError(f("lookup.error.wrong.orgid"));
    } else {
      setError(undefined);
    }
    setValue(newValue);
  };

  const handleChoiceKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && value && !error) {
      event.preventDefault();
      submit();
    }
  };

  const submit = () => {
    const lookupPath = generatePath(ROUTES.COMPANY_LOOKUP_RESULT, { orgId: stripOrgId(value) });
    history.push(lookupPath, { createdFromProject });
  };

  return (
    <Page title={f("new-company-customer.titles.norwegian")}>
      <Stack vertical>
        <TextStyle>{f("lookup.search.title")}</TextStyle>
        <div onKeyDown={handleChoiceKeyDown}>
          <TextField
            id="lookup-input"
            autoFocus
            onChange={handleChange}
            value={value}
            placeholder={f("lookup.input.placeholder")}
            label=""
            labelHidden
            autoComplete="off"
          />
        </div>

        {error && <ErrorPanel message={error} fieldID="lookup-input" />}

        <Stack distribution="trailing">
          <Button primary disabled={!value || Boolean(error)} onClick={submit}>
            {f("lookup.button.search")}
          </Button>
        </Stack>
      </Stack>
    </Page>
  );
};

const stripOrgId = (id?: string) => (id ? id.replace(/\W/g, "") : "");

export default withRouter(NewCompanyLookup);
