import React, { useState } from "react";
import { ActionList, ActionListItemDescriptor, Popover, Tooltip } from "@shopify/polaris";

import FileIcon from "../../../../assets/icons/file.svg";
import api from "../../../api";
import useAttachmentDownload from "../../../hooks/useAttachmentDownload";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { Entity } from "../../../types/utilities";
import { hasSignedAttachment } from "../../../utils/attachmentUtils";
import Util from "../../../utils/util";
import Button from "../../extensions/Button";

interface AttachmentDownloadButtonProps {
  entity: Entity;
  attachment: api.AttachmentDetails;
}

const AttachmentDownloadButton: React.FC<AttachmentDownloadButtonProps> = (props) => {
  const { entity, attachment } = props;

  const [showMenu, setShowMenu] = useState(false);

  const toggleShowMenu = () => setShowMenu(!showMenu);

  const f = useFormatMessage();
  const downloadAttachmentMutation = useAttachmentDownload(entity);

  const isLoading = downloadAttachmentMutation.isLoading;
  const filename = Util.cleanupFilename(attachment.filename);

  const showDownloadMenu = hasSignedAttachment(attachment);

  const downloadOriginalAttachmentActionItem: ActionListItemDescriptor = {
    content: f("attachments.actions.download-attachment"),
    onAction: () => downloadAttachmentMutation.mutate({ attachment }),
    disabled: isLoading,
  };

  const downloadSignedAttachmentActionItem: ActionListItemDescriptor = {
    content: f("attachments.actions.download-signed-attachment"),
    onAction: () => downloadAttachmentMutation.mutate({ attachment, downloadSignedAttachment: true }),
    disabled: isLoading,
  };

  const handleDownloadButtonClick = () => {
    showDownloadMenu ? toggleShowMenu() : downloadOriginalAttachmentActionItem.onAction!();
  };

  const downloadUnsignedAttachButton = (
    <Tooltip content={filename} preferredPosition="above">
      <Button
        plain
        icon={<img src={FileIcon} />}
        truncate
        textAlign="left"
        disclosure={showDownloadMenu}
        loading={isLoading}
        onClick={handleDownloadButtonClick}
      >
        {filename}
      </Button>
    </Tooltip>
  );

  if (showDownloadMenu) {
    return (
      <Popover active={showMenu} activator={downloadUnsignedAttachButton} onClose={toggleShowMenu}>
        <ActionList items={[downloadOriginalAttachmentActionItem, downloadSignedAttachmentActionItem]} />
      </Popover>
    );
  } else {
    return downloadUnsignedAttachButton;
  }
};

export default AttachmentDownloadButton;
