import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Card, Checkbox, RadioButton, Stack, TextField, TextStyle } from "@shopify/polaris";

import api from "../../api";
import ErrorPanel from "../../components/ErrorPanel";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import useFormatMessage from "../../hooks/useFormatMessage";
import Util from "../../utils/util";

const DEFAULT_REVIEW: api.CreateMatchReviewRequest = {
  is_match: false,
  is_pep: false,
  is_sanctioned: false,
  negative_news: false,
  other: false,
  description: "",
};

type CreateMatchReviewRequestValue = api.CreateMatchReviewRequest[keyof api.CreateMatchReviewRequest];

interface MatchReviewFormProps {
  customerId: string;
  id: string;
  readonly: boolean;
  lastReview?: api.MatchReview;
}

const MatchReviewForm: React.FC<MatchReviewFormProps> = (props) => {
  const { customerId, id, readonly, lastReview } = props;

  const { is_match, is_pep, is_sanctioned, negative_news, other } = lastReview || DEFAULT_REVIEW;

  const initialValue = {
    is_match,
    is_pep,
    is_sanctioned,
    negative_news,
    other,
    description: "", // do not copy the description
  };

  const [review, setReview] = useState<api.CreateMatchReviewRequest>(initialValue);
  const [isTouched, setIsTouched] = useState(lastReview !== undefined);

  const f = useFormatMessage();
  const queryClient = useQueryClient();

  const reset = () => {
    saveReviewMutation.reset();
    setReview({ ...DEFAULT_REVIEW });
    setIsTouched(false);
  };

  const saveReviewMutation = useMutation(() => api.createCompanyCustomerMatchReview(customerId, id, review), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["match-cases", customerId, id]);

      reset();

      requestAnimationFrame(() => {
        Util.scrollTo(PAGE_ANCHORS.REVIEWS_SECTION);
      });
    },
  });

  const handleReviewChange = (key: keyof api.CreateMatchReviewRequest, value: CreateMatchReviewRequestValue) => {
    const newReview = { ...review, [key]: value };

    if (key === "is_match" && !newReview.is_match) {
      newReview.is_pep = false;
      newReview.is_sanctioned = false;
      newReview.negative_news = false;
      newReview.other = false;
    }

    setIsTouched(true);
    setReview(newReview);
  };

  return (
    <Card key="review-card">
      <Card.Section title={f("match.title.confirm.match")}>
        <Stack>
          <RadioButton
            label={<TextStyle variation="strong">{f("matches.button.confirm.match")}</TextStyle>}
            checked={isTouched && review.is_match}
            id="true"
            name="approve"
            onChange={(_, newValue) => handleReviewChange("is_match", newValue == "true")}
            disabled={readonly}
          />
          <RadioButton
            label={<TextStyle variation="strong">{f("matches.button.decline.match")}</TextStyle>}
            id="false"
            name="approve"
            checked={isTouched && !review.is_match}
            onChange={(_, newValue) => {
              document.getElementById("text")?.focus();
              handleReviewChange("is_match", newValue == "true");
            }}
            disabled={readonly}
          />
        </Stack>
      </Card.Section>
      <Card.Section title={f("matches.titles.confirm.person")}>
        <Stack vertical wrap={false}>
          <Stack distribution="equalSpacing" wrap={false}>
            <Stack.Item fill>
              <Checkbox
                disabled={!review.is_match || readonly}
                label={<TextStyle variation="strong">{f("matches.labels.negative-news")}</TextStyle>}
                checked={review.negative_news}
                onChange={(checked) => handleReviewChange("negative_news", checked)}
                helpText={<p>{f("matches.labels.negative-news.helper-text")}</p>}
              />
            </Stack.Item>
            <Stack.Item fill>
              <Checkbox
                disabled={!review.is_match || readonly}
                label={<TextStyle variation="strong">{f("matches.labels.pep")}</TextStyle>}
                helpText={<p>{f("matches.labels.pep.helper-text")}</p>}
                checked={review.is_pep}
                onChange={(checked) => handleReviewChange("is_pep", checked)}
              />
            </Stack.Item>
            <Stack.Item fill>
              <Checkbox
                disabled={!review.is_match || readonly}
                label={<TextStyle variation="strong">{f("matches.labels.sanction")}</TextStyle>}
                helpText={<p>{f("matches.labels.sanction.helper-text")}</p>}
                checked={review.is_sanctioned}
                onChange={(checked) => handleReviewChange("is_sanctioned", checked)}
              />
            </Stack.Item>
            <Stack.Item fill>
              <Checkbox
                disabled={!review.is_match || readonly}
                label={<TextStyle variation="strong">{f("matches.labels.other")}</TextStyle>}
                helpText={<p>{f("matches.labels.other.helper-text")}</p>}
                checked={review.other}
                onChange={(checked) => handleReviewChange("other", checked)}
              />
            </Stack.Item>
          </Stack>

          <TextField
            value={review.description || ""}
            placeholder={f("matches.labels.review.description.placeholder")}
            onChange={(newValue) => handleReviewChange("description", newValue)}
            multiline={4}
            id="text"
            labelHidden
            label=""
            autoComplete="off"
            disabled={readonly}
          />

          {saveReviewMutation.isError && <ErrorPanel message={saveReviewMutation.error} />}

          <Stack distribution="trailing">
            <Button onClick={() => reset()} disabled={readonly}>
              {f("default.cancel")}
            </Button>
            <Button
              disabled={saveReviewMutation.isLoading || !isTouched || review.description === "" || readonly}
              loading={saveReviewMutation.isLoading}
              primary
              onClick={() => saveReviewMutation.mutate()}
            >
              {f("default.save")}
            </Button>
          </Stack>
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default MatchReviewForm;
