/** this util file has a ".tsx" extension since it has functions that return object with React component in them **/

import React from "react";
import { AppliedFilterInterface, ChoiceList, FilterInterface } from "@shopify/polaris";

export const createFilter = (
  key: string,
  label: string,
  value: string,
  disabled: boolean,
  choices: Array<{ label: string; value: string }>,
  onChange: (value: string) => void
): [filter: FilterInterface, appliedFilter: AppliedFilterInterface | undefined] => {
  const valueLabel = choices.find((choice) => choice.value === value)?.label || value;
  const displayLabel = `${label}${value === "all" ? "" : `: ${valueLabel}`}`;

  const filter: FilterInterface = {
    key,
    label,
    shortcut: true,
    disabled,
    hideClearButton: true,
    filter: (
      <ChoiceList
        title={label}
        titleHidden
        choices={choices}
        selected={[value]}
        onChange={(selected) => onChange(selected[0])}
      />
    ),
  };

  const appliedFilter: AppliedFilterInterface | undefined =
    value !== "all"
      ? {
          key,
          label: displayLabel,
          onRemove: () => onChange("all"),
        }
      : undefined;

  return [filter, appliedFilter];
};
