import React from "react";
import {
  ActionList,
  ActionListItemDescriptor,
  ActionListSection,
  ButtonGroup,
  ButtonProps,
  Popover,
  Stack,
} from "@shopify/polaris";
import { HorizontalDotsMinor } from "@shopify/polaris-icons";

import useFormatMessage from "../hooks/useFormatMessage";
import useOpenClose from "../hooks/useOpenClose";

import Button from "./extensions/Button";
import Icon from "./extensions/Icon";

interface ActionsDropdownProps extends Omit<ButtonProps, "icon"> {
  items: ActionListItemDescriptor[];
  sections?: ActionListSection[];
  hideTitle?: boolean;
  forceOpen?: boolean;
  icon?: string;
  title?: string;
  // the number of actions to render as individual buttons
  cutoff?: number;
}

const ActionsDropdown: React.FC<ActionsDropdownProps> = (props) => {
  const {
    items = [],
    sections,
    hideTitle,
    forceOpen = false,
    icon,
    title,
    size = "slim",
    cutoff = 0,
    ...buttonProps
  } = props;
  const [popoverActive, togglePopoverActive, closePopover] = useOpenClose();

  const f = useFormatMessage();

  const showButtonGroup = cutoff > 0;
  const showPopover = items.length - cutoff > 0;

  const actionsLink = (
    <div onClick={togglePopoverActive}>
      <Button
        icon={icon ? <Icon source={icon} width="12px" height="12px" /> : HorizontalDotsMinor}
        size={size}
        {...buttonProps}
      >
        {hideTitle ? undefined : title || f("common.buttons.actions.button")}
      </Button>
    </div>
  );

  return (
    <Stack spacing="tight">
      {showButtonGroup && (
        <ButtonGroup>
          {items.slice(0, cutoff).map((item, index) => (
            <Button key={index} onClick={item.onAction} size={size} icon={item.icon || item.image} {...item}>
              {item.content}
            </Button>
          ))}
        </ButtonGroup>
      )}
      {showPopover && (
        <Popover
          active={popoverActive || forceOpen}
          activator={actionsLink}
          onClose={closePopover}
          ariaHaspopup={false}
          sectioned={false}
          preferredAlignment="left"
        >
          <Popover.Pane>
            <ActionList items={items.slice(cutoff)} sections={sections} onActionAnyItem={closePopover} />
          </Popover.Pane>
        </Popover>
      )}
    </Stack>
  );
};

export default ActionsDropdown;
