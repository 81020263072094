import styled from "styled-components";

import EmailField from "../EmailField";
import EmailOrLinkButtonGroupInput from "../inputs/EmailOrLinkButtonGroupInput";

export const StyledEmailField = styled(EmailField)`
  & .Polaris-Labelled--hidden {
    min-width: 32.5rem;
  }
  & .Polaris-TextField {
    max-height: 34.5px;
  }
`;

export const StyledButtonGroup = styled(EmailOrLinkButtonGroupInput)`
  & .Polaris-ButtonGroup--segmented {
    margin-right: 2rem;
  }
`;
