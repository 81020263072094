import React from "react";
import { Card, DataTable, Page, SkeletonBodyText, Stack, TextStyle } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";

const UsersSkeleton: React.FC = () => {
  const f = useFormatMessage();

  return (
    <Page title={f("users.page.title")}>
      <Stack vertical>
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
              <TextStyle variation="subdued">{f("table.column.email")}</TextStyle>,
              <TextStyle variation="subdued">{f("table.column.group")}</TextStyle>,
              <TextStyle variation="subdued">{f("table.column.active")}</TextStyle>,
            ]}
            rows={Array(4).fill([
              <SkeletonBodyText key={1} lines={1} />,
              <SkeletonBodyText key={2} lines={1} />,
              <SkeletonBodyText key={3} lines={1} />,
              <SkeletonBodyText key={4} lines={1} />,
            ])}
          />
        </Card>
      </Stack>
    </Page>
  );
};

export default UsersSkeleton;
