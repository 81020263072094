import React, { ReactNode, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Card, Heading, Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import CalendarIcon from "../../../assets/icons/calendar.svg";
import CheckMarkGreenIcon from "../../../assets/icons/checkmark-green.svg";
import CrossMarkRedIcon from "../../../assets/icons/circle-cross.svg";
import WaitingIcon from "../../../assets/icons/status-waiting.svg";
import api from "../../api";
import useAuth from "../../hooks/useAuth";
import useFormatMessage from "../../hooks/useFormatMessage";
import { getFullName } from "../../utils/displayUtils";
import CustomDate from "../CustomDate";
import Icon from "../extensions/Icon";
import Risk from "../Risk";
import UserInitials from "../UserInitials";

import { Review } from "./types/Review";
import ApproveRejectReview from "./ApproveRejectReview";

const isReviewApproved = (r: api.CustomerReview) => !r.approval?.approved_by || r.approval?.is_approved;

interface ReviewsListProps {
  id: string;
  title: string;
  reviews: Review[];
  readonly?: boolean;
}

type ReviewStatus = "ACCEPTED" | "REJECTED" | "PENDING";

const getReviewStatus = (r: api.CustomerReview): ReviewStatus => {
  if (r.requires_approval && !r.approval?.approved_by) {
    return "PENDING";
  }

  return r.accepted ? "ACCEPTED" : "REJECTED";
};

const ReviewsList: React.FC<ReviewsListProps> = ({ id, title, reviews: outerReviews, readonly }) => {
  const [reviews, setReviews] = useState(outerReviews);
  const { user: currentUser } = useAuth();

  const f = useFormatMessage();

  React.useEffect(() => {
    setReviews(outerReviews);
  }, [outerReviews]);

  const fadeIt = (flag: boolean, children: ReactNode) => {
    return flag ? <Fade triggerOnce>{children}</Fade> : children;
  };

  const reviewsToDisplay = reviews.filter(isReviewApproved);

  if (reviewsToDisplay.length === 0) {
    return null;
  }

  return (
    <Stack vertical>
      <Heading>{title}</Heading>
      <Stack vertical wrap={false} spacing="loose">
        {reviewsToDisplay.map((review, i) => (
          <StyledReviewCard
            key={i}
            status={getReviewStatus(review)}
            isCurrentUser={review.reviewed_by?.email === currentUser?.email}
          >
            <Card>
              {fadeIt(
                review.isNew,
                <>
                  <Card.Section>
                    <Stack vertical>
                      <Stack alignment="center" distribution="fill">
                        <Stack alignment="center">
                          <Stack alignment="center" spacing="extraTight">
                            <Icon source={CalendarIcon} />
                            <CustomDate date={review.date} />
                          </Stack>
                          {review.reviewed_by && (
                            <Stack alignment="center" spacing="extraTight" wrap={false}>
                              <UserInitials user={review.reviewed_by} />
                              <TextStyle>{f("details.reviews.reviewed-by")}</TextStyle>
                              <TextStyle variation="strong">{getFullName(review.reviewed_by)}</TextStyle>
                            </Stack>
                          )}
                          {review.approval?.approved_by && (
                            <Stack alignment="center" spacing="extraTight" wrap={false}>
                              <UserInitials user={review.approval?.approved_by} />
                              <TextStyle>{f("details.reviews.confirmed-by")}</TextStyle>
                              <TextStyle variation="strong">{getFullName(review.approval?.approved_by)}</TextStyle>
                            </Stack>
                          )}
                        </Stack>

                        {/* pending message for current user */}
                        {getReviewStatus(review) === "PENDING" && review.reviewed_by?.email === currentUser?.email && (
                          <Stack distribution="trailing" alignment="center" spacing="extraTight" wrap={false}>
                            <Icon width="10px" height="12px" source={WaitingIcon} />
                            <ItalicText>{f("details.reviews.awaiting-approval")}</ItalicText>
                          </Stack>
                        )}

                        {/* review confirmation for different user */}
                        {getReviewStatus(review) === "PENDING" && review.reviewed_by?.email !== currentUser?.email && (
                          <ApproveRejectReview
                            id={id}
                            review={review}
                            readonly={readonly}
                            onServerResponse={(r) => {
                              reviews[i].approval = r;
                              reviews[i].date = new Date().toString();
                              reviews[i].isNew = true;

                              setReviews(reviews.concat([]));
                            }}
                          />
                        )}
                      </Stack>
                      <Stack alignment="center" spacing="extraTight">
                        <Icon source={review.accepted ? CheckMarkGreenIcon : CrossMarkRedIcon} />
                        <StyledReviewCardText accepted={review.accepted}>
                          {review.accepted ? f("details.reviews.accepted") : f("details.reviews.rejected")}
                        </StyledReviewCardText>
                        {review.accepted && <Risk risk={review.risk_level} displayFullLabel />}
                      </Stack>
                    </Stack>
                  </Card.Section>
                  <Card.Section subdued>{review.comment}</Card.Section>
                </>
              )}
            </Card>
          </StyledReviewCard>
        ))}
      </Stack>
    </Stack>
  );
};

const ItalicText = styled.p`
  font-style: italic;
`;

const StyledReviewCardText = styled.div<{ accepted?: boolean }>`
  ${({ accepted }) => accepted && "padding-right: 1.5rem"};
  ${({ accepted }) => accepted && "margin-right: 1rem"};
  ${({ accepted }) => accepted && "border-right: 1px solid #c1c1c1"};
`;

const StyledReviewCard = styled.div<{ status: ReviewStatus; isCurrentUser: boolean }>`
  & .Polaris-Card__Section {
    ${({ status, isCurrentUser }) => {
      switch (status) {
        case "ACCEPTED":
          return `
            background-color: #F4FFF6;
            border: 1px solid #5DBD92;
          `;
        case "REJECTED":
          return `
            background-color: #FFF6F4;
            border: 1px solid #ED6262;
          `;
        case "PENDING":
          return `
            border: 1px solid #AFAFAF;
          `;
      }
    }}
  }

  & .Polaris-Card__Section + .Polaris-Card__Section {
    border-top: none;
  }
`;

export default React.memo(ReviewsList);
