import { useQuery } from "react-query";

import api from "../api";

const useProjectLabels = () => {
  const getAllLabelsQuery = useQuery(["all-labels"], () => api.getAllLabels());

  return getAllLabelsQuery.data?.labels || [];
};

export default useProjectLabels;
