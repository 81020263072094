import React from "react";
import { ButtonGroup, Modal, Stack } from "@shopify/polaris";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import ErrorPanel from "../ErrorPanel";
import Button from "../extensions/Button";

export interface ConfirmProps {
  title: string;
  description: string;
  error?: string | unknown;
  onYes(): void;
  onNo(): void;
  isDestructive?: boolean;
  actionTitle: string;
  cancelTitle?: string;
  isActionInProgress?: boolean;
  isOpen?: boolean;
}

const Confirm: React.FC<ConfirmProps> = (props) => {
  const {
    title,
    actionTitle,
    cancelTitle,
    description,
    error,
    onYes,
    onNo,
    isActionInProgress,
    isDestructive = true,
    isOpen = true,
  } = props;

  const f = useFormatMessage();

  return (
    <Modal
      open={isOpen}
      onClose={onNo}
      title={
        <Stack distribution="center">
          <p>{title}</p>
        </Stack>
      }
      footer={
        <Stack distribution="center">
          <ButtonGroup spacing="loose">
            <Button onClick={onNo} disabled={isActionInProgress}>
              {cancelTitle || f("default.cancel")}
            </Button>
            <Button onClick={onYes} destructive={isDestructive} loading={isActionInProgress}>
              {actionTitle}
            </Button>
          </ButtonGroup>
        </Stack>
      }
    >
      <Modal.Section>
        <Stack vertical distribution="center">
          <StyledContent>{description}</StyledContent>
          {error && <ErrorPanel message={error} />}
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

const StyledContent = styled.p`
  white-space: pre-wrap;
  text-align: center;
`;

export default Confirm;
