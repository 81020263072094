import React from "react";
import { Stack, TextStyle } from "@shopify/polaris";

import ProjectIcon from "../../assets/icons/folder.svg";
import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";

import Icon from "./extensions/Icon";
import EntitiesResourceList, { EntitiesResourceListProps } from "./EntitiesResourceList";

type ProjectsResourceListProps = Pick<
  EntitiesResourceListProps<api.ProjectListItem>,
  "selectedIds" | "page" | "resetPage" | "onSelectionChange"
>;

const ProjectsResourceList: React.FC<ProjectsResourceListProps> = (props) => {
  const { ...resourceListProps } = props;

  const f = useFormatMessage();

  return (
    <EntitiesResourceList<api.ProjectListItem>
      getEntities={(searchParams) =>
        api.getProjects({ ...searchParams, status: "ACTIVE" }).then((response) => response.projects)
      }
      queryKey="projects"
      queryPlaceholder={f("projects.modal.filters.search.hint")}
      renderItem={(projectListItem) => (
        <Stack spacing="tight" alignment="center">
          <Icon source={ProjectIcon} />
          <TextStyle>{projectListItem.name}</TextStyle>
        </Stack>
      )}
      {...resourceListProps}
    />
  );
};

export default ProjectsResourceList;
