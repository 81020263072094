import { useEffect, useState } from "react";

interface RowType {
  id?: string;
}

export type Row<T extends RowType> = {
  isNew: boolean; // is the row a new row (that wasn't converted from the entities list)
  isCreating: boolean; // is the row a new row that wasn't added to the rows list - still in editing mode
  isEditing: boolean; // is the row currently being edited
  validationErrors: Map<keyof T, string>; // a map of validation errors: key = entity field name; value: error message
  isModified: boolean; // was the entity of the row modified (different from the original entity)
  isLocked: boolean; // is the row locked (which usually means one or more fields will render as readonly)
  entity: T; // the entity of the row
  originalEntity?: T; // a copy of the initial entity to be used to check for modifications
};

// converts an existing entity to a row
const convertToRow = <T extends RowType>(entity: T): Row<T> => ({
  isNew: false,
  isCreating: false,
  isEditing: false,
  isModified: false,
  isLocked: false,
  validationErrors: new Map<keyof T, string>(),
  entity,
  originalEntity: { ...entity },
});

interface useEditableRowsProps<T extends RowType> {
  entities: T[];
  createEmptyEntity: () => T;
  isValidEntity: (entity: Partial<T>, rows: Row<T>[]) => Map<keyof T, string>;
  isEqualEntity: (firstEntity: T, secondEntity: T) => boolean;
}

/**
 * This hook is used to manage a list of editable rows
 *
 * Each row holds an entity and additional properties to indicate the row state
 * The hook provides functions to add/modify/delete rows
 * and returns a list of the current rows
 *
 * @param props
 */
const useEditableRows = <T extends RowType>(props: useEditableRowsProps<T>) => {
  const { entities, createEmptyEntity, isValidEntity, isEqualEntity } = props;

  const [rows, setRows] = useState<Row<T>[]>(entities.map(convertToRow));

  useEffect(() => {
    if (JSON.stringify(entities) !== JSON.stringify(rows.map((row) => row.entity))) {
      setRows(entities.map(convertToRow));
    }
  }, [entities]);

  const validateRows = (rowsToValidate: Row<T>[] = rows) => {
    rowsToValidate.forEach((row) => {
      row.validationErrors = isValidEntity(row.entity, rowsToValidate);
    });
  };

  // creates a new empty row and add it to the rows list
  const createRow = (defaultValues?: Partial<T>, isLocked?: boolean) => {
    const row: Row<T> = {
      isNew: true,
      isCreating: true,
      isEditing: true,
      isModified: false,
      isLocked: Boolean(isLocked),
      validationErrors: new Map<keyof T, string>().set("id", ""), // default row is not valid
      entity: { ...createEmptyEntity(), ...defaultValues },
    };

    setRows((prevState) => [...prevState, row]);
  };

  const saveRow = (row: Row<T>) => {
    if (row.isCreating) {
      row.isCreating = false;
      row.isModified = true;
      row.originalEntity = { ...row.entity };
    }

    row.isEditing = false;
    setRow(row);
  };

  // removes the row to be deleted from the rows list
  const deleteRow = (row: Row<T>) => {
    setRows((prevState) => {
      const updatedRows = prevState.filter((currentRow) => currentRow.entity.id !== row.entity.id);

      validateRows(updatedRows);

      return updatedRows;
    });
  };

  // updates a property on the row's entity
  const updateProperty = (row: Row<T>, key: keyof T, value?: string | boolean) => {
    row.entity = { ...row.entity, [key]: value };
    row.isModified = row.originalEntity ? !isEqualEntity(row.entity, row.originalEntity) : true;

    validateRows();

    setRow(row);
  };

  // starts editing mode for the given row
  const editRow = (row: Row<T>) => {
    row.isEditing = true;

    setRow(row);
  };

  // cancel changes to the given row
  const revertEditRow = (row: Row<T>) => {
    row.isEditing = false;
    if (row.originalEntity) {
      row.entity = { ...row.originalEntity };
    }

    setRow(row);
  };

  // updates the rows list
  const setRow = (row: Row<T>) => {
    setRows(rows.map((currentRow) => (currentRow.entity.id === row.entity.id ? { ...row } : currentRow)));
  };

  return {
    rows,
    createRow,
    editRow,
    updateProperty,
    deleteRow,
    saveRow,
    revertEditRow,
  };
};

export default useEditableRows;
