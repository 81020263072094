import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { DisplayText, Heading, Modal, Stack, Toast } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../../api";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";
import { CustomerDetails, Entity } from "../../../types/utilities";
import { hasSignedAttachment, isAttachmentSigned, wasSignedAttachmentDeleted } from "../../../utils/attachmentUtils";
import { getFullName } from "../../../utils/displayUtils";
import Util, { noop } from "../../../utils/util";
import AddItemButton from "../../AddItemButton";
import ErrorPanel from "../../ErrorPanel";
import Confirm from "../../modals/Confirm";
import AttachmentSignaturesTable from "../table/AttachmentSignaturesTable";
import { AttachmentSignature } from "../types/AttachmentSignature";
import useAttachmentSignatures from "../useAttachmentSignatures";

import AttachmentSignaturesModalFooter from "./AttachmentSignaturesModalFooter";
import AttachmentSignedDocumentSection from "./AttachmentSignedDocumentSection";

/**
Save action label:
  When email is chosen for all recipients, the call to action is labeled "Send"
    - Clicking Send will send the emails and close the modal
    - Present a toast as a confirmation: "Emails sent" (Disappears after 4 seconds)

  When link is chosen for all recipients, the call to action is labeled "Create links"
    - Clicking Create links will not close the modal

  When email and link are mixed for the recipients, the call to action is labeled "Send emails and create links"
    - Clicking Send emails and create links will not close the modal

  When doing changes that does not involve sending emails / creating links, the call to action is labeled "Save"
    - Example: changing a recipient's name, turning on/off birth number authentication
    - Clicking Save will save and close the modal
    - Present a toast as a confirmation: "Changes saved" (Disappears after 4 seconds)

Cancel action label:
  When changes are saved, use the label "Close" instead of "Cancel"
 **/

type AttachmentSignaturesModalProps = {
  entity: Entity;
  attachment: api.AttachmentDetails;
  initialPerson?: api.PersonCustomer;
  fetchSuggestedSigners?: () => Promise<CustomerDetails[]>;
  readonly?: boolean;
  onModalClose(): void;
};

const AttachmentSignaturesModal: React.FC<AttachmentSignaturesModalProps> = (props) => {
  const { entity, attachment, initialPerson, fetchSuggestedSigners = noop, readonly, onModalClose } = props;

  const [savingError, setSavingError] = useState<unknown>();
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showSendEmailsToast, toggleSendEmailsToast, closeSendEmailsToast] = useOpenClose();
  const [isModalVisible, setModalVisible] = useState(true);

  const isSigned = isAttachmentSigned(attachment);
  const isAttachmentReadonly = readonly || isSigned;
  const showSignedDocumentSection = hasSignedAttachment(attachment) || wasSignedAttachmentDeleted(attachment);

  const f = useFormatMessage();

  const {
    isInitialEmpty,
    rows,
    isLoading,
    isSaving,
    isError,
    error,
    hasInvalidRows,
    hasUnsavedChanges,
    isEmailOnly,
    isLinkOnly,
    hasUnsentEmails,
    hasUnsavedLinks,
    createRow,
    saveChanges,
    ...rowsMethods
  } = useAttachmentSignatures(entity, attachment);

  const { data } = useQuery(["suggested-signers", entity.id], fetchSuggestedSigners, {
    enabled: fetchSuggestedSigners !== undefined,
  });

  const customers: CustomerDetails[] = data || [];

  useEffect(() => {
    // creates a default signature if the table is empty
    if (isInitialEmpty && !isAttachmentReadonly) {
      const initialValues: Partial<AttachmentSignature> = {
        fullName: getFullName(initialPerson),
        signer_id: initialPerson?.person_id,
        national_id: initialPerson?.national_id,
        send_email: true,
      };

      createRow(initialValues, initialPerson !== undefined);
    }
  }, [isInitialEmpty, initialPerson, isAttachmentReadonly]);

  const handleSaveChanges = async () => {
    try {
      setSavingError(undefined);
      setSavedSuccessfully(false);
      await saveChanges();
      setSavedSuccessfully(true);
      if (isEmailOnly && hasUnsentEmails) {
        // hide the modal
        setModalVisible(false);
        // show the success message toast
        toggleSendEmailsToast();
      }
    } catch (error: any) {
      try {
        // Try to handle some 422 (beaufort-api),
        // 400 (data-service) errors,
        // 409 error (conflict)
        if (error?.status === 422) {
          error?.data?.detail?.forEach((err: any) => {
            const field = err?.loc[2];
            switch (field) {
              case "first_name": {
                setSavingError(f("common.errors.invalid.first.name"));
                break;
              }
              case "last_name": {
                setSavingError(f("common.errors.invalid.last.name"));
                break;
              }
              case "email": {
                setSavingError(f("common.errors.invalid.email"));
                break;
              }
              case "national_id": {
                setSavingError(f("common.errors.invalid.person.national-id"));
                break;
              }
              default: {
                setSavingError("");
              }
            }
          });
        } else if (error?.status === 400) {
          const personErrors = error?.data?.subject?.person || error?.data?.person || [];
          if ("national_id" in personErrors) {
            setSavingError(f("common.errors.invalid.person.national-id"));
          } else {
            setSavingError(error);
          }
        } else if (error?.status === 409) {
          const errorDetails: string | undefined = error?.data?.detail;
          if (errorDetails?.includes("national_id")) {
            setSavingError(f("common.errors.person-national-id.exists"));
          } else {
            setSavingError(f("common.errors.person.exists"));
          }
        } else {
          setSavingError(error?.data?.detail || error);
        }
      } catch (errorHandlingError: unknown) {
        setSavingError(error);
      }
    }
  };

  const handleCancel = () => {
    if (hasUnsavedChanges) {
      setShowCancelConfirmation(true);
    } else {
      closeModal();
    }
  };

  const handleToastDismiss = () => {
    closeSendEmailsToast();
    closeModal();
  };

  const closeModal = () => {
    setSavingError(undefined);
    onModalClose();
  };

  const showTable = !isLoading && rows;
  const showError = isError || savingError;
  const isSaveDisabled = isLoading || hasInvalidRows || isAttachmentReadonly || (isInitialEmpty && rows.length === 0);

  const title = f("attachments.signatures.modal.title", { filename: Util.cleanupFilename(attachment.filename) });

  const getSaveActionLabel = () => {
    if (isEmailOnly && hasUnsentEmails) {
      return f("default.send");
    } else if (isLinkOnly && hasUnsavedLinks) {
      return f("attachments.signatures.modal.labels.create-links");
    } else if (hasUnsentEmails || hasUnsavedLinks) {
      return f("attachments.signatures.modal.labels.send-emails-and-create-links");
    } else {
      return f("default.save-changes");
    }
  };

  const saveActionLabel = getSaveActionLabel();

  const saveAction = {
    content: saveActionLabel,
    onAction: () => handleSaveChanges(),
    loading: isSaving,
    disabled: isSaveDisabled || !hasUnsavedChanges,
  };

  const cancelAction = {
    content: hasUnsavedChanges ? f("default.cancel") : f("default.close"),
    onAction: () => handleCancel(),
  };

  return (
    <>
      <Modal
        // modal should remain visible until send email success Toast is displayed
        open={isModalVisible}
        large
        title={
          <Stack alignment="center" distribution="center" spacing="extraTight">
            <Heading>{title}</Heading>
          </Stack>
        }
        onClose={handleCancel}
        footer={
          <AttachmentSignaturesModalFooter
            hasUnsavedChanges={hasUnsavedChanges}
            savedSuccessfully={savedSuccessfully}
          />
        }
        primaryAction={saveAction}
        secondaryActions={[cancelAction]}
      >
        <Modal.Section>
          <Stack vertical spacing="tight">
            <Heading>{f("attachments.signatures.modal.heading")}</Heading>
            {showError && <ErrorPanel message={savingError || error} />}
            {isLoading && <DisplayText>{f("default.loading")}</DisplayText>}
          </Stack>
        </Modal.Section>
        {showTable && (
          <AttachmentSignaturesTable
            customerId={entity.id}
            rows={rows}
            customers={customers}
            readonly={readonly}
            {...rowsMethods}
          />
        )}
        {showSignedDocumentSection && (
          <Modal.Section>
            <AttachmentSignedDocumentSection entity={entity} attachment={attachment} readonly={readonly} />
          </Modal.Section>
        )}
        {!isAttachmentReadonly && (
          <StyledStickySection>
            <Modal.Section>
              <AddItemButton disabled={isSaving || isAttachmentReadonly} onClick={() => createRow()}>
                {f("attachments.signatures.modal.button.add-recipient")}
              </AddItemButton>
            </Modal.Section>
          </StyledStickySection>
        )}
      </Modal>
      {showCancelConfirmation && (
        <Confirm
          title={f("attachments.cancel.confirmation.title")}
          description={f("attachments.cancel.confirmation.description")}
          onYes={closeModal}
          onNo={() => setShowCancelConfirmation(false)}
          cancelTitle={f("button.go.back")}
          actionTitle={f("attachments.cancel.confirmation.yes")}
        />
      )}
      {showSendEmailsToast && (
        <Toast
          content={f("attachments.signatures.modal.labels.email-sent")}
          onDismiss={handleToastDismiss}
          duration={4_000}
        />
      )}
    </>
  );
};

const StyledStickySection = styled.div`
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 100;
  border-top: 0.1rem solid var(--p-divider, #dfe3e8);
`;

export default AttachmentSignaturesModal;
