import React, { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ComplexAction, Heading, Modal, Stack } from "@shopify/polaris";
import { validateNorwegianIdNumber } from "norwegian-national-id-validator";

import api from "../../../api";
import AddressForm from "../../../components/AddressForm";
import ErrorPanel from "../../../components/ErrorPanel";
import TextField from "../../../components/extensions/TextField";
import PersonForm, { PersonInformation } from "../../../components/PersonForm";
import { DEFAULT_COUNTRY } from "../../../constants/countries";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { isEmptyString } from "../../../utils/stringUtils";
import usePersonUpdateErrorParser from "../usePersonUpdateErrorParser";

type PersonDataValues = api.PersonCustomer[keyof api.PersonCustomer];

interface EditPersonCustomerDetailsModalProps {
  person: api.PersonCustomer;
  onClose(): void;
}

const EditPersonCustomerDetailsModal: React.FC<EditPersonCustomerDetailsModalProps> = (props) => {
  const { person: originalPerson, onClose } = props;

  const f = useFormatMessage();
  const features = useFeatures();
  const queryClient = useQueryClient();
  const parsePersonUpdateError = usePersonUpdateErrorParser();

  const [person, setPerson] = useState(originalPerson);

  const updatePersonMutation = useMutation(
    () =>
      api.updatePerson(person.id, {
        first_name: person.first_name,
        last_name: person.last_name,
        birth_date: person.birth_date || "",
        country_of_citizenship: person.country_of_citizenship || "NO",
        address: person.address,
        national_id: person.national_id,
        external_id: person.external_id,
      }),
    {
      onSuccess: (updatedPerson) => {
        const personData = queryClient.getQueryData<api.PersonCustomerDetailResponse>(["person-details", person.id]);
        if (personData) {
          const updatedPersonData = { ...personData, ...updatedPerson };
          queryClient.setQueryData(["person-details", person.id], updatedPersonData);
        }
        onClose();
      },
    }
  );

  const isNationalIdValid = useMemo(
    () =>
      person.national_id && person?.country_of_citizenship === DEFAULT_COUNTRY
        ? validateNorwegianIdNumber(person.national_id)
        : true,
    [person.national_id]
  );

  const isInvalidPerson =
    isEmptyString(person.first_name) ||
    isEmptyString(person.last_name) ||
    isEmptyString(person.birth_date) ||
    isEmptyString(person.country_of_citizenship) ||
    !isNationalIdValid;

  const isBirthDateSet = !isEmptyString(originalPerson.birth_date);
  const isNationalIdSet = !isEmptyString(originalPerson.national_id);
  const isCountryOfCitizenshipSet = !isEmptyString(originalPerson.country_of_citizenship);

  // if birth_date, national_id or country_of_citizenship are set, don't allow the user to change them
  const readonlyFields: Array<keyof PersonInformation> = [];
  if (isBirthDateSet) {
    readonlyFields.push("birth_date");
  }
  if (isNationalIdSet && isCountryOfCitizenshipSet) {
    readonlyFields.push("country_of_citizenship");
  }

  const handleChange = (key: keyof api.PersonCustomer, value?: PersonDataValues) =>
    setPerson({ ...person, [key]: value });

  const saveAction: ComplexAction = {
    content: f("default.save"),
    onAction: () => updatePersonMutation.mutate(),
    loading: updatePersonMutation.isLoading,
    disabled: updatePersonMutation.isLoading || isInvalidPerson,
  };

  const cancelAction: ComplexAction = {
    content: f("default.cancel"),
    onAction: onClose,
    disabled: updatePersonMutation.isLoading,
  };

  const { generalError, fieldsErrorsMap } = parsePersonUpdateError(updatePersonMutation.error);

  const nationalIdError = !isNationalIdValid
    ? f("common.errors.invalid.person.national-id")
    : fieldsErrorsMap.get("national_id");

  return (
    <Modal
      open
      onClose={onClose}
      title={f("modals.person.edit.title")}
      primaryAction={saveAction}
      secondaryActions={[cancelAction]}
    >
      <Modal.Section>
        <Stack vertical>
          <PersonForm
            value={person}
            onChange={handleChange}
            disabledFields={readonlyFields}
            fieldsErrorMap={fieldsErrorsMap}
          />
          <Stack>
            <TextField
              onChange={(value) => handleChange("national_id", value)}
              value={person.national_id}
              placeholder={f("person.client.details.personal.number.placeholder")}
              label={f("person.client.details.personal.number")}
              maxLength={11}
              minLength={11}
              error={nationalIdError}
              disabled={updatePersonMutation.isLoading || isNationalIdSet}
              autoComplete="off"
            />
          </Stack>
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <Stack vertical>
          <Heading>{f("modals.headings.address")}</Heading>
          <AddressForm
            value={person.address || {}}
            onChange={(newAddress) => handleChange("address", newAddress)}
            disabled={updatePersonMutation.isLoading}
          />
        </Stack>
      </Modal.Section>
      {features?.EXTERNAL_ID && (
        <Modal.Section>
          <Stack>
            <TextField
              value={person.external_id}
              onChange={(newValue) => handleChange("external_id", newValue)}
              placeholder={f("company-form.labels.external-id")}
              label={f("company-form.labels.external-id")}
              disabled={updatePersonMutation.isLoading}
              maxLength={50}
              error={fieldsErrorsMap.get("external_id")}
              autoComplete="off"
            />
          </Stack>
        </Modal.Section>
      )}
      {updatePersonMutation.isError && (
        <Modal.Section>
          <ErrorPanel message={generalError} />
        </Modal.Section>
      )}
    </Modal>
  );
};

export default EditPersonCustomerDetailsModal;
