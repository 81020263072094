import * as React from "react";
import { useHistory } from "react-router-dom";
import { Button, Link } from "@shopify/polaris";
import styled from "styled-components";

import ChevronIcon from "../../../assets/icons/chevron-left.svg";
import useFormatMessage from "../../hooks/useFormatMessage";
import Icon from "../extensions/Icon";

type BackButtonProps = {
  url?: string;
  displayLabel?: string;
  showAsLink?: boolean;
};

const BackButton: React.FC<BackButtonProps> = ({ url, displayLabel, showAsLink }) => {
  const history = useHistory();
  const f = useFormatMessage();

  if (showAsLink) {
    return (
      <Link url={url} onClick={() => !url && history.goBack()}>
        <StyledBackIcon width="10px" height="12px" source={ChevronIcon} />
        {f("links.go-back", displayLabel ? { name: displayLabel } : undefined)}
      </Link>
    );
  } else {
    return <Button onClick={() => (url ? history.push(url) : history.goBack())}>{f("button.go.back")}</Button>;
  }
};

const StyledBackIcon = styled(Icon)`
  display: inline-block;
`;

export default BackButton;
