import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Card, SkeletonBodyText, SkeletonDisplayText, Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import CompanyIcon from "../../../assets/icons/company.svg";
import DocTextIcon from "../../../assets/icons/doc-text.svg";
import ProjectIcon from "../../../assets/icons/folder.svg";
import GlobeIcon from "../../../assets/icons/globe.svg";
import TagIcon from "../../../assets/icons/tag.svg";
import api from "../../api";
import useCountries from "../../hooks/useCountries";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IDParams } from "../../types/params";
import AddressInfo from "../AddressInfo";
import ErrorPanel from "../ErrorPanel";
import Icon from "../extensions/Icon";

const CompanyCardSkeleton = () => (
  <Card>
    <Card.Section>
      <Stack vertical>
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText />
      </Stack>
    </Card.Section>
  </Card>
);

const CompanyCard = () => {
  const { id } = useParams<IDParams>();
  const query = useQuery(["company-details", id], () => api.getCompanyCustomerDetails(id));
  const f = useFormatMessage();
  const features = useFeatures();
  const { getCountryName } = useCountries();

  if (query.error) {
    return <ErrorPanel message={f("company-card.errors.fetch-failed")} />;
  }

  if (!query.data || query.isLoading) {
    return <CompanyCardSkeleton />;
  }

  const companyCustomer = query.data;

  const companyType =
    companyCustomer.company_type && (companyCustomer.company_type.description_nb || companyCustomer.company_type.code);

  const hasProject =
    features.PROJECTS && companyCustomer.projects?.length === 1 && companyCustomer.projects[0] !== undefined;

  const projectName = hasProject ? companyCustomer.projects![0].name : "";

  return (
    <Card>
      <Card.Section>
        <StyledGridContainer>
          <Icon source={CompanyIcon} />
          <TextStyle variation="subdued">{f("common.labels.name")}</TextStyle>
          <TextStyle variation="strong">{companyCustomer.name}</TextStyle>

          <Icon source={GlobeIcon} />
          <TextStyle variation="subdued">{f("common.address.country")}</TextStyle>
          <TextStyle>{getCountryName(companyCustomer.country)}</TextStyle>

          <Icon source={DocTextIcon} />
          <TextStyle variation="subdued">{f("company-form.labels.national-id")}</TextStyle>
          <TextStyle>
            {companyCustomer.national_id} {companyType && ` - ${companyType}`}
          </TextStyle>

          <Icon source={TagIcon} />
          <TextStyle variation="subdued">{f("common.labels.address")}</TextStyle>
          <AddressInfo address={companyCustomer.business_address} />

          {hasProject && (
            <>
              <Icon source={ProjectIcon} />
              <TextStyle variation="subdued">{f("summary.project.title")}</TextStyle>
              <TextStyle>{projectName}</TextStyle>
            </>
          )}
        </StyledGridContainer>
      </Card.Section>
    </Card>
  );
};

const StyledGridContainer = styled.div`
  display: grid;
  gap: 1.6rem;
  grid-template-columns: min-content min-content auto;
  align-items: center;

  & .Polaris-TextStyle--variationSubdued {
    color: #676767;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
  }
`;

export default CompanyCard;
