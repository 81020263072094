import styled from "styled-components";

const StyledDivider = styled.div`
  border-radius: var(--p-border-radius-wide);
  background-color: var(--p-surface);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  outline: 0.1rem solid transparent;
`;

export default StyledDivider;
