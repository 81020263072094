import React from "react";
import { TextStyle } from "@shopify/polaris";

interface OnboardingSummaryProjectCardProps {
  projectName: string;
}

const OnboardingSummaryProjectCard: React.FC<OnboardingSummaryProjectCardProps> = (props) => {
  const { projectName } = props;

  return <TextStyle>{projectName}</TextStyle>;
};

export default OnboardingSummaryProjectCard;
