import React from "react";
import { Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { getFullName } from "../../utils/displayUtils";
import Util from "../../utils/util";
import DisplayName from "../DisplayName";

import { StyledButtonGroup, StyledEmailField } from "./StyledDisclosureFormComponents";

type PersonDisclosureFormProps = {
  email?: string;
  isSystemEmailEnabled?: boolean;
  person: api.PersonCustomer;
  onSystemEmailChanged: (value: boolean) => void;
  onEmailChanged: (value: string) => void;
};

const PersonDisclosureForm: React.FC<PersonDisclosureFormProps> = ({
  isSystemEmailEnabled,
  email,
  person,
  onSystemEmailChanged,
  onEmailChanged,
}) => {
  const f = useFormatMessage();

  return (
    <Stack
      wrap={false}
      alignment={(isSystemEmailEnabled && email) ?? !Util.isEmail(email) ? "baseline" : "center"}
      distribution="fill"
    >
      <Stack wrap={false} alignment="center" distribution="leading">
        <DisplayName type="person" name={getFullName(person)} birthDate={person.birth_year || person.birth_date} />
      </Stack>
      <Stack wrap={false} alignment={!isSystemEmailEnabled ? "center" : undefined} distribution="trailing">
        <StyledButtonGroup
          value={isSystemEmailEnabled ? "email" : "link"}
          onChange={(value) => onSystemEmailChanged(value === "email")}
        />
        {isSystemEmailEnabled ? (
          <StyledEmailField
            required
            placeholder={f("disclosure-request.field.email.placeholder")}
            id={person.id}
            value={email || ""}
            onUpdate={(id, newEmail) => onEmailChanged(newEmail)}
          />
        ) : (
          <StyledMessage>
            <TextStyle variation="subdued">{f("signature.email.not.provided")}</TextStyle>
          </StyledMessage>
        )}
      </Stack>
    </Stack>
  );
};

const StyledMessage = styled.span`
  & .Polaris-TextStyle--variationSubdued {
    white-space: nowrap;
  }
`;

export default PersonDisclosureForm;
