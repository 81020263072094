import * as React from "react";
import { Banner } from "@shopify/polaris";

import useFormatMessage from "../hooks/useFormatMessage";

const PossibleMatchBanner = () => {
  const f = useFormatMessage();
  return (
    <Banner title={f("common.banner.possible.match.title")} status="warning">
      <p>{f("common.banner.possible.match.description")}</p>
    </Banner>
  );
};

export default PossibleMatchBanner;
