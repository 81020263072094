import styled from "styled-components";

import EmailSmallIcon from "../../assets/icons/email-small.svg";

const StyledEmailView = styled.div`
  &:before {
    display: inline-block;
    content: ' ';
    background-image: url(${EmailSmallIcon});
    width: 20px;
    height: 12px;
    background-size: 16px 12px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 10px;
  },
`;

export default StyledEmailView;
