import React, { ReactNode, useEffect, useLayoutEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { useParams } from "react-router-dom";
import { Badge, Card, DataTable, DisplayText, Link, Page, Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage";
import CustomDate from "../../components/CustomDate";
import DetailsSkeleton from "../../components/DetailsSkeleton";
import MatchReviewCard from "../../components/match/MatchReviewCard";
import BackButton from "../../components/navigation/BackButton";
import NewInfoBadge from "../../components/NewInfoBadge";
import SectionMargin from "../../components/SectionMargin";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePageNavigation from "../../hooks/usePageNavigation";
import { NavigationItem } from "../../providers/PageNavigation";
import { IDAndCustomerIDParams } from "../../types/params";
import { getAddressDisplayLabel, getFullName, getMatchAddressDisplayLabel } from "../../utils/displayUtils";
import Util from "../../utils/util";

import MatchReviewForm from "./MatchReviewForm";
import PageSection from "./PageSection";
import useMatchPage from "./useMatchPage";

const NEW_MATCH_INFO_CLASSNAME = "new-match-info";
const HIGHLIGHT_NEW_INFO_CLASSNAME = "new-info";

const fadeIt = (flag: boolean, children: ReactNode) => {
  return flag ? <Fade triggerOnce>{children}</Fade> : children;
};

const MatchPage: React.FC<AsyncPageChild<api.MatchCaseDetailResponse>> = ({ data }) => {
  const { id, customerId } = useParams<IDAndCustomerIDParams>();

  const {
    match,
    isPerson,
    reviews,
    isUpdated,
    categories,
    isSubjectPerson,
    gender,
    riskography,
    links,
    remarks,
    pepTypesLabels,
    isPepTypesReviewed,
    lastReview,
    showSummary,
    showComments,
    showRelations,
    showSources,
    showLinks,
    showReviews,
  } = useMatchPage(data);

  const f = useFormatMessage();

  const { setNavigationItems, clearNavigationItems } = usePageNavigation();

  useLayoutEffect(() => {
    // using a Map where the keys are the navigation items and the values indicate if they should be shown
    // this is instead of having an if statement for each conditional navigation item
    const navigationItemsMap = new Map<NavigationItem, boolean>([
      [{ anchor: PAGE_ANCHORS.TOP_SECTION, label: f("navigation.match-cases.overview.title") }, true],
      [{ anchor: PAGE_ANCHORS.MATCH_CASES_HITS_IN_CATEGORIES, label: f("matches.titles.hits-in-categories") }, true],
      [{ anchor: PAGE_ANCHORS.MATCH_CASES_PEP_TYPES, label: f("matches.titles.pep-types") }, true],
      [{ anchor: PAGE_ANCHORS.MATCH_CASES_SUMMARY, label: f("matches.titles.summary") }, showSummary],
      [{ anchor: PAGE_ANCHORS.MATCH_CASES_EVENTS, label: f("matches.titles.events") }, true],
      [{ anchor: PAGE_ANCHORS.MATCH_CASES_COMMENTS, label: f("matches.titles.comments") }, showComments],
      [{ anchor: PAGE_ANCHORS.MATCH_CASES_JOBS, label: f("matches.titles.jobs") }, isPerson],
      [{ anchor: PAGE_ANCHORS.MATCH_CASES_RELATIONS, label: f("matches.titles.relations") }, showRelations],
      [{ anchor: PAGE_ANCHORS.MATCH_CASES_SOURCES, label: f("matches.titles.sources") }, showSources],
      [{ anchor: PAGE_ANCHORS.MATCH_CASES_LINKS, label: f("matches.titles.relevant-links") }, showLinks],
      [{ anchor: PAGE_ANCHORS.MATCH_CASES_ASSESSMENT, label: f("matches.titles.assessment") }, true],
    ]);

    const navigationItems = [...navigationItemsMap.entries()]
      .filter(([navigationItem, show]) => show)
      .map(([navigationItem]) => navigationItem);

    setNavigationItems(navigationItems);

    return () => clearNavigationItems();
  }, [showSummary, showComments, isPerson, showRelations, showSources, showLinks]);

  useEffect(() => {
    /**
     * Ugly/hacky way of styling Polaris parent elements,
     * (data table rows or card sections) to highlight
     * new match info
     */
    const newMatchInfoElements = document.getElementsByClassName(NEW_MATCH_INFO_CLASSNAME);
    for (let i = 0; i < newMatchInfoElements.length; i++) {
      const el = newMatchInfoElements[i];
      const polarisElement = el.closest("tr, .Polaris-DataTable__TableRow, .Polaris-Card__Section");
      if (polarisElement) polarisElement.className += " " + HIGHLIGHT_NEW_INFO_CLASSNAME;
    }
  });

  return (
    <Page>
      <Stack vertical wrap={false} spacing="baseTight">
        {/* Match header  */}
        <Stack vertical>
          <BackButton
            url={Util.caseLink(customerId, data.customer_type)}
            displayLabel={data.customer_name}
            showAsLink
          />
          <DisplayText size="large">
            {data.case.summary_text || f("common.banner.possible.match.title")}: {match.entity_name}
          </DisplayText>
          <Stack vertical spacing="none">
            <Stack spacing="tight">
              <TextStyle variation="strong">{f("match.basic-info.id")}</TextStyle>
              <div>{id}</div>
            </Stack>
            <Stack spacing="tight">
              <TextStyle variation="strong">{f("match.basic-info.match-date")}</TextStyle>
              <div>
                <CustomDate date={match.timestamp} />
              </div>
            </Stack>
            <Stack spacing="tight">
              <TextStyle variation="strong">{f("match.basic-info.last-updated")}</TextStyle>
              <div>
                <CustomDate date={match.updated_timestamp} />
              </div>
            </Stack>
          </Stack>
        </Stack>

        {/* Match sections */}
        <Stack vertical wrap={false} spacing="none">
          <SectionMargin />
          <PageSection title={isPerson ? f("matches.title.compare.persons") : f("matches.title.compare.companies")}>
            <DataTable
              columnContentTypes={["text", "text", "text"]}
              headings={[
                "",
                <TextStyle variation="subdued">{f("matches.titles.your-customer")}</TextStyle>,
                <TextStyle variation="subdued">{f("matches.titles.hits-in-match")}</TextStyle>,
              ]}
              rows={[
                [
                  <TextStyle variation="subdued">{f("common.labels.name")}</TextStyle>,
                  isSubjectPerson(match.subject) ? getFullName(match.subject) : match.subject.name,
                  <>
                    {match.entity_name}
                    {match.aliases.map((a, i) => (
                      <div key={i}>
                        <span className="title">{a.type}:</span> {a.name}{" "}
                        {isUpdated && !a.is_reviewed && <NewInfoBadge />}
                      </div>
                    ))}
                  </>,
                ],
                isSubjectPerson(match.subject)
                  ? [
                      <TextStyle variation="subdued">{f("common.labels.birth_date")}</TextStyle>,
                      match.subject.birth_date ? match.subject.birth_date : match.subject.birth_year,
                      match.birth_dates &&
                        match.birth_dates.length > 0 &&
                        match.birth_dates.map((b, i) => (
                          <div key={i}>
                            {b.value} {isUpdated && !b.is_reviewed && <NewInfoBadge />}
                          </div>
                        )),
                    ]
                  : [],
                isSubjectPerson(match.subject)
                  ? [
                      <TextStyle variation="subdued">{f("common.labels.gender")}</TextStyle>,
                      match.subject.gender,
                      gender.length > 0 &&
                        gender.map((g, i) => (
                          <div key={i}>
                            {g.value} {isUpdated && !g.is_reviewed && <NewInfoBadge />}
                          </div>
                        )),
                    ]
                  : [],
                [
                  <TextStyle variation="subdued">{f("common.labels.address")}</TextStyle>,
                  isSubjectPerson(match.subject)
                    ? null
                    : [match.subject.business_address, match.subject.office_address, match.subject.mailing_address]
                        .filter((o) => o)
                        .map((a, i) => <div key={i}>{getAddressDisplayLabel(a)}</div>),
                  match.addresses.map((a, i) => (
                    <div key={i}>
                      {getMatchAddressDisplayLabel(a)} {isUpdated && !a.is_reviewed && <NewInfoBadge />}
                    </div>
                  )),
                ],
              ]}
            />
          </PageSection>

          <PageSection
            title={f("matches.titles.hits-in-categories")}
            anchor={PAGE_ANCHORS.MATCH_CASES_HITS_IN_CATEGORIES}
          >
            <Card.Section>
              <Stack>
                {categories.length > 0 ? (
                  categories.map((c, i) => (
                    <Badge status="info" size="small" key={i}>
                      {c}
                    </Badge>
                  ))
                ) : (
                  <TextStyle>{f("matches.messages.empty.categories")}</TextStyle>
                )}
              </Stack>
            </Card.Section>
          </PageSection>

          <PageSection
            title={f("matches.titles.pep-types")}
            anchor={PAGE_ANCHORS.MATCH_CASES_PEP_TYPES}
            newInfo={isUpdated && !isPepTypesReviewed}
          >
            <Card.Section>
              <div className={isUpdated && !isPepTypesReviewed ? NEW_MATCH_INFO_CLASSNAME : undefined}>
                <Stack>
                  {pepTypesLabels.length > 0 ? (
                    pepTypesLabels.map((p, i) => (
                      <Badge key={i} status="info" size="small">
                        {p}
                      </Badge>
                    ))
                  ) : (
                    <TextStyle>{f("matches.messages.not-pep")}</TextStyle>
                  )}
                </Stack>
              </div>
            </Card.Section>
          </PageSection>

          <PageSection title={f("matches.titles.summary")} anchor={PAGE_ANCHORS.MATCH_CASES_SUMMARY} show={showSummary}>
            {riskography.map((r, i) => {
              const isNewRiskography = isUpdated && !r.is_reviewed;
              return (
                <Card.Section key={i}>
                  {isNewRiskography && <NewInfoBadge />}
                  <div className={isNewRiskography ? NEW_MATCH_INFO_CLASSNAME : undefined}>{r.value}</div>
                </Card.Section>
              );
            })}
          </PageSection>

          <PageSection title={f("matches.titles.events")} anchor={PAGE_ANCHORS.MATCH_CASES_EVENTS}>
            <DataTable
              columnContentTypes={[]}
              headings={[]}
              rows={match.events.map((event) => {
                const isNewEvent = isUpdated && !event.is_reviewed;
                return [
                  <div className={isNewEvent ? NEW_MATCH_INFO_CLASSNAME : undefined}>
                    {isNewEvent && <NewInfoBadge />}
                    <div>
                      <CustomDate date={event.date} />
                      {" " + event.description}
                    </div>
                    <div>
                      <TextStyle variation="subdued">{f("matches.labels.source")}</TextStyle>
                      {event.source ? (
                        <>
                          {event.source.source_url && (
                            <Link url={Util.parseWebsite(event.source.source_url)} external>
                              {event.source.headline || event.source.source_name || event.source.source_url}
                            </Link>
                          )}
                          {" " + [event.source.publisher, event.source.publication_source].filter((v) => v).join(", ")}
                        </>
                      ) : (
                        " -"
                      )}
                    </div>
                  </div>,
                ];
              })}
            />
          </PageSection>

          <PageSection
            title={f("matches.titles.comments")}
            anchor={PAGE_ANCHORS.MATCH_CASES_COMMENTS}
            show={showComments}
          >
            <DataTable
              columnContentTypes={["text"]}
              headings={[]}
              rows={remarks.map((a) => {
                const isNewRemark = isUpdated && !a.is_reviewed;
                return [
                  <>
                    {isNewRemark && <NewInfoBadge />}
                    <div className={isNewRemark ? NEW_MATCH_INFO_CLASSNAME : undefined}>{a.value}</div>
                  </>,
                ];
              })}
            />
          </PageSection>

          <PageSection title={f("matches.titles.jobs")} anchor={PAGE_ANCHORS.MATCH_CASES_JOBS} show={isPerson}>
            {match.positions && match.positions.length > 0 ? (
              <DataTable
                columnContentTypes={["text"]}
                headings={[]}
                rows={match.positions.map((a) => {
                  const isNewPosition = isUpdated && !a.is_reviewed;
                  return [
                    <>
                      {isNewPosition && <NewInfoBadge />}
                      <div className={isNewPosition ? NEW_MATCH_INFO_CLASSNAME : undefined}>{a.value}</div>
                    </>,
                  ];
                })}
              />
            ) : (
              <Card.Section>{f("matches.messages.empty.posts")}</Card.Section>
            )}
          </PageSection>

          <PageSection
            title={f("matches.titles.relations")}
            anchor={PAGE_ANCHORS.MATCH_CASES_RELATIONS}
            show={showRelations}
          >
            <DataTable
              columnContentTypes={[]}
              headings={[]}
              rows={match.relations.map((r) => {
                const isNewRelation = isUpdated && !r.is_reviewed;
                return [
                  <div className={isNewRelation ? NEW_MATCH_INFO_CLASSNAME : undefined}>
                    {isNewRelation && (
                      <>
                        <NewInfoBadge />
                        <br />
                      </>
                    )}
                    <TextStyle variation="subdued">{r.type}</TextStyle> <div>{r.entity_name}</div>
                  </div>,
                ];
              })}
            />
          </PageSection>

          <PageSection title={f("matches.titles.sources")} anchor={PAGE_ANCHORS.MATCH_CASES_SOURCES} show={showSources}>
            <DataTable
              columnContentTypes={[]}
              headings={[]}
              rows={match.sources.map((source) => {
                const isNewSource = isUpdated && !source.is_reviewed;
                return [
                  <div className={isNewSource ? NEW_MATCH_INFO_CLASSNAME : undefined}>
                    <Stack vertical spacing="extraTight">
                      {isNewSource && <NewInfoBadge />}
                      {source.source_name && (
                        <Stack spacing="extraTight">
                          <TextStyle variation="subdued">{f("matches.labels.source.name")}</TextStyle>
                          <div>{source.source_name}</div>
                        </Stack>
                      )}
                      {source.headline && (
                        <Stack spacing="extraTight">
                          <TextStyle variation="subdued">{f("matches.labels.source.heading")}</TextStyle>
                          <div>{source.headline}</div>
                        </Stack>
                      )}
                      {source.source_url && (
                        <Stack spacing="extraTight">
                          <TextStyle variation="subdued">{f("matches.labels.source.url")}</TextStyle>
                          <Link url={Util.parseWebsite(source.source_url)} external>
                            {source.source_url}
                          </Link>
                        </Stack>
                      )}
                      {source.publisher && (
                        <Stack spacing="extraTight">
                          <TextStyle variation="subdued">{f("matches.labels.source.publisher")}</TextStyle>
                          <div>{source.publisher}</div>
                        </Stack>
                      )}
                      {source.publication_source && (
                        <Stack spacing="extraTight">
                          <TextStyle variation="subdued">{f("matches.labels.source.publication-source")}</TextStyle>
                          <div>{source.publication_source}</div>
                        </Stack>
                      )}
                      {source.entity_dt && (
                        <Stack spacing="extraTight">
                          <TextStyle variation="subdued">{f("matches.labels.source.date")}</TextStyle>
                          <div>{source.entity_dt}</div>
                        </Stack>
                      )}
                    </Stack>
                  </div>,
                ];
              })}
            />
          </PageSection>

          <PageSection
            title={f("matches.titles.relevant-links")}
            anchor={PAGE_ANCHORS.MATCH_CASES_LINKS}
            show={showLinks}
          >
            <DataTable
              columnContentTypes={[]}
              headings={[]}
              rows={links.map((l) => {
                const isNewLink = isUpdated && !l.is_reviewed;
                return [
                  <div className={isNewLink ? NEW_MATCH_INFO_CLASSNAME : undefined}>
                    {isNewLink && (
                      <>
                        <NewInfoBadge />
                        <br />
                      </>
                    )}
                    <Link url={Util.parseWebsite(l.value)} external>
                      {l.value}
                    </Link>
                  </div>,
                ];
              })}
            />
          </PageSection>

          <PageSection title={f("matches.titles.assessment")} anchor={PAGE_ANCHORS.MATCH_CASES_ASSESSMENT} withoutCard>
            <MatchReviewForm
              customerId={customerId}
              id={id}
              lastReview={lastReview}
              readonly={data.customer_is_archived}
            />
          </PageSection>

          <PageSection
            title={f("reviews.titles.existing-reviews")}
            anchor={PAGE_ANCHORS.REVIEWS_SECTION}
            show={showReviews}
            withoutCard
          >
            {reviews.map((review, i) => (
              <Card key={i}>
                {/* fade in first review */}
                {fadeIt(i === 0, <MatchReviewCard review={review} />)}
              </Card>
            ))}
          </PageSection>
        </Stack>

        <StyledFooter>
          <BackButton
            url={Util.caseLink(customerId, data.customer_type)}
            displayLabel={data.customer_name}
            showAsLink
          />
        </StyledFooter>
      </Stack>
    </Page>
  );
};

const StyledFooter = styled.div`
  margin: 3.2rem 0;
`;

export default withAsyncPage<api.MatchCaseDetailResponse>(MatchPage, {
  name: "match-cases",
  apiFunction: api.getCustomerMatchCaseDetails,
  paramNames: ["customerId", "id"],
  skeleton: <DetailsSkeleton />,
});
