import React from "react";
import styled from "styled-components";

import PlusIcon from "../../assets/icons/plus.svg";

interface AddItemButtonProps {
  onClick(): void;
  disabled?: boolean;
}

const AddItemButton: React.FC<AddItemButtonProps> = (props) => {
  const { onClick, disabled, children } = props;

  return (
    <StyledAddButton onClick={onClick} disabled={disabled}>
      <img src={PlusIcon} /> {children}
    </StyledAddButton>
  );
};

const StyledAddButton = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  color: ${({ disabled }) => (disabled ? "#777" : "#365BFF")};
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  filter: ${({ disabled }) => (disabled ? "grayscale(1)" : "none")};
  user-select: none;
  &:hover {
    text-decoration: underline;
  }
  & > img {
    margin-right: 6px;
  }
`;

export default AddItemButton;
