import { Dispatch, SetStateAction, useState } from "react";

import history from "../utils/history";

type SetValue<T> = Dispatch<SetStateAction<T>>;

function useBase<T>(
  searchParameterName: string,
  defaultValue: T,
  convertToType: (value: string | null) => T
): [T, SetValue<T>] {
  // get value from url search params (either a string or null is not found)
  const searchParams = new URLSearchParams(history.location.search);
  const searchParamValue = searchParams.get(searchParameterName);

  // initial value is either the converted url search param value or the default value
  const initialValue = convertToType(searchParamValue) || defaultValue;
  const [storedValue, setStoredValue] = useState<T>(initialValue);

  const setValue: SetValue<T> = (value) => {
    // get the current url search params
    const currentSearchParams = new URLSearchParams(history.location.search);

    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    // Save state
    setStoredValue(valueToStore);
    // Save to URLSearchParams or remove from url search params if value is the same as the default one
    if (valueToStore !== defaultValue) {
      currentSearchParams.set(searchParameterName, String(valueToStore));
    } else {
      currentSearchParams.delete(searchParameterName);
    }

    // update the URL
    history.replace({ search: currentSearchParams.toString() });
  };

  return [storedValue, setValue];
}

export function useURLSearchParam<T extends string>(searchParameterName: string, defaultValue = "") {
  return useBase<T>(searchParameterName, defaultValue as T, (value) => (value || defaultValue) as T);
}

export function useBooleanURLSearchParam(searchParameterName: string, defaultValue = false) {
  return useBase<boolean>(searchParameterName, defaultValue, (value) => !!value);
}

const convertParameterValueToNumber = (searchParameterName: string, defaultValue: number) => {
  return (value: string | null) => {
    if (!value) {
      return defaultValue;
    }

    try {
      return parseInt(value, 10);
    } catch (error: unknown) {
      console.error(`Failed to parse search parameter ${searchParameterName} to a number. ${error}`);
      return defaultValue;
    }
  };
};

export function useNumericURLSearchParam(searchParameterName: string, defaultValue = 0) {
  return useBase<number>(
    searchParameterName,
    defaultValue,
    convertParameterValueToNumber(searchParameterName, defaultValue)
  );
}
