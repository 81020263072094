import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { Loading } from "@shopify/polaris";

import { FeaturesTypes } from "../constants/Features";
import useAuth from "../hooks/useAuth";
import usePermissions from "../hooks/usePermissions";
import AccessDenied from "../pages/AccessDenied";
import Expired from "../pages/Expired";

interface PrivateRouteProps extends RouteProps {
  path: string;
  requiredFeature?: FeaturesTypes;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, path, requiredFeature, ...rest }) => {
  const { isLoading, isAuthenticated, isExpired, user } = useAuth();
  const isAllowed = usePermissions();

  if (isExpired) {
    return <Expired />;
  }

  if (isLoading || !isAuthenticated) {
    return <Loading />;
  }

  if (requiredFeature && user && !isAllowed(requiredFeature)) {
    return <AccessDenied />;
  }

  return <Route path={path} component={Component} {...rest} />;
};

export default PrivateRoute;
