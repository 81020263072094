import React from "react";
import { TextStyle, Tooltip } from "@shopify/polaris";
import styled from "styled-components";

import IconCompany from "../../assets/icons/company.svg";
import InfoBlueIcon from "../../assets/icons/info-blue.svg";
import IconPerson from "../../assets/icons/person.svg";
import api from "../api";
import RoleTypes from "../constants/role-types";
import useFormatMessage from "../hooks/useFormatMessage";

interface DisplayNameProps {
  type: api.CustomerType;
  name?: string;
  birthDate?: string;
  orgId?: string;
  comment?: string;
  strong?: boolean;
  roleType?: api.RoleType;
}

const DisplayName: React.FC<DisplayNameProps> = ({
  type,
  name,
  birthDate,
  orgId,
  comment,
  strong = false,
  roleType,
  children = null,
}) => {
  const f = useFormatMessage();

  const displayName = roleType ? `${name} (${f(RoleTypes[roleType])})` : name;

  return (
    <StyledCustomerName customerType={type}>
      <div className="client-name" title={displayName}>
        {strong ? <TextStyle variation="strong">{displayName}</TextStyle> : displayName}
      </div>
      {birthDate && (
        <StyledBirthYear>
          {f("display-name.suffixes.labels.birth-date", { birthDate: new Date(birthDate) })}
        </StyledBirthYear>
      )}
      {orgId && f("display-name.suffixes.labels.national-id", { orgId })}
      {comment && (
        <Tooltip dismissOnMouseOut preferredPosition="above" content={comment}>
          <img src={InfoBlueIcon} />
        </Tooltip>
      )}
      {children}
    </StyledCustomerName>
  );
};

export const LightCustomerName: React.FC<Pick<DisplayNameProps, "type" | "name">> = ({ type, name, children }) => {
  return (
    <StyledCustomerName customerType={type} title={name}>
      {children}
    </StyledCustomerName>
  );
};

const StyledCustomerName = styled.div<{ customerType: api.CustomerType }>`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 0.25rem;

  & > a {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:before {
    display: block;
    content: " ";
    background-size: 20px 20px;
    background-image: url(${(props) =>
      props.customerType === "person" ? IconPerson : props.customerType === "company" ? IconCompany : ""});
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: 0.5rem;
  }
`;

const StyledBirthYear = styled.div`
  white-space: nowrap;
`;

export default DisplayName;
