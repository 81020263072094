import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  Card,
  DataTable,
  FormLayout,
  Modal,
  Page,
  Select,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";

import PlusMajorIconWhite from "../../../assets/icons/plus-major-white.svg";
import api from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage";
import EmailView from "../../components/EmailView";
import ErrorPanel from "../../components/ErrorPanel";
import Icon from "../../components/extensions/Icon";
import RowHighlighter from "../../components/extensions/RowHighlighter";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import useYesNo from "../../hooks/useYesNo";
import { getFullName } from "../../utils/displayUtils";
import Util from "../../utils/util";

import UsersSkeleton from "./UsersSkeleton";

type ServerError = api.HttpValidationError & {
  email?: string[];
};

const EMPTY_USER: api.User = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  is_active: true,
};

const Users: React.FC<AsyncPageChild<api.UserListResponse>> = ({ data }) => {
  const [isModalOpen, toggleModalOpen, closeModal] = useOpenClose();
  const [currentUser, setCurrentUser] = useState<api.User>(EMPTY_USER);
  const [isReactivating, setReactivating] = useState(false);

  const queryClient = useQueryClient();
  const yesNo = useYesNo();
  const f = useFormatMessage();

  const saveUserMutation = useMutation<api.User, { data: ServerError }, api.UpdateUserRequest>(
    (params) => {
      return currentUser.id ? api.updateUser(currentUser.id, params) : api.createUser(params);
    },
    {
      onSuccess: (user) => {
        addUserToList(!currentUser.id, user);
        closeAndClearModal();
      },
    }
  );

  const groups: Record<string, string> = {};
  for (const group of data.groups || []) {
    groups[group.id] = group.name;
  }

  const users = data.users;
  const isSaving = saveUserMutation.isLoading;

  const handleUpdate = (value: string, property: keyof api.User) => {
    const updatedUser = JSON.parse(JSON.stringify(currentUser));
    updatedUser[property] = value;
    if (property === "email") {
      saveUserMutation.reset();
    }
    setCurrentUser(updatedUser);
  };

  const addUserToList = (isNew: boolean, serverUser: api.User) => {
    const usersData = queryClient.getQueryData<api.UserListResponse>("users");
    const cachedUsers = usersData?.users || [];

    if (!isNew) {
      const i = cachedUsers.findIndex((user) => user.id === serverUser.id);

      if (i >= 0) {
        cachedUsers[i] = serverUser;
      }
    } else {
      cachedUsers.push(serverUser);
    }

    queryClient.setQueryData("users", { users: cachedUsers, groups: usersData?.groups || [] });
  };

  const activateUser = (isActive: boolean) => {
    setReactivating(true);
    currentUser.is_active = isActive;
    saveUserMutation.mutate(currentUser, { onSuccess: () => setReactivating(false) });
  };

  const saveUser = () => saveUserMutation.mutate(currentUser);

  const closeAndClearModal = () => {
    closeModal();
    setCurrentUser(EMPTY_USER);
    saveUserMutation.reset();
  };

  const editUser = (user: api.User) => {
    setCurrentUser(user);
    toggleModalOpen();
  };

  const isNewUser = !currentUser.id;
  const modalTitle = isNewUser ? f("users.modal.title.add-user") : f("users.modal.title.update-user");

  const emailServerError =
    saveUserMutation.error?.data?.email && saveUserMutation.error.data.email.length > 0
      ? saveUserMutation.error.data.email[0]
      : undefined;

  return (
    <Page
      title={f("users.page.title")}
      primaryAction={
        <Button
          primary
          onClick={toggleModalOpen}
          icon={<Icon source={PlusMajorIconWhite} width="14px" height="14px" />}
        >
          {f("default.add")}
        </Button>
      }
    >
      <Stack vertical>
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
              <TextStyle variation="subdued">{f("table.column.email")}</TextStyle>,
              <TextStyle variation="subdued">{f("table.column.group")}</TextStyle>,
              <TextStyle variation="subdued">{f("table.column.active")}</TextStyle>,
            ]}
            rows={users.map((user) => [
              <RowHighlighter subdued={!user.is_active} clickable>
                <div onClick={() => editUser(user)}>{getFullName(user)}</div>
              </RowHighlighter>,
              <div onClick={() => editUser(user)}>
                <EmailView>{user.email || ""}</EmailView>
              </div>,
              <div onClick={() => editUser(user)}>{user.group && groups[user.group]}</div>,
              <div onClick={() => editUser(user)}>{yesNo(user.is_active)}</div>,
            ])}
          />
        </Card>
      </Stack>
      <Modal title={modalTitle} open={isModalOpen} onClose={closeAndClearModal}>
        <Modal.Section>
          <Stack vertical>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  autoFocus
                  label={f("common.labels.first_name")}
                  value={currentUser.first_name}
                  placeholder={f("common.labels.first_name")}
                  disabled={isSaving}
                  onChange={(value) => handleUpdate(value, "first_name")}
                  autoComplete="off"
                />
                <TextField
                  label={f("common.labels.last_name")}
                  value={currentUser.last_name}
                  placeholder={f("common.labels.last_name")}
                  disabled={isSaving}
                  onChange={(value) => handleUpdate(value, "last_name")}
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
            <TextField
              label={f("common.labels.email")}
              value={currentUser.email}
              type="email"
              placeholder={f("email.placeholder.text")}
              disabled={isSaving}
              error={
                currentUser.email && !Util.isEmail(currentUser.email)
                  ? f("email.invalid.address.error")
                  : emailServerError
              }
              onChange={(value) => handleUpdate(value, "email")}
              autoComplete="off"
            />
            <Select
              value={currentUser.group + ""}
              onChange={(value) => handleUpdate(value, "group")}
              disabled={isSaving}
              label={f("common.labels.group")}
              options={[
                {
                  label: f("users.modal.empty-group"),
                  value: "0",
                },
                ...Object.keys(groups).map((key) => ({
                  label: groups[key],
                  value: key,
                })),
              ]}
            />

            {saveUserMutation.isError && !emailServerError && <ErrorPanel message={saveUserMutation.error} />}

            <Stack distribution="fill">
              <Button
                disabled={!currentUser.id || isSaving}
                destructive
                loading={isSaving && isReactivating}
                onClick={() => activateUser(!currentUser.is_active)}
              >
                {currentUser.is_active ? f("users.modal.label.deactivate") : f("users.modal.label.activate")}
              </Button>
              <Stack distribution="trailing">
                <Button onClick={closeAndClearModal} disabled={isSaving}>
                  {f("default.cancel")}
                </Button>
                <Button
                  primary
                  onClick={saveUser}
                  loading={isSaving && !isReactivating}
                  disabled={
                    isReactivating ||
                    !Util.isEmail(currentUser.email) ||
                    !currentUser.first_name ||
                    !currentUser.last_name
                  }
                >
                  {f("default.save")}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Modal.Section>
      </Modal>
    </Page>
  );
};

export default withAsyncPage<api.UserListResponse>(Users, {
  name: "users",
  apiFunction: api.getAllUsersAndGroups,
  paramNames: [],
  skeleton: <UsersSkeleton />,
});
