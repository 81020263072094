/* eslint-disable */
/**
 * Object.entriesFrom() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!Object.fromEntries) {
  // @ts-ignore
  Object.fromEntries = function (entries) {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error("Object.fromEntries() requires a single iterable argument");
    }
    let obj = {};
    for (let [key, value] of entries) {
      // @ts-ignore
      obj[key] = value;
    }
    return obj;
  };
}
/* eslint-enabled */

export default {};
