import React, { useState } from "react";
import { Button, Card, Collapsible, Stack } from "@shopify/polaris";

import CalendarIcon from "../../../assets/icons/calendar.svg";
import EmailIcon from "../../../assets/icons/envelope.svg";
import WaitingIcon from "../../../assets/icons/hourglass.svg";
import LinkIcon from "../../../assets/icons/link.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { getBirthDate, getFullName } from "../../utils/displayUtils";
import { isEmailEnabled } from "../../utils/util";
import CustomDate from "../CustomDate";
import DisplayName from "../DisplayName";
import Icon from "../extensions/Icon";
import SignatureActions from "../SignatureActions";
import SigningUserInfo from "../SigningUserInfo";

import Disclosure from "./Disclosure";

type DisclosureDocumentSignature = {
  customerId: string;
  createdTimestamp: string;
  signature: api.Signature;
  existingEmails: string[]; // all emails used in disclosure document
  readonly?: boolean;
};

const DisclosureDocumentSignature: React.FC<DisclosureDocumentSignature> = ({
  customerId,
  signature,
  createdTimestamp,
  existingEmails,
  readonly,
}) => {
  const f = useFormatMessage();

  const [email, setEmail] = useState(signature.email);
  const [isDisclosureOpen, setIsDisclosureOpen] = React.useState(false);
  const [isEmailEnabledForSignature, setIsEmailEnabledForSignature] = React.useState(isEmailEnabled(signature));

  return (
    <Card>
      <Card.Section>
        <Stack alignment="center" wrap={false} distribution="equalSpacing">
          <DisplayName type="person" name={getFullName(signature.person)} birthDate={getBirthDate(signature.person)} />

          <Stack alignment="center" wrap={false} spacing="extraTight">
            {isEmailEnabledForSignature
              ? [<Icon key={1} source={EmailIcon} />, <div key={2}>{email || f("signature.email.not.provided")}</div>]
              : [<Icon key={1} source={LinkIcon} />, <div key={2}>{signature.signing_page_url}</div>]}
          </Stack>

          <Stack alignment="center" wrap={false} spacing="extraTight">
            <Icon source={CalendarIcon} />
            <div>{f("common.created")}</div>
            <CustomDate date={createdTimestamp} />
          </Stack>

          <SignatureActions
            customerId={customerId}
            signature={signature}
            existingEmails={existingEmails}
            onEmailUpdated={(newEmail) => {
              setEmail(newEmail);
              setIsEmailEnabledForSignature(true);
            }}
            readonly={readonly}
          />
        </Stack>
      </Card.Section>

      <Card.Section>
        {signature.disclosure && signature.signing_user_info ? (
          <>
            <SigningUserInfo signingUserInfo={signature.signing_user_info} />
            <br />
            <Button onClick={() => setIsDisclosureOpen(!isDisclosureOpen)}>
              {isDisclosureOpen ? f("disclosure-collapsible.hide") : f("disclosure-collapsible.show")}
            </Button>
            <Collapsible id={signature.id + "-disclosure-collapsible"} open={isDisclosureOpen}>
              <Disclosure disclosure={signature.disclosure} />
            </Collapsible>
          </>
        ) : (
          <Stack spacing="tight" alignment="center">
            <Icon source={WaitingIcon} />
            <div>{f("disclosure-status.waiting")}</div>
          </Stack>
        )}
      </Card.Section>
    </Card>
  );
};

export default DisclosureDocumentSignature;
