import * as React from "react";
import { useState } from "react";
import { ButtonGroup, Modal, Stack } from "@shopify/polaris";
import { CircleTickOutlineMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import useFormatMessage from "../../hooks/useFormatMessage";
import useTimeout from "../../hooks/useTimeout";
import Util from "../../utils/util";
import Button from "../extensions/Button";

type CopyLinkModalProps = {
  title: string;
  url?: string;
  open: boolean;
  onModalClose(): void;
};

const CopyLinkModal: React.FC<CopyLinkModalProps> = ({ title, url, open, onModalClose }) => {
  const [isCopied, setCopied] = useState(false);

  const f = useFormatMessage();

  // After 2s, reset the `isCopied` state to allow copying
  useTimeout(
    () => {
      setCopied(false);
    },
    2000,
    isCopied
  );

  const copyLink = () => {
    Util.copyToClipboard(url);
    setCopied(true);
  };

  const closeModal = () => onModalClose();

  return (
    <Modal open={open} onClose={closeModal} title={title}>
      <Modal.Section>
        <Stack vertical>
          <StyledLink>{url}</StyledLink>

          <Stack distribution="trailing">
            <ButtonGroup fullWidth>
              <Button onClick={closeModal}>{f("default.close")}</Button>
              <StyledCopyButton
                monochrome={isCopied}
                outline={isCopied}
                primary={!isCopied}
                success={isCopied}
                icon={isCopied ? CircleTickOutlineMinor : undefined}
                onClick={copyLink}
              >
                {isCopied ? f("modals.copy.link.button.copied") : f("modals.copy.link.button")}
              </StyledCopyButton>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

const StyledLink = styled.div`
  background-color: #f0f0f0;
  color: #777;
  padding: 0.625rem;
  border: 1px solid #e2e2e2;
`;

const StyledCopyButton = styled(Button)<{ success: boolean }>`
  ${({ success }) => success && `color: #3AAD53;`}
`;

export default CopyLinkModal;
