import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import HistoryLocationState from "../../types/HistoryLocationState";

import CancelOnboardingMenu from "./CancelOnboardingMenu";
import useNavigationToolbar from "./useNavigationToolbar";

interface ItemProps {
  disabled?: boolean;
  onClick?(): void;
  isPast?: boolean;
  isActive?: boolean;
}

const Item: React.FC<ItemProps> = ({ disabled, onClick, isPast, isActive, children }) => {
  return (
    <StyledToolbarItem onClick={onClick} isActive={isActive} disabled={disabled}>
      <TextStyle variation={isActive || isPast ? "strong" : "subdued"}>{children}</TextStyle>
    </StyledToolbarItem>
  );
};

const OnboardingToolbar: React.FC = () => {
  const f = useFormatMessage();
  const history = useHistory<HistoryLocationState>();

  const createdFromProjectId = history.location.state?.createdFromProject?.id;

  const redirectURLAfterClose = createdFromProjectId
    ? generatePath(ROUTES.PROJECT_DETAILS, { id: createdFromProjectId })
    : generatePath(ROUTES.HOME);

  const { id, menuType, onboardingStepIndex, goToStep } = useNavigationToolbar();

  const menuItems = {
    person: [
      f("onboarding.bar.person.step1.title"),
      f("onboarding.bar.person.step2.title"),
      f("onboarding.bar.person.step3.title"),
      f("onboarding.bar.person.step4.title"),
    ],
    company: [
      f("onboarding.bar.company.step1.title"),
      f("onboarding.bar.company.step2.title"),
      f("onboarding.bar.company.step3.title"),
    ],
    manualCompany: [
      f("onboarding.bar.manual-company.step1.title"),
      f("onboarding.bar.manual-company.step2.title"),
      f("onboarding.bar.manual-company.step3.title"),
      f("onboarding.bar.manual-company.step4.title"),
    ],
  };

  return (
    <Stack alignment="fill" distribution="center">
      {menuItems[menuType].map((item, index) => (
        <Item
          disabled={index >= onboardingStepIndex}
          onClick={() => goToStep(index)}
          key={item}
          isPast={index < onboardingStepIndex}
          isActive={onboardingStepIndex === index}
        >
          {item}
        </Item>
      ))}
      <Item>
        <CancelOnboardingMenu id={id} onMenuClose={() => history.push(redirectURLAfterClose)} />
      </Item>
    </Stack>
  );
};

const StyledToolbarItem = styled.div<ItemProps>`
  userselect: none;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10rem;

  &:hover {
    background-color: #f7f7f7;
  }

  padding-bottom: ${(props) => (props.isActive ? "inherit" : "4px")};
  border-bottom: ${(props) => (props.isActive ? "4px solid #365BFF" : "none")};

  ${(props) =>
    props.disabled &&
    `
      cursor: default;
      pointer-events: none;
      &:hover {
        background-color: inherit;
      }
  `}
`;

export default OnboardingToolbar;
