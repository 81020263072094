import api from "../api";

const isEmpty = (str?: string) => !str || !str.trim();

const trim = (str?: string) => (str ? str.trim() : undefined);

const isAddressEmpty = (address: api.Address) => {
  return (
    isEmpty(address.address_line_1) &&
    isEmpty(address.address_line_2) &&
    isEmpty(address.postal_code) &&
    isEmpty(address.city) &&
    isEmpty(address.county)
  );
};

export const trimAddress = (a?: api.Address): api.Address | undefined => {
  if (!a || isAddressEmpty(a)) {
    return undefined;
  }

  return {
    address_line_1: trim(a.address_line_1),
    address_line_2: trim(a.address_line_2),
    postal_code: trim(a.postal_code),
    city: trim(a.city),
    county: trim(a.county),
    country: a.country,
  };
};
