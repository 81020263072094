import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Heading, Spinner, Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import DownloadIcon from "../../../../assets/icons/arrow-down-rectangle.svg";
import FileIcon from "../../../../assets/icons/file.svg";
import DeleteIcon from "../../../../assets/icons/trash.svg";
import api from "../../../api";
import useAttachmentDownload from "../../../hooks/useAttachmentDownload";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { Entity } from "../../../types/utilities";
import { hasSignedAttachment, wasSignedAttachmentDeleted } from "../../../utils/attachmentUtils";
import { getFullName } from "../../../utils/displayUtils";
import Util from "../../../utils/util";
import ActionsDropdown from "../../ActionsDropdown";
import Button from "../../extensions/Button";
import DeleteAttachmentConfirmationModal from "../../modals/DeleteAttachmentConfirmationModal";

interface AttachmentSignedDocumentSectionProps {
  entity: Entity;
  attachment: api.AttachmentDetails;
  readonly?: boolean;
}

const AttachmentSignedDocumentSection: React.FC<AttachmentSignedDocumentSectionProps> = (props) => {
  const { entity, attachment, readonly } = props;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const f = useFormatMessage();
  const intl = useIntl();
  const downloadAttachmentMutation = useAttachmentDownload(entity);

  const hasSignedAttachmentFile = hasSignedAttachment(attachment);
  const hasSignedAttachmentDeleted = wasSignedAttachmentDeleted(attachment);
  const filename = Util.cleanupFilename(attachment.filename);

  const name = getFullName(attachment.signed_document_deleted_by);
  const date = intl.formatDate(attachment.signed_document_deleted_timestamp);

  const actions = [
    {
      content: f("attachments.actions.download-signed-attachment"),
      image: DownloadIcon,
      disabled: downloadAttachmentMutation.isLoading,
      prefix: downloadAttachmentMutation.isLoading ? <Spinner size="small" /> : null,
      onAction: () => downloadAttachmentMutation.mutate({ attachment, downloadSignedAttachment: true }),
    },
    {
      content: f("attachments.actions.delete-signed-attachment"),
      image: DeleteIcon,
      onAction: () => setShowDeleteConfirmation(true),
      disabled: readonly || downloadAttachmentMutation.isLoading,
    },
  ];

  return (
    <Stack vertical>
      <Heading>{f("attachments.actions.section.signed.document.title")}</Heading>
      {hasSignedAttachmentFile && (
        <Stack alignment="center">
          <StyledDownloadSignedDocumentButton
            plain
            icon={<img src={FileIcon} />}
            truncate
            textAlign="left"
            loading={downloadAttachmentMutation.isLoading}
            onClick={() => downloadAttachmentMutation.mutate({ attachment, downloadSignedAttachment: true })}
          >
            {filename}
          </StyledDownloadSignedDocumentButton>
          <ActionsDropdown items={actions} hideTitle />
        </Stack>
      )}
      {hasSignedAttachmentDeleted && (
        <StyledDeleteMessage>
          <TextStyle variation="subdued">
            {f("attachments.signatures.modal.labels.deleted-signed-document", { name, date })}
          </TextStyle>
        </StyledDeleteMessage>
      )}
      {showDeleteConfirmation && (
        <DeleteAttachmentConfirmationModal
          entity={entity}
          attachment={attachment}
          deleteSignedAttachment
          onModalClose={() => setShowDeleteConfirmation(false)}
        />
      )}
    </Stack>
  );
};

const StyledDownloadSignedDocumentButton = styled(Button)`
  margin-left: -1rem;
  display: inline-block;
`;

const StyledDeleteMessage = styled.div`
  font-style: italic;
`;

export default AttachmentSignedDocumentSection;
