import React from "react";
import { Card, RadioButton, Stack, TextStyle } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

interface ScreeningTypeCardProps {
  screeningType: api.ScreeningType;
  onScreeningTypeChange(screeningType: api.ScreeningType): void;
}

const ScreeningTypeCard: React.FC<ScreeningTypeCardProps> = (props) => {
  const { screeningType, onScreeningTypeChange } = props;
  const f = useFormatMessage();

  return (
    <Card>
      <Card.Section>
        <Stack vertical>
          <RadioButton
            label={<TextStyle variation="strong">{f("screening-type.label.monitoring")}</TextStyle>}
            helpText={f("onboarding.screening-type.monitoring.helper.text")}
            checked={screeningType === "MONITORING"}
            id={"MONITORING"}
            name="screeningType"
            onChange={() => onScreeningTypeChange("MONITORING")}
          />
          <RadioButton
            label={<TextStyle variation="strong">{f("screening-type.label.one-time")}</TextStyle>}
            helpText={f("onboarding.screening-type.one-time.helper.text")}
            id={"ONE_TIME"}
            name="screeningType"
            checked={screeningType === "ONE_TIME"}
            onChange={() => onScreeningTypeChange("ONE_TIME")}
          />
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default ScreeningTypeCard;
