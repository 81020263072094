import React from "react";
import { FormLayout, RadioButton } from "@shopify/polaris";

import { CreatedRanges } from "../constants/pagination";
import useFormatMessage from "../hooks/useFormatMessage";

import DateInput from "./inputs/DateInput";

interface CreatedDateFilterProps {
  isLoading: boolean;
  createdRange: string;
  createdAfter: string;
  createdBefore: string;
  setCreatedRange(createdRange: string): void;
  setCreatedAfter(createdAfter: string): void;
  setCreatedBefore(createdBefore: string): void;
}

const CreatedDateFilter: React.FC<CreatedDateFilterProps> = (props) => {
  const {
    isLoading,
    createdRange,
    createdAfter,
    createdBefore,
    setCreatedRange,
    setCreatedAfter,
    setCreatedBefore,
  } = props;

  const f = useFormatMessage();

  return (
    <FormLayout>
      {Object.values(CreatedRanges).map((rangeOption) => (
        <RadioButton
          key={rangeOption}
          name="created"
          label={f(`component.filters.created.range.${rangeOption}`)}
          disabled={isLoading}
          checked={createdRange === rangeOption}
          onChange={() => setCreatedRange(rangeOption)}
        />
      ))}
      <FormLayout.Group condensed>
        <DateInput
          label={f("component.filters.created-after.title")}
          value={createdAfter}
          onChange={(date) => setCreatedAfter(date || "all")}
          maxDate={new Date()}
          minDate={new Date("1900-01-01")}
          errorMsg={""}
          disabled={isLoading || createdRange !== CreatedRanges.CUSTOM}
        />
        <DateInput
          label={f("component.filters.created-before.title")}
          value={createdBefore}
          onChange={(date) => setCreatedBefore(date || "all")}
          maxDate={new Date()}
          minDate={new Date("1900-01-01")}
          errorMsg={""}
          disabled={isLoading || createdRange !== CreatedRanges.CUSTOM}
        />
      </FormLayout.Group>
    </FormLayout>
  );
};

export default CreatedDateFilter;
