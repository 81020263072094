import React, { useRef } from "react";
import { FormLayout, TextField } from "@shopify/polaris";

import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";

import DateInput from "./inputs/DateInput";
import CountrySelect from "./CountrySelect";

export type PersonInformation = {
  first_name?: string;
  last_name?: string;
  birth_date?: string;
  country_of_citizenship?: api.CountryEnum;
};

interface PersonFormProps {
  value: PersonInformation;
  disabled?: boolean;
  disabledFields?: Array<keyof PersonInformation>;
  // key of fieldsErrorMap is set as string (and not as keyof PersonalInformation) in order to enable general utilities
  // to provide errors for this component
  fieldsErrorMap?: Map<string, string>;
  isManuallyCreated?: boolean;
  onChange(field: keyof PersonInformation, newValue?: string): void;
}

const PersonForm: React.FC<PersonFormProps> = (props) => {
  const {
    value,
    disabled = false,
    disabledFields = [],
    fieldsErrorMap = new Map(),
    onChange,
    isManuallyCreated = true,
  } = props;

  const originalValue = useRef(value);

  const f = useFormatMessage();

  return (
    <FormLayout>
      <FormLayout.Group>
        <TextField
          value={value.first_name || ""}
          label={f("common.labels.first_name")}
          onChange={(newValue) => onChange("first_name", newValue)}
          autoFocus
          placeholder={f("common.labels.first_name")}
          disabled={disabled || disabledFields.includes("first_name")}
          error={fieldsErrorMap.get("first_name")}
          autoComplete="off"
          requiredIndicator
        />
        <TextField
          value={value.last_name || ""}
          label={f("common.labels.last_name")}
          onChange={(newValue) => onChange("last_name", newValue)}
          placeholder={f("common.labels.last_name")}
          disabled={disabled || disabledFields.includes("last_name")}
          error={fieldsErrorMap.get("last_name")}
          autoComplete="off"
          requiredIndicator
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <DateInput
          value={value.birth_date || ""}
          label={f("common.labels.birth_date")}
          onChange={(newValue) => onChange("birth_date", newValue)}
          maxDate={new Date()}
          minDate={new Date("1900-01-01")}
          errorMsg={f("common.errors.invalid.birth.date")}
          disabled={
            disabled ||
            disabledFields.includes("birth_date") ||
            (!isManuallyCreated && !!originalValue.current.birth_date)
          }
          requiredIndicator
        />
        <CountrySelect
          value={value.country_of_citizenship}
          onSelect={(newValue) => onChange("country_of_citizenship", newValue)}
          disabled={
            disabled ||
            disabledFields.includes("country_of_citizenship") ||
            (!isManuallyCreated && !!originalValue.current.country_of_citizenship)
          }
          error={fieldsErrorMap.get("country_of_citizenship")}
          label={f("common.labels.citizenship")}
          requiredIndicator
        />
      </FormLayout.Group>
    </FormLayout>
  );
};

export default PersonForm;
