import * as React from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, DataTable, Heading, Layout, Page, Stack } from "@shopify/polaris";

import api from "../../api";
import DisplayName from "../../components/DisplayName";
import ErrorPanel from "../../components/ErrorPanel";
import BackButton from "../../components/navigation/BackButton";
import ScreeningSkeletonPage from "../../components/screening/ScreeningSkeletonPage";
import ScreeningTypeCard from "../../components/screening/ScreeningTypeCard";
import SectionMargin from "../../components/SectionMargin";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IDParams } from "../../types/params";
import { getFullName } from "../../utils/displayUtils";
import ErrorPage from "../ErrorPage";

const PersonOnboardingScreening: React.FC<RouteComponentProps<IDParams>> = ({ history, match }) => {
  const id = match.params.id;
  const [postError, setPostError] = React.useState();
  const [isPosting, setPosting] = React.useState(false);

  const [screeningType, setScreeningType] = React.useState<api.ScreeningType>("MONITORING");

  const f = useFormatMessage();

  const { isLoading, isError, error, data } = useQuery(["person-screening", id], () =>
    Promise.all([api.getPersonCustomerDetails(id), api.getPersonScreeningConfiguration(id)])
  );

  const screening = data ? data[1] : undefined;

  useEffect(() => {
    if (screening?.screening_type && screening?.screening_type !== "NONE") {
      setScreeningType(screening.screening_type);
    }
  }, [screening?.screening_type]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (isLoading || !data) {
    return <ScreeningSkeletonPage />;
  }

  const person = data[0];

  return (
    <Page title={f("screening.titles.person")}>
      <Stack vertical>
        <Layout>
          <Layout.Section>
            <Stack vertical>
              <Card>
                {person && (
                  <DataTable
                    columnContentTypes={["text"]}
                    headings={[]}
                    rows={[
                      [
                        <DisplayName
                          type="person"
                          name={getFullName(person)}
                          birthDate={person.birth_year || person.birth_date}
                        />,
                      ],
                    ]}
                  />
                )}
              </Card>

              <SectionMargin />
              <Heading>{f("screening.titles.screening-frequency")}</Heading>
              <ScreeningTypeCard
                screeningType={screeningType}
                onScreeningTypeChange={(newValue) => setScreeningType(newValue)}
              />
              {postError && <ErrorPanel message={postError} />}
              <Stack distribution="trailing">
                <BackButton url={`/persons/${id}/edit`} />
                <Button
                  primary
                  disabled={isPosting || !screeningType || screeningType === "NONE"}
                  loading={isPosting}
                  onClick={() => {
                    setPosting(true);
                    api
                      .updatePersonScreeningConfiguration(id, {
                        screening_type: screeningType,
                      })
                      .then(() => {
                        setPosting(false);
                        history.push(`/persons/${id}/request-onboarding-disclosure`);
                      })
                      .catch((error) => {
                        setPosting(false);
                        setPostError(error.toString());
                      });
                  }}
                >
                  {f("button.go.next")}
                </Button>
              </Stack>
            </Stack>
          </Layout.Section>
        </Layout>
      </Stack>
    </Page>
  );
};

export default PersonOnboardingScreening;
