import api from "../api";
import Util from "../utils/util";

export default function useValidateCompanyDisclosureForm(): (
  signatures: Map<string, api.CompanyRoleSignatureConfiguration>,
  onValidation?: (isValid: boolean, hasDuplicates: boolean) => void
) => boolean {
  const validateDisclosureForm = (
    signatures: Map<string, api.CompanyRoleSignatureConfiguration>,
    onValidation?: (isValid: boolean, hasDuplicates: boolean) => void
  ) => {
    let hasValidEmails = true;
    let hasDuplicates = false;
    const duplicates = new Set();

    signatures.forEach((signature) => {
      const email = signature.email;

      if (signature.send_email) {
        if (!Util.isEmail(email)) {
          hasValidEmails = false;
        }

        if (duplicates.has(email)) {
          hasValidEmails = false;
          hasDuplicates = true;
        } else {
          if (email) {
            duplicates.add(email);
          }
        }
      }
    });

    onValidation && onValidation(hasValidEmails, hasDuplicates);
    return !hasValidEmails;
  };

  return validateDisclosureForm;
}
