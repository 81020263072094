import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AppProvider, Frame, Stack } from "@shopify/polaris";
import englishTranslations from "@shopify/polaris/locales/en.json";
import norwegianTranslations from "@shopify/polaris/locales/nb.json";

import AdapterLink from "./components/AdapterLink";
import ErrorBoundary from "./components/ErrorBoundary";
import NavigationToolbar from "./components/navigation/NavigationToolbar";
import PageNavigationMenu from "./components/navigation/PageNavigationMenu";
import PrivateRoute from "./components/PrivateRoute";
import SectionMargin from "./components/SectionMargin";
import { FeaturesTypes } from "./constants/Features";
import { ROUTES } from "./constants/routes";
import useAppTitle from "./hooks/useAppTitle";
import useAuth from "./hooks/useAuth";
import usePageNavigation from "./hooks/usePageNavigation";
import NotFound from "./pages/404";
import AccessDenied from "./pages/AccessDenied";
import AdminLabelsPage from "./pages/admin/AdminLabelsPage";
import Users from "./pages/admin/Users";
import CompanyChangeScreening from "./pages/company/CompanyChangeScreening";
import CompanyDetails from "./pages/company/CompanyDetails";
import CompanyLookup from "./pages/company/CompanyLookup";
import CompanyOnboardingDisclosures from "./pages/company/CompanyOnboardingDisclosures";
import CompanyOnboardingForm from "./pages/company/CompanyOnboardingForm";
import CompanyOnboardingScreening from "./pages/company/CompanyOnboardingScreening";
import CompanyOnboardingSummary from "./pages/company/CompanyOnboardingSummary";
import CompanyRequestDisclosures from "./pages/company/CompanyRequestDisclosures";
import NewCompanyCustomer from "./pages/company/NewCompanyCustomer";
import CustomersListPage from "./pages/customers/CustomersListPage";
import Expired from "./pages/Expired";
import MatchPage from "./pages/match/MatchPage";
import OnboardPerson from "./pages/person/OnboardPerson";
import PersonChangeScreening from "./pages/person/PersonChangeScreening";
import PersonDetails from "./pages/person/PersonDetails";
import PersonOnboardingDisclosure from "./pages/person/PersonOnboardingDisclosure";
import PersonOnboardingScreening from "./pages/person/PersonOnboardingScreening";
import PersonOnboardingSummary from "./pages/person/PersonOnboardingSummary";
import PersonRequestDisclosure from "./pages/person/PersonRequestDisclosure";
import ProjectDetailsPage from "./pages/project/ProjectDetailsPage";
import ProjectsListPage from "./pages/projects/ProjectsListPage";
import { LanguageContext } from "./providers/LanguageProvider";
import { getLanguage } from "./utils/languageStorage";

const App = () => {
  const { isAuthenticated } = useAuth();
  const { showNavigationMenu } = usePageNavigation();
  const [language] = useContext(LanguageContext);

  useAppTitle();

  const translations = language === "en" ? englishTranslations : norwegianTranslations;

  return (
    <AppProvider
      linkComponent={AdapterLink}
      theme={{
        colors: {
          primary: "#5D6BC4",
        },
      }}
      i18n={translations}
    >
      <ErrorBoundary>
        <Frame
          topBar={isAuthenticated ? <NavigationToolbar /> : null}
          navigation={showNavigationMenu ? <PageNavigationMenu /> : null}
        >
          <ErrorBoundary>
            <Stack vertical spacing="extraLoose">
              {/* this empty stack item is responsible for top padding of the main content */}
              <Stack.Item />
              <Switch>
                <PrivateRoute
                  path={ROUTES.ADMIN_USERS}
                  exact
                  component={Users}
                  requiredFeature={FeaturesTypes.USER_MANAGEMENT}
                />
                <PrivateRoute
                  path={ROUTES.ADMIN_LABELS}
                  exact
                  component={AdminLabelsPage}
                  requiredFeature={FeaturesTypes.PROJECTS}
                />
                <Route path={ROUTES.EXPIRED} exact component={Expired} />
                <Route path={ROUTES.ACCESS_DENIED} exact component={AccessDenied} />
                {/* Person customer routes */}
                <PrivateRoute path={ROUTES.PERSON_DETAILS} exact component={PersonDetails} />
                <PrivateRoute path={ROUTES.PERSON_REQUEST_DISCLOSURES} exact component={PersonRequestDisclosure} />
                <PrivateRoute path="/persons/:id/change-screening" exact component={PersonChangeScreening} />
                <PrivateRoute path="/persons/:customerId/match-cases/:id" exact component={MatchPage} />
                {/* Person customer onboarding routes */}
                <PrivateRoute path={ROUTES.PERSON_ONBOARDING_NEW} exact component={OnboardPerson} />
                <PrivateRoute path={ROUTES.PERSON_ONBOARDING} exact component={OnboardPerson} />
                <PrivateRoute path={ROUTES.PERSON_ONBOARDING_SCREENING} exact component={PersonOnboardingScreening} />
                <PrivateRoute
                  path={ROUTES.PERSON_ONBOARDING_DISCLOSURES}
                  exact
                  component={PersonOnboardingDisclosure}
                />
                <PrivateRoute path={ROUTES.PERSON_ONBOARDING_SUMMARY} exact component={PersonOnboardingSummary} />
                {/* Company customer routes */}
                <PrivateRoute path={ROUTES.COMPANY_DETAILS} exact component={CompanyDetails} />
                <PrivateRoute path={ROUTES.COMPANY_REQUEST_DISCLOSURES} exact component={CompanyRequestDisclosures} />
                <PrivateRoute path="/companies/:id/change-screening" exact component={CompanyChangeScreening} />
                <PrivateRoute path="/companies/:customerId/match-cases/:id" exact component={MatchPage} />
                {/* Company customer onboarding routes */}
                <PrivateRoute path={ROUTES.COMPANY_LOOKUP} exact component={NewCompanyCustomer} />
                <PrivateRoute path={ROUTES.COMPANY_LOOKUP_RESULT} exact component={CompanyLookup} />
                <PrivateRoute path={ROUTES.COMPANY_ONBOARDING_SCREENING} exact component={CompanyOnboardingScreening} />
                <PrivateRoute
                  path={ROUTES.COMPANY_ONBOARDING_DISCLOSURES}
                  exact
                  component={CompanyOnboardingDisclosures}
                />
                <PrivateRoute path={ROUTES.COMPANY_ONBOARDING_SUMMARY} exact component={CompanyOnboardingSummary} />
                {/* Manual company customer onboarding routes */}
                <PrivateRoute path={ROUTES.MANUAL_COMPANY_ONBOARDING_EDIT} exact component={CompanyOnboardingForm} />
                <PrivateRoute
                  path={ROUTES.MANUAL_COMPANY_ONBOARDING_SCREENING}
                  exact
                  component={CompanyOnboardingScreening}
                />
                <PrivateRoute
                  path={ROUTES.MANUAL_COMPANY_ONBOARDING_DISCLOSURES}
                  exact
                  component={CompanyOnboardingDisclosures}
                />
                <PrivateRoute
                  path={ROUTES.MANUAL_COMPANY_ONBOARDING_SUMMARY}
                  exact
                  component={CompanyOnboardingSummary}
                />
                {/* Match case route */}
                <PrivateRoute path={ROUTES.MATCH_CASE_DETAILS} exact component={MatchPage} />
                {/* Customer list routes */}
                <PrivateRoute path={ROUTES.HOME} exact>
                  <Redirect to={ROUTES.CUSTOMERS} />
                </PrivateRoute>
                <PrivateRoute path={ROUTES.CUSTOMERS} component={CustomersListPage} />

                {/* Projects */}
                <PrivateRoute
                  path={ROUTES.PROJECTS_LIST}
                  exact
                  component={ProjectsListPage}
                  requiredFeature={FeaturesTypes.PROJECTS}
                />
                <PrivateRoute
                  path={ROUTES.PROJECT_DETAILS}
                  exact
                  component={ProjectDetailsPage}
                  requiredFeature={FeaturesTypes.PROJECTS}
                />

                {/* If we end up here it's a 404 */}
                <Route component={NotFound} />
              </Switch>
            </Stack>
            <SectionMargin />
          </ErrorBoundary>
        </Frame>
      </ErrorBoundary>
    </AppProvider>
  );
};

/**
 * Wrap App with Auth0's withAuthenticationRequired.
 * Anonymous users will be redirected to the Auth0-login page
 * and returned to the page they were redirected from after login
 */
export default withAuthenticationRequired(App, {
  returnTo: window.location.pathname,
  loginOptions: {
    ui_locales: getLanguage(),
  },
});
