import React from "react";
import { Card, Page, SkeletonBodyText, SkeletonDisplayText, Stack } from "@shopify/polaris";

function DetailsSkeleton() {
  return (
    <Page>
      <Stack vertical spacing="extraLoose">
        <SkeletonDisplayText />
        <SkeletonBodyText lines={2} />

        <SkeletonDisplayText />
        <Card>
          <Card.Section>
            <SkeletonBodyText lines={6} />
          </Card.Section>
        </Card>

        <SkeletonDisplayText />
        <Card>
          <Card.Section>
            <SkeletonBodyText lines={2} />
          </Card.Section>
        </Card>

        <SkeletonDisplayText />
        <Card>
          <Card.Section>
            <SkeletonBodyText lines={6} />
          </Card.Section>
        </Card>

        <SkeletonDisplayText />
        <Card>
          <Card.Section>
            <SkeletonBodyText lines={6} />
          </Card.Section>
        </Card>
      </Stack>
    </Page>
  );
}

export default DetailsSkeleton;
