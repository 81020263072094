import React from "react";
import { Stack } from "@shopify/polaris";
import styled from "styled-components";

import MainToolbar from "./MainToolbar";
import OnboardingToolbar from "./OnboardingToolbar";
import StatusMessage from "./StatusMessage";
import useNavigationToolbar from "./useNavigationToolbar";

const NavigationToolbar: React.FC = () => {
  const { onboardingStepIndex } = useNavigationToolbar();

  const isMainMenu = onboardingStepIndex < 0;

  return (
    <Stack vertical spacing="none">
      <StatusMessage />
      <StyledNavigationToolbar>{isMainMenu ? <MainToolbar /> : <OnboardingToolbar />}</StyledNavigationToolbar>
    </Stack>
  );
};

const StyledNavigationToolbar = styled.div`
  height: var(--toolbar-height);
  display: flex;
  & > * {
    flex: 1;
  }
  background-color: white;
`;

export default NavigationToolbar;
