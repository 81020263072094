import api from "../../api";
import { Entity, isProject } from "../../types/utilities";

const useAttachmentsAPI = (entity: Entity) => {
  const entityId = entity.id;

  if (isProject(entity)) {
    return {
      entityId,
      getAttachments: api.getProjectAttachments,
      addAttachment: api.addProjectAttachment,
      downloadAttachment: api.downloadProjectAttachment,
      deleteAttachment: api.deleteProjectAttachment,
      getAttachmentSignatures: api.getProjectAttachmentSignatures,
      updateAttachmentSignatures: api.updateProjectAttachmentSignatures,
      downloadSignedDocumentAttachment: api.downloadSignedProjectAttachment,
      deleteSignedAttachment: api.deleteSignedProjectAttachment,
    };
  } else {
    return {
      entityId,
      getAttachments: api.getCustomerAttachments,
      addAttachment: api.addCustomerAttachment,
      downloadAttachment: api.downloadCustomerAttachment,
      deleteAttachment: api.deleteCustomerAttachment,
      getAttachmentSignatures: api.getAttachmentSignatures,
      updateAttachmentSignatures: api.updateAttachmentSignatures,
      downloadSignedDocumentAttachment: api.downloadSignedCustomerAttachment,
      deleteSignedAttachment: api.deleteSignedCustomerAttachment,
    };
  }
};

export default useAttachmentsAPI;
