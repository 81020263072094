import React, { useState } from "react";
import { ActionList, ActionListItemDescriptor, Button, Popover, Spinner } from "@shopify/polaris";

import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";

interface CancelOnboardingMenuProps {
  id?: string;
  onMenuClose(): void;
}

const CancelOnboardingMenu: React.FC<CancelOnboardingMenuProps> = (props) => {
  const { id, onMenuClose } = props;

  const [popoverActive, setPopoverActive] = useState(false);
  const [isCancelingOnboarding, setIsCancelingOnboarding] = useState(false);

  const f = useFormatMessage();

  const cancelLink = (
    <Button
      disclosure="down"
      onClick={() => {
        setPopoverActive((popoverActive) => !popoverActive);
      }}
    >
      {f("onboarding.cancel.onboarding")}
    </Button>
  );

  const closePopupAndNavigateToHome = () => {
    setPopoverActive(false);
    onMenuClose();
  };

  const handleCancelOnboarding = async () => {
    if (id) {
      setIsCancelingOnboarding(true);
      try {
        await api.cancelOnboarding(id);
      } catch (error: unknown) {
        console.error("Error happened during canceling an onboarding", error);
      }
      setIsCancelingOnboarding(false);
    }

    closePopupAndNavigateToHome();
  };

  const items: ActionListItemDescriptor[] = [
    {
      content: f("onboarding.cancel.and.save"),
      onAction: closePopupAndNavigateToHome,
      disabled: isCancelingOnboarding,
    },
    {
      content: isCancelingOnboarding ? f("onboarding.cancel.and.delete.running") : f("onboarding.cancel.and.delete"),
      onAction: handleCancelOnboarding,
      disabled: isCancelingOnboarding,
      suffix: isCancelingOnboarding ? (
        <Spinner accessibilityLabel={f("onboarding.cancel.and.delete.running")} size="small" />
      ) : null,
    },
  ];

  return (
    <Popover active={popoverActive} activator={cancelLink} onClose={() => setPopoverActive(false)} ariaHaspopup={false}>
      <ActionList items={items} />
    </Popover>
  );
};

export default CancelOnboardingMenu;
