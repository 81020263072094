import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Card, EmptyState, Page } from "@shopify/polaris";
import { RefreshMinor } from "@shopify/polaris-icons";

import SessionExpired from "../../assets/images/max-attempts-reached.jpg";
import useAuth from "../hooks/useAuth";
import useFormatMessage from "../hooks/useFormatMessage";

const Expired: React.FC = () => {
  const { logout } = useAuth();
  const queryClient = useQueryClient();
  const f = useFormatMessage();

  useEffect(() => queryClient.clear(), []);

  return (
    <Page>
      <Card>
        <EmptyState
          heading={f("expired.heading")}
          action={{
            content: f("expired.actions.logout"),
            onAction: () => logout(),
            icon: RefreshMinor,
          }}
          image={SessionExpired}
          fullWidth={false}
        >
          <p>{f("expired.description")}</p>
        </EmptyState>
      </Card>
    </Page>
  );
};

export default Expired;
