import { useCallback } from "react";

import api from "../api";

import useDefaultCountry from "./useDefaultCountry";

type DefaultPersonDetails = {
  country_of_citizenship: api.CountryEnum;
  address: {
    country: api.CountryEnum;
  };
  isManuallyCreated?: boolean;
};

const useDefaultPersonDetails = (companyCountry?: api.CountryEnum) => {
  const defaultCountry = useDefaultCountry();

  const newPersonDetails = useCallback(
    (): DefaultPersonDetails => ({
      country_of_citizenship: companyCountry || defaultCountry,
      address: { country: companyCountry || defaultCountry },
    }),
    [companyCountry, defaultCountry]
  );

  return newPersonDetails;
};

export default useDefaultPersonDetails;
