import api from "../api";

import { DEFAULT_COUNTRY } from "./countries";

/**
 * Countries we have integrated with company registers, ie.
 * countries we get roles and owners from, and receive company
 * info updates from automatically
 */
export const COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS: api.CountryEnum[] = [DEFAULT_COUNTRY];
