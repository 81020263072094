import React, { useState } from "react";
import { ActionListItemDescriptor, Stack } from "@shopify/polaris";

import CrossmarkIcon from "../../../../assets/icons/xmark-circle.svg";
import api from "../../../api";
import ActionsDropdown from "../../../components/ActionsDropdown";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";
import useOpenClose from "../../../hooks/useOpenClose";
import { Customer } from "../../../types/utilities";

import ProjectCustomersLabelPopover from "./ProjectCustomersLabelPopover";
import RemoveCustomersFromProjectConfirm from "./RemoveCustomersFromProjectConfirm";

interface ProjectCustomersBulkActionsProps {
  project: api.Project;
  customers: Customer[];
  readonly?: boolean;
  onClearSelection(): void;
}

const ProjectCustomersBulkActions: React.FC<ProjectCustomersBulkActionsProps> = (props) => {
  const { project, customers, readonly, onClearSelection } = props;

  const f = useFormatMessage();
  const features = useFeatures();

  const [showRemoveCustomersConfirm, toggleRemoveCustomersConfirm, closeRemoveCustomersConfirm] = useOpenClose();
  const [isRemovedSuccessfully, setIsRemovedSuccessfully] = useState(false);

  const actions: ActionListItemDescriptor[] = [
    {
      content: f("project.customers.actions.remove-from-project"),
      disabled: customers.length === 0 || readonly,
      image: CrossmarkIcon,
      onAction: toggleRemoveCustomersConfirm,
    },
  ];

  const handleClose = () => {
    closeRemoveCustomersConfirm();

    // only clear the selection after the modal is closed (and not "onSuccess")
    // this is done in order for the RemoveCustomersFromProjectConfirm toast to stay rendered
    // after onSuccess is triggered
    if (isRemovedSuccessfully) {
      onClearSelection();
    }
  };

  const handleOnSuccess = () => setIsRemovedSuccessfully(true);

  return (
    <Stack spacing="tight">
      <ActionsDropdown items={actions} disabled={readonly} cutoff={1} />
      {features.PROJECT_LABELS && (
        <ProjectCustomersLabelPopover project={project} customers={customers} readonly={readonly} />
      )}
      {showRemoveCustomersConfirm && (
        <RemoveCustomersFromProjectConfirm
          project={project}
          customers={customers}
          onClose={handleClose}
          onSuccess={handleOnSuccess}
        />
      )}
    </Stack>
  );
};

export default ProjectCustomersBulkActions;
