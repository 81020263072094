import React, { useState } from "react";
import { generatePath } from "react-router";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { Page, Stack, Tabs, TextStyle } from "@shopify/polaris";

import CompanyCustomerForm from "../../components/CompanyCustomerForm";
import { COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS } from "../../constants/integrations";
import { ROUTES } from "../../constants/routes";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { MessageKey } from "../../providers/LanguageProvider";
import HistoryLocationState from "../../types/HistoryLocationState";
import { hasIntegration } from "../../utils/integrationUtils";

import NewCompanyLookup from "./NewCompanyLookup";

const NewCompanyCustomer: React.FC<RouteComponentProps> = ({ history }) => {
  const f = useFormatMessage();
  const features = useFeatures();
  const defaultCountry = useDefaultCountry();
  const location = useLocation<HistoryLocationState>();

  const createdFromProject = location.state?.createdFromProject;

  const companyCountryOptions = [
    ...COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS.map((countryCode) => ({
      id: countryCode,
      content: f(`new-company-customer.country-options.${countryCode.toLowerCase()}` as MessageKey),
    })),
    { id: "other", content: f("new-company-customer.country-options.other") },
  ];

  const [companyCountry, setCompanyCountry] = useState<string>(defaultCountry);

  return (
    <Page>
      {features.MANUAL_COMPANIES && (
        <Tabs
          tabs={companyCountryOptions}
          selected={companyCountryOptions.findIndex((t) => t.id === companyCountry)}
          onSelect={(i) => setCompanyCountry(companyCountryOptions[i].id)}
        />
      )}

      {hasIntegration(companyCountry) && (
        <NewCompanyLookup projectId={createdFromProject?.id} projectName={createdFromProject?.name} />
      )}
      {!hasIntegration(companyCountry) && (
        <Page title={f("new-company-customer.titles.manual")}>
          <Stack vertical>
            <TextStyle>{f("new-company-customer.texts.manual")}</TextStyle>
            <CompanyCustomerForm
              projectId={createdFromProject?.id}
              projectName={createdFromProject?.name}
              onAfterSubmit={(createdCompanyCustomer) => {
                history.push(
                  generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_SCREENING, { id: createdCompanyCustomer.id })
                );
              }}
            />
          </Stack>
        </Page>
      )}
    </Page>
  );
};

export default withRouter(NewCompanyCustomer);
