import { FeaturesTypes } from "./Features";

const ADMIN_USER = "admin_user";
const ADD_USER = "add_user";
const CHANGE_USER = "change_user";

export const RequiredPermissionsForFeature: Record<FeaturesTypes, string[]> = {
  USER_MANAGEMENT: [ADMIN_USER, ADD_USER, CHANGE_USER],
  EXTERNAL_ID: [],
  ATTACHMENTS: [],
  UPDATE_SCREENING_CONFIGURATION: [],
  RESPONSIBLE_USER: [],
  ARCHIVE_CUSTOMER: [],
  LEGAL_ENTITY_MONITORING: [],
  ELECTRONIC_SIGNATURES: [],
  MANUAL_COMPANIES: [],
  PROJECTS: [],
  PROJECT_LABELS: [],
};
