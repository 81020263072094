import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { generatePath, RouteComponentProps } from "react-router-dom";
import { Button, Card, DataTable, DisplayText, Layout, Page, Stack, TextStyle } from "@shopify/polaris";

import api from "../../api";
import DisplayName from "../../components/DisplayName";
import ErrorPanel from "../../components/ErrorPanel";
import BackButton from "../../components/navigation/BackButton";
import ScreeningSkeletonPage from "../../components/screening/ScreeningSkeletonPage";
import ScreeningStatusBadge from "../../components/screening/ScreeningStatusBadge";
import ScreeningTypeCard from "../../components/screening/ScreeningTypeCard";
import StopScreeningModal from "../../components/screening/StopScreeningModal";
import SectionMargin from "../../components/SectionMargin";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IDParams } from "../../types/params";
import { getFullName } from "../../utils/displayUtils";
import ErrorPage from "../ErrorPage";

const PERSON_DETAILS_RESPONSE_INDEX = 0;
const PERSON_SCREENING_RESPONSE_INDEX = 1;

const PersonChangeScreening: React.FC<RouteComponentProps<IDParams>> = ({ history, match }) => {
  const id = match.params.id;

  const f = useFormatMessage();
  const queryClient = useQueryClient();

  const [postError, setPostError] = useState<string>();
  const [isPosting, setPosting] = useState(false);

  const [screeningType, setScreeningType] = useState<api.ScreeningType>("MONITORING");

  const [isControlConfirmationActive, setControlConfirmationActive] = useState(false);

  const { isLoading, isError, error, data } = useQuery(["person-change-screening", id], () =>
    Promise.all([api.getPersonCustomerDetails(id), api.getPersonScreeningConfiguration(id)])
  );

  const screening = data ? data[PERSON_SCREENING_RESPONSE_INDEX] : undefined;
  const isNewScreening = screening?.screening_status && screening.screening_status === "DONE";
  const customerName = screening?.customer_name || "";

  useEffect(() => {
    if (screening?.screening_type) {
      setScreeningType(screening.screening_type);
    }
  }, [screening?.screening_type]);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  if (isLoading || !data) {
    return <ScreeningSkeletonPage />;
  }

  const person = data[PERSON_DETAILS_RESPONSE_INDEX];

  return (
    <Page>
      {/* // Render stop control modal */}
      <StopScreeningModal
        id={id}
        isOpen={isControlConfirmationActive}
        isPerson={true}
        onStop={() => {
          setControlConfirmationActive(false);
          history.push(`/persons/${id}`, { snackbar: f("screening.snackbar.stopped") });
        }}
        onClose={() => {
          setControlConfirmationActive(false);
        }}
      />
      <Stack vertical>
        <BackButton url={generatePath(ROUTES.PERSON_DETAILS, { id: id })} displayLabel={customerName} showAsLink />

        <DisplayText size="large">
          {!isNewScreening ? f("change-screening.titles.screening-title") : f("change-screening.titles.new-screening")}
        </DisplayText>

        <Layout>
          <Layout.Section>
            <Stack vertical>
              {!isNewScreening && (
                <Stack distribution="equalSpacing">
                  <Stack alignment="center" spacing="tight">
                    <TextStyle variation="strong">Status:</TextStyle>
                    <div>{f(`screening-type.${screeningType}`)}</div>
                    <ScreeningStatusBadge
                      screening={{
                        screening_status: screening!.screening_status,
                        screening_type: screeningType,
                      }}
                    />
                  </Stack>
                  <Button onClick={() => setControlConfirmationActive(true)}>
                    {f("change-screening.buttons.stop-monitoring")}
                  </Button>
                </Stack>
              )}

              <DisplayText size="small">{f("change-screening.titles.screening-person")}</DisplayText>

              <Card>
                <DataTable
                  columnContentTypes={["text"]}
                  headings={[]}
                  rows={[
                    [
                      <DisplayName
                        type={"person"}
                        name={getFullName(person)}
                        birthDate={person.birth_year || person.birth_date}
                      />,
                    ],
                  ]}
                />
              </Card>

              {isNewScreening && (
                <Stack vertical>
                  <SectionMargin />
                  <DisplayText size="medium">{f("change-screening.titles.choose-screening-type")}</DisplayText>
                  <ScreeningTypeCard
                    screeningType={screeningType}
                    onScreeningTypeChange={(newValue) => setScreeningType(newValue)}
                  />

                  {postError && <ErrorPanel message={postError} />}

                  <Stack distribution="trailing">
                    <Button
                      disabled={isPosting}
                      onClick={() => {
                        history.push(`/persons/${id}`);
                      }}
                    >
                      {f("default.cancel")}
                    </Button>

                    <Button
                      primary
                      disabled={isPosting || !screeningType}
                      loading={isPosting}
                      onClick={() => {
                        setPosting(true);
                        api
                          .updatePersonScreeningConfiguration(id, {
                            screening_type: screeningType,
                          })
                          .then(() => {
                            setPosting(false);
                            queryClient.invalidateQueries(["person-change-screening", id]);
                            history.push(`/persons/${id}`, { snackbar: f("screening.snackbar.started") });
                          })
                          .catch((error) => {
                            setPosting(false);
                            setPostError(error.toString());
                          });
                      }}
                    >
                      {f("change-screening.buttons.start-screening")}
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Layout.Section>
        </Layout>
      </Stack>
    </Page>
  );
};

export default PersonChangeScreening;
