{
  "Polaris": {
    "ActionMenu": {
      "Actions": {
        "moreActions": "More actions"
      },
      "RollupActions": {
        "rollupButton": "View actions"
      }
    },
    "Avatar": {
      "label": "Avatar",
      "labelWithInitials": "Avatar with initials {initials}"
    },
    "Autocomplete": {
      "spinnerAccessibilityLabel": "Loading",
      "ellipsis": "{content}…"
    },
    "Badge": {
      "PROGRESS_LABELS": {
        "incomplete": "Incomplete",
        "partiallyComplete": "Partially complete",
        "complete": "Complete"
      },
      "STATUS_LABELS": {
        "info": "Info",
        "success": "Success",
        "warning": "Warning",
        "critical": "Critical",
        "attention": "Attention",
        "new": "New"
      },
      "progressAndStatus": "{statusLabel} {progressLabel}"
    },
    "Button": {
      "spinnerAccessibilityLabel": "Loading",
      "connectedDisclosureAccessibilityLabel": "Related actions"
    },
    "Common": {
      "checkbox": "checkbox",
      "undo": "Undo",
      "cancel": "Cancel",
      "newWindowAccessibilityHint": "(opens a new window)",
      "clear": "Clear",
      "close": "Close",
      "submit": "Submit",
      "more": "More"
    },
    "ContextualSaveBar": {
      "save": "Save",
      "discard": "Discard"
    },
    "DataTable": {
      "sortAccessibilityLabel": "sort {direction} by",
      "navAccessibilityLabel": "Scroll table {direction} one column",
      "totalsRowHeading": "Totals",
      "totalRowHeading": "Total"
    },
    "DatePicker": {
      "previousMonth": "Show previous month, {previousMonthName} {showPreviousYear}",
      "nextMonth": "Show next month, {nextMonth} {nextYear}",
      "today": "Today ",
      "start": "Start of range",
      "end": "End of range",
      "months": {
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December"
      },
      "days": {
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday"
      },
      "daysAbbreviated": {
        "monday": "Mo",
        "tuesday": "Tu",
        "wednesday": "We",
        "thursday": "Th",
        "friday": "Fr",
        "saturday": "Sa",
        "sunday": "Su"
      }
    },
    "DiscardConfirmationModal": {
      "title": "Discard all unsaved changes",
      "message": "If you discard changes, you’ll delete any edits you made since you last saved.",
      "primaryAction": "Discard changes",
      "secondaryAction": "Continue editing"
    },
    "DropZone": {
      "single": {
        "overlayTextFile": "Drop file to upload",
        "overlayTextImage": "Drop image to upload",
        "actionTitleFile": "Add file",
        "actionTitleImage": "Add image",
        "actionHintFile": "or drop file to upload",
        "actionHintImage": "or drop image to upload",
        "labelFile": "Upload file",
        "labelImage": "Upload image"
      },
      "allowMultiple": {
        "overlayTextFile": "Drop files to upload",
        "overlayTextImage": "Drop images to upload",
        "actionTitleFile": "Add files",
        "actionTitleImage": "Add images",
        "actionHintFile": "or drop files to upload",
        "actionHintImage": "or drop images to upload",
        "labelFile": "Upload files",
        "labelImage": "Upload images"
      },
      "errorOverlayTextFile": "File type is not valid",
      "errorOverlayTextImage": "Image type is not valid"
    },
    "EmptySearchResult": {
      "altText": "Empty search results"
    },
    "Frame": {
      "skipToContent": "Skip to content",
      "navigationLabel": "Navigation",
      "Navigation": {
        "closeMobileNavigationLabel": "Close navigation"
      }
    },
    "Filters": {
      "moreFilters": "More filters",
      "moreFiltersWithCount": "More filters ({count})",
      "filter": "Filter {resourceName}",
      "noFiltersApplied": "No filters applied",
      "cancel": "Cancel",
      "done": "Done",
      "clearAllFilters": "Clear all filters",
      "clear": "Clear",
      "clearLabel": "Clear {filterName}"
    },
    "IndexProvider": {
      "defaultItemSingular": "Item",
      "defaultItemPlural": "Items",
      "allItemsSelected": "All {itemsLength}+ {resourceNamePlural} are selected.",
      "selected": "{selectedItemsCount} selected",
      "a11yCheckboxDeselectAllSingle": "Deselect {resourceNameSingular}",
      "a11yCheckboxSelectAllSingle": "Select {resourceNameSingular}",
      "a11yCheckboxDeselectAllMultiple": "Deselect all {itemsLength} {resourceNamePlural}",
      "a11yCheckboxSelectAllMultiple": "Select all {itemsLength} {resourceNamePlural}"
    },
    "IndexTable": {
      "emptySearchTitle": "No {resourceNamePlural} found",
      "emptySearchDescription": "Try changing the filters or search term",
      "onboardingBadgeText": "New",
      "resourceLoadingAccessibilityLabel": "Loading {resourceNamePlural}…",
      "selectAllLabel": "Select all {resourceNamePlural}",
      "selected": "{selectedItemsCount} selected",
      "undo": "Undo",
      "selectAllItems": "Select all {itemsLength}+ {resourceNamePlural}",
      "selectItem": "Select {resourceName}",
      "selectButtonText": "Select"
    },
    "Loading": {
      "label": "Page loading bar"
    },
    "Modal": {
      "iFrameTitle": "body markup",
      "modalWarning": "These required properties are missing from Modal: {missingProps}"
    },
    "Page": {
      "Header": {
        "rollupActionsLabel": "View actions for {title}"
      }
    },
    "Pagination": {
      "previous": "Previous",
      "next": "Next",
      "pagination": "Pagination"
    },
    "ProgressBar": {
      "negativeWarningMessage": "Values passed to the progress prop shouldn’t be negative. Resetting {progress} to 0.",
      "exceedWarningMessage": "Values passed to the progress prop shouldn’t exceed 100. Setting {progress} to 100."
    },
    "ResourceList": {
      "sortingLabel": "Sort by",
      "defaultItemSingular": "item",
      "defaultItemPlural": "items",
      "showing": "Showing {itemsCount} {resource}",
      "showingTotalCount": "Showing {itemsCount} of {totalItemsCount} {resource}",
      "loading": "Loading {resource}",
      "selected": "{selectedItemsCount} selected",
      "allItemsSelected": "All {itemsLength}+ {resourceNamePlural} in your store are selected.",
      "allFilteredItemsSelected": "All {itemsLength}+ {resourceNamePlural} in this filter are selected.",
      "selectAllItems": "Select all {itemsLength}+ {resourceNamePlural} in your store",
      "selectAllFilteredItems": "Select all {itemsLength}+ {resourceNamePlural} in this filter",
      "emptySearchResultTitle": "No {resourceNamePlural} found",
      "emptySearchResultDescription": "Try changing the filters or search term",
      "selectButtonText": "Select",
      "a11yCheckboxDeselectAllSingle": "Deselect {resourceNameSingular}",
      "a11yCheckboxSelectAllSingle": "Select {resourceNameSingular}",
      "a11yCheckboxDeselectAllMultiple": "Deselect all {itemsLength} {resourceNamePlural}",
      "a11yCheckboxSelectAllMultiple": "Select all {itemsLength} {resourceNamePlural}",
      "ariaLiveSingular": "{itemsLength} item",
      "ariaLivePlural": "{itemsLength} items",
      "Item": {
        "actionsDropdownLabel": "Actions for {accessibilityLabel}",
        "actionsDropdown": "Actions dropdown",
        "viewItem": "View details for {itemName}"
      },
      "BulkActions": {
        "actionsActivatorLabel": "Actions",
        "moreActionsActivatorLabel": "More actions",
        "warningMessage": "To provide a better user experience. There should only be a maximum of {maxPromotedActions} promoted actions."
      },
      "FilterCreator": {
        "filterButtonLabel": "Filter",
        "selectFilterKeyPlaceholder": "Select a filter\u2026",
        "addFilterButtonLabel": "Add filter",
        "showAllWhere": "Show all {resourceNamePlural} where:"
      },
      "FilterControl": {
        "textFieldLabel": "Search {resourceNamePlural}"
      },
      "FilterValueSelector": {
        "selectFilterValuePlaceholder": "Select a filter\u2026"
      },
      "DateSelector": {
        "dateFilterLabel": "Select a value",
        "dateValueLabel": "Date",
        "dateValueError": "Match YYYY-MM-DD format",
        "dateValuePlaceholder": "YYYY-MM-DD",
        "SelectOptions": {
          "PastWeek": "in the last week",
          "PastMonth": "in the last month",
          "PastQuarter": "in the last 3 months",
          "PastYear": "in the last year",
          "ComingWeek": "next week",
          "ComingMonth": "next month",
          "ComingQuarter": "in the next 3 months",
          "ComingYear": "in the next year",
          "OnOrBefore": "on or before",
          "OnOrAfter": "on or after"
        },
        "FilterLabelForValue": {
          "past_week": "in the last week",
          "past_month": "in the last month",
          "past_quarter": "in the last 3 months",
          "past_year": "in the last year",
          "coming_week": "next week",
          "coming_month": "next month",
          "coming_quarter": "in the next 3 months",
          "coming_year": "in the next year",
          "on_or_before": "before {date}",
          "on_or_after": "after {date}"
        }
      }
    },
    "SkeletonPage": {
      "loadingLabel": "Page loading"
    },
    "Tabs": {
      "toggleTabsLabel": "More tabs"
    },
    "Tag": {
      "ariaLabel": "Remove {children}"
    },
    "TextField": {
      "characterCount": "{count} characters",
      "characterCountWithMaxLength": "{count} of {limit} characters used"
    },
    "TooltipOverlay": {
      "accessibilityLabel": "Tooltip: {label}"
    },
    "TopBar": {
      "toggleMenuLabel": "Toggle menu",
      "SearchField": {
        "clearButtonLabel": "Clear",
        "search": "Search"
      }
    },
    "MediaCard": {
      "popoverButton": "Actions"
    },
    "VideoThumbnail": {
      "playButtonA11yLabel": {
        "default": "Play video",
        "defaultWithDuration": "Play video of length {duration}",
        "duration": {
          "hours": {
            "other": {
              "only": "{hourCount} hours",
              "andMinutes": "{hourCount} hours and {minuteCount} minutes",
              "andMinute": "{hourCount} hours and {minuteCount} minute",
              "minutesAndSeconds": "{hourCount} hours, {minuteCount} minutes, and {secondCount} seconds",
              "minutesAndSecond": "{hourCount} hours, {minuteCount} minutes, and {secondCount} second",
              "minuteAndSeconds": "{hourCount} hours, {minuteCount} minute, and {secondCount} seconds",
              "minuteAndSecond": "{hourCount} hours, {minuteCount} minute, and {secondCount} second",
              "andSeconds": "{hourCount} hours and {secondCount} seconds",
              "andSecond": "{hourCount} hours and {secondCount} second"
            },
            "one": {
              "only": "{hourCount} hour",
              "andMinutes": "{hourCount} hour and {minuteCount} minutes",
              "andMinute": "{hourCount} hour and {minuteCount} minute",
              "minutesAndSeconds": "{hourCount} hour, {minuteCount} minutes, and {secondCount} seconds",
              "minutesAndSecond": "{hourCount} hour, {minuteCount} minutes, and {secondCount} second",
              "minuteAndSeconds": "{hourCount} hour, {minuteCount} minute, and {secondCount} seconds",
              "minuteAndSecond": "{hourCount} hour, {minuteCount} minute, and {secondCount} second",
              "andSeconds": "{hourCount} hour and {secondCount} seconds",
              "andSecond": "{hourCount} hour and {secondCount} second"
            }
          },
          "minutes": {
            "other": {
              "only": "{minuteCount} minutes",
              "andSeconds": "{minuteCount} minutes and {secondCount} seconds",
              "andSecond": "{minuteCount} minutes and {secondCount} second"
            },
            "one": {
              "only": "{minuteCount} minute",
              "andSeconds": "{minuteCount} minute and {secondCount} seconds",
              "andSecond": "{minuteCount} minute and {secondCount} second"
            }
          },
          "seconds": {
            "other": "{secondCount} seconds",
            "one": "{secondCount} second"
          }
        }
      }
    }
  }
}
