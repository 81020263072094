import React from "react";
import { Stack, Tooltip } from "@shopify/polaris";
import styled from "styled-components";

import EnvelopeIcon from "../../../../assets/icons/envelope.svg";
import useFormatMessage from "../../../hooks/useFormatMessage";
import CopyLabel from "../../CopyLabel";
import Icon from "../../extensions/Icon";
import { AttachmentSignature } from "../types/AttachmentSignature";

interface AttachmentSignatureEmailOrLinkLabelProps {
  attachmentSignature: AttachmentSignature;
}

const AttachmentSignatureEmailOrLinkLabel: React.FC<AttachmentSignatureEmailOrLinkLabelProps> = (props) => {
  const { attachmentSignature } = props;

  const f = useFormatMessage();

  if (attachmentSignature.send_email) {
    return (
      <Tooltip content={attachmentSignature.email} preferredPosition="above">
        <Stack spacing="extraTight" alignment="center">
          <Icon source={EnvelopeIcon} />
          <StyledEmailLabel>{attachmentSignature.email}</StyledEmailLabel>
        </Stack>
      </Tooltip>
    );
  }

  if (!attachmentSignature.signing_page_url) {
    return <div>{f("attachments.signatures.modal.labels.missing-url")}</div>;
  }

  return (
    <CopyLabel size="slim" maxWidth="15ch">
      {attachmentSignature.signing_page_url}
    </CopyLabel>
  );
};

const StyledEmailLabel = styled.div`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default AttachmentSignatureEmailOrLinkLabel;
