import React, { useLayoutEffect, useState } from "react";
import { Banner, Card, DataTable, DisplayText, Heading, Page, Stack, TextStyle } from "@shopify/polaris";

import api, { CompanyCustomerDetailResponse } from "../../api";
import { AsyncPageChild, withAsyncPage } from "../../components/AsyncPage";
import AttachmentsSection from "../../components/attachments/attachments-section/AttachmentsSection";
import Badges from "../../components/Badges";
import BasicCustomerInfo from "../../components/BasicCustomerInfo";
import CompanyInfo from "../../components/company-details/CompanyInfo";
import CompanyOwners from "../../components/company-details/CompanyOwners";
import CompanyRoles from "../../components/company-details/CompanyRoles";
import CriticalUpdatesBanner from "../../components/company-details/CriticalUpdatesBanner";
import CustomerActionsMenu from "../../components/CustomerActionsMenu";
import DetailsSkeleton from "../../components/DetailsSkeleton";
import DisclosureDocumentSection from "../../components/disclosure/DisclosureDocumentSection";
import DisplayName from "../../components/DisplayName";
import MatchCases from "../../components/MatchCases";
import BackButton from "../../components/navigation/BackButton";
import NavigationSection from "../../components/navigation/NavigationSection";
import PossibleMatchBanner from "../../components/PossibleMatchBanner";
import ProjectsSection from "../../components/ProjectsSection";
import CustomerReviewsSection from "../../components/reviews-section/CustomerReviewsSection";
import SectionMargin from "../../components/SectionMargin";
import Snackbar from "../../components/Snackbar";
import { PAGE_ANCHORS } from "../../constants/page-anchors";
import RoleTypes from "../../constants/role-types";
import useAppTitle from "../../hooks/useAppTitle";
import useCustomerBackButton from "../../hooks/useCustomerBackButton";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePageNavigation from "../../hooks/usePageNavigation";
import useSetLocationState from "../../hooks/useSetLocationState";
import { NavigationItem } from "../../providers/PageNavigation";
import { getBirthDate, getCompanyDisplayName, getFullName } from "../../utils/displayUtils";
import { isOnboardingFinished } from "../../utils/onboardingUtils";
import { getCustomerRisk } from "../../utils/riskUtils";

const isScreeningStarting = (screeningStatus?: api.ScreeningStatus) =>
  screeningStatus && ["NOT_STARTED", "STARTING"].includes(screeningStatus);

const isRole = (ownerOrRole: api.Role | api.Owner): ownerOrRole is api.Role =>
  (ownerOrRole as api.Role).type !== undefined;

const CompanyDetails: React.FC<AsyncPageChild<api.CompanyCustomerDetailResponse>> = ({ data: customer }) => {
  const features = useFeatures();
  const f = useFormatMessage();
  useAppTitle(customer.name);

  const [risk, setRisk] = useState<api.RiskLevel | undefined>(getCustomerRisk(customer));
  const { setNavigationItems, clearNavigationItems } = usePageNavigation();

  const { backButtonDisplayLabel, backButtonURL } = useCustomerBackButton();

  useSetLocationState("fromCustomer", { id: customer.id, name: getCompanyDisplayName(customer), type: "company" });

  const showProjects = features.PROJECTS && customer.projects && customer.projects.length > 0;

  useLayoutEffect(() => {
    if (!customer) {
      return;
    }

    const hasNewInfo =
      features.LEGAL_ENTITY_MONITORING && customer.info_updates.find((info) => !info.is_reviewed) !== undefined;

    const navigationItems: NavigationItem[] = [
      { anchor: PAGE_ANCHORS.TOP_SECTION, label: f("navigation.details.overview.title") },
      { anchor: PAGE_ANCHORS.DISCLOSURES_SECTION, label: f("disclosure-documents.title") },
      { anchor: PAGE_ANCHORS.CUSTOMER_DETAILS, label: f("company.details.title"), options: { isNew: hasNewInfo } },
    ];

    if (showProjects) {
      navigationItems.push({
        anchor: PAGE_ANCHORS.CUSTOMER_PROJECTS,
        label: f("projects.page.title"),
      });
    }

    navigationItems.push({
      anchor: PAGE_ANCHORS.COMPANY_ROLES_AND_OWNERS_SECTION,
      label: f("navigation.details.company.roles-and-owners"),
    });

    if (features.ATTACHMENTS) {
      navigationItems.push({
        anchor: PAGE_ANCHORS.ATTACHMENTS_SECTION,
        label: f("attachments.box.title"),
      });
    }

    navigationItems.push({ anchor: PAGE_ANCHORS.REVIEWS_SECTION, label: f("reviews.header.title") });

    setNavigationItems(navigationItems);

    return () => clearNavigationItems();
  }, [customer, features]);

  const renderScreening = (s: (api.Role | api.Owner)[]) => {
    return s.map((r) => {
      return [
        <DisplayName
          type="person"
          name={getFullName(r.person)}
          birthDate={getBirthDate(r.person)}
          comment={r.comment}
        />,
        isRole(r) ? f(RoleTypes[r.type]) : f("table.row.owner"),
      ];
    });
  };

  const industryRisk = customer.risk_information;

  const disclosureDocuments = customer.disclosure_documents;
  const roles = customer.roles;
  const expiredRoles = customer.expired_roles;
  const owners = customer.beneficial_owners;

  const screeningRoles = (customer.screening?.roles || []).map((role) => role.role);
  const screeningOwners = (customer.screening?.beneficial_owners || []).map((beneficialOwner) => beneficialOwner.owner);

  const screening = [...screeningRoles, ...screeningOwners];
  const screeningStatus = customer.screening_status;

  const matchCases = customer.match_cases;
  const reviews = customer.reviews;
  const pendingReviews = customer.pending_reviews;

  const hasFinishedOnboarding = isOnboardingFinished(customer);

  const isMonitoring =
    customer.screening?.screening_status === "MONITORING" && screeningStatus.screening_stopped !== undefined;

  const readonly = customer.is_archived || !hasFinishedOnboarding;

  const fetchSuggestedSigners = () => Promise.resolve<CompanyCustomerDetailResponse[]>([customer]);

  return (
    <Page>
      <Stack vertical wrap={false} spacing="baseTight">
        {/* Header */}
        <Stack vertical wrap={false}>
          <BackButton showAsLink displayLabel={backButtonDisplayLabel} url={backButtonURL} />
          <Stack alignment="trailing" spacing="tight">
            <DisplayText size="large">{customer.name || ""}</DisplayText>
            <Badges isArchived={customer.is_archived} isPep={customer.is_pep} isSanctioned={customer.is_sanctioned} />
          </Stack>
          <Stack distribution="equalSpacing">
            <BasicCustomerInfo
              customerId={customer.id}
              risk={risk}
              onboardingStatus={customer.onboarding_status}
              reviewStatus={customer.review_status}
              screening={screeningStatus!}
              responsibleUser={customer.responsible}
              readonly={readonly}
            />
            {hasFinishedOnboarding && <CustomerActionsMenu customer={customer} isMonitoring={isMonitoring} />}
          </Stack>

          <CriticalUpdatesBanner companyDetails={customer} />
          {isScreeningStarting(customer.screening?.screening_status) && (
            <Banner title={f("details.banners.screening.in.progress.title")} status="info">
              <p>{f("details.banners.screening.in.progress.content")}</p>
            </Banner>
          )}

          {!isScreeningStarting(customer.screening?.screening_status) &&
            hasFinishedOnboarding &&
            matchCases.length == 0 && (
              <Banner title={f("details.banners.no.matches.title")} status="success">
                <p>{f("details.banners.no.matches.content")}</p>
              </Banner>
            )}

          {matchCases.length > 0 && matchCases.findIndex((m) => m.status != "RESOLVED") >= 0 && <PossibleMatchBanner />}
        </Stack>

        <SectionMargin />
        <MatchCases
          customerId={customer.id}
          hasFinishedOnboarding={hasFinishedOnboarding}
          matchCases={matchCases}
          readonly={readonly}
        />

        <SectionMargin />
        <Heading>{f("company.details.onboarding.title")}</Heading>
        <Card>
          {hasFinishedOnboarding ? (
            <DataTable
              columnContentTypes={["text", "text"]}
              headings={[
                <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
                <TextStyle variation="subdued">{f("table.column.title")}</TextStyle>,
              ]}
              rows={[
                [
                  <DisplayName type="company" name={customer.name} orgId={customer.national_id} />,
                  f("screening.titles.customer"),
                ],
                ...renderScreening(screening),
              ]}
            />
          ) : (
            <Card.Section>{f("company.details.onboarding.no-screening-performed")}</Card.Section>
          )}
        </Card>

        <NavigationSection anchor={PAGE_ANCHORS.DISCLOSURES_SECTION} />
        <DisclosureDocumentSection
          customerId={customer.id}
          disclosureDocuments={disclosureDocuments}
          readonly={readonly}
        />
        <NavigationSection anchor={PAGE_ANCHORS.CUSTOMER_DETAILS} />
        <CompanyInfo companyDetails={customer} info_updates={customer.info_updates} />

        {showProjects && (
          <>
            <NavigationSection anchor={PAGE_ANCHORS.CUSTOMER_PROJECTS} />
            <ProjectsSection projects={customer.projects} />
          </>
        )}

        <NavigationSection anchor={PAGE_ANCHORS.COMPANY_ROLES_AND_OWNERS_SECTION} />
        <CompanyOwners
          customerId={customer.id}
          owners={owners}
          readonly={readonly}
          companyType={customer.company_type}
          companyCountry={customer.country}
          canHaveShareholders={customer.can_have_shareholders === false ? false : true}
        />
        <SectionMargin />
        <CompanyRoles
          customerId={customer.id}
          companyCountry={customer.country}
          roles={roles}
          expiredRoles={expiredRoles}
          readonly={readonly}
        />

        {features.ATTACHMENTS && (
          <>
            <NavigationSection anchor={PAGE_ANCHORS.ATTACHMENTS_SECTION} />
            <AttachmentsSection entity={customer} fetchSuggestedSigners={fetchSuggestedSigners} readonly={readonly} />
          </>
        )}

        <NavigationSection anchor={PAGE_ANCHORS.REVIEWS_SECTION} />
        <CustomerReviewsSection
          risk={risk}
          customerId={customer.id}
          reviews={reviews}
          pendingReviews={pendingReviews}
          industryRisk={industryRisk}
          readonly={readonly}
          onRiskTypeChange={(newRisk) => setRisk(newRisk)}
        />
        <Snackbar />
      </Stack>
    </Page>
  );
};

export default withAsyncPage<api.CompanyCustomerDetailResponse>(CompanyDetails, {
  name: "company-details",
  apiFunction: api.getCompanyCustomerDetails,
  paramNames: ["id"],
  skeleton: <DetailsSkeleton />,
});
