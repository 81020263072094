import React, { CSSProperties } from "react";
import styled from "styled-components";

import IconApproved from "../../assets/icons/status-approved.svg";
import IconReady from "../../assets/icons/status-ready.svg";
import IconRejected from "../../assets/icons/status-rejected.svg";
import IconStarted from "../../assets/icons/status-started.svg";
import IconWaiting from "../../assets/icons/status-waiting.svg";
import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";

type StateIconProperties = { icon: string; width: CSSProperties["width"]; color: CSSProperties["color"] };

const StateIcons: Record<string, StateIconProperties> = {
  started: {
    icon: IconStarted,
    width: "10px",
    color: "inherit",
  },
  waiting: {
    icon: IconWaiting,
    width: "8px",
    color: "var(--gray)",
  },
  ready: {
    icon: IconReady,
    width: "10px",
    color: "#E08C3F",
  },
  approved: {
    icon: IconApproved,
    width: "10px",
    color: "var(--greenLighten30)",
  },
  rejected: {
    icon: IconRejected,
    width: "10px",
    color: "var(--red)",
  },
};

const ONBOARDING_STATUS_CLASSES = {
  FETCHING_DATA: "started",
  SCREENING_CONFIG: "started",
  SIGNATURE_CONFIG: "started",
  SIGNATURE_EMAILS: "started",
  NOT_SIGNED: "waiting",
  READY: "started",
};

const REVIEW_STATUS_CLASSES = {
  NOT_READY: "waiting",
  REVIEW_REQUIRED: "ready",
  APPROVED: "approved",
  REJECTED: "rejected",
};

const getStatusIconProperties = (
  onboardingStatus: api.OnboardingStatus,
  reviewStatus: api.ReviewStatus
): StateIconProperties => {
  if (onboardingStatus != "DONE") {
    return StateIcons[ONBOARDING_STATUS_CLASSES[onboardingStatus]];
  } else {
    return StateIcons[REVIEW_STATUS_CLASSES[reviewStatus]];
  }
};

const CaseStatus = ({
  onboardingStatus,
  reviewStatus,
}: {
  onboardingStatus: api.OnboardingStatus;
  reviewStatus: api.ReviewStatus;
}) => {
  const f = useFormatMessage();

  const customerStatusTitle = (onboardingStatus: api.OnboardingStatus, reviewStatus: api.ReviewStatus) => {
    // If the customer is still onboarding, ie. onboardingStatus != "DONE",
    // return the title for the onboarding status
    if (onboardingStatus !== "DONE") {
      // the subject has been created, but subject data (error.g. roles, ownership etc.) is still being fetched
      // the subject has been created and data has been fetched, next operation is to select roles/owners etc. for screening
      // screening roles have been selected, next operation is to specify which roles should sign on behalf of the subject
      // signature roles have been selected, next operation is to add email addresses for the selected roles/persons
      // the onboarding is fully configured and ready to be finished, but has not been so. Next operation is to show summary and finish onboarding
      if (
        ["FETCHING_DATA", "SCREENING_CONFIG", "SIGNATURE_CONFIG", "SIGNATURE_EMAILS", "READY"].includes(
          onboardingStatus
        )
      ) {
        return f("onboarding.status.started");
        // the onboarding is completed, but it has not yet been signed by the selected role(s)
      } else if (onboardingStatus === "NOT_SIGNED") {
        return f("onboarding.status.not-signed");
      } else {
        return "";
      }
    } else {
      // Else, return the title for the review status
      switch (reviewStatus) {
        // subject is not ready for a review, eg. because onboarding is not done, or screening is not done
        case "NOT_READY":
          return f("review.status.not-ready");
        // a new subject review is required, eg. because there are open cases, or onboarding is done and there exists no review
        case "REVIEW_REQUIRED":
          return f("review.status.required");
        // the last review approved the subject, and there is nothing new to be reviewed
        case "APPROVED":
          return f("review.status.approved");
        // the last review rejected the subject, and there is nothing new to be reviewed
        case "REJECTED":
          return f("review.status.rejected");
        default:
          return "";
      }
    }
  };

  return (
    <StyledCaseStatus {...getStatusIconProperties(onboardingStatus, reviewStatus)}>
      <div className="not-on-mobile">{customerStatusTitle(onboardingStatus, reviewStatus)}</div>
    </StyledCaseStatus>
  );
};

const StyledCaseStatus = styled.div<StateIconProperties>`
  display: inline-flex;
  align-items: center;
  &:before {
    margin-right: 5px;
    margin-left: 5px;
    content: " ";
    background-image: url(${(props) => props.icon});
    background-size: ${(props) => props.width} 10px;
    height: 10px;
    width: ${(props) => props.width};
    min-width: ${(props) => props.width};
    color: ${(props) => props.color};
  }
`;

export default CaseStatus;
