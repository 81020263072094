import { FeaturesTypes } from "../constants/Features";
import { RequiredPermissionsForFeature } from "../constants/permissions";

import useAuth from "./useAuth";

const usePermissions = () => {
  const { user } = useAuth();

  const permissions = user?.permissions || [];
  const features = user?.features || {};

  const isAllowed = (featureType?: FeaturesTypes) => {
    const hasFeature = Boolean(featureType && features[featureType]);

    const requiredPermissions = featureType ? RequiredPermissionsForFeature[featureType] : [];

    return hasFeature && requiredPermissions.every((permission) => permissions.includes(permission));
  };

  return isAllowed;
};

export default usePermissions;
