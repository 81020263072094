import React from "react";
import { Stack, TextStyle } from "@shopify/polaris";

import api from "../../../api";
import { AttachmentSignatureStatusIconsMap } from "../../../constants/attachment-signature-statuses";
import useFormatMessage from "../../../hooks/useFormatMessage";
import { MessageKey } from "../../../providers/LanguageProvider";
import Icon from "../../extensions/Icon";

const AttachmentSignatureStatusTranslationKeys: Record<api.AttachmentSignatureStatus, MessageKey> = {
  PENDING: "attachments.labels.signature-status.pending",
  EXPIRED: "attachments.labels.signature-status.expired",
  SIGNED: "attachments.labels.signature-status.signed",
};

interface AttachmentSignatureStatusLabelProps {
  status?: api.AttachmentSignatureStatus;
}

const AttachmentSignatureStatusLabel: React.FC<AttachmentSignatureStatusLabelProps> = (props) => {
  const { status } = props;

  const f = useFormatMessage();

  if (!status) {
    return <TextStyle> - </TextStyle>;
  }

  const icon = AttachmentSignatureStatusIconsMap[status];

  return (
    <Stack spacing="extraTight">
      <Stack.Item>
        <Icon source={icon} />
      </Stack.Item>
      <Stack.Item>{f(AttachmentSignatureStatusTranslationKeys[status])}</Stack.Item>
    </Stack>
  );
};

export default AttachmentSignatureStatusLabel;
