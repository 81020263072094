import React, { useState } from "react";
import { Button, Card, Stack, TextField } from "@shopify/polaris";

import api from "../api";
import { COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS } from "../constants/integrations";
import useFeatures from "../hooks/useFeatures";
import useFormatMessage from "../hooks/useFormatMessage";

import AssociatedProjectSection from "./AssociatedProjectSection";
import CompanyForm, { CompanyFormValues } from "./CompanyForm";
import ErrorPanel from "./ErrorPanel";

type KeysEnum<T> = { [P in keyof Required<T>]: boolean };

const CompanyCustomerFormFields: KeysEnum<api.CreateCompanyCustomerRequest> = {
  name: true,
  country: true,
  national_id: true,
  business_address: true,
  external_id: true,
  project_id: false,
};

/**
 * Returns true if it is a field that can be manually defined in
 * CompanyCustomerForm, else false
 */
export const isFieldInCompanyCustomerForm = (fieldName: string) => {
  return fieldName in CompanyCustomerFormFields;
};

interface CompanyCustomerFormProps {
  companyCustomer?: api.CompanyCustomerDetailResponse;
  onAfterSubmit: (company: api.CompanyCustomer) => void;
  submitLabel?: string;
  projectId?: string;
  projectName?: string;
}

const CompanyCustomerForm: React.FC<CompanyCustomerFormProps> = ({
  companyCustomer,
  onAfterSubmit,
  submitLabel,
  projectId,
  projectName,
}) => {
  const f = useFormatMessage();
  const features = useFeatures();

  const [companyFormValues, setCompanyFormValues] = useState<CompanyFormValues>();
  const [externalId, setExternalId] = useState(companyCustomer?.external_id || "");

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState<Map<string, string>>(new Map());

  /**
   * Form/payload is valid if name, national_id, country and business_address is set
   * Additionally, country must be a country we haven't integrated with
   */
  const isValid =
    companyFormValues &&
    companyFormValues.name &&
    companyFormValues.nationalId &&
    companyFormValues.country &&
    !COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS.includes(companyFormValues.country) &&
    companyFormValues.address &&
    companyFormValues.address.country !== undefined
      ? true
      : false;

  /**
   * Create or update company customer, then call the onAfterSubmit-callback
   */
  const handleSubmit = () => {
    if (!isValid || !companyFormValues) return;

    setIsSaving(true);

    const payload: api.CreateCompanyCustomerRequest = {
      name: companyFormValues.name,
      national_id: companyFormValues.nationalId!,
      country: companyFormValues.country!,
      business_address: companyFormValues.address as api.Address,
      external_id: externalId,
    };

    // If companyCustomer was provided, we're updating an existing company customer
    // Else, we're creating a new company customer
    const apiCall = companyCustomer
      ? api.updateCompanyCustomer(companyCustomer.id, payload)
      : api.createCompanyCustomer({ ...payload, project_id: projectId });
    apiCall
      .then((data) => {
        // Success!
        setError("");
        setIsSaving(false);

        // Call the onAfterSubmit-callback
        onAfterSubmit(data);
      })
      .catch((error: any) => {
        // Error - display error message
        let errorMsg = f("common.errors.generic");
        const newFieldErrors = new Map<string, string>();

        try {
          if (error.status === 400) {
            const companyErrors = error?.data?.subject?.legal_entity || error?.data?.legal_entity || [];
            if ("national_id" in companyErrors) {
              // National ID validation in server failed
              newFieldErrors.set("national_id", f("common.errors.invalid.company.national-id"));
              errorMsg = "";
            }
          }

          // If conflict, try to find the field that triggered the conflict
          if (error.status === 409) {
            errorMsg = f("common.errors.company.exists");
            const errorDetails: string | undefined = error?.data?.detail;
            if (errorDetails) {
              if (errorDetails.includes("national_id")) {
                newFieldErrors.set("national_id", f("common.errors.company-national-id.exists"));
                errorMsg = "";
              } else if (errorDetails.includes("external_id")) {
                newFieldErrors.set("external_id", f("common.errors.company-external-id.exists"));
                errorMsg = "";
              }
            }
          }
        } catch {
          // Do nothing
        }

        setError(errorMsg);
        setFieldErrors(newFieldErrors);
        setIsSaving(false);
      });
  };

  return (
    <Card>
      <CompanyForm
        company={companyCustomer}
        excludedCountries={COUNTRIES_WITH_COMPANY_REGISTER_INTEGRATIONS}
        onChange={(v) => setCompanyFormValues(v)}
        disabled={isSaving}
        nationalIdError={fieldErrors.get("national_id")}
      />
      {features.PROJECTS && projectId && projectName && <AssociatedProjectSection projectName={projectName} />}
      {features?.EXTERNAL_ID && (
        <Card.Section>
          <Stack>
            <TextField
              value={externalId}
              disabled={isSaving}
              label={f("company-form.labels.external-id")}
              placeholder={f("company-form.labels.external-id")}
              onChange={(v) => setExternalId(v)}
              maxLength={50}
              error={fieldErrors.get("external_id")}
              autoComplete="off"
            />
          </Stack>
        </Card.Section>
      )}
      <Card.Section>
        <Stack vertical>
          {error && <ErrorPanel message={error} />}
          <Stack distribution="trailing">
            <Button primary loading={isSaving} disabled={!isValid} onClick={handleSubmit}>
              {submitLabel || f("common.next")}
            </Button>
          </Stack>
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default CompanyCustomerForm;
