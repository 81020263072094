import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Layout, Page, Stack, TextStyle } from "@shopify/polaris";

import api from "../../api";
import PersonDisclosureForm from "../../components/disclosure/PersonDisclosureForm";
import ErrorPanel from "../../components/ErrorPanel";
import BackButton from "../../components/navigation/BackButton";
import useFormatMessage from "../../hooks/useFormatMessage";
import { IDParams } from "../../types/params";
import Util from "../../utils/util";

import DisclosureSkeleton from "./PersonDisclosureSkeleton";

const PersonOnboardingDisclosure: React.FC<RouteComponentProps<IDParams>> = ({ history, match }) => {
  const id = match.params.id;

  const f = useFormatMessage();
  const queryClient = useQueryClient();

  const [email, setEmail] = useState<string | undefined>();
  const [isSystemEmailEnabled, setSystemEmailEnabled] = useState(true);
  const [isFormValid, setFormValid] = useState(true);

  const { isLoading, isError: isFetchError, error: fetchError, data } = useQuery(
    ["person-onboarding-disclosure", id],
    () => Promise.all([api.getPersonCustomerDetails(id), api.getPersonSignatureConfiguration(id)])
  );

  const person = data && data[0];
  const personSignatureConfiguration = data && data[1];

  const onSignatureConfigurationUpdated = (response: api.PersonSignatureConfiguration) => {
    // Update the person onboarding signature config data with the response
    queryClient.setQueryData(["person-onboarding-disclosure", id], [person, response]);

    // Update the person onboarding summary data
    const summaryData = queryClient.getQueryData<api.PersonCustomerDetailResponse>(["person-onboarding-summary", id]);
    if (summaryData) {
      summaryData.disclosure_documents[0] = {
        ...summaryData.disclosure_documents[0],
        signature_required: response.signature_required,
        signatures: [
          {
            ...summaryData.disclosure_documents[0].signatures[0],
            email: response.email,
            send_email: response.send_email,
          },
        ],
      };
      queryClient.setQueryData(["person-onboarding-summary", id], summaryData);
    }

    // Navigate to the person onboarding summary step
    history.push(`/persons/${id}/summary`);
  };

  const skipDisclosureStepMutation = useMutation(
    () => {
      return api.setPersonSignatureConfiguration(id, { signature_required: false });
    },
    {
      onSuccess: (response) => {
        onSignatureConfigurationUpdated(response);
      },
    }
  );

  const updateSignatureConfigurationMutation = useMutation(
    () => {
      return api.setPersonSignatureConfiguration(id, { email: email, send_email: isSystemEmailEnabled });
    },
    {
      onSuccess: (response) => {
        onSignatureConfigurationUpdated(response);
      },
    }
  );

  useEffect(() => {
    setFormValid(!isSystemEmailEnabled || Util.isEmail(email));
  }, [email, isSystemEmailEnabled]);

  useEffect(() => {
    if (personSignatureConfiguration) {
      setEmail(personSignatureConfiguration.email);
      setSystemEmailEnabled(personSignatureConfiguration.send_email ?? true);
    }
  }, [personSignatureConfiguration]);

  if (isLoading && !data) {
    return <DisclosureSkeleton />;
  }

  const isDisabled = skipDisclosureStepMutation.isLoading || updateSignatureConfigurationMutation.isLoading;
  const isError = isFetchError || skipDisclosureStepMutation.isError || updateSignatureConfigurationMutation.isError;

  return (
    <Page title={f("onboarding.disclosures.title")}>
      <Stack vertical>
        <Layout>
          <Layout.Section>
            <Stack vertical>
              <TextStyle>{f("onboarding.disclosures.person-description")}</TextStyle>

              <Card>
                <Card.Section>
                  <PersonDisclosureForm
                    isSystemEmailEnabled={isSystemEmailEnabled}
                    email={email}
                    person={person!}
                    onSystemEmailChanged={(value) => {
                      setSystemEmailEnabled(value);
                      if (!value) {
                        // Clear email if system email was disabled
                        setEmail(undefined);
                      }
                    }}
                    onEmailChanged={(value) => {
                      setEmail(value);
                    }}
                  />
                </Card.Section>
              </Card>

              {isError && (
                <ErrorPanel
                  message={fetchError || skipDisclosureStepMutation.error || updateSignatureConfigurationMutation.error}
                />
              )}

              <Stack distribution="trailing">
                <BackButton url={`/persons/${id}/screening`} />
                <Button
                  disabled={isDisabled}
                  loading={skipDisclosureStepMutation.isLoading}
                  onClick={() => {
                    skipDisclosureStepMutation.mutate();
                  }}
                >
                  {f("common.buttons.actions.skip-credentials")}
                </Button>
                <Button
                  disabled={isDisabled || !isFormValid}
                  loading={updateSignatureConfigurationMutation.isLoading}
                  primary
                  onClick={() => {
                    updateSignatureConfigurationMutation.mutate();
                  }}
                >
                  {f("button.go.next")}
                </Button>
              </Stack>
            </Stack>
          </Layout.Section>
        </Layout>
      </Stack>
    </Page>
  );
};

export default PersonOnboardingDisclosure;
