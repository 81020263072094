import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ActionListItemDescriptor, Toast } from "@shopify/polaris";

import ArchiveIcon from "../../assets/icons/archivebox.svg";
import DownloadIcon from "../../assets/icons/arrow-down-rectangle.svg";
import ExistingProjectIcon from "../../assets/icons/folders.svg";
import RestoreIcon from "../../assets/icons/restore.svg";
import api from "../api";
import useDownload from "../hooks/useDownload";
import useFeatures from "../hooks/useFeatures";
import useFormatMessage from "../hooks/useFormatMessage";
import useOpenClose from "../hooks/useOpenClose";
import AddToProjectModal from "../pages/customers/actions/AddToProjectModal";
import { Customer } from "../types/utilities";

import Button from "./extensions/Button";
import ActionsDropdown from "./ActionsDropdown";
import CustomerArchiveConfirmation from "./CustomerArchiveConfirmation";

interface CustomerActionsMenuProps {
  customer: Customer;
  isMonitoring: boolean;
}

const CustomerActionsMenu: React.FC<CustomerActionsMenuProps> = (props) => {
  const { customer, isMonitoring } = props;

  const [showArchiveCustomerConfirmation, setShowArchiveCustomerConfirmation] = useState(false);
  const [showAddToProjectModal, toggleAddToProjectModal, closeAddToProjectModal] = useOpenClose();

  const features = useFeatures();
  const f = useFormatMessage();
  const queryClient = useQueryClient();

  const download = useDownload();
  const downloadReportMutation = useMutation(() => download(`/customers/${customer.id}/report`));

  const restoreCustomerMutation = useMutation(
    () =>
      api.updateCustomerDetails(customer.id, {
        is_archived: false,
      }),
    {
      onSuccess: async (response) => {
        const queryKey = [customer.type === "company" ? "company-details" : "person-details", customer.id];
        queryClient.setQueryData(queryKey, response);
      },
    }
  );

  const openArchiveCustomerConfirmation = () => setShowArchiveCustomerConfirmation(true);
  const closeArchiveCustomerConfirmation = () => setShowArchiveCustomerConfirmation(false);

  const downloadReport = () => downloadReportMutation.mutate();
  const restoreCustomer = () => restoreCustomerMutation.mutate();

  const actions: ActionListItemDescriptor[] = [
    {
      content: f("common.buttons.actions.download.report"),
      onAction: downloadReport,
      image: DownloadIcon,
      disabled: downloadReportMutation.isLoading,
    },
  ];

  if (features.PROJECTS) {
    actions.push({
      content: f("bulk.actions.add-to-existing-project.label"),
      image: ExistingProjectIcon,
      onAction: toggleAddToProjectModal,
      disabled: customer.is_archived,
    });
  }

  if (features.ARCHIVE_CUSTOMER && customer.is_archived) {
    actions.push({
      content: f("common.buttons.actions.restore-customer"),
      onAction: restoreCustomer,
      image: RestoreIcon,
      disabled: restoreCustomerMutation.isLoading,
    });
  }

  if (features.ARCHIVE_CUSTOMER && !customer.is_archived) {
    actions.push({
      content: f("common.buttons.actions.archive-customer"),
      onAction: openArchiveCustomerConfirmation,
      image: ArchiveIcon,
    });
  }

  const isLoading = downloadReportMutation.isLoading || restoreCustomerMutation.isLoading;
  const isError = downloadReportMutation.isError || restoreCustomerMutation.isError;
  const error = downloadReportMutation.error || restoreCustomerMutation.error;

  const resetErroneousMutation = () => {
    if (downloadReportMutation.isError) {
      downloadReportMutation.reset();
    } else if (restoreCustomerMutation.isError) {
      restoreCustomerMutation.reset();
    }
  };

  return (
    <>
      {actions.length > 1 && <ActionsDropdown items={actions} loading={isLoading} />}
      {actions.length === 1 && (
        <Button
          icon={DownloadIcon}
          onClick={downloadReport}
          disabled={downloadReportMutation.isLoading}
          loading={downloadReportMutation.isLoading}
          size="slim"
        >
          {f("common.buttons.actions.download.report")}
        </Button>
      )}
      {showAddToProjectModal && (
        <AddToProjectModal customers={[customer]} navigateToProject onClose={closeAddToProjectModal} />
      )}
      {isError && <Toast content={error as string} error onDismiss={resetErroneousMutation} />}
      <CustomerArchiveConfirmation
        customer={customer}
        isMonitoring={isMonitoring}
        open={showArchiveCustomerConfirmation}
        onClose={closeArchiveCustomerConfirmation}
      />
    </>
  );
};

export default CustomerActionsMenu;
