/**
 * Beaufort API
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type CountryEnum = "AW" | "AF" | "AO" | "AI" | "AX" | "AL" | "AD" | "AE" | "AR" | "AM" | "AS" | "AQ" | "TF" | "AG" | "AU" | "AT" | "AZ" | "BI" | "BE" | "BJ" | "BQ" | "BF" | "BD" | "BG" | "BH" | "BS" | "BA" | "BL" | "BY" | "BZ" | "BM" | "BO" | "BR" | "BB" | "BN" | "BT" | "BV" | "BW" | "CF" | "CA" | "CC" | "CH" | "CL" | "CN" | "CI" | "CM" | "CD" | "CG" | "CK" | "CO" | "KM" | "CV" | "CR" | "CU" | "CW" | "CX" | "KY" | "CY" | "CZ" | "DE" | "DJ" | "DM" | "DK" | "DO" | "DZ" | "EC" | "EG" | "ER" | "EH" | "ES" | "EE" | "ET" | "FI" | "FJ" | "FK" | "FR" | "FO" | "FM" | "GA" | "GB" | "GE" | "GG" | "GH" | "GI" | "GN" | "GP" | "GM" | "GW" | "GQ" | "GR" | "GD" | "GL" | "GT" | "GF" | "GU" | "GY" | "HK" | "HM" | "HN" | "HR" | "HT" | "HU" | "ID" | "IM" | "IN" | "IO" | "IE" | "IR" | "IQ" | "IS" | "IL" | "IT" | "JM" | "JE" | "JO" | "JP" | "KZ" | "KE" | "KG" | "KH" | "KI" | "KN" | "KR" | "KW" | "LA" | "LB" | "LR" | "LY" | "LC" | "LI" | "LK" | "LS" | "LT" | "LU" | "LV" | "MO" | "MF" | "MA" | "MC" | "MD" | "MG" | "MV" | "MX" | "MH" | "MK" | "ML" | "MT" | "MM" | "ME" | "MN" | "MP" | "MZ" | "MR" | "MS" | "MQ" | "MU" | "MW" | "MY" | "YT" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NU" | "NL" | "NO" | "NP" | "NR" | "NZ" | "OM" | "PK" | "PA" | "PN" | "PE" | "PH" | "PW" | "PG" | "PL" | "PR" | "KP" | "PT" | "PY" | "PS" | "PF" | "QA" | "RE" | "RO" | "RU" | "RW" | "SA" | "SD" | "SN" | "SG" | "GS" | "SH" | "SJ" | "SB" | "SL" | "SV" | "SM" | "SO" | "PM" | "RS" | "SS" | "ST" | "SR" | "SK" | "SI" | "SE" | "SZ" | "SX" | "SC" | "SY" | "TC" | "TD" | "TG" | "TH" | "TJ" | "TK" | "TM" | "TL" | "TO" | "TT" | "TN" | "TR" | "TV" | "TW" | "TZ" | "UG" | "UA" | "UM" | "UY" | "US" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "YE" | "ZA" | "ZM" | "ZW" | "XK";
export type GenderEnum = "M" | "F";
export type Address = {
    address_line_1?: string;
    address_line_2?: string;
    postal_code?: string;
    city?: string;
    county?: string;
    country: CountryEnum;
};
export type OnboardingPersonCustomer = {
    first_name: string;
    last_name: string;
    national_id?: string;
    birth_date?: string;
    birth_year?: string;
    country_of_citizenship?: CountryEnum;
    country_of_origin?: CountryEnum;
    country_of_domicile?: CountryEnum;
    gender?: GenderEnum;
    address?: Address;
    phone?: string;
    email?: string;
    external_id?: string;
    extra?: object;
};
export type ScreeningSettings = {
    realtime?: boolean;
    monitoring?: boolean;
};
export type EnrichmentSettings = {
    brreg?: boolean;
    beneficial_owners?: boolean;
};
export type SignatureSettings = {
    create?: boolean;
    send_email?: boolean;
    use_disclosure?: boolean;
    use_verification?: boolean;
    signer_email?: string;
};
export type OnboardingSettings = {
    screening?: ScreeningSettings;
    enrichment?: EnrichmentSettings;
    signature?: SignatureSettings;
};
export type PersonOnboarding = {
    person: OnboardingPersonCustomer;
    settings?: OnboardingSettings;
};
export type DataServiceSubjectScreeningStatus = {
    screening_type?: string;
    screening_status: string;
    screening_started?: string;
    screening_stopped?: string;
    screening_updated?: string;
    unresolved_cases: number;
};
export type DataServiceRiskLevel = "LOW" | "NORMAL" | "HIGH";
export type DataServiceOnboardingStatus = "FETCHING_DATA" | "SCREENING_CONFIG" | "SIGNATURE_CONFIG" | "SIGNATURE_EMAILS" | "READY" | "NOT_SIGNED" | "DONE" | "STARTED";
export type DataServiceReviewStatus = "NOT_READY" | "REVIEW_REQUIRED" | "APPROVED" | "REJECTED";
export type DataServiceUser = {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    is_active?: boolean;
    groups?: number[];
};
export type DataServiceProjectStatus = "ACTIVE" | "INACTIVE";
export type DataServiceProjectListItem = {
    id: string;
    name: string;
    status: DataServiceProjectStatus;
    responsible?: DataServiceUser;
    created_dt: string;
    subject_count: number;
};
export type DataServicePersonDetail = {
    first_name: string;
    last_name: string;
    national_id?: string;
    country_of_citizenship?: string;
    country_of_origin?: string;
    country_of_domicile?: string;
    birth_date?: string;
    birth_year?: string;
    gender?: string;
    phone?: string;
    email?: string;
    mailing_address?: Address;
    is_pep?: boolean;
    is_sanctioned?: boolean;
    id: string;
};
export type DataServicePersonSubjectDetailResponse = {
    id: string;
    external_id?: string;
    created_dt?: string;
    screening_status: DataServiceSubjectScreeningStatus;
    risk_level: DataServiceRiskLevel;
    onboarding_status: DataServiceOnboardingStatus;
    review_status: DataServiceReviewStatus;
    extra?: object;
    responsible?: DataServiceUser;
    is_archived?: boolean;
    is_manual: boolean;
    projects: DataServiceProjectListItem[];
    person: DataServicePersonDetail;
    onboarding_document_url?: string;
    onboarding_document_signed?: boolean;
};
export type DataServiceEnrichmentSource = "BRREG" | "BENEFICIAL_OWNERS";
export type DataServiceEnrichmentSettings = {
    sources?: DataServiceEnrichmentSource[];
};
export type DataServiceScreeningType = "NONE" | "ONE_TIME" | "MONITORING";
export type DataServiceScreeningSettings = {
    "type"?: DataServiceScreeningType;
};
export type DataServiceOnboardingSignatureSettings = {
    create?: boolean;
    send_email?: boolean;
    signer_email?: string;
    use_disclosure?: boolean;
    use_verification?: boolean;
};
export type DataServiceOnboardingSettings = {
    enrichment?: DataServiceEnrichmentSettings;
    screening?: DataServiceScreeningSettings;
    signature?: DataServiceOnboardingSignatureSettings;
    signature_required?: boolean;
};
export type PersonOnboardingResponse = {
    id: string;
    subject: DataServicePersonSubjectDetailResponse;
    settings: DataServiceOnboardingSettings;
};
export type ValidationError = {
    loc: string[];
    msg: string;
    "type": string;
};
export type HttpValidationError = {
    detail?: ValidationError[];
};
export type DataServiceLegalEntityType = {
    code: string;
    description_en?: string;
    description_nb?: string;
};
export type SectorCode = {
    code: string;
    description_en?: string;
    description_nb?: string;
};
export type DataServiceIndustryCode = {
    code: string;
    description_en?: string;
    description_nb?: string;
};
export type IndustryTypeRiskLevel = "LOW" | "MEDIUM" | "HIGH";
export type IndustryRiskDetail = {
    code: string;
    industry_code_description: string;
    risk_level: IndustryTypeRiskLevel;
    risk_description?: string;
};
export type IndustryRiskDefinitions = {
    industry_code_1?: IndustryRiskDetail;
    industry_code_2?: IndustryRiskDetail;
    industry_code_3?: IndustryRiskDetail;
};
export type IndustryRiskInformation = {
    industry_types: IndustryRiskDefinitions;
};
export type DataServiceLegalEntityInfoUpdateFieldChange = {
    field_name: string;
    old?: any;
    "new"?: any;
};
export type DataServiceLegalEntityInfoUpdate = {
    field_changes: DataServiceLegalEntityInfoUpdateFieldChange[];
    timestamp: string;
    is_reviewed: boolean;
};
export type DataServiceLegalEntityDetail = {
    name?: string;
    national_id: string;
    country: string;
    office_address?: Address;
    business_address?: Address;
    id: string;
    global_id?: string;
    parent_national_id?: string;
    legal_entity_type?: DataServiceLegalEntityType;
    sector_code?: SectorCode;
    industry_code_1?: DataServiceIndustryCode;
    industry_code_2?: DataServiceIndustryCode;
    industry_code_3?: DataServiceIndustryCode;
    risk_information?: IndustryRiskInformation;
    mailing_address?: Address;
    website?: string;
    number_of_employees?: number;
    founded_date?: string;
    closure_date?: string;
    is_bankrupt?: boolean;
    under_liquidation?: boolean;
    under_forced_liquidation_or_dissolution?: boolean;
    registered_in_vat_registry?: boolean;
    registered_in_foundation_registry?: boolean;
    registered_as_nonprofit?: boolean;
    registered_in_business_registry?: boolean;
    is_pep?: boolean;
    is_sanctioned?: boolean;
    info_updates: DataServiceLegalEntityInfoUpdate[];
    can_have_shareholders?: boolean;
};
export type DataServiceSubjectEnrichmentStatus = {
    latest_beneficial_ownership_summary?: string;
    latest_role_summary?: string;
};
export type DataServiceLegalEntitySubjectDetailResponse = {
    id: string;
    external_id?: string;
    created_dt?: string;
    screening_status: DataServiceSubjectScreeningStatus;
    risk_level: DataServiceRiskLevel;
    onboarding_status: DataServiceOnboardingStatus;
    review_status: DataServiceReviewStatus;
    extra?: object;
    responsible?: DataServiceUser;
    is_archived?: boolean;
    is_manual: boolean;
    projects: DataServiceProjectListItem[];
    legal_entity: DataServiceLegalEntityDetail;
    enrichment_status: DataServiceSubjectEnrichmentStatus;
};
export type CustomerType = "person" | "company";
export type GetCustomerByNationalIdRequest = {
    "type": CustomerType;
    national_id: string;
};
export type ValidCountriesEnum = "NO";
export type CompanyInformationLookupIndustryCode = {
    code: string;
    description_en: string;
    description_nb: string;
    short_description_en: string;
    short_description_nb: string;
    level1_code?: string;
    level1_code_description_en?: string;
    level1_code_description_nb?: string;
    level1_code_short_description_en?: string;
    level1_code_short_description_nb?: string;
    level2_code?: string;
    level2_code_description_en?: string;
    level2_code_description_nb?: string;
    level2_code_short_description_en?: string;
    level2_code_short_description_nb?: string;
    level3_code?: string;
    level3_code_description_en?: string;
    level3_code_description_nb?: string;
    level3_code_short_description_en?: string;
    level3_code_short_description_nb?: string;
    level4_code?: string;
    level4_code_description_en?: string;
    level4_code_description_nb?: string;
    level4_code_short_description_en?: string;
    level4_code_short_description_nb?: string;
};
export type CompanyInformationLookupInfo = {
    national_id: number;
    parent_national_id?: number;
    name: string;
    legal_entity_type: DataServiceLegalEntityType;
    business_address?: Address;
    mailing_address?: Address;
    office_address?: Address;
    website?: string;
    number_of_employees?: number;
    language?: string;
    registration_date?: string;
    founded_date?: string;
    commencement_date?: string;
    closure_date?: string;
    ownership_date?: string;
    sector_code?: SectorCode;
    industry_code_1?: CompanyInformationLookupIndustryCode;
    industry_code_2?: CompanyInformationLookupIndustryCode;
    industry_code_3?: CompanyInformationLookupIndustryCode;
    risk_information: IndustryRiskInformation;
    last_submitted_annual_accounts?: number;
    registered_as_nonprofit: boolean;
    registered_in_vat_registry: boolean;
    registered_in_business_registry: boolean;
    registered_in_foundation_registry: boolean;
    is_bankrupt?: boolean;
    under_liquidation: boolean;
    under_forced_liquidation_or_dissolution: boolean;
    record_hash?: string;
    record_date: string;
    most_recent_record: boolean;
};
export type CompanyInformationLookupResponse = {
    organisation_number: number;
    response_timestamp: string;
    server_error: boolean;
    bad_request: boolean;
    basic_legal_entity_info: CompanyInformationLookupInfo;
    hash_matched: boolean;
};
export type CreateCompanyCustomerRequest = {
    project_id?: string;
    external_id?: string;
    national_id: string;
    country: CountryEnum;
    name?: string;
    business_address?: Address;
};
export type OnboardingStatus = "FETCHING_DATA" | "SCREENING_CONFIG" | "SIGNATURE_CONFIG" | "SIGNATURE_EMAILS" | "READY" | "NOT_SIGNED" | "DONE";
export type ReviewStatus = "NOT_READY" | "REVIEW_REQUIRED" | "APPROVED" | "REJECTED";
export type CustomerStatus = "STARTED" | "NOT_SIGNED" | "NOT_READY" | "REVIEW_REQUIRED" | "APPROVED" | "REJECTED";
export type RiskLevel = "LOW" | "MEDIUM" | "HIGH";
export type User = {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    is_active: boolean;
    group?: number;
};
export type Label = {
    id: string;
    title: string;
    is_assigned?: boolean;
};
export type ScreeningType = "NOT_SET" | "NONE" | "ONE_TIME" | "MONITORING";
export type ScreeningStatus = "NOT_STARTED" | "STARTING" | "MONITORING" | "DONE" | "NONE";
export type CustomerScreeningStatus = {
    screening_type?: ScreeningType;
    screening_status: ScreeningStatus;
    screening_started?: string;
    screening_updated?: string;
    screening_stopped?: string;
    unresolved_cases?: number;
};
export type CaseStatus = "OPEN" | "PENDING" | "RESOLVED" | "ON_HOLD";
export type CaseSeverity = "LOW" | "MEDIUM" | "HIGH";
export type SubjectType = "person" | "company";
export type MatchReview = {
    id: string;
    created_dt: string;
    is_match: boolean;
    is_pep?: boolean;
    is_sanctioned?: boolean;
    negative_news?: boolean;
    other?: boolean;
    description: string;
    reviewed_by?: User;
};
export type MatchCaseSummary = {
    id: string;
    status: CaseStatus;
    severity: CaseSeverity;
    timestamp: string;
    updated_timestamp?: string;
    subject_name: string;
    subject_type: SubjectType;
    summary_text: string;
    review?: MatchReview;
};
export type CustomerReviewApproval = {
    approved_by: User;
    is_approved: boolean;
    timestamp: string;
};
export type CustomerReview = {
    id: string;
    date: string;
    previous_risk_level?: RiskLevel;
    risk_level?: RiskLevel;
    accepted: boolean;
    comment?: string;
    reviewed_by?: User;
    requires_approval: boolean;
    approval?: CustomerReviewApproval;
};
export type SigningUserInfo = {
    name: string;
    birth_date: string;
    country: string;
    social_no?: string;
    address?: Address;
    phone?: string;
    email?: string;
};
export type RoleType = "UNKNOWN_ROLE" | "CEO" | "CHAIRMAN" | "DEPUTY_CHAIRMAN" | "BOARD_MEMBER" | "DEPUTY_BOARD_MEMBER" | "PROPRIETOR" | "CONTACT_PERSON" | "MANAGING_DIRECTOR" | "MANAGER" | "ACCOUNTANT" | "TRUSTEE" | "NORWEGIAN_REPRESENTATIVE" | "AUDITOR" | "OBSERVER" | "ALTERNATE_BOARD_MEMBER" | "PARTICIPANT_PART_RESPONSIBILITY" | "PARTICIPANT_FULL_RESPONSIBILITY" | "COMPLEMENTARY" | "SIGNATURE" | "SIGNATURE_JOINTLY" | "SIGNATURE_SEPARATELY" | "PROCURA" | "PROCURA_JOINTLY" | "PROCURA_SEPARATELY" | "REPRESENTATIVE";
export type RoleStatus = "ACTIVE" | "EXPIRED";
export type SignerRole = {
    id: string;
    "type": RoleType;
    status: RoleStatus;
    comment?: string;
};
export type Person = {
    id: string;
    first_name: string;
    last_name: string;
    birth_date?: string;
    birth_year?: string;
    national_id?: string;
    address?: Address;
    country_of_citizenship?: CountryEnum;
};
export type ValueAndLabel = {
    value: string;
    label: string;
};
export type MifidDisclosure = {
    transaction_role?: ValueAndLabel;
    transaction_role_desc?: string;
    profession?: string;
    industry?: string;
    education_level?: ValueAndLabel;
    financial_instrument_trading_experience?: ValueAndLabel;
    is_accounting_balance_over_threshold?: boolean;
    is_net_turnover_over_threshold?: boolean;
    is_equity_over_threshold?: boolean;
    additional_mifid_info?: string;
};
export type CustomerDisclosure = {
    purpose: ValueAndLabel;
    purpose_desc?: string;
    source_of_funds: ValueAndLabel;
    source_of_funds_desc?: string;
    additional_info?: string;
    is_pep: boolean;
    pep_desc?: string;
    pep_role?: ValueAndLabel;
    pep_name?: string;
    pep_birth_date?: string;
    pep_relation?: ValueAndLabel;
    pep_relation_desc?: string;
    pep_position?: ValueAndLabel;
    pep_position_desc?: string;
    pep_position_org?: string;
    pep_position_country?: CountryEnum;
    pep_position_termination_date?: string;
    country_of_origin?: CountryEnum;
    is_foreign_assignment?: boolean;
    foreign_assignment_desc?: string;
    ubo_agreements_exists?: boolean;
    ubo_agreements_desc?: string;
    ubo_family_members_exists?: boolean;
    ubo_family_members_desc?: string;
    is_publicly_owned_or_administrative_body?: boolean;
    is_listed_on_regulated_market?: boolean;
    regulated_market_name?: string;
    additional_ownership_info?: string;
    mifid?: MifidDisclosure;
    disclosed_by: string;
    timestamp: string;
};
export type Signature = {
    signed_dt?: string;
    signing_user_info?: SigningUserInfo;
    signing_method?: string;
    notified_dt?: string;
    signing_page_url: string;
    id: string;
    role_id?: string;
    role?: SignerRole;
    person: Person;
    send_email?: boolean;
    email?: string;
    is_complete: boolean;
    disclosure?: CustomerDisclosure;
    use_disclosure: boolean;
    use_verification: boolean;
    pin_code?: string;
};
export type CustomerDisclosureDocument = {
    created_dt: string;
    document_type: string;
    is_signed?: boolean;
    signed_dt?: string;
    signature_required?: boolean;
    signatures: Signature[];
};
export type ProjectStatus = "ACTIVE" | "INACTIVE";
export type ProjectListItem = {
    id: string;
    name: string;
    status: ProjectStatus;
    responsible?: User;
    created_dt: string;
    customer_count: number;
};
export type CompanyType = {
    code: string;
    description_en?: string;
    description_nb?: string;
};
export type IndustryCode = {
    code: string;
    description: string;
    description_en?: string;
    description_nb?: string;
};
export type IdentificationStatus = "PENDING" | "FAILED" | "SUCCESS";
export type RolePerson = {
    id: string;
    first_name: string;
    last_name: string;
    birth_date?: string;
    birth_year?: string;
    national_id?: string;
    address?: Address;
    country_of_citizenship?: CountryEnum;
    identification_required?: boolean;
    identification_status?: IdentificationStatus;
};
export type Role = {
    id?: string;
    person: RolePerson;
    "type": RoleType;
    status: RoleStatus;
    is_new: boolean;
    is_ubo: boolean;
    created_by?: User;
    created_dt?: string;
    updated_by?: User;
    updated_dt?: string;
    comment?: string;
};
export type RoleScreeningConfiguration = {
    role: Role;
    screening_required: boolean;
};
export type OwnerPerson = {
    id: string;
    first_name: string;
    last_name: string;
    birth_date?: string;
    birth_year?: string;
    national_id?: string;
    address?: Address;
    country_of_citizenship?: CountryEnum;
};
export type Owner = {
    id?: string;
    person: OwnerPerson;
    is_ubo: boolean;
    percent_share?: string;
    created_by?: User;
    created_dt?: string;
    updated_by?: User;
    updated_dt?: string;
    deleted?: boolean;
    comment?: string;
};
export type BeneficialOwnerScreeningConfiguration = {
    owner: Owner;
    screening_required: boolean;
};
export type CompanyScreeningConfiguration = {
    customer_id: string;
    customer_name: string;
    roles: RoleScreeningConfiguration[];
    beneficial_owners: BeneficialOwnerScreeningConfiguration[];
    screening_type: ScreeningType;
    screening_status: ScreeningStatus;
    roles_fetched?: string;
    beneficial_owners_fetched?: string;
    customer_type?: CustomerType;
};
export type CompanyInfoUpdateFieldChange = {
    field_name: string;
    old?: any;
    "new"?: any;
};
export type CompanyInfoUpdate = {
    field_changes: CompanyInfoUpdateFieldChange[];
    timestamp: string;
    is_reviewed: boolean;
};
export type CompanyCustomerDetailResponse = {
    id: string;
    created?: string;
    onboarding_status: OnboardingStatus;
    review_status: ReviewStatus;
    status?: CustomerStatus;
    risk_level?: RiskLevel;
    external_id?: string;
    extra?: object;
    unresolved_case_count: number;
    is_pep: boolean;
    is_sanctioned: boolean;
    responsible?: User;
    is_archived?: boolean;
    is_manual: boolean;
    labels?: Label[];
    screening_status: CustomerScreeningStatus;
    signature_required?: boolean;
    match_cases: MatchCaseSummary[];
    reviews: CustomerReview[];
    pending_reviews: CustomerReview[];
    disclosure_documents: CustomerDisclosureDocument[];
    projects?: ProjectListItem[];
    name?: string;
    national_id: string;
    country: CountryEnum;
    address?: Address;
    parent_national_id?: string;
    global_id?: string;
    company_type?: CompanyType;
    sector_code?: SectorCode;
    industry_code_1?: IndustryCode;
    industry_code_2?: IndustryCode;
    industry_code_3?: IndustryCode;
    risk_information?: IndustryRiskInformation;
    number_of_employees?: number;
    office_address?: Address;
    business_address?: Address;
    mailing_address?: Address;
    website?: string;
    founded_date?: string;
    closure_date?: string;
    is_bankrupt?: boolean;
    under_liquidation?: boolean;
    under_forced_liquidation_or_dissolution?: boolean;
    registered_in_vat_registry?: boolean;
    registered_in_foundation_registry?: boolean;
    registered_as_nonprofit?: boolean;
    registered_in_business_registry?: boolean;
    can_have_shareholders?: boolean;
    "type"?: CustomerType;
    screening?: CompanyScreeningConfiguration;
    roles_fetched?: string;
    roles: Role[];
    expired_roles: Role[];
    beneficial_owners_fetched?: string;
    beneficial_owners: Owner[];
    info_updates: CompanyInfoUpdate[];
};
export type CreatePersonCustomerRequest = {
    first_name: string;
    last_name: string;
    birth_date: string;
    national_id?: string;
    country_of_citizenship: CountryEnum;
    address?: Address;
    project_id?: string;
    external_id?: string;
};
export type PersonScreeningConfiguration = {
    customer_id: string;
    customer_name: string;
    screening_type: ScreeningType;
    screening_status: ScreeningStatus;
    customer_type?: CustomerType;
};
export type PersonCustomerDetailResponse = {
    id: string;
    created?: string;
    onboarding_status: OnboardingStatus;
    review_status: ReviewStatus;
    status?: CustomerStatus;
    risk_level?: RiskLevel;
    external_id?: string;
    extra?: object;
    unresolved_case_count: number;
    is_pep: boolean;
    is_sanctioned: boolean;
    responsible?: User;
    is_archived?: boolean;
    is_manual: boolean;
    labels?: Label[];
    screening_status: CustomerScreeningStatus;
    signature_required?: boolean;
    match_cases: MatchCaseSummary[];
    reviews: CustomerReview[];
    pending_reviews: CustomerReview[];
    disclosure_documents: CustomerDisclosureDocument[];
    projects?: ProjectListItem[];
    first_name: string;
    last_name: string;
    birth_date?: string;
    birth_year?: string;
    national_id?: string;
    address?: Address;
    country_of_citizenship?: CountryEnum;
    person_id: string;
    "type"?: CustomerType;
    screening?: PersonScreeningConfiguration;
};
export type UpdatePersonCustomerRequest = {
    first_name: string;
    last_name: string;
    birth_date: string;
    national_id?: string;
    country_of_citizenship: CountryEnum;
    address?: Address;
    project_id?: string;
    external_id?: string;
};
export type PersonCustomer = {
    id: string;
    created?: string;
    onboarding_status: OnboardingStatus;
    review_status: ReviewStatus;
    status?: CustomerStatus;
    risk_level?: RiskLevel;
    external_id?: string;
    extra?: object;
    unresolved_case_count: number;
    is_pep: boolean;
    is_sanctioned: boolean;
    responsible?: User;
    is_archived?: boolean;
    is_manual: boolean;
    labels?: Label[];
    first_name: string;
    last_name: string;
    birth_date?: string;
    birth_year?: string;
    national_id?: string;
    address?: Address;
    country_of_citizenship?: CountryEnum;
    person_id: string;
    "type"?: CustomerType;
};
export type PutCompanyScreeningConfigurationRequest = {
    roles: string[];
    owners: string[];
    screening_type: ScreeningType;
};
export type PatchCompanyScreeningConfigurationRequest = {
    roles: string[];
    owners: string[];
    screening_type: ScreeningType;
};
export type PersonScreeningConfigurationRequest = {
    screening_type: ScreeningType;
};
export type SignatureRole = {
    id?: string;
    person: RolePerson;
    "type": RoleType;
    status: RoleStatus;
    is_new: boolean;
    is_ubo: boolean;
    created_by?: User;
    created_dt?: string;
    updated_by?: User;
    updated_dt?: string;
    comment?: string;
};
export type SigningCombination = {
    code: string;
    description: string;
    signers: string[];
};
export type CompanySignatureConfiguration = {
    signing_basis_exists: boolean;
    signature_description?: string;
    signatures: Signature[];
    possible_signers: SignatureRole[];
    possible_combinations: SigningCombination[];
    signature_descriptions: string[];
};
export type CompanyRoleSignatureConfiguration = {
    email?: string;
    send_email?: boolean;
    use_disclosure?: boolean;
    use_verification?: boolean;
    id: string;
};
export type PutCompanySignatureConfigurationRequest = {
    signature_required?: boolean;
    signatures?: CompanyRoleSignatureConfiguration[];
};
export type PatchCompanySignatureConfigurationRequest = {
    signature_required?: boolean;
    signatures?: CompanyRoleSignatureConfiguration[];
};
export type CompanyCustomer = {
    id: string;
    created?: string;
    onboarding_status: OnboardingStatus;
    review_status: ReviewStatus;
    status?: CustomerStatus;
    risk_level?: RiskLevel;
    external_id?: string;
    extra?: object;
    unresolved_case_count: number;
    is_pep: boolean;
    is_sanctioned: boolean;
    responsible?: User;
    is_archived?: boolean;
    is_manual: boolean;
    labels?: Label[];
    name?: string;
    national_id: string;
    country: CountryEnum;
    address?: Address;
    "type"?: CustomerType;
};
export type CustomerListResponse = {
    count: number;
    customers: (CompanyCustomer | PersonCustomer)[];
};
export type UpdateCustomerRequest = {
    responsible_id?: string;
    external_id?: string;
    is_archived?: boolean;
};
export type UpdateAddressRequest = {
    address_line_1?: string;
    address_line_2?: string;
    postal_code?: string;
    city?: string;
    county?: string;
    country?: CountryEnum;
};
export type UpdateCompanyCustomerRequest = {
    responsible_id?: string;
    external_id?: string;
    is_archived?: boolean;
    national_id?: string;
    country?: CountryEnum;
    name?: string;
    business_address?: UpdateAddressRequest;
};
export type MatchAttribute = {
    code: string;
    description: string;
    value: string;
    detail?: string;
    is_reviewed: boolean;
};
export type MatchAddress = {
    locator_typ?: string;
    addr1?: string;
    addr2?: string;
    city?: string;
    state_prov?: string;
    country_code?: string;
    from_dt?: string;
    to_dt?: string;
    is_reviewed: boolean;
};
export type MatchRelation = {
    direction: string;
    "type": string;
    sys_id: string;
    entity_name: string;
    is_reviewed: boolean;
};
export type MatchSource = {
    source_name?: string;
    source_url?: string;
    entity_dt?: string;
    headline?: string;
    publication_source?: string;
    publisher?: string;
    is_reviewed: boolean;
};
export type MatchCategory = {
    code: string;
    description: string;
};
export type Source = {
    source_name?: string;
    source_url?: string;
    entity_dt?: string;
    headline?: string;
    publication_source?: string;
    publisher?: string;
};
export type MatchEvent = {
    category: MatchCategory;
    sub_category: MatchCategory;
    description: string;
    date?: string;
    source?: Source;
    is_reviewed: boolean;
};
export type MatchAlias = {
    "type": string;
    name: string;
    is_reviewed: boolean;
};
export type MatchPosition = {
    value: string;
    is_reviewed: boolean;
};
export type MatchBirthDate = {
    value: string;
    is_reviewed: boolean;
};
export type PersonDetail = {
    id: string;
    first_name: string;
    last_name: string;
    birth_date?: string;
    birth_year?: string;
    national_id?: string;
    address?: Address;
    country_of_citizenship?: CountryEnum;
    country_of_origin?: CountryEnum;
    country_of_domicile?: CountryEnum;
    gender?: GenderEnum;
    phone?: string;
    email?: string;
    is_pep?: boolean;
    is_sanctioned?: boolean;
};
export type CompanyDetail = {
    name?: string;
    national_id: string;
    country: CountryEnum;
    address?: Address;
    parent_national_id?: string;
    global_id?: string;
    company_type?: CompanyType;
    sector_code?: SectorCode;
    industry_code_1?: IndustryCode;
    industry_code_2?: IndustryCode;
    industry_code_3?: IndustryCode;
    risk_information?: IndustryRiskInformation;
    number_of_employees?: number;
    office_address?: Address;
    business_address?: Address;
    mailing_address?: Address;
    website?: string;
    founded_date?: string;
    closure_date?: string;
    is_bankrupt?: boolean;
    under_liquidation?: boolean;
    under_forced_liquidation_or_dissolution?: boolean;
    registered_in_vat_registry?: boolean;
    registered_in_foundation_registry?: boolean;
    registered_as_nonprofit?: boolean;
    registered_in_business_registry?: boolean;
    is_pep?: boolean;
    is_sanctioned?: boolean;
    can_have_shareholders?: boolean;
};
export type Match = {
    id: string;
    match_score?: number;
    risk_score?: number;
    entity_id: number;
    entity_name: string;
    attributes: MatchAttribute[];
    addresses: MatchAddress[];
    relations: MatchRelation[];
    source: MatchSource;
    sources: MatchSource[];
    events: MatchEvent[];
    aliases: MatchAlias[];
    positions: MatchPosition[];
    birth_dates: MatchBirthDate[];
    timestamp: string;
    updated_timestamp?: string;
    review_required: boolean;
    subject_type: SubjectType;
    subject: PersonDetail | CompanyDetail;
};
export type MatchListResponse = {
    matches: Match[];
};
export type MatchDetailResponse = {
    customer_id: string;
    customer_name: string;
    customer_type: CustomerType;
    match: Match;
};
export type MatchCaseListResponse = {
    match_cases: MatchCaseSummary[];
};
export type MatchCaseDetail = {
    id: string;
    match: Match;
    timestamp: string;
    updated_timestamp?: string;
    status: CaseStatus;
    severity: CaseSeverity;
    responsible?: User;
    resolved_dt?: string;
    resolved_by?: User;
    reviews: MatchReview[];
    summary_text: string;
};
export type MatchCaseDetailResponse = {
    customer_id: string;
    customer_name: string;
    customer_type: CustomerType;
    customer_is_archived: boolean;
    "case": MatchCaseDetail;
};
export type CreateMatchReviewRequest = {
    is_match: boolean;
    is_pep?: boolean;
    is_sanctioned?: boolean;
    negative_news?: boolean;
    other?: boolean;
    description: string;
};
export type CreateCustomerReviewRequest = {
    requires_approval?: boolean;
    risk_level?: RiskLevel;
    accepted: boolean;
    comment: string;
};
export type CreatePersonRequest = {
    first_name: string;
    last_name: string;
    birth_date: string;
    national_id?: string;
    country_of_citizenship: CountryEnum;
    address?: Address;
};
export type CreateRoleRequest = {
    "type": RoleType;
    person: CreatePersonRequest;
    comment?: string;
};
export type UpdatePersonRequest = {
    first_name?: string;
    last_name?: string;
    birth_date?: string;
    birth_year?: number;
    country_of_citizenship?: CountryEnum;
    address?: UpdateAddressRequest;
};
export type UpdateRoleRequest = {
    "type"?: RoleType;
    status?: RoleStatus;
    person?: UpdatePersonRequest;
    comment?: string;
};
export type CreateOwnerRequest = {
    person: CreatePersonRequest;
    percent_share: number;
    comment?: string;
};
export type UpdateOwnerRequest = {
    person?: UpdatePersonRequest;
    percent_share?: number;
    deleted?: boolean;
    comment?: string;
};
export type PersonSignatureConfiguration = {
    signature_required?: boolean;
    email?: string;
    send_email?: boolean;
    use_disclosure?: boolean;
    use_verification?: boolean;
};
export type PutPersonSignatureConfigurationRequest = {
    email?: string;
    send_email?: boolean;
    use_disclosure?: boolean;
    use_verification?: boolean;
    signature_required?: boolean;
};
export type PatchPersonSignatureConfigurationRequest = {
    email?: string;
    send_email?: boolean;
    use_disclosure?: boolean;
    use_verification?: boolean;
    signature_required?: boolean;
};
export type UpdateSignatureRequest = {
    email?: string;
    send_email?: boolean;
    use_disclosure?: boolean;
    use_verification?: boolean;
};
export type AttachmentDownloadUrl = {
    download_url: string;
};
export type AttachmentSignatureStatus = "PENDING" | "SIGNED" | "EXPIRED";
export type SignedAttachmentDocumentStatus = "EXISTS" | "DELETED";
export type AttachmentDetails = {
    id: string;
    created_dt: string;
    created_by?: User;
    filename: string;
    signature_required: boolean;
    signature_count: number;
    completed_signature_count: number;
    signature_status?: AttachmentSignatureStatus;
    signed_document_status?: SignedAttachmentDocumentStatus;
    signed_document_deleted_timestamp?: string;
    signed_document_deleted_by?: User;
};
export type AttachmentDetailsList = {
    attachment_list: AttachmentDetails[];
};
export type CreateAttachmentMetadataRequest = {
    filename: string;
    upload_id: string;
};
export type CreateCompanyCustomerDisclosureDocumentRequest = {
    signatures: CompanyRoleSignatureConfiguration[];
};
export type CreatePersonCustomerDisclosureDocumentRequest = {
    email?: string;
    send_email?: boolean;
    use_disclosure?: boolean;
    use_verification?: boolean;
};
export type SavedAttachmentSignature = {
    signed_dt?: string;
    signing_user_info?: SigningUserInfo;
    signing_method?: string;
    notified_dt?: string;
    signing_page_url: string;
    id?: string;
    signer_id?: string;
    role_id?: string;
    first_name?: string;
    last_name?: string;
    national_id?: string;
    send_email: boolean;
    email?: string;
    use_verification: boolean;
    pin: string;
    status: AttachmentSignatureStatus;
    role?: SignerRole;
};
export type AttachmentSignaturesResponse = {
    signatures: SavedAttachmentSignature[];
};
export type CreateOrUpdateAttachmentSignatureRequest = {
    id?: string;
    signer_id?: string;
    role_id?: string;
    first_name?: string;
    last_name?: string;
    national_id?: string;
    send_email: boolean;
    email?: string;
    use_verification: boolean;
};
export type UpdateAttachmentSignaturesRequest = {
    signatures: CreateOrUpdateAttachmentSignatureRequest[];
    "delete"?: boolean;
};
export type BatchSetResponsibleOnCustomers = {
    customer_ids: string[];
    user_id: string;
};
export type BatchCustomersRequestBase = {
    customer_ids: string[];
};
export type ProjectList = {
    count: number;
    projects: ProjectListItem[];
};
export type CreateProjectRequest = {
    name: string;
    description: string;
    customer_ids?: string[];
};
export type ProjectReview = {
    id: string;
    created_dt: string;
    comment: string;
    reviewed_by?: User;
};
export type VitecRole = "SELLER" | "BUYER";
export type VitecReportStatus = "DONE" | "PENDING" | "FAILED";
export type VitecReport = {
    id: string;
    status: VitecReportStatus;
    uploaded_dt: string;
};
export type VitecCustomer = {
    id: string;
    role: VitecRole;
    report: VitecReport;
};
export type VitecData = {
    id: string;
    vitec_url: string;
    customers: VitecCustomer[];
};
export type ProjectIntegrationData = {
    vitec_data: VitecData;
};
export type Project = {
    id: string;
    name: string;
    description: string;
    status: ProjectStatus;
    responsible?: User;
    created_dt: string;
    created_by?: User;
    updated_by?: User;
    updated_dt?: string;
    stopped_by?: User;
    stopped_dt?: string;
    customers: (CompanyCustomer | PersonCustomer)[];
    reviews: ProjectReview[];
    integrations?: ProjectIntegrationData;
};
export type UpdateProjectRequest = {
    name?: string;
    description?: string;
    customer_ids?: string[];
    status?: ProjectStatus;
    responsible_id?: string;
};
export type ProjectCustomersDetails = {
    customers: (CompanyCustomerDetailResponse | PersonCustomerDetailResponse)[];
};
export type CreateProjectReviewRequest = {
    comment: string;
};
export type LabelsOnTargets = {
    target_ids: string[];
    label_ids: string[];
};
export type TitleOnTargets = {
    target_ids: string[];
    title: string;
};
export type AttachmentUploadRequest = {
    filename: string;
    content_type: string;
};
export type AttachmentUploadResponse = {
    upload_url: string;
    upload_id: string;
};
export type Group = {
    id: number;
    name: string;
};
export type UserListResponse = {
    users: User[];
    groups: Group[];
};
export type CreateUserRequest = {
    first_name: string;
    last_name: string;
    email: string;
    group?: number;
};
export type UpdateUserRequest = {
    first_name: string;
    last_name: string;
    email: string;
    is_active: boolean;
    group?: number;
};
export type LabelList = {
    count: number;
    labels: Label[];
};
export type LabelData = {
    title: string;
};
export type Organization = {
    id: string;
    name: string;
    country: CountryEnum;
};
export type AuthUser = {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    is_active: boolean;
    group?: number;
    organization: Organization;
    permissions?: string[];
    features?: {
        [key: string]: boolean;
    };
};
export type StatusMessageSeverity = "INFO" | "WARNING" | "CRITICAL";
export type StatusMessageDetail = {
    id: number;
    message: string;
    severity: StatusMessageSeverity;
    start_time?: string;
    end_time?: string;
};
export type StatusMessageList = {
    status_messages: StatusMessageDetail[];
};
/**
 * Person Onboarding
 */
export function startPersonOnboarding(personOnboarding: PersonOnboarding, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: PersonOnboardingResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/onboarding/person", oazapfts.json({
        ...opts,
        method: "POST",
        body: personOnboarding
    })));
}
/**
 * Get Customer By External Id
 */
export function getCustomerByExternalId(externalId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: DataServicePersonSubjectDetailResponse | DataServiceLegalEntitySubjectDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v1/customers/external-id/${externalId}`, {
        ...opts
    }));
}
/**
 * Delete Customer By External Id
 */
export function deleteCustomerByExternalId(externalId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v1/customers/external-id/${externalId}`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Get Customer By National Id
 */
export function getCustomerByNationalId(getCustomerByNationalIdRequest: GetCustomerByNationalIdRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/v1/customers/national-id", oazapfts.json({
        ...opts,
        method: "POST",
        body: getCustomerByNationalIdRequest
    })));
}
/**
 * Company Info
 */
export function getCompanyInfo(country: ValidCountriesEnum, nationalId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanyInformationLookupResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/lookup/company/${country}/${nationalId}/info`, {
        ...opts
    }));
}
/**
 * Company Ownership
 */
export function getCompanyOwnership(country: ValidCountriesEnum, nationalId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/lookup/company/${country}/${nationalId}/ownership`, {
        ...opts
    }));
}
/**
 * Company Roles
 */
export function getCompanyRoles(country: ValidCountriesEnum, nationalId: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/lookup/company/${country}/${nationalId}/roles`, {
        ...opts
    }));
}
/**
 * Create Company Customer
 */
export function createCompanyCustomer(createCompanyCustomerRequest: CreateCompanyCustomerRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: CompanyCustomerDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/v2/customers/companies", oazapfts.json({
        ...opts,
        method: "POST",
        body: createCompanyCustomerRequest
    })));
}
/**
 * Create Person Customer
 */
export function createPersonCustomer(createPersonCustomerRequest: CreatePersonCustomerRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: PersonCustomerDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/v2/customers/persons", oazapfts.json({
        ...opts,
        method: "POST",
        body: createPersonCustomerRequest
    })));
}
/**
 * Get Person Customer Details
 */
export function getPersonCustomerDetails(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: PersonCustomerDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}`, {
        ...opts
    }));
}
/**
 * Update Person Customer
 */
export function updatePerson(uuid: string, updatePersonCustomerRequest: UpdatePersonCustomerRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: PersonCustomer;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updatePersonCustomerRequest
    })));
}
/**
 * Get Company Screening Configuration
 */
export function getCompanyScreeningConfiguration(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanyScreeningConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/screening`, {
        ...opts
    }));
}
/**
 * Put Company Screening Configuration
 */
export function updateCompanyScreeningConfiguration(uuid: string, putCompanyScreeningConfigurationRequest: PutCompanyScreeningConfigurationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanyScreeningConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/screening`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: putCompanyScreeningConfigurationRequest
    })));
}
/**
 * Patch Company Screening Configuration
 */
export function stopCompanyScreeningConfiguration(uuid: string, patchCompanyScreeningConfigurationRequest: PatchCompanyScreeningConfigurationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanyScreeningConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/screening`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: patchCompanyScreeningConfigurationRequest
    })));
}
/**
 * Stop Company Screening Configuration
 */
export function stopCompanyScreening(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/screening/stop`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Stop Person Screening Configuration
 */
export function stopPersonScreening(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}/screening/stop`, {
        ...opts,
        method: "PUT"
    }));
}
/**
 * Get Person Screening Configuration
 */
export function getPersonScreeningConfiguration(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: PersonScreeningConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}/screening`, {
        ...opts
    }));
}
/**
 * Put Person Screening Configuration
 */
export function updatePersonScreeningConfiguration(uuid: string, personScreeningConfigurationRequest: PersonScreeningConfigurationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: PersonScreeningConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}/screening`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: personScreeningConfigurationRequest
    })));
}
/**
 * Patch Person Screening Configuration
 */
export function setPersonScreeningConfiguration(uuid: string, personScreeningConfigurationRequest: PersonScreeningConfigurationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: PersonScreeningConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}/screening`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: personScreeningConfigurationRequest
    })));
}
/**
 * Get Company Signature Configuration
 */
export function getCompanySignatureConfiguration(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanySignatureConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/signatures`, {
        ...opts
    }));
}
/**
 * Put Company Signature Configuration
 */
export function updateCompanySignatureConfiguration(uuid: string, putCompanySignatureConfigurationRequest: PutCompanySignatureConfigurationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanySignatureConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/signatures`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: putCompanySignatureConfigurationRequest
    })));
}
/**
 * Patch Company Signature Configuration
 */
export function setCompanySignatureConfiguration(uuid: string, patchCompanySignatureConfigurationRequest: PatchCompanySignatureConfigurationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanySignatureConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/signatures`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: patchCompanySignatureConfigurationRequest
    })));
}
/**
 * Remind Signer
 */
export function remindSigner(uuid: string, signatureUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/signatures/${signatureUuid}/remind`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Get Customer List
 */
export function getCustomers({ limit, offset, type, status, riskLevel, hasUnresolvedCases, hasPendingReviews, isPep, isSanctioned, q, responsibleId, ordering, isArchived, createdAfter, createdBefore, screeningType, countryCode }: {
    limit?: number;
    offset?: number;
    "type"?: string;
    status?: string;
    riskLevel?: string;
    hasUnresolvedCases?: string;
    hasPendingReviews?: string;
    isPep?: string;
    isSanctioned?: string;
    q?: string;
    responsibleId?: string;
    ordering?: string;
    isArchived?: string;
    createdAfter?: string;
    createdBefore?: string;
    screeningType?: ScreeningType | "all";
    countryCode?: CountryEnum | "all";
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CustomerListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${QS.query(QS.form({
        limit,
        offset,
        type,
        status,
        risk_level: riskLevel,
        has_unresolved_cases: hasUnresolvedCases,
        has_pending_reviews: hasPendingReviews,
        is_pep: isPep,
        is_sanctioned: isSanctioned,
        q,
        responsible_id: responsibleId,
        ordering,
        is_archived: isArchived,
        created_after: createdAfter,
        created_before: createdBefore,
        screening_type: screeningType,
        country_code: countryCode
    }))}`, {
        ...opts
    }));
}
/**
 * Get Customer Details
 */
export function getCustomerDetails(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanyCustomerDetailResponse | PersonCustomerDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}`, {
        ...opts
    }));
}
/**
 * Archive Customer
 */
export function archiveCustomer(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Update Customer Details
 */
export function updateCustomerDetails(uuid: string, updateCustomerRequest: UpdateCustomerRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanyCustomerDetailResponse | PersonCustomerDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateCustomerRequest
    })));
}
/**
 * Get Company Customer Details
 */
export function getCompanyCustomerDetails(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanyCustomerDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}`, {
        ...opts
    }));
}
/**
 * Update Company Customer
 */
export function updateCompanyCustomer(uuid: string, updateCompanyCustomerRequest: UpdateCompanyCustomerRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CompanyCustomer;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateCompanyCustomerRequest
    })));
}
/**
 * Get Customer Matches
 */
export function getCustomerMatches(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: MatchListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/matches`, {
        ...opts
    }));
}
/**
 * Get Customer Match Detail
 */
export function getCustomerMatchDetails(uuid: string, matchId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: MatchDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/matches/${matchId}`, {
        ...opts
    }));
}
/**
 * Get Customer Match Cases
 */
export function getCustomerMatchCases(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: MatchCaseListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/match-cases`, {
        ...opts
    }));
}
/**
 * Get Customer Match Case Detail
 */
export function getCustomerMatchCaseDetails(uuid: string, caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: MatchCaseDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/match-cases/${caseId}`, {
        ...opts
    }));
}
/**
 * Create Match Review
 */
export function createMatchReview(uuid: string, caseId: string, createMatchReviewRequest: CreateMatchReviewRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: MatchReview;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/match-cases/${caseId}/reviews`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createMatchReviewRequest
    })));
}
/**
 * Create Customer Review
 */
export function createCustomerReview(uuid: string, createCustomerReviewRequest: CreateCustomerReviewRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: CustomerReview;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/reviews`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createCustomerReviewRequest
    })));
}
/**
 * Approve Customer Review
 */
export function approveCustomerReview(uuid: string, reviewUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: CustomerReviewApproval;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/reviews/${reviewUuid}/approve`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Reject Customer Review
 */
export function rejectCustomerReview(uuid: string, reviewUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: CustomerReviewApproval;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/reviews/${reviewUuid}/reject`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Start Company Screening
 */
export function startCompanyScreening(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/screening/start`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Start Person Screening
 */
export function startPersonScreening(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}/screening/start`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Finish Onboarding
 */
export function finishOnboarding(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/onboarding/finish`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Cancel Onboarding
 */
export function cancelOnboarding(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/onboarding/cancel`, {
        ...opts,
        method: "POST"
    }));
}
/**
 * Create Company Role
 */
export function createCompanyRole(uuid: string, createRoleRequest: CreateRoleRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: Role;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/roles`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createRoleRequest
    })));
}
/**
 * Delete Company Role
 */
export function deleteCompanyRole(uuid: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/roles/${roleId}`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Update Company Role
 */
export function updateCompanyRole(uuid: string, roleId: string, updateRoleRequest: UpdateRoleRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Role;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/roles/${roleId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateRoleRequest
    })));
}
/**
 * Create Company Owner
 */
export function createCompanyOwner(uuid: string, createOwnerRequest: CreateOwnerRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: Owner;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/owners`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createOwnerRequest
    })));
}
/**
 * Delete Company Owner
 */
export function deleteCompanyOwner(uuid: string, ownerId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/owners/${ownerId}`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Update Company Owner
 */
export function updateCompanyOwner(uuid: string, ownerId: string, updateOwnerRequest: UpdateOwnerRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Owner;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/owners/${ownerId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateOwnerRequest
    })));
}
/**
 * Get Person Signature Configuration
 */
export function getPersonSignatureConfiguration(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: PersonSignatureConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}/signatures`, {
        ...opts
    }));
}
/**
 * Put Person Signature Configuration
 */
export function updatePersonSignatureConfiguration(uuid: string, putPersonSignatureConfigurationRequest: PutPersonSignatureConfigurationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: PersonSignatureConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}/signatures`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: putPersonSignatureConfigurationRequest
    })));
}
/**
 * Patch Person Signature Configuration
 */
export function setPersonSignatureConfiguration(uuid: string, patchPersonSignatureConfigurationRequest: PatchPersonSignatureConfigurationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: PersonSignatureConfiguration;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}/signatures`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: patchPersonSignatureConfigurationRequest
    })));
}
/**
 * Patch Person Signature
 */
export function updatePersonSignature(uuid: string, signatureId: string, updateSignatureRequest: UpdateSignatureRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Signature;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}/signatures/${signatureId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateSignatureRequest
    })));
}
/**
 * Patch Company Signature
 */
export function updateCompanySignature(uuid: string, signatureId: string, updateSignatureRequest: UpdateSignatureRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Signature;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/signatures/${signatureId}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateSignatureRequest
    })));
}
/**
 * Get Company Customer Matches
 */
export function getCompanyCustomerMatches(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: MatchListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/matches`, {
        ...opts
    }));
}
/**
 * Get Company Customer Match Detail
 */
export function getCompanyCustomerMatchDetails(uuid: string, matchId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: MatchDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/matches/${matchId}`, {
        ...opts
    }));
}
/**
 * Get Company Customer Match Cases
 */
export function getCompanyCustomerMatchCases(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: MatchCaseListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/match-cases`, {
        ...opts
    }));
}
/**
 * Get Company Customer Match Case Detail
 */
export function getCompanyCustomerMatchCaseDetails(uuid: string, caseId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: MatchCaseDetailResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/match-cases/${caseId}`, {
        ...opts
    }));
}
/**
 * Create Company Customer Match Review
 */
export function createCompanyCustomerMatchReview(uuid: string, caseId: string, createMatchReviewRequest: CreateMatchReviewRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: MatchReview;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/match-cases/${caseId}/reviews`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createMatchReviewRequest
    })));
}
/**
 * Create Company Customer Review
 */
export function createCompanyCustomerReview(uuid: string, createCustomerReviewRequest: CreateCustomerReviewRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CustomerReview;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/reviews`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createCustomerReviewRequest
    })));
}
/**
 * Delete Customer Signature
 */
export function deleteCustomerSignature(uuid: string, signatureId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/signatures/${signatureId}`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Get Report For Customer
 */
export function getCustomerReport(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/report`, {
        ...opts
    }));
}
/**
 * Download Customer Attachment
 */
export function downloadCustomerAttachment(uuid: string, attachmentUuid: any, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AttachmentDownloadUrl;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/attachments/${attachmentUuid}/download`, {
        ...opts
    }));
}
/**
 * Get Customer Attachment List
 */
export function getCustomerAttachments(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AttachmentDetailsList;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/attachments`, {
        ...opts
    }));
}
/**
 * Generate Customer Attachment Metadata
 */
export function addCustomerAttachment(uuid: string, createAttachmentMetadataRequest: CreateAttachmentMetadataRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: AttachmentDetails;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/attachments`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createAttachmentMetadataRequest
    })));
}
/**
 * Delete Customer Attachment
 */
export function deleteCustomerAttachment(uuid: string, attachmentUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/attachments/${attachmentUuid}`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Create Company Customer Disclosure Document
 */
export function createCompanyCustomerDisclosureDocument(uuid: string, createCompanyCustomerDisclosureDocumentRequest: CreateCompanyCustomerDisclosureDocumentRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: CustomerDisclosureDocument;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/companies/${uuid}/disclosure-documents`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createCompanyCustomerDisclosureDocumentRequest
    })));
}
/**
 * Create Person Customer Disclosure Document
 */
export function createPersonCustomerDisclosureDocument(uuid: string, createPersonCustomerDisclosureDocumentRequest: CreatePersonCustomerDisclosureDocumentRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: CustomerDisclosureDocument;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/persons/${uuid}/disclosure-documents`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createPersonCustomerDisclosureDocumentRequest
    })));
}
/**
 * Get Subject Attachment Signatures
 */
export function getAttachmentSignatures(uuid: string, attachmentUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AttachmentSignaturesResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/attachments/${attachmentUuid}/signatures`, {
        ...opts
    }));
}
/**
 * Update Subject Attachment Signatures
 */
export function updateAttachmentSignatures(uuid: string, attachmentUuid: string, updateAttachmentSignaturesRequest: UpdateAttachmentSignaturesRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AttachmentSignaturesResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/attachments/${attachmentUuid}/signatures`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: updateAttachmentSignaturesRequest
    })));
}
/**
 * Download Signed Customer Attachment
 */
export function downloadSignedCustomerAttachment(uuid: string, attachmentUuid: any, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/attachments/${attachmentUuid}/signed_document`, {
        ...opts
    }));
}
/**
 * Delete Signed Customer Attachment
 */
export function deleteSignedCustomerAttachment(uuid: string, attachmentUuid: any, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/customers/${uuid}/attachments/${attachmentUuid}/signed_document`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Batch Set Responsible On Customers
 */
export function batchSetResponsibleOnCustomers(batchSetResponsibleOnCustomers: BatchSetResponsibleOnCustomers, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CustomerListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/v2/customers/batch/responsible", oazapfts.json({
        ...opts,
        method: "PATCH",
        body: batchSetResponsibleOnCustomers
    })));
}
/**
 * Archive Customers
 */
export function archiveCustomers(batchCustomersRequestBase: BatchCustomersRequestBase, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CustomerListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/v2/customers/batch/archive", oazapfts.json({
        ...opts,
        method: "PATCH",
        body: batchCustomersRequestBase
    })));
}
/**
 * Restore Customers
 */
export function restoreCustomers(batchCustomersRequestBase: BatchCustomersRequestBase, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CustomerListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/v2/customers/batch/restore", oazapfts.json({
        ...opts,
        method: "PATCH",
        body: batchCustomersRequestBase
    })));
}
/**
 * Get Project List
 */
export function getProjects({ limit, offset, status, q, responsibleId, createdAfter, createdBefore, ordering }: {
    limit?: number;
    offset?: number;
    status?: string;
    q?: string;
    responsibleId?: string;
    createdAfter?: string;
    createdBefore?: string;
    ordering?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ProjectList;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${QS.query(QS.form({
        limit,
        offset,
        status,
        q,
        responsible_id: responsibleId,
        created_after: createdAfter,
        created_before: createdBefore,
        ordering
    }))}`, {
        ...opts
    }));
}
/**
 * Create Project
 */
export function createProject(createProjectRequest: CreateProjectRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: Project;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/v2/projects/", oazapfts.json({
        ...opts,
        method: "POST",
        body: createProjectRequest
    })));
}
/**
 * Get Project
 */
export function getProject(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Project;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}`, {
        ...opts
    }));
}
/**
 * Update Project
 */
export function updateProject(uuid: string, updateProjectRequest: UpdateProjectRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Project;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: updateProjectRequest
    })));
}
/**
 * Get Project Customers Details
 */
export function getProjectCustomersDetails(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: ProjectCustomersDetails;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}/customers`, {
        ...opts
    }));
}
/**
 * Create Project Review
 */
export function createProjectReview(uuid: string, createProjectReviewRequest: CreateProjectReviewRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: ProjectReview;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}/reviews`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createProjectReviewRequest
    })));
}
/**
 * Get Project Attachment List
 */
export function getProjectAttachments(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AttachmentDetailsList;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}/attachments`, {
        ...opts
    }));
}
/**
 * Generate Project Attachment Metadata
 */
export function addProjectAttachment(uuid: string, createAttachmentMetadataRequest: CreateAttachmentMetadataRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: AttachmentDetails;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}/attachments`, oazapfts.json({
        ...opts,
        method: "POST",
        body: createAttachmentMetadataRequest
    })));
}
/**
 * Download Project Attachment
 */
export function downloadProjectAttachment(uuid: string, attachmentUuid: any, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}/attachments/${attachmentUuid}/download`, {
        ...opts
    }));
}
/**
 * Delete Project Attachment
 */
export function deleteProjectAttachment(uuid: string, attachmentUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}/attachments/${attachmentUuid}`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Get Project Attachment Signatures
 */
export function getProjectAttachmentSignatures(uuid: string, attachmentUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AttachmentSignaturesResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}/attachments/${attachmentUuid}/signatures`, {
        ...opts
    }));
}
/**
 * Update Project Attachment Signatures
 */
export function updateProjectAttachmentSignatures(uuid: string, attachmentUuid: string, updateAttachmentSignaturesRequest: UpdateAttachmentSignaturesRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AttachmentSignaturesResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}/attachments/${attachmentUuid}/signatures`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: updateAttachmentSignaturesRequest
    })));
}
/**
 * Download Signed Project Attachment
 */
export function downloadSignedProjectAttachment(uuid: string, attachmentUuid: any, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}/attachments/${attachmentUuid}/signed_document`, {
        ...opts
    }));
}
/**
 * Delete Signed Project Attachment
 */
export function deleteSignedProjectAttachment(uuid: string, attachmentUuid: any, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${uuid}/attachments/${attachmentUuid}/signed_document`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Batch Assign Labels To Project Customers
 */
export function batchAssignLabelsToProjectCustomers(projectUuid: string, labelsOnTargets: LabelsOnTargets, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CustomerListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${projectUuid}/batch-assign-label-to-customers`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: labelsOnTargets
    })));
}
/**
 * Batch Set Labels To Project Customers
 */
export function batchSetLabelsOnProjectCustomers(projectUuid: string, labelsOnTargets: LabelsOnTargets, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CustomerListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${projectUuid}/batch-set-label-on-customers`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: labelsOnTargets
    })));
}
/**
 * Batch Create And Assign Label To Project Customers
 */
export function batchCreateAndAssignLabelOnProjectCustomers(projectUuid: string, titleOnTargets: TitleOnTargets, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CustomerListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${projectUuid}/batch-create-and-assign-label-on-customers`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: titleOnTargets
    })));
}
/**
 * Batch Unassign Labels From Project Customers
 */
export function batchUnassignLabelsFromProjectCustomers(projectUuid: string, labelsOnTargets: LabelsOnTargets, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: CustomerListResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/projects/${projectUuid}/batch-unassign-labels-from-customers`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: labelsOnTargets
    })));
}
/**
 * Generate Attachment Upload Id And Url
 */
export function generateAttachmentUploadIdAndUrl(attachmentUploadRequest: AttachmentUploadRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AttachmentUploadResponse;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/v2/attachments/upload", oazapfts.json({
        ...opts,
        method: "POST",
        body: attachmentUploadRequest
    })));
}
/**
 * List Users
 */
export function getAllUsersAndGroups(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: UserListResponse;
    } | {
        status: 401;
    }>("/v2/users/", {
        ...opts
    }));
}
/**
 * Create User
 */
export function createUser(createUserRequest: CreateUserRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: User;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/v2/users/", oazapfts.json({
        ...opts,
        method: "POST",
        body: createUserRequest
    })));
}
/**
 * Update User
 */
export function updateUser(uuid: any, updateUserRequest: UpdateUserRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: User;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/users/${uuid}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: updateUserRequest
    })));
}
/**
 * List Labels
 */
export function getAllLabels({ q }: {
    q?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: LabelList;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/labels/${QS.query(QS.form({
        q
    }))}`, {
        ...opts
    }));
}
/**
 * Create Label
 */
export function createLabel(labelData: LabelData, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 201;
        data: Label;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>("/v2/labels/", oazapfts.json({
        ...opts,
        method: "POST",
        body: labelData
    })));
}
/**
 * Delete Label
 */
export function deleteLabel(uuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/labels/${uuid}`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Update Label
 */
export function updateLabel(uuid: string, labelData: LabelData, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Label;
    } | {
        status: 401;
    } | {
        status: 422;
        data: HttpValidationError;
    }>(`/v2/labels/${uuid}`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: labelData
    })));
}
/**
 * Auth verification
 */
export function getWhoAmI(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: any;
    } | {
        status: 401;
    }>("/auth/whoami", {
        ...opts
    }));
}
/**
 * User auth
 */
export function getLoggedInUser(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: AuthUser;
    } | {
        status: 401;
    }>("/auth/user", {
        ...opts
    }));
}
/**
 * Get Status Message List
 */
export function getStatusMessageList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: StatusMessageList;
    } | {
        status: 401;
    }>("/service/status-message/", {
        ...opts
    }));
}
