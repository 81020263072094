import React, { ReactElement, useState } from "react";
import { BadgeProps,Button, Card, Popover, Stack, TextContainer, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import ChevronIcon from "../../../assets/icons/chevron-right.svg";
import api from "../../api";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { isEmpty } from "../../utils/util";
import CustomDate from "../CustomDate";
import Badge from "../extensions/Badge";
import Icon from "../extensions/Icon";

export type InfoUpdateFieldChange = api.CompanyInfoUpdateFieldChange & {
  timestamp: string;
  isReviewed: boolean;
};

const isEmptyChange = (change: InfoUpdateFieldChange) => isEmpty(change.old) && isEmpty(change.new);

export function hasUnreviewedChanges(changes?: InfoUpdateFieldChange[]) {
  if (!changes) {
    return false;
  }

  return changes.find((change) => !change.isReviewed && !isEmptyChange(change)) !== undefined;
}

export interface ValueWithChangesProps<T> {
  value?: T;
  changes?: InfoUpdateFieldChange[];
  markAsNew?: boolean;
  hideHistory?: boolean;
  lastUpdated?: string;
  tooltipContent?: string;
  tooltipNavigationLabel?: string;
  status?: BadgeProps["status"];
  onTooltipNavigation?(): void;
  render?(value?: T): ReactElement;
}

function ValueWithChanges<T = string>(props: ValueWithChangesProps<T>) {
  const {
    value,
    changes = [],
    markAsNew,
    hideHistory,
    lastUpdated,
    tooltipContent,
    tooltipNavigationLabel,
    status = "info",
    onTooltipNavigation,
    render = (value) => <TextStyle>{value}</TextStyle>,
  } = props;

  const [showTooltip, setShowTooltip] = useState(false);
  const f = useFormatMessage();
  const features = useFeatures();

  if (!features.LEGAL_ENTITY_MONITORING || (!markAsNew && (!changes || changes.length === 0))) {
    return render(value);
  }

  const lastUpdatedDate = lastUpdated || (changes.length > 0 ? changes[0].timestamp : undefined);
  const showNewBadge = markAsNew || hasUnreviewedChanges(changes);

  const displayChange = changes.filter((change) => !isEmpty(change.old));

  return (
    <TextContainer spacing="tight">
      <Stack alignment="center" spacing="extraTight">
        {render(value)}
        {showNewBadge && (
          <Popover
            preferredPosition="above"
            active={showTooltip}
            onClose={() => setShowTooltip(false)}
            activator={
              <StyledActivator onClick={() => setShowTooltip(true)}>
                <Badge size="small" status={status}>
                  <TextStyle variation="strong">
                    {f("default.new")} ({<CustomDate date={lastUpdatedDate} />})
                  </TextStyle>
                </Badge>
              </StyledActivator>
            }
          >
            <Card>
              <Card.Section>
                <Stack vertical spacing="tight">
                  <TextStyle>{tooltipContent}</TextStyle>
                  <Stack spacing="extraTight" alignment="center">
                    <Button plain onClick={onTooltipNavigation}>
                      {tooltipNavigationLabel}
                    </Button>
                    <Icon source={ChevronIcon} width="8px" height="14px" />
                  </Stack>
                </Stack>
              </Card.Section>
            </Card>
          </Popover>
        )}
      </Stack>

      {!hideHistory && displayChange.length > 0 && (
        <Stack vertical spacing="extraTight">
          {displayChange.map((change, index) => (
            <TextStyle key={index} variation="subdued">
              {render(change.old || "-")} ({f("default.expired")} <CustomDate date={change.timestamp} />)
            </TextStyle>
          ))}
        </Stack>
      )}
    </TextContainer>
  );
}

const StyledActivator = styled.div`
  cursor: pointer;
  & .Polaris-Badge {
    padding: 0 0.8rem;
    bordercolor: transparent;
  }

  &:hover .Polaris-Badge {
    filter: brightness(85%);
  }

  &:active .Polaris-Badge {
    filter: brightness(75%);
  }
`;

export default ValueWithChanges;
