import * as React from "react";
import { Stack } from "@shopify/polaris";

import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";
import { getBirthDate, getFullName } from "../utils/displayUtils";
import { isEmailEnabled } from "../utils/util";

import DisplayName from "./DisplayName";

type OnboardingSummarySignatureCardProps = {
  signature: api.Signature;
};

const OnboardingSummarySignatureCard: React.FC<OnboardingSummarySignatureCardProps> = ({ signature }) => {
  const f = useFormatMessage();

  const signerName = getFullName(signature.person);
  const signerEmail =
    isEmailEnabled(signature) && signature.email ? signature.email : f("signature.email.not.provided");

  return (
    <Stack distribution="fillEvenly" spacing="extraLoose" alignment="center">
      <DisplayName type="person" name={signerName} birthDate={getBirthDate(signature.person)} />
      <span>{signerEmail}</span>
    </Stack>
  );
};

export default OnboardingSummarySignatureCard;
