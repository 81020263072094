import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Card, DataTable, Heading, Stack, TextStyle } from "@shopify/polaris";

import api from "../../api";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useDefaultPersonDetails from "../../hooks/useDefaultPersonDetails";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import AddItemButton from "../AddItemButton";
import RowHighlighter from "../extensions/RowHighlighter";
import RoleModal, { RoleModalData } from "../modals/RoleModal";
import useRoleOrOwnerRow from "../screening/useRoleOrOwnerRow";

interface CompanyRolesProps {
  customerId: string;
  companyCountry: api.CountryEnum;
  roles: api.Role[];
  expiredRoles?: api.Role[];
  readonly?: boolean;
}

const CompanyRoles: React.FC<CompanyRolesProps> = (props) => {
  const { customerId, companyCountry, roles = [], expiredRoles = [], readonly } = props;

  const [modalRole, setModalRole] = useState<RoleModalData>();
  const [showExpiredRoles, setShowExpiredRoles] = useState(false);
  const f = useFormatMessage();
  const features = useFeatures();
  const queryClient = useQueryClient();
  const newPersonDetails = useDefaultPersonDetails(companyCountry);
  const defaultCountry = useDefaultCountry();

  const toggleShowExpiredRoles = () => setShowExpiredRoles(!showExpiredRoles);

  const closeModal = () => setModalRole(undefined);

  const renderRole = useRoleOrOwnerRow();

  const rowsToDisplay = showExpiredRoles ? [...roles, ...expiredRoles] : roles;

  const buttonLabel = showExpiredRoles
    ? f("roles.button.labels.hide-expired-roles")
    : f("roles.button.labels.show-expired-roles");

  const handleCreateRole = (createdRole: api.Role) => {
    const companyDetailsQueryKey = ["company-details", customerId];

    const companyDetailsData = queryClient.getQueryData<api.CompanyCustomerDetailResponse>(companyDetailsQueryKey);

    if (companyDetailsData) {
      companyDetailsData.roles = companyDetailsData.roles.concat(createdRole);
      queryClient.setQueryData(companyDetailsQueryKey, companyDetailsData);
    }

    closeModal();
  };

  return (
    <Stack vertical>
      <Heading>{f("company.roles.title")}</Heading>
      <Card>
        {rowsToDisplay.length === 0 && (
          <Card.Section>
            <TextStyle>{f("roles.messages.no-roles")}</TextStyle>
          </Card.Section>
        )}
        {rowsToDisplay.length > 0 && (
          <DataTable
            columnContentTypes={["text", "text", "text"]}
            headings={[]}
            rows={rowsToDisplay.map((r) => {
              const row = renderRole({
                entity: r,
                onEdit: (id) => {
                  const country = r.person.country_of_citizenship || companyCountry || defaultCountry;
                  setModalRole({
                    id: id,
                    comment: r.comment,
                    type: r.type,
                    first_name: r.person.first_name,
                    last_name: r.person.last_name,
                    birth_date: r.person.birth_date,
                    country_of_citizenship: country,
                    address: r.person.address ? r.person.address : { country },
                    isManuallyCreated: Boolean(r.created_by),
                  });
                },
              });

              const editButton = !readonly ? row.editBtn : undefined;

              return [
                row.stack,
                row.div,
                features.LEGAL_ENTITY_MONITORING ? (
                  <RowHighlighter highlight={r.is_new} subdued={r.status === "EXPIRED"}>
                    {editButton}
                  </RowHighlighter>
                ) : (
                  editButton
                ),
              ];
            })}
          />
        )}
        <Card.Section>
          <AddItemButton onClick={() => setModalRole(newPersonDetails())} disabled={readonly}>
            {f("screening.buttons.add-role")}
          </AddItemButton>
        </Card.Section>
      </Card>
      {expiredRoles.length > 0 && (
        <Button onClick={() => toggleShowExpiredRoles()} textAlign="left" disclosure={showExpiredRoles ? "down" : "up"}>
          {buttonLabel}
        </Button>
      )}
      {modalRole && (
        <RoleModal
          id={customerId}
          dataRole={modalRole}
          onClose={closeModal}
          isDeleteDisabled
          onDelete={closeModal}
          onUpdate={(updatedRole) => {
            const roleToUpdate = roles.find((r) => updatedRole.id === r.id);
            if (roleToUpdate) {
              // This way we propagate the change to the original `roles`
              // So the list of roles get updated
              Object.assign(roleToUpdate, updatedRole);
            }
            closeModal();
          }}
          onCreate={(createdRole) => handleCreateRole(createdRole)}
        />
      )}
    </Stack>
  );
};

export default CompanyRoles;
