import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ActionListItemDescriptor } from "@shopify/polaris";
import styled from "styled-components";

import EmailIcon from "../../assets/icons/envelope.svg";
import CopyLinkIcon from "../../assets/icons/link.svg";
import EditIcon from "../../assets/icons/pencil.svg";
import RemindIcon from "../../assets/icons/remind.svg";
import DeleteIcon from "../../assets/icons/trash.svg";
import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";
import { getBirthDate, getFullName } from "../utils/displayUtils";
import { isEmailEnabled } from "../utils/util";

import CopyLinkModal from "./modals/CopyLinkModal";
import DeleteSignatureModal from "./modals/DeleteSignatureModal";
import RemindSignerModal from "./modals/RemindSignerModal";
import UpdateEmailModal from "./modals/UpdateEmailModal";
import ActionsDropdown from "./ActionsDropdown";

type SignatureActionsProps = {
  customerId: string;
  signature: api.Signature;
  existingEmails: string[];
  onEmailUpdated?(newEmail: string): void;
  readonly?: boolean;
};

const SignatureActions: React.FC<SignatureActionsProps> = ({
  customerId,
  signature,
  existingEmails,
  onEmailUpdated,
  readonly,
}) => {
  const [email, setEmail] = useState(signature.email);
  const [showEditEmailModal, setShowEditEmailModal] = useState(false);
  const [showRemindSignerModal, setShowRemindSignerModal] = useState(false);
  const [showCopySignatureLinkModal, setShowCopySignatureLinkModal] = useState(false);
  const [showDeleteSignatureModal, setShowDeleteSignatureModal] = useState(false);
  const [isEmailEnabledForSignature, setIsEmailEnabledForSignature] = useState(isEmailEnabled(signature));

  const f = useFormatMessage();
  const history = useHistory();

  const actions: ActionListItemDescriptor[] = [];

  actions.push({
    content: isEmailEnabledForSignature ? f("signature.actions.change.email") : f("signature.actions.add.email"),
    image: isEmailEnabledForSignature ? EditIcon : EmailIcon,
    onAction: () => setShowEditEmailModal(true),
    disabled: readonly,
  });

  if (isEmailEnabledForSignature && email) {
    actions.push({
      content: f("signature.actions.remind.signer"),
      image: RemindIcon,
      onAction: () => setShowRemindSignerModal(true),
      disabled: readonly,
    });
  }

  actions.push(
    {
      content: f("signature.actions.copy.link"),
      image: CopyLinkIcon,
      onAction: () => setShowCopySignatureLinkModal(true),
    },
    {
      content: f("signature.actions.delete.signature"),
      image: DeleteIcon,
      onAction: () => setShowDeleteSignatureModal(true),
      disabled: readonly,
    }
  );

  if (signature.is_complete) {
    return <StyledDropdown />;
  }

  return (
    <>
      <ActionsDropdown hideTitle items={actions} />
      <UpdateEmailModal
        customerId={customerId}
        signatureId={signature.id}
        email={email || ""}
        roleId={signature.role_id}
        person={signature.person}
        open={showEditEmailModal}
        onEmailUpdated={(newEmail: string) => {
          setEmail(newEmail);
          setIsEmailEnabledForSignature(true);
          onEmailUpdated && onEmailUpdated(newEmail);
        }}
        onModalClose={() => setShowEditEmailModal(false)}
        existingEmails={existingEmails}
      />
      <RemindSignerModal
        customerId={customerId}
        signatureId={signature.id}
        email={email!}
        name={getFullName(signature.person)}
        birthdate={getBirthDate(signature.person)}
        open={showRemindSignerModal}
        onModalClose={() => setShowRemindSignerModal(false)}
      />
      <CopyLinkModal
        title={f("signature.modal.and.tooltips.copy.link")}
        url={signature.signing_page_url}
        open={showCopySignatureLinkModal}
        onModalClose={() => setShowCopySignatureLinkModal(false)}
      />
      <DeleteSignatureModal
        customerId={customerId}
        signatureId={signature.id}
        signatureName={getFullName(signature.person)}
        open={showDeleteSignatureModal}
        onSignatureDeleted={() => {
          history.push(history.location.pathname, { snackbar: f("signature.snackbar.deleted") });

          // While push should take us back to a specific route, since we're on the same route
          // we'll have to "push and go" to refresh the page with the snackbar state :-)
          history.go(0);
        }}
        onModalClose={() => setShowDeleteSignatureModal(false)}
      />
    </>
  );
};

const StyledDropdown = styled.div`
  width: 43.66px;
  height: 34.75px;
`;

export default SignatureActions;
