import { useCallback, useState } from "react";

import { EntityWithId } from "../types/utilities";

const useSelectionMap = <T extends EntityWithId>(
  entities: T[]
): [string[], T[], (selectedIds: string[]) => void, () => void] => {
  const [selectionMap, setSelectionMap] = useState<Record<string, T>>({});

  const selectedIds = Object.keys(selectionMap);
  const selectedEntities = Object.values(selectionMap);

  const handleSelectionChange = useCallback(
    (changedSelectedIds: string[]) => {
      setSelectionMap((currentSelectedCustomersMap) => {
        const selectionMap: Record<string, T> = {};

        changedSelectedIds.forEach((selectedCustomerId) => {
          selectionMap[selectedCustomerId] =
            currentSelectedCustomersMap[selectedCustomerId] ||
            entities.find((entity) => entity.id === selectedCustomerId);
        });

        return selectionMap;
      });
    },
    [entities, setSelectionMap]
  );

  const clearSelection = () => setSelectionMap({});

  return [selectedIds, selectedEntities, handleSelectionChange, clearSelection];
};

export default useSelectionMap;
