import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import {
  Banner,
  Button,
  Card,
  Checkbox,
  DataTable,
  EmptyState,
  Heading,
  Layout,
  Page,
  Stack,
  TextStyle,
} from "@shopify/polaris";

import MaxAttemptsReached from "../../../assets/images/max-attempts-reached.jpg";
import api from "../../api";
import AddItemButton from "../../components/AddItemButton";
import CompanyCard from "../../components/company-details/CompanyCard";
import ErrorPanel from "../../components/ErrorPanel";
import InfoBox from "../../components/InfoBox";
import OwnerModal, { OwnerModalData } from "../../components/modals/OwnerModal";
import RoleModal, { RoleModalData } from "../../components/modals/RoleModal";
import BackButton from "../../components/navigation/BackButton";
import useNavigationToolbar from "../../components/navigation/useNavigationToolbar";
import ScreeningSkeletonPage from "../../components/screening/ScreeningSkeletonPage";
import ScreeningTypeCard from "../../components/screening/ScreeningTypeCard";
import useRoleOrOwnerRow from "../../components/screening/useRoleOrOwnerRow";
import SectionMargin from "../../components/SectionMargin";
import { ROUTES } from "../../constants/routes";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useDefaultPersonDetails from "../../hooks/useDefaultPersonDetails";
import useFormatMessage from "../../hooks/useFormatMessage";
import useGetCompanyScreeningConfiguration from "../../hooks/useGetCompanyScreeningConfiguration";
import { IDParams } from "../../types/params";
import { SelectionMap } from "../../types/utilities";
import ErrorPage from "../ErrorPage";

const CompanyOnboardingScreening: React.FC<RouteComponentProps<IDParams>> = ({ history, match }) => {
  const id = match.params.id;

  const f = useFormatMessage();
  const renderRow = useRoleOrOwnerRow();

  const { isManualCompany } = useNavigationToolbar();
  const defaultCountry = useDefaultCountry();
  const queryClient = useQueryClient();

  const [owners, setOwners] = useState<api.Owner[]>([]);
  const [roles, setRoles] = useState<api.Role[]>([]);

  const [modalRole, setModalRole] = useState<RoleModalData>();
  const [modalOwner, setModalOwner] = useState<OwnerModalData>();

  const [screeningType, setScreeningType] = useState<api.ScreeningType>("MONITORING");

  const [selectedRoles, setSelectedRoles] = useState<SelectionMap>({});
  const [selectedOwners, setSelectedOwners] = useState<SelectionMap>({});

  const [isAllRoles, setAllRoles] = useState(false);
  const [isAllOwners, setAllOwners] = useState(false);

  const toggleAllRoles = (newValue: boolean) => {
    setAllRoles(newValue);

    const newSelectedRoles: typeof selectedRoles = {};

    if (newValue) {
      roles.forEach((r) => {
        newSelectedRoles[r.id!] = true;
      });
    }

    setSelectedRoles(newSelectedRoles);
  };

  const toggleAllOwners = (newValue: boolean) => {
    setAllOwners(newValue);
    const newSelectedOwners: typeof selectedOwners = {};

    if (newValue) {
      owners.forEach((o) => {
        newSelectedOwners[o.id!] = true;
      });
    } else {
      owners.forEach((o) => {
        if (o.is_ubo) newSelectedOwners[o.id!] = true;
      });
    }

    setSelectedOwners(newSelectedOwners);
  };

  const updateOwners = (id: string, newValue: boolean) => {
    setAllOwners(false);
    const tmpOwners = Object.assign({}, selectedOwners);
    tmpOwners[id] = newValue;
    setSelectedOwners(tmpOwners);
  };

  const updateRoles = (id: string, newValue: boolean) => {
    setAllRoles(false);
    const tmpRoles = Object.assign({}, selectedRoles);
    tmpRoles[id] = newValue;
    setSelectedRoles(tmpRoles);
  };

  // Get screening config and company customer details
  const companyCustomerDetailsQuery = useQuery(["company-details", id], () => api.getCompanyCustomerDetails(id));
  const companyScreeningConfigQuery = useGetCompanyScreeningConfiguration(id);

  const newPersonDetails = useDefaultPersonDetails(companyCustomerDetailsQuery.data?.country);

  //modals
  const editModalRole = useCallback(
    (id: string) => {
      const r = roles.find((role) => role.id === id);

      if (!r) {
        return;
      }

      const country = r.person.country_of_citizenship || companyCustomerDetailsQuery.data?.country || defaultCountry;

      setModalRole({
        id: id,
        comment: r.comment,
        type: r.type,
        first_name: r.person.first_name,
        last_name: r.person.last_name,
        birth_date: r.person.birth_date,
        country_of_citizenship: country,
        address: r.person.address ? r.person.address : { country },
      });
    },
    [id, companyCustomerDetailsQuery.data?.country, roles]
  );

  const editModalOwner = useCallback(
    (id: string) => {
      const o = owners.find((owner) => owner.id === id);

      if (!o) {
        return;
      }

      let percent_share = 0;
      try {
        percent_share = parseFloat(o.percent_share || "0");
      } catch (error) {
        console.error(`Failed to parse percent_share: ${error}`);
      }

      const country = o.person.country_of_citizenship || companyCustomerDetailsQuery.data?.country || defaultCountry;

      setModalOwner({
        id: id,
        comment: o.comment,
        percent_share,
        first_name: o.person.first_name,
        last_name: o.person.last_name,
        birth_date: o.person.birth_date,
        country_of_citizenship: country,
        address: o.person.address ? o.person.address : { country },
      });
    },
    [id, companyCustomerDetailsQuery.data?.country, owners]
  );
  //modals end

  // mutation
  const updateScreeningConfiguration = useMutation(
    () => {
      return api.updateCompanyScreeningConfiguration(id, {
        roles: Object.keys(selectedRoles).filter((r) => selectedRoles[r]),
        owners: Object.keys(selectedOwners).filter((o) => selectedOwners[o]),
        screening_type: screeningType,
      });
    },
    {
      onSuccess: () => {
        companyScreeningConfigQuery.invalidateQuery();
        queryClient.invalidateQueries(["company-onboarding-disclosures", id]);

        if (isManualCompany) {
          history.push(generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_DISCLOSURES, { id: id }));
        } else {
          history.push(generatePath(ROUTES.COMPANY_ONBOARDING_DISCLOSURES, { id: id }));
        }
      },
    }
  );
  // mutation end

  useEffect(() => {
    if (companyScreeningConfigQuery.isLoading || !companyScreeningConfigQuery.data) {
      return;
    }

    const screening = companyScreeningConfigQuery.data;
    const { beneficial_owners: tmp_owners, roles: tmp_roles } = screening;

    setOwners(tmp_owners.map((o) => o.owner));
    setRoles(tmp_roles.map((r) => r.role));

    const tmp_selectedRoles: typeof selectedRoles = {};
    tmp_roles.forEach((r) => (tmp_selectedRoles[r.role.id!] = r.screening_required));
    setSelectedRoles(tmp_selectedRoles);

    const tmp_selectedOwners: typeof selectedOwners = {};
    tmp_owners.forEach((o) => (tmp_selectedOwners[o.owner.id!] = o.owner.is_ubo || o.screening_required));
    setSelectedOwners(tmp_selectedOwners);

    if (screening.screening_type && screening.screening_type !== "NONE") {
      setScreeningType(screening.screening_type);
    }
  }, [companyScreeningConfigQuery.data, companyScreeningConfigQuery.isLoading]);

  if (companyScreeningConfigQuery.isError || companyCustomerDetailsQuery.isError) {
    if (companyScreeningConfigQuery.isMaxRetriesReached) {
      return (
        <EmptyState
          heading={f("screening.errors.heading")}
          action={{
            content: f("screening.errors.content"),
            onAction: () => window.location.reload(),
          }}
          image={MaxAttemptsReached}
          fullWidth={true}
        />
      );
    } else {
      return <ErrorPage error={companyScreeningConfigQuery.error || companyCustomerDetailsQuery.error} />;
    }
  }

  const displaySkeleton =
    companyScreeningConfigQuery.isLoading ||
    !companyScreeningConfigQuery.data ||
    companyCustomerDetailsQuery.isLoading ||
    !companyCustomerDetailsQuery.data;

  if (displaySkeleton) {
    return (
      <ScreeningSkeletonPage
        progress={companyScreeningConfigQuery.loadingProgress}
        message={f("screening.messages.retrieving-details")}
      />
    );
  }

  const companyCustomer = companyCustomerDetailsQuery.data;
  const canHaveShareholders = companyCustomer.can_have_shareholders === false ? false : true;
  const companyType = companyCustomer.company_type?.description_nb || companyCustomer.company_type?.code || "-";

  return (
    <Page title={f("screening.titles.screening")}>
      <Layout>
        <Layout.Section>
          <Stack vertical>
            <TextStyle>
              {companyCustomer.is_manual
                ? f("screening.titles.screening.manual.description")
                : canHaveShareholders
                ? f("screening.titles.screening.description")
                : f("screening.titles.screening.without-shareholders.description")}
            </TextStyle>

            <Banner title={f("screening.titles.banner.title")} status="info">
              {f("screening.titles.banner.description")}
            </Banner>

            <SectionMargin size="medium" />
            <Heading>{f("screening.titles.customer")}</Heading>
            <CompanyCard />
          </Stack>
        </Layout.Section>
        <Layout.Section secondary />

        <Layout.Section>
          <SectionMargin size="medium" />
          <Heading>{f("screening.titles.owners")}</Heading>
          {companyCustomer.is_manual && <TextStyle>{f("screening.messages.specify-owners")}</TextStyle>}
        </Layout.Section>
        <Layout.Section secondary />
        <Layout.Section>
          <Card>
            {!canHaveShareholders && (
              <Card.Section>{f("screening.cards.cannot-have-shareholders", { companyType: companyType })}</Card.Section>
            )}
            {canHaveShareholders && (
              <>
                {!companyScreeningConfigQuery.isLoading && owners.length === 0 && (
                  <Card.Section>{f("screening.cards.no-owners")}</Card.Section>
                )}
                {owners.length > 0 && (
                  <DataTable
                    columnContentTypes={["text", "text", "text"]}
                    headings={[
                      <Checkbox checked={isAllOwners} onChange={toggleAllOwners} label="" labelHidden />,
                      <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
                      <TextStyle variation="subdued">{f("table.column.shares")}</TextStyle>,
                      "",
                    ]}
                    rows={owners.map((o) => {
                      const row = renderRow({
                        entity: o,
                        checked: selectedOwners[o.id!] === true,
                        onCheckboxChange: (id, newValue) => {
                          updateOwners(id, newValue);
                        },
                        onEdit: (id) => editModalOwner(id),
                      });

                      return [row.checkbox, row.stack, row.div, row.editBtn];
                    })}
                  />
                )}

                <Card.Section>
                  <AddItemButton onClick={() => setModalOwner(newPersonDetails())}>
                    {f("screening.buttons.add-owner")}
                  </AddItemButton>
                </Card.Section>
              </>
            )}
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <InfoBox title={f("screening.infobox.owners.title")}>
            <p>{f("screening.infobox.owners.definition")}</p>
            <p>
              {companyCustomer.is_manual
                ? f("screening.infobox.owners.text.manual")
                : f("screening.infobox.owners.text.norwegian")}
            </p>
          </InfoBox>
        </Layout.Section>

        <Layout.Section>
          <SectionMargin size="medium" />
          <Heading>{f("screening.titles.roles")}</Heading>
          {companyCustomer.is_manual && <TextStyle>{f("screening.messages.specify-roles")}</TextStyle>}
        </Layout.Section>
        <Layout.Section>
          <Card>
            {!companyScreeningConfigQuery.isLoading && roles.length === 0 && (
              <Card.Section>{f("roles.messages.no-roles")}</Card.Section>
            )}
            {roles.length > 0 ? (
              <DataTable
                hideScrollIndicator={true}
                columnContentTypes={["text", "text", "text", "text"]}
                headings={[
                  <Checkbox checked={isAllRoles} onChange={toggleAllRoles} label="" labelHidden />,
                  <TextStyle variation="subdued">{f("table.column.name")}</TextStyle>,
                  <TextStyle variation="subdued">{f("table.column.title")}</TextStyle>,
                  "",
                ]}
                rows={roles.map((r) => {
                  const row = renderRow({
                    entity: r,
                    checked: selectedRoles[r.id!],
                    onCheckboxChange: (id, newValue) => {
                      updateRoles(id, newValue);
                    },
                    onEdit: (id) => editModalRole(id),
                  });

                  return [row.checkbox, row.stack, row.div, row.editBtn];
                })}
              />
            ) : null}
            <Card.Section>
              <AddItemButton onClick={() => setModalRole(newPersonDetails())}>
                {f("screening.buttons.add-role")}
              </AddItemButton>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <InfoBox title={f("screening.infobox.roles.title")}>
            <p>{f("screening.infobox.roles.definition")}</p>
            <p>
              {companyCustomer.is_manual
                ? f("screening.infobox.roles.text.manual")
                : f("screening.infobox.roles.text.norwegian")}
            </p>
          </InfoBox>
        </Layout.Section>

        <Layout.Section>
          <SectionMargin size="medium" />
          <Heading>{f("screening.titles.screening-frequency")}</Heading>
        </Layout.Section>
        <Layout.Section>
          <Stack vertical>
            <ScreeningTypeCard
              screeningType={screeningType}
              onScreeningTypeChange={(newValue) => setScreeningType(newValue)}
            />
            {updateScreeningConfiguration.isError && <ErrorPanel message={updateScreeningConfiguration.error} />}
            <Stack distribution="trailing">
              {companyCustomer.is_manual && (
                <BackButton url={generatePath(ROUTES.MANUAL_COMPANY_ONBOARDING_EDIT, { id: id })} />
              )}
              <Button
                primary
                disabled={updateScreeningConfiguration.isLoading || !screeningType || screeningType === "NONE"}
                loading={updateScreeningConfiguration.isLoading}
                onClick={() => updateScreeningConfiguration.mutate()}
              >
                {f("button.go.next")}
              </Button>
            </Stack>
          </Stack>
        </Layout.Section>
        <Layout.Section secondary />
      </Layout>

      {modalOwner && (
        <OwnerModal
          id={id}
          dataOwner={modalOwner}
          onClose={() => setModalOwner(undefined)}
          isDeleteDisabled={selectedOwners[modalOwner.id!]}
          onDelete={(deletedOwnerId) => {
            const i = owners.findIndex((r) => r.id === deletedOwnerId);
            if (i >= 0) {
              const minusOne = [...owners];
              minusOne.splice(i, 1);
              setOwners(minusOne);
            }
            setModalOwner(undefined);
          }}
          onUpdate={(updatedOwner) => {
            const i = owners.findIndex((r) => r.id === modalOwner.id);
            if (i >= 0) {
              const newOwners = [...owners];
              newOwners[i] = updatedOwner;
              setOwners(newOwners);
            }

            if (updatedOwner.is_ubo) {
              const tmpSelectedOwners = Object.assign({}, selectedOwners);
              tmpSelectedOwners[updatedOwner.id!] = true;
              setSelectedOwners(tmpSelectedOwners);
            }

            setModalOwner(undefined);
          }}
          onCreate={(createdOwner) => {
            setOwners([createdOwner].concat(owners));

            if (createdOwner.is_ubo) {
              const tmpSelectedOwners = Object.assign({}, selectedOwners);
              tmpSelectedOwners[createdOwner.id!] = true;
              setSelectedOwners(tmpSelectedOwners);
            }

            setModalOwner(undefined);
          }}
        />
      )}

      {modalRole && (
        <RoleModal
          id={id}
          dataRole={modalRole}
          onClose={() => setModalRole(undefined)}
          isDeleteDisabled={selectedRoles[modalRole.id!]}
          onDelete={(deletedRoleId) => {
            const i = roles.findIndex((r) => r.id === deletedRoleId);
            if (i >= 0) {
              const minusOne = [...roles];
              minusOne.splice(i, 1);
              setRoles(minusOne);
            }
            setModalRole(undefined);
          }}
          onUpdate={(updatedRole) => {
            const i = roles.findIndex((r) => r.id === modalRole.id);
            if (i >= 0) {
              const newRoles = [...roles];
              newRoles[i] = updatedRole;
              setRoles(newRoles);
            }
            setModalRole(undefined);
          }}
          onCreate={(createdRole) => {
            setRoles([createdRole].concat(roles));
            setModalRole(undefined);
          }}
        />
      )}
    </Page>
  );
};

export default CompanyOnboardingScreening;
