import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./utils/polyfills";

import ErrorBoundary from "./components/ErrorBoundary";
import { AuthProvider } from "./providers/AuthProvider";
import { LanguageContextProvider } from "./providers/LanguageProvider";
import { PageNavigationContextProvider } from "./providers/PageNavigation";
import fetchInterceptor from "./utils/fetchInterceptor";
import history from "./utils/history";
import api from "./api";
import App from "./App";
// Set API defaults
api.defaults.fetch = fetchInterceptor;
api.defaults.baseUrl = `${process.env.API_URL?.replace("/v2", "")}`;

// Init Sentry
// Only track and send events/reports to Sentry when running in (pre)prod, ie:
// * NODE_ENV is "production", and
// * REACT_APP_SENTRY_ENVIRONMENT is either "production" or "preprod"
const isSentryEnabled =
  process.env.NODE_ENV === "production" &&
  ["production", "preprod"].includes(process.env.REACT_APP_SENTRY_ENVIRONMENT as string);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tunnel: `${api.defaults.baseUrl}/sentry`,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // Transaction sample rate (ie. recording rate of page loads, API requests etc.)
  // Docs recommend starting with a low sampling rate, then gradually increase it,
  // or configure it with `tracesSampler`.
  // For more, see https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/
  tracesSampleRate: 0.2,
  beforeSend(event) {
    if (isSentryEnabled) {
      // Send event to Sentry if running in (pre)prod
      return event;
    } else {
      // Do not send events to Sentry for other envs. Log instead
      console.error("Captured Sentry event: ", event);
      return null;
    }
  },
  sendClientReports: isSentryEnabled,
  autoSessionTracking: isSentryEnabled,
});

const isDevelopment = process.env.NODE_ENV === "development";

if (isDevelopment) {
  import("mimic");
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !isDevelopment,
    },
  },
});

const OuterErrorBoundaryFallback = () => (
  // At this point none of the providers has been loaded
  // -> Cannot use Polaris components, translation hooks etc.
  // -> Render basic error message
  // --> Hopefully we won't get here :)
  <div style={{ padding: "2em" }}>
    <h2 style={{ fontSize: "1.5em" }}>En feil oppstod.</h2>
    <br />
    <h4 style={{ fontSize: "1em" }}>Vi er på saken!</h4>
  </div>
);

ReactDOM.render(
  <ErrorBoundary fallback={OuterErrorBoundaryFallback}>
    <AuthProvider>
      <LanguageContextProvider>
        <Router history={history}>
          <QueryClientProvider client={queryClient}>
            <PageNavigationContextProvider>
              <App />
            </PageNavigationContextProvider>
          </QueryClientProvider>
        </Router>
      </LanguageContextProvider>
    </AuthProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
