import React from "react";
import { Stack, TextStyle } from "@shopify/polaris";

import api from "../api";

import IndustryInfo from "./IndustryInfo";

interface IndustryRiskProps {
  industryRisk?: api.IndustryRiskInformation;
  title?: string;
}

const IndustryRisk: React.FC<IndustryRiskProps> = (props) => {
  const { industryRisk, title } = props;

  return (
    <Stack vertical>
      {title && <TextStyle variation="subdued">{title}</TextStyle>}
      <IndustryInfo industry={industryRisk?.industry_types?.industry_code_1} />
      <IndustryInfo industry={industryRisk?.industry_types?.industry_code_2} />
      <IndustryInfo industry={industryRisk?.industry_types?.industry_code_3} />
    </Stack>
  );
};

export default IndustryRisk;
