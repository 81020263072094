import React from "react";
import { Card, Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import CalendarIcon from "../../../assets/icons/calendar.svg";
import api from "../../api";
import useFormatMessage from "../../hooks/useFormatMessage";
import { getFullName } from "../../utils/displayUtils";
import Badges from "../Badges";
import CustomDate from "../CustomDate";
import Icon from "../extensions/Icon";
import MatchCaseStatus from "../MatchCaseStatus";
import UserInitials from "../UserInitials";

const hasBadges = (r: api.MatchReview) => r.is_pep || r.is_sanctioned || r.negative_news || r.other;

type MatchReviewCardProps = {
  review: api.MatchReview;
};

const MatchReviewCard: React.FC<MatchReviewCardProps> = ({ review }) => {
  const f = useFormatMessage();
  return (
    <Card>
      <StyledMatchCard isMatch={review.is_match}>
        <Card.Section>
          <Stack vertical>
            <Stack alignment="center">
              <Stack alignment="center" spacing="extraTight">
                <Icon source={CalendarIcon} />
                <CustomDate date={review.created_dt} />
              </Stack>

              <Stack alignment="center" spacing="extraTight">
                <UserInitials user={review.reviewed_by} />
                <TextStyle>{f("details.reviews.reviewed-by")}</TextStyle>
                <TextStyle variation="strong">{getFullName(review.reviewed_by)}</TextStyle>
              </Stack>
            </Stack>

            <Stack alignment="center" spacing="extraTight">
              <MatchCaseStatus isResolved={true} isConfirmed={review.is_match} />
              <StyledMatchCardText hasBadges={hasBadges(review) || false}>
                {review.is_match ? f("matches.review.approved") : f("matches.review.denied")}
              </StyledMatchCardText>
              <Badges
                isPep={review.is_pep}
                isSanctioned={review.is_sanctioned}
                hasNegativeNews={review.negative_news}
                isOther={review.other}
              />
            </Stack>
          </Stack>
        </Card.Section>
        <Card.Section subdued>{review.description}</Card.Section>
      </StyledMatchCard>
    </Card>
  );
};

const StyledMatchCardText = styled.div<{ hasBadges: boolean }>`
  ${({ hasBadges }) =>
    hasBadges &&
    `
    padding-right: 1.5rem;
    margin-right: 1rem;
    border-right: 1px solid #c1c1c1;
  `}
`;

const StyledMatchCard = styled.div<{ isMatch?: boolean }>`
  & .Polaris-Card__Section {
    background-color: ${({ isMatch }) => (isMatch ? "#F4FFF6" : "#FFF6F4")};
    border: 1px solid ${({ isMatch }) => (isMatch ? "#5DBD92" : "#ED6262")};
  }

  & .Polaris-Card__Section + .Polaris-Card__Section {
    border-top: none;
  }
`;

export default MatchReviewCard;
