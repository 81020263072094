import { ROUTES } from "../constants/routes";

import history from "./history";

/**
 * Wrap the default fetch API. If the response status is 401
 * or if there's an exception with a status of 401, assume that
 * the auth token has expired, as AuthProvider should handle
 * the actual user auth, and redirect to the Expired page.
 *
 * This interceptor is used as a default for the generated api
 * @see https://github.com/cellular/oazapfts#overriding-the-defaults
 *
 * Please be advised that when using fetch directly, this interceptor will not run
 *
 * @param input
 * @param init
 */
export default function fetchInterceptor(input: RequestInfo, init?: RequestInit): Promise<Response> {
  return new Promise<Response>((resolve, reject) => {
    fetch(input, init)
      .then((response) => {
        // Do not intercept auth requests (handled by AuthProvider)
        if (input.toString().includes("/auth/")) {
          return resolve(response);
        }

        // If 401, assume that the auth token has expired
        // -> Redirect to /expired
        if (response.status === 401) {
          history.push(ROUTES.EXPIRED);
          reject();
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        const status = error?.response?.status || error?.status;

        // If 401, assume that the auth token has expired
        // -> Redirect to /expired
        if (status === 401) {
          history.push(ROUTES.EXPIRED);
        }
        reject(error);
      });
  });
}
