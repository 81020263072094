import React from "react";
import { useIntl } from "react-intl";
import { Stack, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import api from "../../../api";
import ProjectStatus from "../../../components/ProjectStatus";
import useFeatures from "../../../hooks/useFeatures";
import useFormatMessage from "../../../hooks/useFormatMessage";

import ResponsibleUserSelect from "./ResponsibleUserSelect";

interface BasicProjectInfoProps {
  project: api.Project;
  readonly?: boolean;
}

const BasicProjectInfo: React.FC<BasicProjectInfoProps> = (props) => {
  const { project, readonly } = props;

  const features = useFeatures();
  const f = useFormatMessage();
  const { formatDate } = useIntl();

  return (
    <Stack vertical spacing="extraTight">
      <Stack alignment="center">
        <TextStyle variation="strong">{f("project.info.labels.status")}:</TextStyle>
        <ProjectStatus status={project.status} />
      </Stack>
      <Stack alignment="center">
        <TextStyle variation="strong">{f("project.info.labels.created")}:</TextStyle>
        <TextStyle>{formatDate(project.created_dt)}</TextStyle>
      </Stack>
      <Stack alignment={"leading"}>
        <TextStyle variation="strong">{f("project.info.labels.description")}:</TextStyle>
        <StyledDescription>{project.description}</StyledDescription>
      </Stack>
      {features.RESPONSIBLE_USER && (
        <Stack alignment="center" spacing="tight">
          <TextStyle variation="strong">{f("project.info.labels.project-manager")}:</TextStyle>
          <ResponsibleUserSelect projectId={project.id} currentUser={project.responsible} readonly={readonly} />
        </Stack>
      )}
    </Stack>
  );
};

const StyledDescription = styled.p`
  white-space: pre-wrap;
  word-break: break-all;
`;

export default BasicProjectInfo;
