import React from "react";
import { EmptyState } from "@shopify/polaris";

import useFormatMessage from "../../hooks/useFormatMessage";

const ProjectsListEmptyState: React.FC = () => {
  const f = useFormatMessage();

  return (
    <EmptyState heading={f("projects.list.empty.projects.header")} image={""}>
      <p>{f("projects.list.empty.projects.message")}</p>
    </EmptyState>
  );
};

export default ProjectsListEmptyState;
