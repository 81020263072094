import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import { ComplexAction, FormLayout, Modal, TextStyle } from "@shopify/polaris";

import api from "../../api";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";
import { Customer } from "../../types/utilities";
import { isEmptyString } from "../../utils/stringUtils";
import ErrorPanel from "../ErrorPanel";
import TextField from "../extensions/TextField";

interface ProjectInfoModalProps {
  project?: api.Project;
  customers?: Customer[];
  onClose(): void;
}

const ProjectInfoModal: React.FC<ProjectInfoModalProps> = (props) => {
  const { project, customers = [], onClose } = props;

  const [name, setName] = useState(project?.name || "");
  const [description, setDescription] = useState(project?.description || "");
  const f = useFormatMessage();
  const queryClient = useQueryClient();
  const history = useHistory();

  const isNewProject = project?.id === undefined;
  const showFooter = isNewProject && customers.length > 0;

  const customersIds = customers.map((customer) => customer.id);

  const actionLabel = isNewProject
    ? f("projects.common.labels.create.project")
    : f("projects.common.labels.update.project");

  const saveProjectMutation = useMutation(
    () =>
      isNewProject
        ? api.createProject({ name, description, customer_ids: customersIds })
        : api.updateProject(project.id, { name, description }),
    {
      onSuccess: (updatedProject) => {
        if (isNewProject) {
          history.push(generatePath(ROUTES.PROJECT_DETAILS, { id: updatedProject.id }));
        } else {
          queryClient.invalidateQueries(["project-details", project.id]);
        }
        handleClose();
      },
    }
  );

  const handleClose = () => {
    saveProjectMutation.reset();
    onClose();
  };

  const isInvalid = () => isEmptyString(name) || isEmptyString(description);

  const saveProjectAction: ComplexAction = {
    content: actionLabel,
    onAction: () => saveProjectMutation.mutate(),
    loading: saveProjectMutation.isLoading,
    disabled: saveProjectMutation.isLoading || isInvalid(),
  };

  const cancelAction: ComplexAction = {
    content: f("default.cancel"),
    onAction: () => handleClose(),
    disabled: saveProjectMutation.isLoading,
  };

  return (
    <Modal
      open
      title={actionLabel}
      onClose={handleClose}
      primaryAction={saveProjectAction}
      secondaryActions={[cancelAction]}
      footer={
        showFooter && <TextStyle>{f("common.labels.selected-customers-count", { count: customers.length })}</TextStyle>
      }
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label={f("project.details.labels.name")}
            autoComplete="off"
            requiredIndicator
            autoFocus
            disabled={saveProjectMutation.isLoading}
            value={name}
            onChange={setName}
          />
          <TextField
            label={f("project.details.labels.description")}
            autoComplete="off"
            requiredIndicator
            multiline={4}
            disabled={saveProjectMutation.isLoading}
            value={description}
            onChange={setDescription}
          />
        </FormLayout>
        {saveProjectMutation.isError && <ErrorPanel message={saveProjectMutation.error} />}
      </Modal.Section>
    </Modal>
  );
};

export default ProjectInfoModal;
