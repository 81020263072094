import React, { ReactElement, useLayoutEffect } from "react";
import { useQuery } from "react-query";
import { RouteComponentProps, useParams } from "react-router-dom";

import usePageNavigation from "../hooks/usePageNavigation";
import ErrorPage from "../pages/ErrorPage";

export interface AsyncPageChild<DATA> {
  data: DATA;
}

type AsyncProps<DATA = any> = {
  name: string;
  apiFunction: (...params: any[]) => Promise<DATA>;
  skeleton: JSX.Element;
  paramNames: string[];
  render(data: DATA, prop: object): ReactElement;
};

const AsyncPage: React.FC<AsyncProps> = ({
  name,
  apiFunction,
  skeleton = <div>Loading...</div>,
  paramNames = [],
  render,
  ...props
}) => {
  const params: Record<string, string> = useParams();
  const paramValues = paramNames.map((paramName) => params[paramName]);

  const { navigateInPage } = usePageNavigation();
  const { isLoading, isError, error, data } = useQuery(
    [name, ...paramValues],
    () => {
      const controller = new AbortController();
      const signal = controller.signal;

      const promise = apiFunction(...paramValues, { signal });

      // cancel method is being used by react-query to abort inactive queries
      // see: https://react-query.tanstack.com/guides/query-cancellation
      // @ts-ignore
      promise.cancel = () => controller.abort();

      return promise;
    },
    {
      /**
       * Default query retry function. Do not retry if 404,
       * else retry 3 times before failing
       */
      retry: (failureCount: number, error: unknown) => {
        // @ts-ignore
        if (error?.status === 404) {
          return false;
        }

        return failureCount < 3;
      },
    }
  );

  useLayoutEffect(() => {
    if (!data) {
      return;
    }

    const handler = requestAnimationFrame(() => {
      navigateInPage(location.hash ? location.hash.substring(1) : "root", { block: "start" });
    });

    return () => {
      cancelAnimationFrame(handler);
    };
  }, [data]);

  if (isLoading) {
    return skeleton;
  }

  if (isError || error) {
    return <ErrorPage error={error} />;
  }

  return render(data, props);
};

export function withAsyncPage<DATA>(
  Component: React.FC<AsyncPageChild<DATA>>,
  { ...props }: Omit<AsyncProps<DATA>, "render">
) {
  return ({ ...rest }: React.FC<RouteComponentProps>) => (
    <AsyncPage
      {...props}
      {...rest}
      render={(data, ...componentProps) => <Component data={data} {...componentProps} />}
    />
  );
}
