import {
  CREATED_AFTER_NAME_PARAMETER,
  CREATED_BEFORE_NAME_PARAMETER,
  CREATED_RANGE_NAME_PARAMETER,
  CreatedRanges,
  CUSTOMERS_PER_PAGE,
  LIMIT_NAME_PARAMETER,
  ORDER_NAME_PARAMETER,
  PAGE_NAME_PARAMETER,
  QUERY_NAME_PARAMETER,
  SORT_BY_ADDED_DATE_PARAMETER,
} from "../constants/pagination";

import useNonInitialEffect from "./useNonInitialEffect";
import { useNumericURLSearchParam, useURLSearchParam } from "./useURLSearchParam";

/**
 * hook to handle basic server side url list params such as pagination, ordering, name filter and created filter
 */
const useListParams = () => {
  const [order, setOrder] = useURLSearchParam(ORDER_NAME_PARAMETER, "-" + SORT_BY_ADDED_DATE_PARAMETER);
  const [page, setPage] = useNumericURLSearchParam(PAGE_NAME_PARAMETER);
  const [limit, setLimit] = useNumericURLSearchParam(LIMIT_NAME_PARAMETER, CUSTOMERS_PER_PAGE);
  const [q, setQ] = useURLSearchParam(QUERY_NAME_PARAMETER, "");
  const [createdRange, setCreatedRange] = useURLSearchParam<CreatedRanges>(
    CREATED_RANGE_NAME_PARAMETER,
    CreatedRanges.ALL
  );
  const [createdAfter, setCreatedAfter] = useURLSearchParam(CREATED_AFTER_NAME_PARAMETER, "all");
  const [createdBefore, setCreatedBefore] = useURLSearchParam(CREATED_BEFORE_NAME_PARAMETER, "all");

  useNonInitialEffect(() => {
    setPage(0);
  }, [order, limit, q, createdRange, createdAfter, createdBefore]);

  const getCreatedAfterValue = () => {
    const now = new Date();

    // reset time properties
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    switch (createdRange) {
      case CreatedRanges.WEEK:
        now.setDate(now.getDate() - 7);
        return now.toISOString();
      case CreatedRanges.MONTH:
        now.setMonth(now.getMonth() - 1);
        return now.toISOString();
      case CreatedRanges.YEAR:
        now.setFullYear(now.getFullYear() - 1);
        return now.toISOString();
      case CreatedRanges.CUSTOM:
      case CreatedRanges.ALL:
      default:
        return createdAfter;
    }
  };

  const searchParams = {
    ordering: order,
    limit,
    offset: page * limit,
    q,
    createdAfter: getCreatedAfterValue(),
    createdBefore,
  };

  const isEmptySearch =
    q === "" && createdRange === CreatedRanges.ALL && createdAfter === "all" && createdBefore === "all";

  const resetAllFilters = () => {
    setQ("");
    setOrder("-" + SORT_BY_ADDED_DATE_PARAMETER);
    setPage(0);
    setLimit(CUSTOMERS_PER_PAGE);
    setCreatedRange(CreatedRanges.ALL);
    setCreatedAfter("all");
    setCreatedBefore("all");
  };

  const handleSetCreatedRange = (range: CreatedRanges) => {
    if (range !== CreatedRanges.CUSTOM) {
      setCreatedAfter("all");
      setCreatedBefore("all");
    }
    setCreatedRange(range);
  };

  return {
    order,
    setOrder,
    page,
    setPage,
    limit,
    setLimit,
    q,
    setQ,
    createdRange,
    setCreatedRange: handleSetCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    searchParams,
    isEmptySearch,
    resetAllFilters,
  };
};

export default useListParams;
