import * as React from "react";
import { FormattedDate } from "react-intl";

type CustomDateProps = {
  date?: string;
};

const CustomDate: React.FC<CustomDateProps> = ({ date }) =>
  date ? <FormattedDate value={date} year="2-digit" month="2-digit" day="2-digit" /> : <>{"-"}</>;

export default CustomDate;
