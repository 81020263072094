import React from "react";
import { Navigation } from "@shopify/polaris";
import { ItemProps } from "@shopify/polaris/build/ts/latest/src/components/Navigation/components";
import styled from "styled-components";

import { PAGE_ANCHORS } from "../../constants/page-anchors";
import { ROUTES } from "../../constants/routes";
import usePageNavigation from "../../hooks/usePageNavigation";
import { NavigationItem } from "../../providers/PageNavigation";

const isActive = (anchor: string) =>
  location.hash === `#${anchor}` || (anchor === PAGE_ANCHORS.TOP_SECTION && location.hash === "");

const PageNavigationMenu = () => {
  const { showNavigationMenu, navigationItems, navigateInPage } = usePageNavigation();

  if (!showNavigationMenu) {
    return null;
  }

  const createItemProp = ({ anchor, label, options: { count, isNew } = {} }: NavigationItem): ItemProps => ({
    url: "",
    onClick: () => navigateInPage(anchor),
    label,
    selected: isActive(anchor),
    badge: count && count > 0 ? `${count}` : undefined,
    // in order to show new indicator in Polaris, we need to set a sub navigation item with a "new" property set to true
    subNavigationItems: isNew
      ? [
          {
            url: "",
            label: "",
            new: true,
          },
        ]
      : undefined,
  });

  const items = navigationItems.map(createItemProp);

  return (
    <Navigation location={ROUTES.HOME}>
      <StyledWrapper>
        <Navigation.Section items={items} />
      </StyledWrapper>
    </Navigation>
  );
};

const StyledWrapper = styled.span`
    & .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded {
      display: none;
    },
`;

export default PageNavigationMenu;
