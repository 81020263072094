import React from "react";
import { Badge as PolarisBadge, BadgeProps as PolarisBadgeProps } from "@shopify/polaris";
import styled from "styled-components";

// get Polaris badge size type definition
type PolarisBadgeSize = PolarisBadgeProps["size"];

// extend Polaris badge size type definition with additional "xsmall" value
type BadgeSize = PolarisBadgeSize | "xsmall";

// extend Polaris badge properties without original "size" property
interface BadgeProps extends Omit<PolarisBadgeProps, "size" | "children"> {
  // replace the omitted size property with the extended size type
  size?: BadgeSize;
}

const Badge: React.FC<BadgeProps> = ({ size, children, ...props }) => {
  const isExtraSmall = size === "xsmall";

  // for "xsmall" size = pass "small" size value to Polaris Badge to avoid typescript conflicts
  // but style the badge to look extra small
  const badgeSize = isExtraSmall ? "small" : size;

  return (
    <StyledBadge isExtraSmall={isExtraSmall}>
      <PolarisBadge size={badgeSize} {...props}>
        {children as string}
      </PolarisBadge>
    </StyledBadge>
  );
};

const StyledBadge = styled.span<{ isExtraSmall: boolean }>`
  ${(props) =>
    props.isExtraSmall &&
    `& .Polaris-Badge {
    font-size: 1rem;
    font-weight: 600;
    line-height: 0.9rem;
    padding: 0.3rem 0.6rem;
  }`}
`;

export default Badge;
