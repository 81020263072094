import React, { useCallback, useState } from "react";
import { TextField as PolarisTextField, TextFieldProps as PolarisTextFieldProps } from "@shopify/polaris";

import { ERROR_DEBOUNCE_DELAY_IN_MILLISECONDS } from "../../constants/attachment-signature-statuses";
import useDebounceValue from "../../hooks/useDebouncedValue";

type TextFieldProps = PolarisTextFieldProps & {
  debounceError?: boolean;
  hideErrorIfUntouched?: boolean;
};

const TextField: React.FC<TextFieldProps> = (props) => {
  const {
    debounceError,
    error,
    hideErrorIfUntouched,
    onBlur,
    onChange,
    onClearButtonClick,
    ...polarisTextFieldProps
  } = props;

  // if there's no error - don't wait to remove the error
  const debouncedError = useDebounceValue(error, error ? ERROR_DEBOUNCE_DELAY_IN_MILLISECONDS : 0);

  const [touched, setTouched] = useState(false);

  const errorToDisplay = hideErrorIfUntouched && !touched ? undefined : debounceError ? debouncedError : error;

  const handleOnBlur = useCallback(() => {
    setTouched(true);

    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const handleOnChange = useCallback(
    (value: string, id: string) => {
      setTouched(true);

      if (onChange) {
        onChange(value, id);
      }
    },
    [onChange]
  );

  const handleOnClearButtonClick = useCallback(
    (id: string) => {
      setTouched(true);

      if (onClearButtonClick) {
        onClearButtonClick(id);
      }
    },
    [onClearButtonClick]
  );

  return (
    <PolarisTextField
      error={errorToDisplay}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      onClearButtonClick={handleOnClearButtonClick}
      {...polarisTextFieldProps}
    />
  );
};

export default TextField;
