import React from "react";
import { Stack, Tooltip } from "@shopify/polaris";

import api from "../../api";
import useFeatures from "../../hooks/useFeatures";

import Label from "./Label";

interface DisplayLabelsWrapperProps {
  labels?: api.Label[];
  cutoff?: number;
}

const DisplayLabelsWrapper: React.FC<DisplayLabelsWrapperProps> = (props) => {
  const { labels = [], cutoff = 2, children } = props;

  const features = useFeatures();

  if (labels.length === 0 || !features.PROJECT_LABELS) {
    return <>{children}</>;
  }

  const labelsToRender = labels.slice(0, cutoff);

  const otherLabelsCount = labels.length - cutoff;

  return (
    <Stack spacing="extraTight" alignment="center">
      <Stack.Item fill>{children}</Stack.Item>
      {labelsToRender.map((label) => (
        <Label key={label.id}>{label.title}</Label>
      ))}
      {otherLabelsCount > 0 && (
        <Tooltip
          preferredPosition="above"
          content={
            <Stack wrap={false} spacing="tight">
              {labels.slice(cutoff).map((label) => (
                <Label key={label.id}>{label.title}</Label>
              ))}
            </Stack>
          }
        >
          <Label>{otherLabelsCount}+</Label>
        </Tooltip>
      )}
    </Stack>
  );
};

export default DisplayLabelsWrapper;
