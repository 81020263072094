import { useBetween } from "use-between";

import api from "../../api";
import useListParams from "../../hooks/useListParams";
import useNonInitialEffect from "../../hooks/useNonInitialEffect";
import { useURLSearchParam } from "../../hooks/useURLSearchParam";

type ProjectsListParams = Parameters<typeof api.getProjects>[0];

const useProjectListParams = () => {
  const {
    order,
    setOrder,
    page,
    setPage,
    limit,
    setLimit,
    q,
    setQ,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    searchParams: baseSearchParams,
    isEmptySearch: baseIsEmptySearch,
    resetAllFilters: baseResetAllFilters,
  } = useListParams();

  const [status, setStatus] = useURLSearchParam("status", "all");
  const [responsibleUserId, setResponsibleUserId] = useURLSearchParam("responsibleUserId", "all");

  useNonInitialEffect(() => {
    setPage(0);
  }, [status, responsibleUserId]);

  const searchParams: ProjectsListParams = {
    ...baseSearchParams,
    status: status as api.ProjectStatus,
  };

  if (responsibleUserId !== "all") {
    searchParams.responsibleId = responsibleUserId;
  }

  const isEmptySearch = baseIsEmptySearch && status === "all" && responsibleUserId === "all";

  const resetAllFilters = () => {
    baseResetAllFilters();
    setStatus("all");
    setResponsibleUserId("all");
  };

  return {
    order,
    setOrder,
    page,
    setPage,
    limit,
    setLimit,
    q,
    setQ,
    status,
    setStatus,
    responsibleUserId,
    setResponsibleUserId,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    searchParams,
    isEmptySearch,
    resetAllFilters,
  };
};

// in order to share the state of the filters between CustomersListPage, CustomersListTable and CustomersListFilters
// wrap the hook using useBetween - which creates a single instance of the hook and returns it
const useSharedProjectsListParams = () => useBetween(useProjectListParams);

export default useSharedProjectsListParams;
