import { useContext } from "react";

import { Features } from "../constants/Features";
import { AuthContext } from "../providers/AuthProvider";

const useFeatures: () => Partial<Features> = () => {
  const { user } = useContext(AuthContext);

  return user?.features || {};
};

export default useFeatures;
