import React from "react";
import { generatePath } from "react-router-dom";
import { ActionListItemDescriptor } from "@shopify/polaris";

import CompanyIcon from "../../../assets/icons/company.svg";
import PersonIcon from "../../../assets/icons/person.svg";
import PlusMajorIcon from "../../../assets/icons/plus-major-white.svg";
import ActionsDropdown from "../../components/ActionsDropdown";
import { ROUTES } from "../../constants/routes";
import useFormatMessage from "../../hooks/useFormatMessage";

interface RegisterCustomerDropdownMenuProps {
  readonly?: boolean;
}

const RegisterCustomerDropdownMenu: React.FC<RegisterCustomerDropdownMenuProps> = (props) => {
  const { readonly } = props;

  const f = useFormatMessage();

  const personOnboardingPath = generatePath(ROUTES.PERSON_ONBOARDING_NEW);
  const companyOnboardingPath = generatePath(ROUTES.COMPANY_LOOKUP);

  const items: ActionListItemDescriptor[] = [
    {
      content: f("nav-bar.new-customer.person"),
      url: personOnboardingPath,
      disabled: readonly,
      image: PersonIcon,
    },
    {
      content: f("nav-bar.new-customer.company"),
      url: companyOnboardingPath,
      disabled: readonly,
      image: CompanyIcon,
    },
  ];

  return (
    <ActionsDropdown
      items={items}
      icon={PlusMajorIcon}
      title={f("nav-bar.new-customer")}
      disabled={readonly}
      primary
      size="medium"
    />
  );
};

export default RegisterCustomerDropdownMenu;
