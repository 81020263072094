import React from "react";
import { useQueryClient } from "react-query";

import api from "../../../api";
import ConfirmAction from "../../../components/modals/ConfirmAction";
import useFormatMessage from "../../../hooks/useFormatMessage";

interface ProjectEndConfirmationProps {
  project: api.Project;
  onClose(): void;
}

const ProjectEndConfirmation: React.FC<ProjectEndConfirmationProps> = (props) => {
  const { project, onClose } = props;

  const queryClient = useQueryClient();
  const f = useFormatMessage();

  return (
    <ConfirmAction
      apiCall={() => api.updateProject(project.id, { status: "INACTIVE" })}
      onSuccess={(updatedProject) => queryClient.setQueryData(["project-details", project.id], updatedProject)}
      title={f("project.end.confirmation.title")}
      description={f("project.end.confirmation.description", { name: project.name })}
      actionTitle={f("project.end.confirmation.action.label", { name: project.name })}
      onNo={onClose}
    />
  );
};

export default ProjectEndConfirmation;
