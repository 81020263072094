import { useIntl } from "react-intl";
import { AppliedFilterInterface } from "@shopify/polaris";

import { CreatedRanges } from "../constants/pagination";

import useFormatMessage from "./useFormatMessage";

interface useCreatedDataAppliedFiltersProps {
  createdRange: CreatedRanges;
  createdBefore: string;
  createdAfter: string;
  setCreatedRange(createdRange: string): void;
  setCreatedAfter(createdAfter: string): void;
  setCreatedBefore(createdBefore: string): void;
}

const useCreatedDataAppliedFilters = (props: useCreatedDataAppliedFiltersProps) => {
  const { createdRange, createdBefore, createdAfter, setCreatedRange, setCreatedAfter, setCreatedBefore } = props;

  const f = useFormatMessage();
  const { formatDate } = useIntl();

  const appliedCreatedDateFilters: AppliedFilterInterface[] = [];

  if (createdRange !== CreatedRanges.CUSTOM && createdRange !== CreatedRanges.ALL) {
    appliedCreatedDateFilters.push({
      key: "created",
      label: f(`component.filters.created.applied.range.${createdRange}`),
      onRemove: () => setCreatedRange(CreatedRanges.ALL),
    });
  }

  if (createdAfter !== "all" && createdRange === CreatedRanges.CUSTOM) {
    appliedCreatedDateFilters.push({
      key: "created-after",
      label: f("component.filters.created.labels.after", { date: formatDate(createdAfter) }),
      onRemove: () => setCreatedAfter("all"),
    });
  }

  if (createdBefore !== "all" && createdRange === CreatedRanges.CUSTOM) {
    appliedCreatedDateFilters.push({
      key: "created-before",
      label: f("component.filters.created.labels.before", { date: formatDate(createdBefore) }),
      onRemove: () => setCreatedBefore("all"),
    });
  }

  return appliedCreatedDateFilters;
};

export default useCreatedDataAppliedFilters;
