import React, { createContext, useEffect, useRef, useState } from "react";
import { IntlProvider } from "react-intl";

import * as sourceOfTruth from "../../assets/i18n/no.json";
import { LanguageStorage, LocalLanguageStorage } from "../utils/languageStorage";
import { noop } from "../utils/util";

export type LocaleMessages = typeof sourceOfTruth;
export type MessageKey = keyof LocaleMessages;

const messagesLoader: Record<string, () => Promise<LocaleMessages>> = {
  nb: () => import("../../assets/i18n/no.json"),
  en: () => import("../../assets/i18n/en.json"),
};

export const LanguageContext = createContext<[string, (language: string) => void]>([
  LocalLanguageStorage.DEFAULT_LANGUAGE,
  noop,
]);

export const LanguageContextProvider: React.FC = ({ children }) => {
  const localLangStorageRef = useRef<LanguageStorage>(new LocalLanguageStorage());
  const [language, setLanguage] = useState<string>(LocalLanguageStorage.DEFAULT_LANGUAGE);
  const [messages, setMessages] = useState<Record<string, Record<string, string>>>({});

  useEffect(() => {
    const saveLanguage = localLangStorageRef.current.getLanguage();
    handleSetLanguage(saveLanguage);
  }, []);

  const handleSetLanguage = async (lang: string) => {
    localLangStorageRef.current.saveLanguage(lang);

    if (!messages[lang]) {
      const messages = await messagesLoader[lang]();
      setMessages((prevState) => ({ ...prevState, [lang]: messages }));
    }

    setLanguage(() => lang);
  };

  return (
    <LanguageContext.Provider value={[language, handleSetLanguage]}>
      <IntlProvider locale={language} messages={messages[language]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
