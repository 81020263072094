import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, matchPath, useHistory } from "react-router-dom";
import { ActionList, Link, Popover, Stack, TopBar } from "@shopify/polaris";
import styled from "styled-components";

import LogoutIcon from "../../../assets/icons/arrow-right-offset-circle.svg";
import CompanyIcon from "../../../assets/icons/company.svg";
import GlobeIcon from "../../../assets/icons/globe.svg";
import PersonIcon from "../../../assets/icons/person.svg";
import LabelIcon from "../../../assets/icons/tag.svg";
import Logo from "../../../assets/images/kdk-logo.svg";
import { FeaturesTypes } from "../../constants/Features";
import { ROUTES } from "../../constants/routes";
import useAuth from "../../hooks/useAuth";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import useOpenClose from "../../hooks/useOpenClose";
import usePermissions from "../../hooks/usePermissions";
import { LanguageContext } from "../../providers/LanguageProvider";
import { getFullName } from "../../utils/displayUtils";
import { isEmptyString } from "../../utils/stringUtils";

const MainToolbar = () => {
  const isAllowed = usePermissions();
  const [isUserMenuOpen, toggleUserMenu] = useOpenClose();
  const [popoverActive, togglePopover, closePopover] = useOpenClose();

  const { logout, user } = useAuth();

  const history = useHistory();
  const f = useFormatMessage();
  const features = useFeatures();
  const path = history.location.pathname;

  const isOverviewActive =
    isEmptyString(path) || matchPath(path, { path: [ROUTES.HOME, ROUTES.CUSTOMERS], exact: true }) !== null;

  const isProjectListActive = !!matchPath(path, ROUTES.PROJECTS_LIST);

  const isOnboardingActive = matchPath(path, { path: [ROUTES.COMPANY_LOOKUP, ROUTES.PERSON_ONBOARDING_NEW] }) !== null;

  const [language, setLanguage] = useContext(LanguageContext);

  // this variable should be of type IconableAction[] but that type doesn't support the image property
  // so as along as TopBar.UserMenu doesn't force using IconableAction[], we don't specify the type here
  const userMenuItems = [];

  if (isAllowed(FeaturesTypes.USER_MANAGEMENT)) {
    userMenuItems.push({
      content: f("menu.users"),
      image: PersonIcon,
      url: ROUTES.ADMIN_USERS,
    });
  }

  // We enable this feature on staging/dev for now
  // Until we get all stuff translated to english
  if (process.env.NINJA_DEV == "true") {
    userMenuItems.push({
      content: language === "en" ? f("menu.language.english") : f("menu.language.norwegian"),
      image: GlobeIcon,
      onAction: () => {
        setLanguage(language === "en" ? "nb" : "en");
      },
    });
  }

  if (isAllowed(FeaturesTypes.PROJECT_LABELS)) {
    userMenuItems.push({
      content: f("menu.labels"),
      image: LabelIcon,
      url: ROUTES.ADMIN_LABELS,
    });
  }

  userMenuItems.push({
    content: f("menu.logout"),
    image: LogoutIcon,
    onAction: () => logout({ returnTo: window.location.origin }),
  });

  const OnBoardingLink = (
    <StyledMainToolbarLink onClick={togglePopover} isActive={isOnboardingActive}>
      <svg width="30" height="30" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7 6C11.7 7.49117 10.4912 8.7 9 8.7C7.50883 8.7 6.3 7.49117 6.3 6C6.3 4.50883 7.50883 3.3 9 3.3C10.4912 3.3 11.7 4.50883 11.7 6ZM13 6C13 8.20914 11.2091 10 9 10C6.79086 10 5 8.20914 5 6C5 3.79086 6.79086 2 9 2C11.2091 2 13 3.79086 13 6ZM4.52336 11.6647C4.60775 11.5983 4.72559 11.6009 4.81351 11.6625C5.99852 12.4932 7.44306 12.9817 9 12.9817C10.3526 12.9817 11.6204 12.613 12.7069 11.9715C11.667 12.7958 11 14.07 11 15.5C11 15.8018 11.0297 16.0966 11.0863 16.3817H3.55C3.41193 16.3817 3.3 16.2698 3.3 16.1317V14.1817C3.3 13.1616 3.77755 12.2513 4.52336 11.6647ZM11.5633 17.6817H2.5C2.22386 17.6817 2 17.4578 2 17.1817V14.1817C2 12.2848 3.17365 10.6621 4.83439 10C5.91355 11.0412 7.38198 11.6817 9 11.6817C10.618 11.6817 12.0864 11.0412 13.1656 10C13.7664 10.2395 14.3034 10.6047 14.7442 11.0632C14.99 11.0216 15.2424 11 15.5 11C17.9853 11 20 13.0147 20 15.5C20 17.9853 17.9853 20 15.5 20C13.8063 20 12.3312 19.0643 11.5633 17.6817ZM16.1504 13.65C16.1504 13.291 15.8594 13 15.5004 13C15.1414 13 14.8504 13.291 14.8504 13.65V14.8523H13.65C13.291 14.8523 13 15.1434 13 15.5023C13 15.8613 13.291 16.1523 13.65 16.1523H14.8504V17.35C14.8504 17.709 15.1414 18 15.5004 18C15.8594 18 16.1504 17.709 16.1504 17.35V16.1523H17.35C17.709 16.1523 18 15.8613 18 15.5023C18 15.1434 17.709 14.8523 17.35 14.8523H16.1504V13.65Z"
          fill={isOnboardingActive ? "white" : "#212b36"}
        />
      </svg>
      <div>{f("nav-bar.new-customer")}</div>
    </StyledMainToolbarLink>
  );

  return (
    <Stack alignment="fill">
      <Stack.Item>
        <Link url={ROUTES.HOME}>
          <StyledLogo src={Logo} />
        </Link>
      </Stack.Item>
      <Stack.Item fill>
        <StyledMainButtons>
          <Stack spacing="extraLoose">
            <StyledMainToolbarLink isActive={isOverviewActive} onClick={() => history.push(ROUTES.HOME)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.45 3.3C11.5881 3.3 11.7 3.41193 11.7 3.55V6.5V16.45L11.7 16.4507C11.6996 16.5884 11.5878 16.7 11.45 16.7H3.55C3.41193 16.7 3.3 16.5881 3.3 16.45L3.3 3.55C3.3 3.41193 3.41193 3.3 3.55 3.3H11.45ZM12.2 18H2.5C2.22386 18 2 17.7761 2 17.5V2.5C2 2.22386 2.22386 2 2.5 2H12.5C12.7761 2 13 2.22386 13 2.5V6L17.5 6C17.7761 6 18 6.22386 18 6.5V17.5C18 17.7761 17.7761 18 17.5 18H12.5H12.2ZM13 16.7H16.45C16.5881 16.7 16.7 16.5881 16.7 16.45V7.55C16.7 7.41193 16.5881 7.3 16.45 7.3H13V16.7ZM14.1996 9H15.4996V10.3H14.1996V9ZM15.4996 13H14.1996V14.3H15.4996V13ZM4.85037 5H6.15037V6.3H4.85037V5ZM10.1504 5H8.85037V6.3H10.1504V5ZM4.85037 9H6.15037V10.3H4.85037V9ZM10.1504 9H8.85037V10.3H10.1504V9ZM4.85037 13H6.15037V14.3H4.85037V13ZM10.1504 13H8.85037V14.3H10.1504V13Z"
                  fill={isOverviewActive ? "white" : "#212b36"}
                />
              </svg>
              <div>
                <FormattedMessage id="nav-bar.overview" />
              </div>
            </StyledMainToolbarLink>
            {features.PROJECTS && (
              <StyledMainToolbarLink isActive={isProjectListActive} onClick={() => history.push(ROUTES.PROJECTS_LIST)}>
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0.5C0 0.223858 0.223858 0 0.5 0H6.5H7L9 2L15.5 2C15.7761 2 16 2.22386 16 2.5V11.5C16 11.7761 15.7761 12 15.5 12L0.5 12C0.223858 12 0 11.7761 0 11.5V2.5V0.5ZM14.7 10.7L14.7 3.3L1.3 3.3L1.3 10.7L14.7 10.7Z"
                    fill={isProjectListActive ? "white" : "#212b36"}
                  />
                </svg>
                <div>{f("projects.page.title")}</div>
              </StyledMainToolbarLink>
            )}
            {!features.PROJECTS && (
              <StyledMainToolbarLink>
                <Popover active={popoverActive} activator={OnBoardingLink} onClose={closePopover}>
                  <ActionList
                    items={[
                      {
                        content: f("nav-bar.new-customer.person"),
                        url: generatePath(ROUTES.PERSON_ONBOARDING_NEW),
                        image: PersonIcon,
                      },
                      {
                        content: f("nav-bar.new-customer.company"),
                        url: generatePath(ROUTES.COMPANY_LOOKUP),
                        image: CompanyIcon,
                        onAction: closePopover,
                      },
                    ]}
                  />
                </Popover>
              </StyledMainToolbarLink>
            )}
          </Stack>
        </StyledMainButtons>
      </Stack.Item>
      <Stack.Item>
        <Stack distribution="trailing" alignment="center" wrap={false}>
          <TopBar.UserMenu
            actions={[
              {
                items: userMenuItems,
              },
            ]}
            name={getFullName(user)}
            open={isUserMenuOpen}
            onToggle={toggleUserMenu}
            initials=""
          />
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

const StyledMainToolbarLink = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  height: var(--toolbar-height);
  align-items: center;
  justify-content: center;
  padding: 0 1.6rem;
  gap: 0.5rem;

  color: ${(props) => (props.isActive ? "white" : "inherit")};
  background-color: ${(props) => (props.isActive ? "var(--red)" : "inherit")};
`;

const StyledLogo = styled.img`
  height: var(--toolbar-height);
  padding: 1rem;
`;

const StyledMainButtons = styled.div`
  margin: 0 auto;
  padding-left: 15.4rem;
`;

export default MainToolbar;
