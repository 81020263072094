import React from "react";
import { AppliedFilterInterface, FilterInterface, Filters } from "@shopify/polaris";

import CreatedDateFilter from "../../components/CreatedDateFilter";
import useCreatedDataAppliedFilters from "../../hooks/useCreatedDataAppliedFilters";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import { createFilter } from "../../utils/filtersUtils";
import useFiltersOptions from "../customers/useFiltersOptions";

import useProjectListParams from "./useProjectListParams";

interface ProjectsListFiltersProps {
  isLoading: boolean;
}

const ProjectsListFilters: React.FC<ProjectsListFiltersProps> = (props) => {
  const { isLoading } = props;

  const features = useFeatures();
  const f = useFormatMessage();
  const filterOptions = useFiltersOptions();
  const {
    q,
    setQ,
    status,
    setStatus,
    responsibleUserId,
    setResponsibleUserId,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    resetAllFilters,
  } = useProjectListParams();

  const appliedCreatedDateFilters = useCreatedDataAppliedFilters({
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
  });

  const [statusFilter, statusAppliedFilter] = createFilter(
    "status",
    f("component.filters.status.title"),
    status || "",
    isLoading,
    filterOptions.projectStatus,
    setStatus
  );

  const createdDateFilter: FilterInterface = {
    key: "created",
    label: f("component.filters.created.title"),
    shortcut: true,
    disabled: isLoading,
    hideClearButton: true,
    filter: (
      <CreatedDateFilter
        isLoading={isLoading}
        createdRange={createdRange}
        createdAfter={createdAfter}
        createdBefore={createdBefore}
        setCreatedRange={setCreatedRange}
        setCreatedAfter={setCreatedAfter}
        setCreatedBefore={setCreatedBefore}
      />
    ),
  };

  const filters: FilterInterface[] = [statusFilter, createdDateFilter];
  const appliedFilters = [statusAppliedFilter].filter(Boolean) as AppliedFilterInterface[];

  appliedFilters.push(...appliedCreatedDateFilters);

  if (features.RESPONSIBLE_USER) {
    const [responsibleUserFilter, responsibleUserAppliedFilter] = createFilter(
      "responsible-user",
      f("responsible.label"),
      responsibleUserId,
      isLoading,
      filterOptions.responsibleUser,
      setResponsibleUserId
    );

    filters.push(responsibleUserFilter);

    if (responsibleUserAppliedFilter) {
      appliedFilters.push(responsibleUserAppliedFilter);
    }
  }

  return (
    <Filters
      queryValue={q}
      queryPlaceholder={f("projects.list.filters.search.hint")}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={setQ}
      onQueryClear={() => setQ("")}
      onClearAll={resetAllFilters}
    />
  );
};

export default ProjectsListFilters;
