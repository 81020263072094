import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { PAGE_ANCHORS } from "../../constants/page-anchors";
import usePageNavigation from "../../hooks/usePageNavigation";

interface NavigationSectionProps {
  anchor: string;
}

const NavigationSection: React.FC<NavigationSectionProps> = (props) => {
  const { anchor, children } = props;

  const { isNavigationScrolling } = usePageNavigation();
  const location = useLocation();
  const history = useHistory();

  const { ref, inView } = useInView({
    threshold: 1,
    skip: isNavigationScrolling,
    initialInView: isNavigationScrolling,
    rootMargin: "-5% 0% -90% 0%",
  });

  useEffect(() => {
    if (inView) {
      location.hash = anchor === PAGE_ANCHORS.TOP_SECTION ? "" : `#${anchor}`;
      history.replace(location);
    }
  }, [inView]);

  return (
    <StyledDiv id={anchor} ref={ref}>
      {children}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  height: var(--toolbar-height);
`;

export default NavigationSection;
