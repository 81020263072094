import React from "react";

import { PAGE_ANCHORS } from "../../constants/page-anchors";
import useFormatMessage from "../../hooks/useFormatMessage";
import usePageNavigation from "../../hooks/usePageNavigation";

import ValueWithChanges, { ValueWithChangesProps } from "./ValueWithChanges";

function InfoValue<T = string>({
  tooltipContent,
  tooltipNavigationLabel,
  onTooltipNavigation,
  ...props
}: ValueWithChangesProps<T>) {
  const { navigateInPage } = usePageNavigation();
  const f = useFormatMessage();

  const scrollToReviews = () => navigateInPage(PAGE_ANCHORS.REVIEWS_SECTION);

  return (
    <ValueWithChanges
      tooltipContent={tooltipContent || f("company.details.changes.tooltip.banner")}
      tooltipNavigationLabel={tooltipNavigationLabel || f("company.details.changes.tooltip.button.label")}
      onTooltipNavigation={onTooltipNavigation || scrollToReviews}
      {...props}
    />
  );
}

export default InfoValue;
