import { useMemo } from "react";

import api from "../../api";
import { groupMatchAttributesByCode } from "../../utils/matchUtils";

const useMatchPage = (data: api.MatchCaseDetailResponse) => {
  const match = data.case.match;
  const isPerson = match.subject_type !== "company";
  const status = data.case.status;
  const reviews = data.case.reviews;

  // isUpdated is true if the match has been reviewed, but has been
  // updated since the last review, ie. it needs a new review
  const isUpdated = reviews.length > 0 && status !== "RESOLVED";

  const categories = [
    ...new Set(match.events.map((event) => `${event.category.description} [${event.sub_category.description}]`)),
  ].sort();

  // used to hint TypeScript which type to check
  const isSubjectPerson = (subject: api.PersonDetail | api.CompanyDetail): subject is api.PersonDetail => isPerson;

  const { gender, riskography, links, remarks, pepTypes } = useMemo(() => groupMatchAttributesByCode(match), [match]);

  const pepTypesLabels = useMemo(() => pepTypes.map((pty) => pty.detail || pty.value || pty.description).sort(), [
    pepTypes,
  ]);
  const isPepTypesReviewed = useMemo(() => pepTypes.every((pty) => pty.is_reviewed), [pepTypes]);

  // don't copy description from last review
  const lastReview = data.case.reviews.length > 0 ? { ...data.case.reviews[0], description: "" } : undefined;

  const showSummary = riskography.length > 0;
  const showComments = remarks.length > 0;
  const showRelations = match.relations.length > 0;
  const showSources = match.sources.length > 0;
  const showLinks = links.length > 0;
  const showReviews = reviews.length > 0;

  return {
    match,
    isPerson,
    reviews,
    isUpdated,
    categories,
    isSubjectPerson,
    gender,
    riskography,
    links,
    remarks,
    pepTypesLabels,
    isPepTypesReviewed,
    lastReview,
    showSummary,
    showComments,
    showRelations,
    showSources,
    showLinks,
    showReviews,
  };
};

export default useMatchPage;
