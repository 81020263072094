import React from "react";
import { Stack, TextStyle } from "@shopify/polaris";

import api from "../api";
import useFeatures from "../hooks/useFeatures";
import useFormatMessage from "../hooks/useFormatMessage";

import ScreeningStatusBadge from "./screening/ScreeningStatusBadge";
import CaseStatus from "./CaseStatus";
import CustomerResponsibleUserSelect from "./CustomerResponsibleUserSelect";
import Risk from "./Risk";

type BasicCustomerInfoProp = {
  customerId: string;
  risk?: api.RiskLevel;
  onboardingStatus: api.OnboardingStatus;
  reviewStatus: api.ReviewStatus;
  screening: api.CustomerScreeningStatus;
  responsibleUser?: api.User;
  readonly?: boolean;
};

const BasicCustomerInfo: React.FC<BasicCustomerInfoProp> = ({
  customerId,
  risk,
  onboardingStatus,
  reviewStatus,
  screening,
  responsibleUser,
  readonly,
}) => {
  const features = useFeatures();
  const f = useFormatMessage();

  return (
    <Stack vertical spacing="extraTight">
      <Stack alignment="center">
        <TextStyle variation="strong">{f("details.basic-info.customer-risk")}:</TextStyle>
        <Risk risk={risk} showHyphenIfNotSet={!risk} />
      </Stack>
      <Stack alignment="center">
        <TextStyle variation="strong">{f("details.basic-info.status")}:</TextStyle>
        <CaseStatus onboardingStatus={onboardingStatus} reviewStatus={reviewStatus} />
      </Stack>
      <Stack alignment="center">
        <TextStyle variation="strong">{f("details.basic-info.screening-type")}:</TextStyle>
        <Stack alignment="center" spacing="extraTight">
          <TextStyle>{f(`screening-type.${screening.screening_type || "NOT_SET"}`)}</TextStyle>
          {features?.UPDATE_SCREENING_CONFIGURATION && <ScreeningStatusBadge screening={screening} />}
        </Stack>
      </Stack>
      {features.RESPONSIBLE_USER && (
        <Stack alignment="center" spacing="tight">
          <TextStyle variation="strong">{`${f("responsible.label")}:`}</TextStyle>
          <CustomerResponsibleUserSelect customerId={customerId} currentUser={responsibleUser} readonly={readonly} />
        </Stack>
      )}
    </Stack>
  );
};

export default BasicCustomerInfo;
