import React from "react";
import { Stack, TextStyle } from "@shopify/polaris";

import IconWaiting from "../../assets/icons/inactive-icon.svg";
import IconApproved from "../../assets/icons/status-approved.svg";
import api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";
import { MessageKey } from "../providers/LanguageProvider";

import Icon from "./extensions/Icon";

const PROJECT_STATUS_TRANSLATION_MAP: Record<api.ProjectStatus, MessageKey> = {
  ACTIVE: "project.status.ACTIVE",
  INACTIVE: "project.status.INACTIVE",
};

interface ProjectStatusProps {
  status: api.ProjectStatus;
}

const ProjectStatus: React.FC<ProjectStatusProps> = (props) => {
  const { status } = props;

  const f = useFormatMessage();
  const icon = status === "ACTIVE" ? IconApproved : IconWaiting;

  return (
    <Stack alignment="center" spacing="extraTight">
      <Icon source={icon} width="8px" height="8px" />
      <TextStyle>{f(PROJECT_STATUS_TRANSLATION_MAP[status])}</TextStyle>
    </Stack>
  );
};

export default ProjectStatus;
