import download from "../utils/download";

export default function useDownload(): (url: string, filename?: string) => Promise<unknown> {
  const performDownload = (url: string, filename?: string) => {
    const download_url = `${process.env.API_URL}${url}`;

    return download(download_url, filename);
  };

  return performDownload;
}
