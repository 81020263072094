import { useContext } from "react";

import { AuthContext } from "../providers/AuthProvider";

/**
 * Use the `useAuth` hook in your components to access the auth state and methods.
 */
const useAuth = () => useContext(AuthContext);

export default useAuth;
