import * as React from "react";
import { Stack, TextStyle } from "@shopify/polaris";

import InfoIcon from "../../assets/icons/info-blue.svg";
import * as api from "../api";
import useFormatMessage from "../hooks/useFormatMessage";

import Icon from "./extensions/Icon";
import ServerAddressInfo from "./ServerAddressInfo";

type SigningUserInfoProps = {
  signingUserInfo: api.SigningUserInfo;
};

const SigningUserInfo: React.FC<SigningUserInfoProps> = ({ signingUserInfo }) => {
  const f = useFormatMessage();
  return (
    <Stack vertical={false} wrap={false} spacing="tight" alignment="center">
      <Icon source={InfoIcon} />

      <TextStyle variation="strong">{f("signing-user-info.name")}:</TextStyle>
      <span>{signingUserInfo.name}</span>

      <TextStyle variation="strong">{f("signing-user-info.birth-date")}:</TextStyle>
      <span>{signingUserInfo.birth_date}</span>

      <TextStyle variation="strong">{f("signing-user-info.social-no")}:</TextStyle>
      <span>{signingUserInfo.social_no}</span>

      <TextStyle variation="strong">{f("signing-user-info.address")}:</TextStyle>
      <ServerAddressInfo address={signingUserInfo.address} />
    </Stack>
  );
};

export default SigningUserInfo;
