import React, { useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { Button, Card, Page, ProgressBar, Stack, TextField } from "@shopify/polaris";
import { validateNorwegianIdNumber } from "norwegian-national-id-validator";

import api from "../../api";
import AddressForm from "../../components/AddressForm";
import AssociatedProjectSection from "../../components/AssociatedProjectSection";
import CountrySelect from "../../components/CountrySelect";
import ErrorPanel from "../../components/ErrorPanel";
import DateInput from "../../components/inputs/DateInput";
import { DEFAULT_COUNTRY } from "../../constants/countries";
import useDefaultCountry from "../../hooks/useDefaultCountry";
import useDefaultPersonDetails from "../../hooks/useDefaultPersonDetails";
import useFeatures from "../../hooks/useFeatures";
import useFormatMessage from "../../hooks/useFormatMessage";
import HistoryLocationState from "../../types/HistoryLocationState";
import { IDParams } from "../../types/params";
import { trimAddress } from "../../utils/addressUtils";
import isValidDate from "../../utils/isValidDate";

import usePersonUpdateErrorParser from "./usePersonUpdateErrorParser";

type PersonValues = api.UpdatePersonCustomerRequest[keyof api.UpdatePersonCustomerRequest];

const MIN_DATE = new Date("1900-01-01");
const MAX_DATE = new Date();

const OnboardPerson = () => {
  const f = useFormatMessage();
  const history = useHistory<HistoryLocationState>();
  const { id } = useParams<IDParams>();
  const defaultCountry = useDefaultCountry();
  const newPersonDetails = useDefaultPersonDetails();

  const [person, setPerson] = useState<api.UpdatePersonCustomerRequest>();

  const [isPersonPosting, setPersonPosting] = useState(false);
  const [error, setError] = useState<string>();
  const [invalidNationalIdError, setInvalidNationalIdError] = useState<string>();
  const [invalidExternalIdError, setInvalidExternalIdError] = useState<string>();
  const [firstNameError, setFirstNameError] = useState<string>();
  const [lastNameError, setLastNameError] = useState<string>();
  const [isValidBirthDate, setIsValidBirthDate] = useState(false);
  const features = useFeatures();
  const parsePersonUpdateError = usePersonUpdateErrorParser();

  const createdFromProject = history.location.state?.createdFromProject;

  useEffect(() => {
    if (id) {
      api.getPersonCustomerDetails(id).then((data) => {
        const country = data.country_of_citizenship || defaultCountry;

        setPerson({
          ...data,
          address: data.address || { country },
          birth_date: data.birth_date || "",
          country_of_citizenship: country,
        });
        setIsValidBirthDate(isValidDate(data.birth_date, MIN_DATE, MAX_DATE));
      });
    } else {
      setPerson({
        first_name: "",
        last_name: "",
        birth_date: "",
        ...newPersonDetails(),
      });
    }
  }, []);

  const savePerson = (p: api.UpdatePersonCustomerRequest) => {
    setPersonPosting(true);
    const apiCall = !id
      ? api.createPersonCustomer({ ...p, project_id: createdFromProject?.id })
      : api.updatePerson(id, p);

    apiCall
      .then((updatedPerson) => {
        setError(undefined);
        setPersonPosting(false);
        history.push(`/persons/${updatedPerson.id}/screening`);
      })
      .catch((error) => {
        const { generalError, fieldsErrorsMap } = parsePersonUpdateError(error);
        setError(generalError);
        setFirstNameError(fieldsErrorsMap.get("first_name"));
        setLastNameError(fieldsErrorsMap.get("last_name"));
        setInvalidNationalIdError(fieldsErrorsMap.get("national_id"));
        setInvalidExternalIdError(fieldsErrorsMap.get("external_id"));
        if (fieldsErrorsMap.has("birth_date")) {
          setIsValidBirthDate(false);
          setError(fieldsErrorsMap.get("birth_date"));
        }
        setPersonPosting(false);
      });
  };

  const isValidNationalId = (nationalId?: string) => {
    // Empty nationalId is allowed, as it is
    // not required
    if (!nationalId) return true;

    // If national id is defined, and country of
    // citizenship is Norway, validate norwegian
    // national id by checking checksum etc.
    if (person?.country_of_citizenship === DEFAULT_COUNTRY) {
      return validateNorwegianIdNumber(String(nationalId));
    }

    // Default to true otherwise
    return true;
  };

  const isValidName = (name?: string) => name && name.trim().length > 0;

  const handlePersonChange = (key: keyof api.UpdatePersonCustomerRequest, value: PersonValues) =>
    setPerson({ ...person!, [key]: value });

  return (
    <Page title={f("person.client.details.title")}>
      {!person && <PersonSkeleton />}
      {person && (
        <Card>
          <Card.Section>
            <Stack vertical>
              <Stack>
                <TextField
                  autoFocus
                  onChange={(newValue) => handlePersonChange("first_name", newValue)}
                  onBlur={() => {
                    if (isValidName(person.first_name)) setFirstNameError(undefined);
                    else setFirstNameError(f("common.errors.required.first.name"));
                  }}
                  value={person.first_name}
                  placeholder={f("common.labels.first_name")}
                  label={f("common.labels.first_name")}
                  error={firstNameError}
                  disabled={isPersonPosting}
                  autoComplete="off"
                  requiredIndicator
                />
                <TextField
                  onChange={(newValue) => handlePersonChange("last_name", newValue)}
                  onBlur={() => {
                    if (isValidName(person.last_name)) setLastNameError(undefined);
                    else setLastNameError(f("common.errors.required.last.name"));
                  }}
                  value={person.last_name}
                  placeholder={f("common.labels.last_name")}
                  label={f("common.labels.last_name")}
                  error={lastNameError}
                  disabled={isPersonPosting}
                  autoComplete="off"
                  requiredIndicator
                />
              </Stack>
              <Stack>
                <DateInput
                  label={f("common.labels.birth_date")}
                  onChange={(v, isValid) => {
                    handlePersonChange("birth_date", v);
                    setIsValidBirthDate(isValid);
                  }}
                  value={person.birth_date}
                  maxDate={MAX_DATE}
                  minDate={MIN_DATE}
                  errorMsg={f("common.errors.invalid.birth.date")}
                  disabled={isPersonPosting}
                  requiredIndicator
                />
                <CountrySelect
                  value={person.country_of_citizenship}
                  onSelect={(newValue) => handlePersonChange("country_of_citizenship", newValue)}
                  disabled={isPersonPosting}
                  label={f("common.labels.citizenship")}
                  requiredIndicator
                />
              </Stack>
              <Stack>
                <TextField
                  onChange={(value) => {
                    handlePersonChange("national_id", value);
                  }}
                  onBlur={() => {
                    if (isValidNationalId(person.national_id)) {
                      setInvalidNationalIdError(undefined);
                    } else {
                      setInvalidNationalIdError(f("common.errors.invalid.person.national-id"));
                    }
                  }}
                  value={person.national_id}
                  placeholder={f("person.client.details.personal.number.placeholder")}
                  label={f("person.client.details.personal.number")}
                  maxLength={11}
                  minLength={11}
                  error={invalidNationalIdError}
                  disabled={isPersonPosting}
                  autoComplete="off"
                />
              </Stack>
            </Stack>
          </Card.Section>
          <Card.Section>
            <AddressForm value={person.address!} onChange={(address) => handlePersonChange("address", address)} />
          </Card.Section>

          {features.PROJECTS && createdFromProject && (
            <AssociatedProjectSection projectName={createdFromProject.name} />
          )}

          {features?.EXTERNAL_ID && (
            <Card.Section>
              <Stack>
                <TextField
                  value={person.external_id}
                  onChange={(newValue) => handlePersonChange("external_id", newValue)}
                  placeholder={f("company-form.labels.external-id")}
                  label={f("company-form.labels.external-id")}
                  disabled={isPersonPosting}
                  maxLength={50}
                  error={invalidExternalIdError}
                  autoComplete="off"
                />
              </Stack>
            </Card.Section>
          )}

          <Card.Section>
            <Stack vertical>
              {error && <ErrorPanel message={error} />}

              <Stack distribution="trailing">
                <Button
                  onClick={() =>
                    savePerson({
                      first_name: person.first_name.trim(),
                      last_name: person.last_name.trim(),
                      birth_date: person.birth_date,
                      national_id: person.national_id,
                      country_of_citizenship: person.country_of_citizenship,
                      address: trimAddress(person.address) || newPersonDetails().address,
                      external_id: person.external_id,
                    })
                  }
                  disabled={
                    isPersonPosting ||
                    !person.country_of_citizenship ||
                    !isValidName(person.first_name) ||
                    !isValidName(person.last_name) ||
                    !isValidBirthDate ||
                    !isValidNationalId(person.national_id)
                  }
                  loading={isPersonPosting}
                  primary
                >
                  {f("common.next")}
                </Button>
              </Stack>
            </Stack>
          </Card.Section>
        </Card>
      )}
    </Page>
  );
};

const PersonSkeleton = () => {
  const f = useFormatMessage();

  return (
    <Card>
      <Card.Section>
        <ProgressBar progress={85} size="small" />
      </Card.Section>

      <Card.Section>
        <Stack vertical>
          <Stack distribution="trailing">
            <Button disabled={true}>{f("default.cancel")}</Button>
            <Button disabled={true} primary>
              {f("common.next")}
            </Button>
          </Stack>
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default withRouter(OnboardPerson);
