import { useBetween } from "use-between";

import api from "../../api";
import useListParams from "../../hooks/useListParams";
import useNonInitialEffect from "../../hooks/useNonInitialEffect";
import { useBooleanURLSearchParam, useURLSearchParam } from "../../hooks/useURLSearchParam";

type CustomersListParams = Parameters<typeof api.getCustomers>[0];

enum MATCH_FILTERS {
  unresolved = "unresolved",
  isPep = "isPep",
  isSanctioned = "isSanctioned",
}

const useCustomerListParams = () => {
  const {
    order,
    setOrder,
    page,
    setPage,
    limit,
    setLimit,
    q,
    setQ,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    searchParams: baseSearchParams,
    isEmptySearch: baseIsEmptySearch,
    resetAllFilters: baseResetAllFilters,
  } = useListParams();

  const [status, setStatus] = useURLSearchParam("status", "all");
  const [customerType, setCustomerType] = useURLSearchParam("customerType", "all");
  const [risk, setRisk] = useURLSearchParam("risk", "all");
  const [responsibleUserId, setResponsibleUserId] = useURLSearchParam("responsibleUserId", "all");
  const [isArchived, setIsArchived] = useBooleanURLSearchParam("isArchived");
  const [unresolved, setUnresolved] = useBooleanURLSearchParam(MATCH_FILTERS.unresolved);
  const [isPep, setIsPep] = useBooleanURLSearchParam(MATCH_FILTERS.isPep);
  const [isSanctioned, setIsSanctioned] = useBooleanURLSearchParam(MATCH_FILTERS.isSanctioned);
  const [screeningType, setScreeningType] = useURLSearchParam("screeningType", "all");
  const [countryCode, setCountryCode] = useURLSearchParam<api.CountryEnum | "all">("countryCode", "all");

  useNonInitialEffect(() => {
    setPage(0);
  }, [
    status,
    customerType,
    risk,
    responsibleUserId,
    isArchived,
    unresolved,
    isPep,
    isSanctioned,
    screeningType,
    countryCode,
  ]);

  const matchFilters = Object.entries({
    unresolved,
    isPep,
    isSanctioned,
  })
    .filter(([value, key]) => key)
    .map(([value]) => value);

  const handleMatchFiltersChange = (filters: string[]) => {
    // Check the filtered items to toggle them
    setUnresolved(filters.includes(MATCH_FILTERS.unresolved));
    setIsPep(filters.includes(MATCH_FILTERS.isPep));
    setIsSanctioned(filters.includes(MATCH_FILTERS.isSanctioned));
  };

  const searchParams: CustomersListParams = {
    ...baseSearchParams,
    status,
    type: customerType,
    riskLevel: risk,
    isArchived: isArchived ? "1" : "",
    hasUnresolvedCases: unresolved ? "1" : "",
    isPep: isPep ? "1" : "",
    isSanctioned: isSanctioned ? "1" : "",
    screeningType: screeningType as api.ScreeningType,
    countryCode: countryCode as api.CountryEnum,
  };

  if (responsibleUserId !== "all") {
    searchParams.responsibleId = responsibleUserId;
  }

  const isEmptySearch =
    baseIsEmptySearch &&
    risk === "all" &&
    customerType === "all" &&
    status === "all" &&
    responsibleUserId === "all" &&
    screeningType === "all" &&
    countryCode === "all";

  const resetAllFilters = () => {
    baseResetAllFilters();
    setStatus("all");
    setCustomerType("all");
    setRisk("all");
    setResponsibleUserId("all");
    setIsArchived(false);
    setIsPep(false);
    setUnresolved(false);
    setIsSanctioned(false);
    setScreeningType("all");
    setCountryCode("all");
  };

  return {
    order,
    setOrder,
    page,
    setPage,
    limit,
    setLimit,
    q,
    setQ,
    status,
    setStatus,
    customerType,
    setCustomerType,
    risk,
    setRisk,
    screeningType,
    setScreeningType,
    isArchived,
    setIsArchived,
    unresolved,
    setUnresolved,
    isPep,
    setIsPep,
    isSanctioned,
    setIsSanctioned,
    responsibleUserId,
    setResponsibleUserId,
    countryCode,
    setCountryCode,
    createdRange,
    setCreatedRange,
    createdAfter,
    setCreatedAfter,
    createdBefore,
    setCreatedBefore,
    searchParams,
    isEmptySearch,
    matchFilters,
    handleMatchFiltersChange,
    resetAllFilters,
  };
};

// in order to share the state of the filters between CustomersListPage, CustomersListTable and CustomersListFilters
// wrap the hook using useBetween - which creates a single instance of the hook and returns it
const useSharedCustomerListParams = () => useBetween(useCustomerListParams);

export default useSharedCustomerListParams;
