import * as React from "react";
import { Toast } from "@shopify/polaris";

import useSnackbar from "../hooks/useSnackbar";

const Snackbar = () => {
  const [snackbar, clearSnackbar] = useSnackbar();

  if (!snackbar) {
    return null;
  }

  return <Toast content={snackbar} onDismiss={clearSnackbar} />;
};

export default Snackbar;
